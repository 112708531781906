import axios from 'axios'

export const RECEIVE_TARGET = 'RECEIVE_TARGET'
export const RECEIVE_TARGETS = 'RECEIVE_TARGETS'
export const REMOVE_TARGET = 'REMOVE_TARGET'

export const fetchTargetsForMapAsIcs = (uri) => axios.get(`/target?format=ics&map=${uri}`).then(({data}) => data)

export const fetchTargetForNode = (nodeUri) => (dispatch, getState) =>
    axios.get(`/node/${nodeUri}/target`).then(({data}) => {
        dispatch(receiveTarget(data))

        return data
    })

export const storeTarget = (nodeUri, data) => (dispatch, getState) =>
    axios.post(`/node/${nodeUri}/target`, data).then(({data}) => {
        dispatch(receiveTarget(data))

        return data
    })

export const updateTarget = (id, data) => (dispatch, getState) =>
    axios.put(`/target/${id}`, data).then(({data}) => {
        dispatch(receiveTarget(data))

        return data
    })

export const fetchTargetsForMap = (mapUri) => (dispatch, getState) =>
    axios.get(`/target?map=${mapUri}`).then(({data}) => {
        dispatch(receiveTargets(data))

        return data
    })

export const fetchTargetsForNode = (nodeId) => (dispatch, getState) =>
    axios.get(`/target?node=${nodeId}`).then(({data}) => dispatch(receiveTargets(data)))

export const deleteTarget = (id) => (dispatch, getState) =>
    axios.delete(`/target/${id}`).then(() => dispatch(removeTarget(id)))

export const storeTargetData = (targetId, data) => (dispatch, getState) =>
    axios.post(`/target/${targetId}/data`, data).then(({data}) => dispatch(receiveTarget(data)))

export const targetsForMap = (targets, map) => Object.values(targets).filter(({node_id}) => map ? map.nodes_index.includes(node_id) : false)

export const targetsForNode = (targets, nodeId) => Object.values(targets).filter(({node_id}) => node_id === nodeId)

const receiveTarget = (target) => ({
    type: RECEIVE_TARGET,
    payload: {
        target,
    },
})

const receiveTargets = (targets) => ({
    type: RECEIVE_TARGETS,
    payload: {
        targets,
    },
})

const removeTarget = (id) => ({
    type: REMOVE_TARGET,
    payload: {
        id,
    },
})

export const getTargetPerformance = (map, config) => axios.get('/target', {
    params: {
        format: 'performance',
        map,
        ...mapConfigForRequest(config),
    },
}).then(({data}) => data)

export const getTargetTransformation = (map, config) => axios.get('/target', {
    params: {
        format: 'transformation',
        map,
        ...mapConfigForRequest(config),
    },
}).then(({data}) => data)

const mapConfigForRequest = (config) => Object.keys(config).filter((item) => ['labels', 'scopes', 'users'].includes(item)).reduce((carry, key, index) => {
    if (Array.isArray(config[key])) {
        config[key].forEach((item, index) => {
            carry[`config[${key}][${index}]`] = item
        })
    }

    return carry
}, {})

export const userCanManageTargets = (user, map) => Array.isArray(user.teams_index[map.team_uri]) ? user.teams_index[map.team_uri].includes('MANAGE_TARGETS') : false
