import CircularProgress from '@material-ui/core/CircularProgress'
import Close from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputBase from '@material-ui/core/InputBase'
import LaunchIcon from '@material-ui/icons/Launch'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'
import PinDropIcon from '@material-ui/icons/PinDrop'
import PropTypes from 'prop-types'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { VariableSizeList } from 'react-window'
import { Link } from 'react-router-dom'
import { generateMapLinks } from '../actions/map'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Container from './Shared/Container'
import ItemContainer from '../containers/Goal/ItemContainer'
import LoadingButton from './LoadingButton'
import Modal from './Shared/Modal'
import PageLoadingIndicator from './PageLoadingIndicator'
import UserIcon from './UserIcon'

const Search = ({currentTerm, isBusy, isBusyGoals, isBusyMaps, goals, onChange, onLoad, maps, onClickNext, remainingGoals, remainingMaps, showResults}) => {
    const state = useSelector((state) => state)

    const [ windowHeight, setWindowHeight ] = React.useState(0)

    const classes = useStyles({showResults})
    const theme = useTheme()

    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    React.useLayoutEffect(() => {
        const measure = () => window.requestAnimationFrame(() => setWindowHeight(window.innerHeight))
        measure()

        window.addEventListener('resize', measure)

        return () => window.removeEventListener('resize', measure)
    }, [window])

    const GoalRow = ({index, style}) => {
        const handleClickNext = () => onClickNext('nodes')

        return goals[index] ? (
            <ListItem ContainerComponent="div" ContainerProps={{className: classes.itemContainer, style}} className={classes.item} disableGutters={mobile} key={index} dense>
                <ListItemAvatar>
                    <UserIcon userId={goals[index].assigned_user ? goals[index].assigned_user.id : null} />
                </ListItemAvatar>
                <ListItemText
                    primary={(
                        <Typography variant="body2" noWrap>{goals[index].name}</Typography>
                    )}
                    secondary={(
                        <Typography color="textSecondary" variant="body2" noWrap>{goals[index].map_name}</Typography>
                    )} />
                <ListItemSecondaryAction className={classes.actions}>
                    <Tooltip title="Open goal">
                        <IconButton component={Link} to={`/m/${goals[index].map_uri}/g/${goals[index].uri}`}>
                            <LaunchIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Show on goal map">
                        <IconButton component={Link} to={{pathname: `/m/${goals[index].map_uri}`, state: {focalGoalUri: goals[index].uri}}}>
                            <PinDropIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
        ) : (
            <div className={classes.nextPageContainer} style={style}>
                <LoadingButton busy={isBusyGoals} color="primary" onClick={handleClickNext} hideWhenBusy>Load {remainingGoals} more goal{remainingGoals > 1 && 's'}&hellip;</LoadingButton>
            </div>
        )
    }

    const MapRow = ({index, style}) => {
        const handleClickNext = () => onClickNext('maps')

        return maps[index] ? (
            <ListItem ContainerComponent="div" ContainerProps={{className: classes.itemContainer, style}} className={classes.item} disableGutters={mobile} key={index} dense>
                <ListItemAvatar>
                    <UserIcon userId={maps[index].creator ? maps[index].creator.id : null} />
                </ListItemAvatar>
                <ListItemText
                    primary={(
                        <Typography variant="body2" noWrap>{maps[index].name}</Typography>
                    )}
                    secondary={(
                        <Typography color="textSecondary" variant="body2" noWrap>{maps[index].team_name}</Typography>
                    )} />
                <ListItemSecondaryAction className={classes.actions}>
                    {generateMapLinks(state, maps[index].uri, 'm', true).map(({Icon, pathname, state = {}, tooltip}, subIndex) => (
                        <Tooltip key={`ml-${index}-${subIndex}`} title={tooltip}>
                            <IconButton component={Link} to={{pathname, state}}>
                                <Icon />
                            </IconButton>
                        </Tooltip>
                    ))}
                </ListItemSecondaryAction>
            </ListItem>
        ) : (
            <div className={classes.nextPageContainer} style={style}>
                <LoadingButton busy={isBusyMaps} color="primary" onClick={handleClickNext} hideWhenBusy>Load {remainingMaps} more goal map{remainingMaps > 1 && 's'}&hellip;</LoadingButton>
            </div>
        )
    }

    const rowHeight = mobile ? 108 : 60
    const maxRows = Math.max(Math.min(Math.floor(((windowHeight - 153 - theme.spacing(11)) / rowHeight) / 2), 4), 1)
    const getGoalsItemSize = (index) => goals[index] ? rowHeight : 44
    const getMapsItemSize = (index) => maps[index] ? rowHeight : 44

    const goalsRef = React.createRef()
    React.useEffect(() => {
        if (goalsRef.current) {
            goalsRef.current.resetAfterIndex(0)
        }
    }, [goals])

    const mapsRef = React.createRef()
    React.useEffect(() => {
        if (mapsRef.current) {
            mapsRef.current.resetAfterIndex(0)
        }
    }, [maps])

    return (
        <Modal>
            <Container width={theme.breakpoints.values.md} modal>
                <InputBase
                    autoComplete="off"
                    className={classes.input}
                    endAdornment={(
                        <InputAdornment position="end">
                            {isBusy && <CircularProgress color="inherit" size={24} />}
                        </InputAdornment>
                    )}
                    onChange={onChange}
                    placeholder="Start typing to search&hellip;"
                    startAdornment={(
                        <InputAdornment position="start">
                            <SearchIcon color="inherit" />
                        </InputAdornment>
                    )}
                    type="search"
                    value={currentTerm}
                    autoFocus fullWidth />
                {showResults ? (
                    Boolean(goals.length || maps.length) ? (
                        <React.Fragment>
                            <ListSubheader className={classes.subheader} color="primary" component="div" disableGutters={mobile}>Goals</ListSubheader>
                            {goals.length ? (
                                <VariableSizeList height={Math.min(maxRows, goals.length) * rowHeight} itemCount={goals.length + (remainingGoals ? 1 : 0)} itemSize={getGoalsItemSize} ref={goalsRef} width="100%">
                                    {GoalRow}
                                </VariableSizeList>
                            ) : (
                                isBusy ? (
                                    <PageLoadingIndicator size={30} />
                                ) : (
                                    <Typography className={classes.emptyText} variant="subtitle2">We couldn&rsquo;t find any goals matching your search query.</Typography>
                                )
                            )}
                            <Divider className={classes.divider} light />
                            <ListSubheader className={classes.subheader} color="primary" component="div" disableGutters={mobile}>Goal Maps</ListSubheader>
                            {maps.length ? (
                                <VariableSizeList height={Math.min(maxRows, maps.length) * rowHeight} itemCount={maps.length + (remainingMaps ? 1 : 0)} itemSize={getMapsItemSize} ref={mapsRef} width="100%">
                                    {MapRow}
                                </VariableSizeList>
                            ) : (
                                isBusy ? (
                                    <PageLoadingIndicator size={30} />
                                ) : (
                                    <Typography className={classes.emptyText} variant="subtitle2">We couldn&rsquo;t find any goal maps matching your search query.</Typography>
                                )
                            )}
                        </React.Fragment>
                    ) : (
                        isBusy ? (
                            <PageLoadingIndicator className={classes.emptyState} size={30} />
                        ) : (
                            <Typography className={`${classes.emptyText} ${classes.emptyState}`} variant="subtitle2">We couldn&rsquo;t find anything matching your search query.</Typography>
                        )
                    )
                ) : null}
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    input: ({showResults}) => showResults ? ({
        borderRadius: 4,
        border: 'solid 1px rgba(0, 0, 0, .23)',
        padding: '11px 14px',
        marginBottom: theme.spacing(2),
    }) : {},
    itemContainer: {
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '100%',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'minmax(0, 1fr) auto',
        },
    },
    item: {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    actions: {
        display: 'flex',
        height: 48,
        position: 'relative',
        right: 'auto',
        top: 'auto',
        transform: 'none',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 40 + theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
        },
    },
    nextPageContainer: {
        alignItems: 'center',
        display: 'flex',
        paddingTop: theme.spacing(1),
        justifyContent: 'center',
    },
    divider: {
        marginTop: theme.spacing(1),
    },
    emptyText: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    emptyState: {
        marginTop: theme.spacing(2),
    },
}))

Search.defaultProps = {
    isBusy: false,
    remainingGoals: 0,
    remainingMaps: 0,
    showResults: false,
}

Search.propTypes = {
    currentTerm: PropTypes.string.isRequired,
    goals: PropTypes.array.isRequired,
    isBusy: PropTypes.bool,
    isBusyGoals: PropTypes.bool,
    isBusyMaps: PropTypes.bool,
    maps: PropTypes.array.isRequired,
    onClickNext: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    remainingGoals: PropTypes.number,
    remainingMaps: PropTypes.number,
    showResults: PropTypes.bool,
}

export default Search
