import React from 'react'
import { connect } from 'react-redux'

import NodeOwner from '../../components/Node/NodeOwner'
import { fetchUser } from '../../actions/user'

class NodeOwnerContainer extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            fetching: false,
        }
    }

    componentDidMount () {
        this._fetchUserIfNeeded()
    }

    componentDidUpdate (prevProps) {
        if (owningUserId(this.props) !== owningUserId(prevProps)) {
            this._fetchUserIfNeeded(this.props)
        }
    }

    render () {
        return (
            <NodeOwner busy={this.state.fetching} label={owningLabel(this.props)} node={this.props.node} userId={owningUserId(this.props)} />
        )
    }

    _fetchUserIfNeeded = () => {
        if (! userOwnsNode(this.props) || owningUser(this.props)) {
            return
        }

        this.setState({
            fetching: true,
        })

        this.props.fetchUser(owningUserId(this.props)).then(() => this.setState({
            fetching: false,
        }))
    }
}

const userOwnsNode = ({node}) => node.assigned_user
const owningLabel = ({node}) => null
const owningUser = ({node, users}) => node.assigned_user ? users[node.assigned_user] : null
const owningUserId = ({node}) => node.assigned_user ? node.assigned_user.id : null

const mapStateToProps = (state, ownProps) => ({
    node: state.nodes[ownProps.id],
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchUser: (id) => dispatch(fetchUser(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeOwnerContainer)
