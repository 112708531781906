import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Person from '@material-ui/icons/Person'
import React from 'react'

import UserIcon from '../UserIcon'
import { COLOR_GREY, COLOR_GREY_DARK, COLOR_GREY_LIGHTER, COLOR_WHITE } from '../../constants/colors'

class NodeOwner extends React.Component {
    render () {
        if (! this.props.node) {
            return (
                <Avatar style={styles.icon} />
            )
        }

        if (this.props.busy) {
            return (
                <Avatar style={styles.icon} />
            )
        }

        if (this.props.userId) {
            return (
                <UserIcon size={32} userId={this.props.userId} />
            )
        }

        if (this.props.label) {
            return (
                <Avatar style={styles.icon}>
                    <span style={styles.label}>{this.props.label}</span>
                </Avatar>
            )
        }

        return (
            <Avatar style={styles.iconLight}>
                <Person color="action" />
            </Avatar>
        )
    }
}

const styles = {
    label: {
        color: 'rgba(255, 255, 255, .7)',
        fontSize: 12,
    },
    icon: {
        backgroundColor: COLOR_GREY_DARK,
        height: 32,
        width: 32,
    },
    iconLight: {
        backgroundColor: COLOR_GREY_LIGHTER,
        height: 32,
        width: 32,
    },
    loadingIcon: {
        color: COLOR_WHITE,
    },
}

export default NodeOwner
