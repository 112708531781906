import CloseIcon from '@material-ui/icons/Close'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PropTypes from 'prop-types'
import React from 'react'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import { generateMapLinks, mapFromUri } from '../../actions/map'
import { prompt } from '../../actions/ui'

const ActionsMenu = ({className, history, location, match, pristine, prompt, staticContext, uri, ...rest}) => {
    const state = useSelector((state) => state)

    const [open, setOpen] = React.useState(false)

    const classes = useStyles()

    const confirmLink = (pathname) => {
        if (pristine) {
            return handleLink(pathname)
        }

        prompt('Discard all changes to this goal map?', 'Discarding will lose all changes you\'ve made to this goal map since it was last saved.', () => handleLink(pathname))
    }

    const handleClose = () => setOpen(false)
    const handleLink = (pathname, state = {}) => history.push(pathname, state)
    const handleOpen = () => setOpen(true)

    return (
        <SpeedDial ariaLabel="Map actions" className={`${classes.container} ${className}`} direction="up" icon={<SpeedDialIcon icon={<MoreHorizIcon />} openIcon={<CloseIcon />} />} onClose={handleClose} onOpen={handleOpen} open={open} {...rest}>
            {generateMapLinks(state, uri, match.params.view).filter(({pathname}) => pathname !== location.pathname).map(({Icon, pathname, state = {}, tooltip}, index) => {
                const handleClick = () => state.modal ? handleLink(pathname, state) : confirmLink(pathname)

                return (
                    <SpeedDialAction
                        icon={<Icon />}
                        key={`aml-${index}`}
                        onClick={handleClick}
                        tooltipTitle={tooltip} />
                )
            })}
        </SpeedDial>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        zIndex: 101,
    },
}))

ActionsMenu.defaultProps = {
    pristine: true,
}

ActionsMenu.propTypes = {
    pristine: PropTypes.bool,
    uri: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    prompt: (title, message, confirmMethod, dismissMethod, options = {}) => dispatch(prompt(title, message, confirmMethod, dismissMethod, options)),
})

export default withRouter(connect(null, mapDispatchToProps)(ActionsMenu))
