import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { DragSource, DropTarget } from 'react-dnd'
import { findDOMNode } from 'react-dom'

class ReorderableListItem extends React.Component {
    render () {
        const { children, connectDragPreview, connectDragSource, connectDropTarget, dropItem, leftIcon, isDragging, style, index, moveReport, primaryText, renameReport, rightIconButton, secondaryText, source, moveItem, listIdentifier, ...rest } = this.props

        return connectDragPreview(connectDropTarget(
            <div {...rest} style={{...style, ...styles.container}}>
                {leftIcon ? (
                    connectDragSource(<div style={styles.moveIcon}>
                        {leftIcon}
                    </div>)
                ) : null}
                <Typography style={styles.text}>{primaryText}</Typography>
                {rightIconButton ? (
                    <div>
                        {rightIconButton}
                    </div>
                ) : null}
            </div>
        ))
    }
}

const itemSource = {
    beginDrag: ({index}) => ({
        index: index
    }),
    endDrag: ({dropItem, source}) => dropItem && dropItem(source),
}

const itemTarget = {
    hover:  ({index, moveItem, source}, monitor, component) => {
        const dragIndex = monitor.getItem().index
        const hoverIndex = index

        // don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
        }

        // Time to actually perform the action
        moveItem(source, dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,  but it's good here for the sake of performance to avoid expensive index searches.
        monitor.getItem().index = hoverIndex
    }
}

const dndType = ({listIdentifier}) => listIdentifier || 'reorderableListItem'

const connectTarget = connect => ({
    connectDropTarget: connect.dropTarget()
})

const connectSource = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
})

const styles = {
    container: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: 16,
        marginTop: 16,
    },
    moveIcon: {
        cursor: 'move',
    },
    text: {
        flex: 1,
        paddingLeft: 12,
        paddingRight: 12,
    },
}

export default (
    DropTarget(dndType, itemTarget, connectTarget)(
        DragSource(dndType, itemSource, connectSource)(
            ReorderableListItem
        )
    )
)
