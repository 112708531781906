import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

import Overlay from './Overlay'

const Modal = ({history, onEscape, ...rest}) => (
    <Overlay
        onEscape={onEscape || history.goBack}
        {...rest} />
)

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    onEscape: PropTypes.func,
}

export default withRouter(Modal)
