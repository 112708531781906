import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import Reorder from '@material-ui/icons/Reorder'
import { Link } from 'react-router-dom'

import Modal from '../Shared/Modal'
import { ReorderableList, ReorderableListItem } from '../../includes/ReorderableList'

const ResourcesManage = ({mapUri, nodeUri, onDelete, onDropItem, onMoveItem, resources, view}) => (
    <Modal>
        <DialogTitle>Manage resources</DialogTitle>
        <DialogContent>
            <DialogContentText>Manage this goal&rsquo;s available resources.</DialogContentText>
            <ReorderableList>
                {resources.map(({id, label}, i) => (
                    <ReorderableListItem
                        dropItem={onDropItem}
                        index={i}
                        key={`resource-${id}`}
                        leftIcon={(
                            <IconButton disabled>
                                <Reorder />
                            </IconButton>
                        )}
                        moveItem={onMoveItem}
                        primaryText={label}
                        rightIconButton={(
                            <React.Fragment>
                                <IconButton component={Link} to={{pathname: `/${view}/${mapUri}/g/${nodeUri}/resource/${id}/edit`, state: {modal: true}}}>
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={onDelete} value={id}>
                                    <Delete />
                                </IconButton>
                            </React.Fragment>
                        )}
                        source="resourceIds" />
                ))}
            </ReorderableList>
            <div style={styles.footerContainer}>
                <Link to={{pathname: `/${view}/${mapUri}/g/${nodeUri}/resource/create`, state: {modal: true}}}>Add a new resource to this goal</Link>
            </div>
        </DialogContent>
    </Modal>
)

ResourcesManage.propTypes = {
    mapUri: PropTypes.string.isRequired,
    nodeUri: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDropItem: PropTypes.func.isRequired,
    onMoveItem: PropTypes.func.isRequired,
    resources: PropTypes.array.isRequired,
    view: PropTypes.string.isRequired,
}

const styles = {
    footerContainer: {
        marginTop: 24,
    },
}

export default ResourcesManage
