import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../actions/stashes'

const SetLabel = memo(({description: defaultDescription = '', nodeUri}) => {
    const dispatch = useDispatch()

    const [ description, setDescription ] = useState(defaultDescription)

    const _handleChange = (event) => {
        setDescription(event.target.value)

        dispatch(stashTarget(nodeUri, {
            description: event.target.value,
        }))
    }

    return (
        <TextField
            label="Label"
            onChange={_handleChange}
            value={description}
            fullWidth />
    )
})

SetLabel.propTypes = {
    description: PropTypes.string,
    nodeUri: PropTypes.string.isRequired,
}

export default SetLabel
