export const STASH_MAP = 'STASH_MAP'
export const UNSTASH_MAP = 'UNSTASH_MAP'

const handleStashMap = (uri, data) => ({
    type: STASH_MAP,
    payload: {
        uri, data,
    },
})

const handleUnstashMap = (uri) => ({
    type: UNSTASH_MAP,
    payload: {
        uri,
    }
})

export const stashMap = (uri, data) => handleStashMap(uri, data)
export const unstashMap = (uri) => handleUnstashMap(uri)

export const STASH_MAP_LAYOUT = 'STASH_MAP_LAYOUT'
export const UNSTASH_MAP_LAYOUT = 'UNSTASH_MAP_LAYOUT'

const handleStashMapLayout = (uri, data) => ({
    type: STASH_MAP_LAYOUT,
    payload: {
        uri, data,
    },
})

const handleUnstashMapLayout = (uri) => ({
    type: UNSTASH_MAP_LAYOUT,
    payload: {
        uri,
    }
})

export const stashMapLayout = (uri, data) => handleStashMapLayout(uri, data)
export const unstashMapLayout = (uri) => handleUnstashMapLayout(uri)

export const STASH_TARGET = 'STASH_TARGET'
export const UNSTASH_TARGET = 'UNSTASH_TARGET'

const handleStashTarget = (nodeUri, data) => ({
    type: STASH_TARGET,
    payload: {
        nodeUri, data,
    },
})

const handleUnstashTarget = (nodeUri) => ({
    type: UNSTASH_TARGET,
    payload: {
        nodeUri,
    }
})

export const stashTarget = (nodeUri, data) => handleStashTarget(nodeUri, data)
export const unstashTarget = (nodeUri) => handleUnstashTarget(nodeUri)
