import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import PinIcon from 'mdi-material-ui/Pin'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import Typography from '@material-ui/core/Typography'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import UserIcon from '../UserIcon'
import { COLOR_BRAND_PRIMARY, COLOR_GREY_LIGHTER, COLOR_GREY_LIGHTEST } from '../../constants/colors'
import { getAttachmentToken } from '../../actions/attachment'
import { toggleCommentPinned } from '../../actions/comment'

const Item = memo(({className, comment, created_at, created_by, id, pinned, resources}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const user = useSelector(({users}) => users[created_by])

    const handleClick = () => dispatch(toggleCommentPinned(id))

    return (
        <div className={className}>
            <div className={classes.header}>
                <div className={classes.headerWrapper}>
                    <UserIcon
                        userId={user.id} />
                    <div className={classes.meta}>
                        <Typography variant="subtitle2" noWrap>{user.name}</Typography>
                        <Typography variant="caption" noWrap>{formatDistanceToNow(parseISO(created_at), {addSuffix: true})}</Typography>
                    </div>
                </div>
                <IconButton color={pinned ? 'primary' : 'secondary'} className={classes.pin} onClick={handleClick}>
                    <PinIcon color="inherit" />
                </IconButton>
            </div>
            <Typography className={classes.comment} component={ReactMarkdown} source={comment} variant="body2" />
            {Boolean(resources.length) && (
                <div className={classes.resources}>
                    {resources.map(({id, ...rest}) => (
                        <Resource key={id} {...rest} />
                    ))}
                </div>
            )}
        </div>
    )
})

const Resource = memo(({resourceable, ...rest}) => {
    const classes = useStyles()

    const handleClick = () => getAttachmentToken(resourceable.id).then((token) => window.location = `${process.env.REACT_APP_APP_URL}/${process.env.REACT_APP_RESOURCE_URL}/${token}`)

    return resourceable.type === 'media' ? (
        <ButtonBase className={classes.resource} component="a" href={resourceable.url} target="_blank">
            <Avatar className={classes.avatar} src={resourceable.thumb} variant="square" />
            <div className={classes.resourceText}>
                {Boolean(resourceable.meta_title) && (
                    <Typography variant="subtitle2" noWrap>{resourceable.meta_title}</Typography>
                )}
                {Boolean(resourceable.meta_description) && (
                    <Typography className={classes.resourceDescription} variant="body2">{resourceable.meta_description}</Typography>
                )}
            </div>
        </ButtonBase>
    ) : (
        <ButtonBase className={classes.resource} onClick={handleClick}>
            <Avatar className={classes.avatar} variant="square">
                <CloudDownloadIcon />
            </Avatar>
            <div className={classes.resourceText}>
                {Boolean(resourceable.name) && (
                    <Typography variant="subtitle2" noWrap>{resourceable.name}</Typography>
                )}
                {Boolean(resourceable.human_size) && (
                    <Typography className={classes.resourceDescription} variant="body2">{resourceable.human_size}</Typography>
                )}
            </div>
        </ButtonBase>
    )
})

const useStyles = makeStyles((theme) => ({
    header: {
        alignItems: 'flex-start',
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    headerWrapper: {
        alignItems: 'stretch',
        display: 'flex',
        flex: 1,
        justifyContent: 'stretch',
    },
    meta: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
    },
    pin: {
        marginRight: theme.spacing(-1),
        marginTop: theme.spacing(-1),
    },
    comment: {
        overflowWrap: 'break-word',
        wordBreak: 'break-word',

        '&> *': {
            marginBottom: theme.spacing(1),
        },
    },
    resources: {
        display: 'grid',
        gridRowGap: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    resource: {
        alignItems: 'flex-start',
        backgroundColor: COLOR_GREY_LIGHTEST,
        display: 'flex',
        minWidth: 0,
        padding: theme.spacing(1),
        textAlign: 'left',
        transition: 'background-color .2s',

        '&:hover': {
            backgroundColor: COLOR_GREY_LIGHTER,
            textDecoration: 'none',
        },
    },
    resourceText: {
        justifyContent: 'flex-start',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minWidth: 0,
    },
    resourceDescription: {
        fontSize: theme.typography.pxToRem(13),
    },
    avatar: {
        alignSelf: 'center',
        backgroundColor: COLOR_BRAND_PRIMARY,
        height: theme.spacing(8),
        marginRight: theme.spacing(1),
        width: theme.spacing(8),
    },
}))

Item.defaultProps = {
    className: '',
    pinned: false,
}

Item.propTypes = {
    className: PropTypes.string,
    comment: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    created_by: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    pinned: PropTypes.bool,
    resources: PropTypes.array.isRequired,
}

Resource.propTypes = {
    resourceable: PropTypes.shape({
        type: PropTypes.string.isRequired,
    }).isRequired,
}

export default Item
