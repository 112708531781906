import React from 'react'
import { connect } from 'react-redux'

import CommentEdit from '../../components/Comment/CommentEdit'
import { updateComment } from '../../actions/comment'

class CommentEditContainer extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            isBusy: false,
            isValid: false,
        }
    }

    render () {
        return (
            <CommentEdit
                comment={this.props.comment.comment}
                isBusy={this.state.isBusy}
                isValid={this.state.isValid}
                onInvalid={this._handleInvalid}
                onClose={this._handleClose}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid} />
        )
    }

    _handleClose = () => {
        if (this.props.location.state && this.props.location.state.modal) {
            this.props.history.goBack()
        } else {
            this.props.history.push('/')
        }
    }

    _handleSubmit = (data, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.update({comment: data.comment}).then(() => {
            resetForm()

            this.setState({
                isBusy: false,
                isValid: false,
            })

            this._handleClose()
        })
    }

    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const mapStateToProps = (state, ownProps) => ({
    comment: state.comments[ownProps.match.params.comment],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    update: (data) => dispatch(updateComment(ownProps.match.params.comment, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditContainer)
