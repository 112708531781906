import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Divider from '@material-ui/core/Divider'
import FunctionsIcon from '@material-ui/icons/Functions'
import React, { Fragment, cloneElement, memo } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { fade, makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import { COLOR_GREY_LIGHTER } from '../../../constants/colors'
import { stashTarget } from '../../../actions/stashes'

const SelectService = memo(({nodeUri, service}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const _handleSelect = (label) => {
        dispatch(stashTarget(nodeUri, {
            service: {
                ...service,
                label,
            },
        }))
    }

    const _handleClickManual = () => _handleSelect('internal.manual')

    return (
        <Fragment>
            <div className={classes.items}>
                {getServices().map(({key, description, icon, name}) => {
                    const handleClick = () => _handleSelect(key)

                    return (
                        <ButtonBase className={`${classes.item} ${key === service.label ? 'selected' : ''}`} key={`service-${key}`} onClick={handleClick}>
                            {cloneElement(typeof icon === 'string' ? <img src={icon} /> : icon, {className: classes.icon})}
                            <Typography variant="subtitle2">{name}</Typography>
                            <Typography variant="caption">{description}</Typography>
                        </ButtonBase>
                    )
                })}
            </div>
            <Typography variant="subtitle2" paragraph>Looking for a use&#8209;case we don&rsquo;t cover yet?</Typography>
            <Tooltip title="Track your target by manually updating its value.">
                <Button color="primary" className={service.label === 'internal.manual' ? classes.buttonSelected : ''} onClick={_handleClickManual} variant="outlined">Manual Target</Button>
            </Tooltip>
        </Fragment>
    )
})

const getServices = () => ([
    {
        key: 'google.analytics',
        name: 'Google Analytics',
        description: 'Track your target against a Google Analytics metric.',
        icon: '//s3-eu-west-1.amazonaws.com/goalatlas.assets/targets/google_analytics_logo.png',
    },
    {
        key: 'google.sheets',
        name: 'Google Sheets',
        description: 'Track your target against a value in a Google Sheets document.',
        icon: '//s3-eu-west-1.amazonaws.com/goalatlas.assets/targets/google_sheets_logo.png',
    },
    {
        key: 'internal.aggregate',
        name: 'Aggregate',
        description: 'Track against the aggregated value of other targets.',
        icon: <FunctionsIcon />
    }
])

const useStyles = makeStyles((theme) => ({
    items: {
        display: 'grid',
        gridColumnGap: theme.spacing(2),
        gridRowGap: theme.spacing(2),
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        marginBottom: theme.spacing(4),
    },
    item: {
        alignItems: 'flex-start',
        borderColor: COLOR_GREY_LIGHTER,
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        transition: theme.transitions.create('background-color', {duration: theme.transitions.duration.short}),
        textAlign: 'left',

        '&:hover, &.selected': {
            backgroundColor: COLOR_GREY_LIGHTER,
        },
    },
    buttonSelected: {
        backgroundColor: fade(theme.palette.primary.main, theme.palette.action.activatedOpacity),

        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
    },
    icon: {
        alignSelf: 'center',
        color: theme.palette.primary.main,
        fontSize: 80,
        height: 120,
        marginBottom: theme.spacing(2),
        maxWidth: '100%',
        objectFit: 'contain',
    },
}))

SelectService.propTypes = {
    nodeUri: PropTypes.string.isRequired,
    service: PropTypes.shape({
        label: PropTypes.oneOf([
            'google.analytics', 'google.sheets', 'internal.aggregate', 'internal.manual',
        ]),
    }),
}

export default SelectService
