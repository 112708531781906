import Formsy from 'formsy-react'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Container from './Shared/Container'
import FormsyText from './formsy/FormsyText'
import Heading from './Shared/Heading'
import LoadingButton from './LoadingButton'
import Modal from './Shared/Modal'
import PageLoadingIndicator from './PageLoadingIndicator'

const TeamForm = ({canSubmit, hasFetched, isBusy, isReady, onChange, onInvalid, onSubmit, onValid, saveLabel, team, title, ...rest}) => {
    const classes = useStyles()

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>{title}</Heading>
                        <Formsy onChange={onChange} onInvalid={onInvalid} onValid={onValid} onValidSubmit={onSubmit}>
                            <fieldset>
                                <FormsyText
                                    defaultValue={team.name}
                                    label="Name"
                                    name="name"
                                    validationError="You must provide a name"
                                    fullWidth required />
                            </fieldset>
                            <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                {saveLabel}
                            </LoadingButton>
                        </Formsy>
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

TeamForm.defaultProps = {
    canSubmit: false,
    isBusy: false,
    isReady: true,
    team: {},
}

TeamForm.propsTypes = {
    canSubmit: PropTypes.bool,
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onInvalid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
    saveLabel: PropTypes.string.isRequired,
    team: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4),
    },
}))

export default TeamForm
