import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dropzone from 'react-dropzone'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import React from 'react'

import FormsyText from '../formsy/FormsyText'
import Modal from '../Shared/Modal'
import { COLOR_BRAND_PRIMARY, COLOR_GREY_LIGHT, COLOR_RED } from '../../constants/colors'

const Profile = ({canSubmit, isBusy, onChange, onDrop, onInvalid, onValid, onSubmit, profilePhotoPreview, user}) => (
    <Modal>
        <DialogTitle>Profile</DialogTitle>
        <Formsy onChange={onChange} onInvalid={onInvalid} onValid={onValid} onValidSubmit={onSubmit}>
            <DialogContent>
                <Dropzone accept={'image/*'} multiple={false} onDrop={onDrop}>
                    {({getInputProps, getRootProps, isDragAccept, isDragReject}) => (
                        <div {...getRootProps()} style={{...styles.dropzone, ...(isDragAccept ? styles.dragValid : {}), ...(isDragReject ? styles.dragInvalid : {})}}>
                            <input {...getInputProps()} />
                            {profilePhotoPreview && (
                                <img src={profilePhotoPreview} style={styles.profilePhotoPreview} />
                            )}
                        </div>
                    )}
                </Dropzone>
                <fieldset>
                    <FormsyText
                        autoComplete="username"
                        defaultValue={user.username}
                        label="Username"
                        name="username"
                        validationError="You must provide your username"
                        fullWidth required />
                </fieldset>
                <fieldset>
                    <FormsyText
                        autoComplete="email"
                        defaultValue={user.email}
                        label="Email"
                        name="email"
                        validations="isEmail"
                        validationError="Your email must be a valid email address"
                        fullWidth required />
                </fieldset>
                <fieldset>
                    <FormsyText
                        autoComplete="given-name"
                        defaultValue={user.first_name}
                        label="First name"
                        name="first_name"
                        validationError="You must provide your first name"
                        fullWidth required />
                </fieldset>
                <fieldset>
                    <FormsyText
                        autoComplete="family-name"
                        defaultValue={user.last_name}
                        label="Last name"
                        name="last_name"
                        validationError="You must provide your last name"
                        fullWidth required />
                </fieldset>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={! canSubmit} type="submit">
                    Update
                </Button>
            </DialogActions>
        </Formsy>
    </Modal>
)

Profile.defaultProps = {
    canSubmit: false,
    isBusy: false,
}

Profile.propTypes = {
    canSubmit: PropTypes.bool,
    isBusy: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onInvalid: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    profilePhotoPreview: PropTypes.string,
    user: PropTypes.object.isRequired,
}

const styles = {
    dropzone: {
        alignItems: 'center',
        borderColor: COLOR_GREY_LIGHT,
        borderRadius: '100%',
        borderStyle: 'dashed',
        borderWidth: 2,
        display: 'flex',
        height: 150,
        justifyContent: 'center',
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        overflow: 'hidden',
        width: 150,
    },
    dragValid: {
        borderColor: COLOR_BRAND_PRIMARY,
    },
    dragInvalid: {
        borderColor: COLOR_RED,
    },
    profilePhotoPreview: {
        alignSelf: 'center',
        borderRadius: '100%',
        height: '100%',
        width: '100%',
    },
}

export default Profile
