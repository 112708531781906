import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import Welcome from '../components/Welcome'

class WelcomeContainer extends React.Component {
    state = {
        html: null,
        isReady: false,
    }

    componentDidMount () {
        axios.get(`/landing-page?team=${this.props.auth.teamUri}`).then(({data}) => this.setState({
            html: data.data,
            isReady: true,
        })).catch(() => this.props.history.push('/'))
    }

    render () {
        return (
            <Welcome
                html={this.state.html}
                isReady={this.state.isReady} />
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
})

export default connect(mapStateToProps)(WelcomeContainer)
