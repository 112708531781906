import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Bar } from 'react-chartjs-2'

import { COLOR_BRAND_PRIMARY, COLOR_GREY_DARKER, COLOR_RED } from '../../constants/colors'

const GoalTransformation = ({data, isFetching, total}) => (
    <div style={styles.outer}>
        <Toolbar>
            <Typography color="inherit" variant="h6">Transformation Rate</Typography>
        </Toolbar>
        {isFetching ? (
            <CircularProgress style={styles.busy} />
        ) : (
            <React.Fragment>
                <div style={{...styles.container, ...(total ? {} : styles.empty)}}>
                    <Bar data={parseProps(data, total)} options={options()} />
                </div>
                {total ? null : (
                    <Typography style={styles.emptyState}>Over time you can track your target performance week&#45;on&#45;week.</Typography>
                )}
            </React.Fragment>
        )}
    </div>
)

GoalTransformation.defaultProps = {
    hasFetched: false,
    isFetching: false,
}

GoalTransformation.propTypes = {
    data: PropTypes.array.isRequired,
    hasFetched: PropTypes.bool,
    isFetching: PropTypes.bool,
    style: PropTypes.object,
    total: PropTypes.number.isRequired,
}

const options = () => ({
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            display: false,
        }],
        yAxes: [{
            ticks: {
                callback: (value, index, values) => `${value}%`
            },
        }],
    },
    tooltips: {
        callbacks: {
            label: (tooltipItem, data) => `${tooltipItem.yLabel}%`
        },
    },
})

const parseProps = (data, total) => {
    return (total ? data : defaultDataset(total)).reduce((carry, item) => {
        carry.labels.push(`To date: ${item.label}`)

        carry.datasets[0].data.push(item.percent)
        carry.datasets[0].backgroundColor.push(item.percent >= 0 ? COLOR_BRAND_PRIMARY : COLOR_RED)

        return carry
    }, {labels:[], datasets:[{data:[], backgroundColor:[]}]})
}

const defaultDataset = (total) => [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].reduce((carry, index) => {
    carry.push({
        percent: total ? (index % 2 === 0 && index % 3 !== 0 ? index * -1 : (index % 2 === 0 ? index : index - (index + 2))) : 0,
    })

    return carry
}, [])

const styles = {
    outer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
    },
    container: {
        padding: 12,
        marginBottom: 24,
    },
    empty: {
        filter: 'blur(4px)',
        opacity: .5,
    },
    busy: {
        margin: 'auto',
    },
    emptyState: {
        backgroundColor: 'rgba(255, 255, 255, .7)',
        bottom: 0,
        color: COLOR_GREY_DARKER,
        fontSize: 14,
        fontWeight: '300',
        left: 0,
        position: 'absolute',
        paddingLeft: 24,
        paddingTop: 24,
        paddingRight: 24,
        right: 0,
        textAlign: 'center',
        top: 64,
    },
}

export default GoalTransformation
