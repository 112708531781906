export const HIDE_PROMPT = 'HIDE_PROMPT'
export const SHOW_ALERT = 'SHOW_ALERT'
export const SHOW_PROMPT = 'SHOW_PROMPT'

const handleDismissPrompt = () => ({
    type: HIDE_PROMPT,
})

export const alert = (title, message, dismissMethod) => ({
    type: SHOW_ALERT,
    payload: {
        title, message, dismissMethod,
    },
})

export const prompt = (title, message, confirmMethod, dismissMethod, options = {}) => ({
    type: SHOW_PROMPT,
    payload: {
        title, message, confirmMethod, dismissMethod, options,
    },
})

export const confirmPrompt = () => (dispatch, getState) => {
    const { ui } = getState()
    let confirm = ui.prompt.confirmMethod

    if (ui.prompt.type === 'alert') {
        confirm = ui.prompt.dismissMethod
    }

    if (typeof confirm === 'function') {
        confirm(dispatch, getState)
    }

    dispatch(handleDismissPrompt())
}

export const dismissPrompt = () => (dispatch, getState) => {
    const dismiss = getState().ui.prompt.dismissMethod

    if (typeof dismiss === 'function') {
        dismiss(dispatch, getState)
    }

    dispatch(handleDismissPrompt())
}
