import ButtonBase from '@material-ui/core/ButtonBase'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import { Link } from 'react-router-dom'
import { StyleSheet, css } from 'aphrodite/no-important'

import ActionsMenu from './Map/ActionsMenu'
import { COLOR_BRAND_PRIMARY, COLOR_GREY, COLOR_GREY_LIGHTEST } from '../constants/colors'

const Dashboard = ({currentModuleIndex, history, map, modules, onChangeModuleIndex}) => (
    <div className={css(classes.outer)}>
        <div className={css(classes.container)}>
            <div className={css(classes.modules)} style={styles.modules(currentModuleIndex)}>
                {modules.map(({component: Component, key, extras, size}) => (
                    <Paper className={css(classes[`module${size.charAt(0).toUpperCase()}${size.slice(1)}`])} key={key}>
                        <Component
                            history={history}
                            mapUri={map.uri}
                            {...extras} />
                    </Paper>
                ))}
            </div>
            {modules.length > 1 && (
                <div className={css(classes.moduleControls)}>
                    {[...Array(modules.length)].map((item, index) => (
                        <ButtonBase className={css(classes.moduleControl)} key={index} onClick={onChangeModuleIndex} style={index === currentModuleIndex ? styles.moduleControlCurrent : null} value={index} />
                    ))}
                </div>
            )}
        </div>
        <Fab color="primary" component={Link} to={`/d/${map.uri}/configure`} style={styles.configureTrigger}>
            <SettingsIcon />
        </Fab>
        <ActionsMenu
            style={styles.actionsMenuTrigger}
            uri={map.uri} />
    </div>
)

const classes = StyleSheet.create({
    outer: {
        backgroundColor: COLOR_GREY_LIGHTEST,
        minHeight: 'calc(100vh - 68px)',
    },
    container: {
        margin: '0 auto',
        maxWidth: 1400,
        overflowX: 'hidden',

        '@media (min-width: 768px)': {
            overflow: 'initial',
            padding: 24,
        },
    },
    modules: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 100vw)',
        transition: 'transform .4s',

        '@media (min-width: 768px)': {
            columnGap: 20,
            gridTemplateColumns: 'repeat(6, 1fr)',
            gridTemplateRows: 'repeat(auto-fit, 33.33333vw)',
            rowGap: 40,
        },
    },
    moduleSm: {
        height: 'calc(100vh - 68px)',
        gridRow: 1,
        width: '100vw',

        '@media (min-width: 768px)': {
            gridColumn: 'span 2',
            gridRow: 'span 1',
            height: '100%',
            overflow: 'hidden',
            width: 'auto',
        },
    },
    moduleLg: {
        height: 'calc(100vh - 68px)',
        gridRow: 1,
        width: '100vw',

        '@media (min-width: 768px)': {
            gridColumn: 'span 3',
            gridRow: 'span 3',
            height: 'calc(100vh - 116px)',
            width: 'auto',
        },
    },
    moduleControls: {
        bottom: 20,
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        width: '100vw',

        '@media (min-width: 768px)': {
            display: 'none',
        },
    },
    moduleControl: {
        backgroundColor: COLOR_GREY,
        borderRadius: '100%',
        height: 20,
        marginLeft: 10,
        marginRight: 10,
        transition: 'background-color .2s',
        width: 20,
    },
})

const styles = {
    modules: (index) => ({
        transform: `translateX(-${index}00vw)`,
    }),
    moduleControlCurrent: {
        backgroundColor: COLOR_BRAND_PRIMARY,
    },
    configureTrigger: {
        bottom: 20,
        left: 20,
        position: 'absolute',
    },
    actionsMenuTrigger: {
        bottom: 20,
        position: 'absolute',
        right: 20,
    },
}

Dashboard.defaultProps = {
    currentModuleIndex: 0,
}

Dashboard.propTypes = {
    currentModuleIndex: PropTypes.number,
    history: PropTypes.object.isRequired,
    map: PropTypes.object.isRequired,
    modules: PropTypes.array.isRequired,
    onChangeModuleIndex: PropTypes.func.isRequired,
}

export default Dashboard
