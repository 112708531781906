import Formsy from 'formsy-react'
import React, { memo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import Chip from './Chip'
import Container from '../Shared/Container'
import Fields from './Fields'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'
import { historyShape, mapMatchShape } from '../../constants/types'
import { storeLabel } from '../../actions/label'

const Create = memo(({history, match}) => {
    const classes = useStyles()

    const [ hasChanged, setHasChanged ] = useState(false)
    const [ isBusy, setIsBusy ] = useState(false)
    const [ isValid, setIsValid ] = useState(false)

    const canSubmit = Boolean(hasChanged && isValid)

    const dispatch = useDispatch()

    const _handleSubmit = (formData, resetForm, invalidateForm) => {
        setIsBusy(true)

        dispatch(storeLabel(match.params.map, formData)).then(history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setIsBusy(false))
    }

    const _handleChange = (formData, hasChanged) => setHasChanged(hasChanged)
    const _handleInvalid = () => setIsValid(false)
    const _handleValid = () => setIsValid(true)

    return (
        <Modal>
            <Container modal>
                <Heading>Add a Label</Heading>
                <Formsy onChange={_handleChange} onInvalid={_handleInvalid} onValid={_handleValid} onValidSubmit={_handleSubmit}>
                    <Fields />
                    <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                        Add
                    </LoadingButton>
                </Formsy>
            </Container>
        </Modal>
    )
})

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4),
    },
}))

Create.propTypes = {
    history: historyShape.isRequired,
    match: mapMatchShape.isRequired,
}

export default Create
