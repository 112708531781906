import React from 'react'
import Rollbar from 'rollbar'
import localForage from 'localforage'

import { COLOR_BRAND_PRIMARY, COLOR_GREY_DARKER, COLOR_WHITE } from '../constants/colors'

class ErrorBoundary extends React.Component {
    constructor (props) {
        super(props)

        Rollbar.init({
            accessToken: process.env.REACT_APP_ROLLBAR_KEY,
            captureUncaught: true,
            captureUnhandledRejections: true,
            enabled: process.env.NODE_ENV !== 'dev',
            payload: {
                environment: process.env.NODE_ENV,
                client: {
                    javascript: {
                        guess_uncaught_frames: true,
                        source_map_enabled: true,
                    },
                },
            },
        })

        this.state = {
            hasError: false,
        }
    }

    static getDerivedStateFromError (error) {
        return {
            hasError: true,
        }
    }

    componentDidCatch (error, info) {
        if (error.handled) {
            return
        }

        if (process.env.NODE_ENV === 'dev') {
            console.error(error, info)
        } else {
            Rollbar.error(error)
        }
    }

    render () {
        if (this.state.hasError) {
            return (
                <div style={styles.container}>
                    <div style={styles.containerInner}>
                        <h1 style={styles.heading}>Sorry, something has gone wrong!</h1>
                        <p style={styles.text}>Please reload the page and try again.</p>
                        <p style={styles.text}>We&rsquo;ve reported this error so we can take a look into what happened.</p>
                        <button onClick={this._handleReload} style={{...styles.button, ...styles.primaryButton}} type="button">Reload</button>
                    </div>
                    <div style={styles.containerFooter}>
                        <p style={styles.footerText}>We can reset your local app data. Doing so may help resolve your error, but will end your current session and require you to log in again.</p>
                        <button onClick={this._handleReset} style={{...styles.button, ...styles.footerButton}} type="button">Reset</button>
                    </div>
                </div>
            )
        }

        return this.props.children
    }

    _handleReload = () => window.location.reload(true)
    _handleReset = () => localForage.removeItem('persist:root').then(this._handleReload)
}

const styles = {
    container: {
        display: 'grid',
        gridAutoColumns: '1fr',
        gridTemplateRows: '1fr auto',
        justifyContent: 'center',
        minHeight: '100vh',
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
    },
    containerInner: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    heading: {
        color: COLOR_BRAND_PRIMARY,
        fontSize: '2.125rem',
        fontWeight: 400,
        letterSpacing: '0.00735em',
        lineHeight: 1.235,
        marginBottom: 32,
        textAlign: 'center',
    },
    text: {
        color: COLOR_GREY_DARKER,
        fontSize: '1rem',
        fontWeight: 400,
        letterSpacing: '0.00938em',
        lineHeight: 1.5,
        marginBottom: 16,
        textAlign: 'center',
    },
    button: {
        cursor: 'pointer',
        borderRadius: 4,
        fontWeight: 500,
        letterSpacing: '0.02857em',
        lineHeight: 1.75,
        minWidth: 64,
        outline: 'none',
        textTransform: 'uppercase',
        width: 'min-content',
    },
    primaryButton: {
        backgroundColor: COLOR_BRAND_PRIMARY,
        border: 0,
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        color: COLOR_WHITE,
        fontSize: '0.9375rem',
        marginTop: 48,
        paddingBottom: 8,
        paddingLeft: 22,
        paddingRight: 22,
        paddingTop: 8,
    },
    footerButton: {
        border: '1px solid rgba(245, 0, 87, 0.5)',
        color: '#f50057',
        fontSize: '0.8125rem',
        marginLeft: 16,
        paddingBottom: 3,
        paddingLeft: 9,
        paddingRight: 9,
        paddingTop: 3,
    },
    containerFooter: {
        alignItems: 'center',
        display: 'flex',
        justifySelf: 'flex-end',
        paddingTop: 48,
    },
    footerText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57,
        letterSpacing: '0.00714em',
    },
}

export default ErrorBoundary
