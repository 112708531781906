import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import React, { memo, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import { withFormsy } from 'formsy-react'

const FormsySelect = memo(({
    children, defaultValue, disabled, errorMessage, errorMessages, fullWidth, hasValue, innerRef, isFormDisabled, isFormSubmitted, isPristine, isRequired, isValid, isValidValue, label, margin, onChange, resetValue, setValidations, setValue, showError, showRequired, validationError, validationErrors, validations, value, ...rest
}) => {
    useEffect(() => setValue(defaultValue), [])

    const handleChange = (event) => {
        setValue(event.target.value)

        if (onChange) {
            onChange(event, event.target.value)
        }
    }

    return (
        <FormControl disabled={isFormDisabled || disabled} error={showError} fullWidth={fullWidth} margin={margin}>
            <InputLabel>{label}</InputLabel>
            <Select
                {...rest}
                onChange={handleChange}
                value={value === undefined ? (defaultValue === undefined ? (rest.multiple ? [] : '') : defaultValue) : value}>
                {children}
            </Select>
            <FormHelperText>{showError && errorMessage}</FormHelperText>
        </FormControl>
    )
})

FormsySelect.defaultProps = {
    margin: 'normal',
    multiple: false,
}

FormsySelect.propTypes = {
    defaultValue: PropTypes.oneOfType([
        PropTypes.array, PropTypes.number, PropTypes.string,
    ]),
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    margin: PropTypes.oneOf([
        'none', 'normal', 'dense',
    ]),
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
}

export default withFormsy(FormsySelect)
