import React from 'react'

import TargetIcon from '../../components/Target/Icon'

class TargetIconContainer extends React.Component {
    render () {
        if (! this.props.target) {
            return null
        }

        return (
            <TargetIcon
                duration={this.props.target.duration}
                progress={this.props.target.progress}
                showDuration={this.props.showDuration}
                showProgress={this.props.showProgress}
                showValue={this.props.showValue}
                size={this.props.size} />
        )
    }
}

TargetIconContainer.defaultProps = {
    showDuration: true,
    showProgress: true,
    showValue: false,
    size: 32,
}

export default TargetIconContainer
