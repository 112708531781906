import List from '@material-ui/core/List'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { Link } from 'react-router-dom'
import { TouchBackend } from 'react-dnd-touch-backend'

class ReorderableList extends React.Component {
    state = {
        dndConstraint: this.props.dndConstraint || (Math.random() + 1).toString()
    }

    render () {
        const { children, ...rest } = this.props

        return (
            <DndProvider backend={'ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch) ? TouchBackend : HTML5Backend}>
                <List {...rest}>
                    {React.Children.map(children, (child) => ((['div', Typography, Link].includes(child.type)) ? child : React.cloneElement(child, {listIdentifier: this.state.dndConstraint})))}
                </List>
            </DndProvider>
        )
    }
}

export default ReorderableList
