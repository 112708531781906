import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Build from '../containers/Map/BuildContainer'
import Welcome from '../containers/Sandbox/WelcomeContainer'

class SandboxRoutes extends React.Component {
    previousLocation = this.props.location

    shouldComponentUpdate (nextProps) {
        if (nextProps.history.action !== 'POP' && (! this.props.location.state || ! this.props.location.state.modal)) {
            this.previousLocation = this.props.location
        }

        return true
    }

    render () {
        const isModal = this.props.location.state && this.props.location.state.modal && this.previousLocation !== this.props.location

        const switchProps = {
            ...this.props,
            location: isModal ? this.previousLocation : this.props.location,
        }

        return (
            <React.Fragment>
                <Route path="/" component={Welcome} exact />
                <Route path="/:map([a-zA-Z0-9]{8})" component={Build} />
            </React.Fragment>
        )
    }
}

export default SandboxRoutes
