import { REHYDRATE } from 'redux-persist'

import { RECEIVE_COMMENT, RECEIVE_COMMENTS } from '../actions/comment'
import { RECEIVE_NODE, RECEIVE_NODES } from '../actions/node'
import { RECEIVE_TARGET, RECEIVE_TARGETS } from '../actions/target'
import { RECEIVE_TEAM, RECEIVE_TEAMS } from '../actions/team'
import { RECEIVE_USER, RECEIVE_USERS } from '../actions/user'

const initialState = {
    default_map_uri: null,
    department: '',
    first_name: '',
    hex: '',
    id: null,
    initials: '',
    name: '',
    last_name: '',
    permissions: [],
    profile_picture: null,
    teams_index: [],
    tutorials_handled: [],
    username: '',
}

const userReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_USER]: () => ({
            ...state,
            ...action.payload.user,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const usersReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => action.payload && action.payload.auth && action.payload.auth.userId && action.payload.users && action.payload.users[action.payload.auth.userId] ? ({
            [action.payload.auth.userId]: {
                ...initialState,
                ...action.payload.users[action.payload.auth.userId],
            },
        }) : ({}),
        [RECEIVE_COMMENTS]: () => ({
            ...state,
            ...action.payload.comments.filter(({creator}) => creator).reduce((carry, {creator: user}) => ({
                ...carry,
                [user.id]: userReducer(state[user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user,
                    },
                }),
            }), {}),
        }),
        [RECEIVE_COMMENT]: () => ({
            ...state,
            ...action.payload.comment.creator ? ({
                [action.payload.comment.creator.id]: userReducer(state[action.payload.comment.creator.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user: action.payload.comment.creator,
                    },
                }),
            }) : {},
        }),
        [RECEIVE_NODES]: () => ({
            ...state,
            ...action.payload.nodes.filter(({assigned_user}) => assigned_user).reduce((carry, {assigned_user: user}) => ({
                ...carry,
                [user.id]: userReducer(state[user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user,
                    },
                }),
            }), {}),
        }),
        [RECEIVE_NODE]: () => ({
            ...state,
            ...action.payload.node.assigned_user ? ({
                [action.payload.node.assigned_user.id]: userReducer(state[action.payload.node.assigned_user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user: action.payload.node.assigned_user,
                    },
                }),
            }) : {},
        }),
        [RECEIVE_TARGET]: () => ({
            ...state,
            ...action.payload.target.node && action.payload.target.node.assigned_user ? ({
                [action.payload.target.node.assigned_user.id]: userReducer(state[action.payload.target.node.assigned_user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user: action.payload.target.node.assigned_user,
                    },
                }),
            }) : {},
        }),
        [RECEIVE_TARGETS]: () => ({
            ...state,
            ...action.payload.targets.filter(({node}) => node && node.assigned_user).reduce((carry, {node}) => ({
                ...carry,
                [node.assigned_user.id]: userReducer(state[node.assigned_user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user: node.assigned_user,
                    },
                })
            }), {}),
        }),
        [RECEIVE_TEAMS]: () => action.payload.teams.reduce((carry, {users}) => ({
            ...state,
            ...(Array.isArray(users) ? users.reduce((carry, user) => ({
                ...carry,
                [user.id]: userReducer(state[user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user,
                    },
                }),
            }), {}) : ({})),
        }), {}),
        [RECEIVE_TEAM]: () => Array.isArray(action.payload.team.users) ? ({
            ...state,
            ...action.payload.team.users.reduce((carry, user) => ({
                ...carry,
                [user.id]: userReducer(state[user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user,
                    },
                }),
            }), {}),
        }) : state,
        [RECEIVE_USERS]: () => ({
            ...state,
            ...action.payload.users.reduce((carry, user) => ({
                ...carry,
                [user.id]: userReducer(state[user.id], {
                    type: RECEIVE_USER,
                    payload: {
                        user,
                    },
                }),
            }), {}),
        }),
        [RECEIVE_USER]: () => ({
            ...state,
            [action.payload.user.id]: userReducer(state[action.payload.user.id], action),
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default usersReducer
