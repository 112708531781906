import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'
import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { FixedSizeList } from 'react-window'
import { makeStyles } from '@material-ui/core/styles'

import Container from './Shared/Container'
import Heading from './Shared/Heading'
import Modal from './Shared/Modal'
import PageLoadingIndicator from './PageLoadingIndicator'

const TeamList = ({hasFetched, onAdd, onDelete, onEdit, onManageMembers, teams}) => {
    const classes = useStyles()
    const rowHeight = 48

    const Row = ({index, style}) => {
        const handleDelete = () => onDelete(teams[index].uri)
        const handleEdit = () => onEdit(teams[index].uri)
        const handleManageMembers = () => onManageMembers(teams[index].uri)

        return (
            <ListItem ContainerComponent="div" ContainerProps={{style}} key={index}>
                <ListItemText>{teams[index].name}</ListItemText>
                <ListItemSecondaryAction>
                    <Tooltip title="Manage members">
                        <IconButton onClick={handleManageMembers}>
                            <PersonIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton onClick={handleEdit}>
                            <CreateIcon />
                        </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Delete">
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>*/}
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    return (
        <Modal>
            <Container modal>
                <Heading color="primary">Manage Teams</Heading>
                {teams.length ? (
                    <React.Fragment>
                        <FixedSizeList height={Math.min(6, teams.length) * rowHeight} itemCount={teams.length} itemSize={rowHeight} width="100%">
                            {Row}
                        </FixedSizeList>
                        <div className={classes.addButtonWrapper}>
                            <Fab color="primary" onClick={onAdd}>
                                <AddIcon />
                            </Fab>
                        </div>
                    </React.Fragment>
                ) : (
                    hasFetched ? (
                        <Typography>Well this is embarassing! You don&rsquo;t appear to have any teams and that shouldn&rsquo;t be possible! Perhaps a page refresh would help?</Typography>
                    ) : (
                        <PageLoadingIndicator className={classes.loadingIndicator} />
                    )
                )}
            </Container>
        </Modal>
    )
}

TeamList.defaultProps = {
    hasFetched: false,
}

TeamList.propTypes = {
    hasFetched: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onManageMembers: PropTypes.func.isRequired,
    teams: PropTypes.array.isRequired,
}

const useStyles = makeStyles((theme) => ({
    addButtonWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        paddingTop: theme.spacing(4),
    },
    loadingIndicator: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
}))

export default TeamList
