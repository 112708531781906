import React from 'react'
import { connect } from 'react-redux'

import Configure from '../components/Map/Configure'
import Modal from '../components/Shared/Modal'
import { configureMap, fetchMap, mapFromUri } from '../actions/map'
import { dashboardModules } from '../constants/modules'

class DashboardConfigureContainer extends React.Component {
    state = {
        configureComponent: null,
        isBusy: false,
        isReady: false,
        modules: [],
    }

    componentDidMount () {
        this._fetchMapIfNeeded()
    }

    render () {
        const ConfigureComponent = this.state.configureComponent
        if (ConfigureComponent) {
            return (
                <Modal onEscape={this._handleEscape} fullWidth>
                    <ConfigureComponent onClose={this._handleEscape} />
                </Modal>
            )
        }

        return (
            <Configure
                isBusy={this.state.isBusy}
                isReady={this.state.isReady}
                modules={this.state.modules}
                onConfigure={this._handleConfigure}
                onMoveItem={this._handleMoveItem}
                onSave={this._handleSave}
                onToggleItem={this._handleToggleItem}
                scope="dashboard" />
        )
    }

    _handleMoveItem = (source, dragIndex, hoverIndex) => {
        const modules = this.state.modules.filter((id, i) => i !== dragIndex)

        this.setState({
            modules: [
                ...modules.slice(0, hoverIndex).map((item, index) => ({
                    ...item,
                    order: index,
                })),
                {
                    ...this.state.modules[dragIndex],
                    order: hoverIndex,
                },
                ...modules.slice(hoverIndex).map((item, index) => ({
                    ...item,
                    order: index + (hoverIndex + 1),
                })),
            ],
        })
    }

    _handleSave = () => {
        this.setState({isBusy: true})

        this.props.save({modules: this.state.modules}).finally(() => this.setState({isBusy: false}))
    }

    _handleConfigure = (event) => this.setState({
        configureComponent: dashboardModules[event.currentTarget.value].configureComponent
    })

    _handleMapReady = () => this.setState({
        isReady: true,
        modules: modules(this.props)
    })

    _handleToggleItem = (event) => this.setState({
        modules: this.state.modules.map((item) => item.key === event.target.value ? ({
            ...item,
            enabled: ! item.enabled,
        }) : item)
    })

    _fetchMapIfNeeded = () => mapFromUri(this.props.maps, this.props.match.params.map).id ? this._handleMapReady() : this.props.fetch().then(this._handleMapReady)
    _handleEscape = () => this.setState({configureComponent: null})
}

const modules = ({maps, match}) => {
    const map = mapFromUri(maps, match.params.map)

    return Object.keys(dashboardModules).map((key, index) => ({
        configurable: Boolean(dashboardModules[key].configureComponent),
        label: dashboardModules[key].label,
        enabled: Boolean(map.config.modules[key] ? map.config.modules[key].enabled : dashboardModules[key].default),
        order: map.config.modules[key] && map.config.modules[key].order !== undefined ? parseInt(map.config.modules[key].order) : index,
        key,
    })).sort((a, b) => a.order - b.order)
}

const mapStateToProps = (state, ownProps) => ({
    maps: state.maps,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchMap(ownProps.match.params.map)),
    save: (data) => dispatch(configureMap(ownProps.match.params.map, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardConfigureContainer)
