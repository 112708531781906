import { v1 as uuid } from 'uuid'

import { CONTROLS_HEIGHT, HEADER_HEIGHT, LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH, TRAY_HEIGHT } from '../constants/layout'

export class RowsBuilder {
    _defaultInstanceId = null
    _dragBehaviour = 'move'
    _draggingFocalIndex = 0
    _draggingInstanceId = null
    _focalIndex = -1
    _focalInstanceId = null
    _goals = {}
    _greedyBehaviour = 'non-greedy'
    _indexes = {}
    _instances = {}
    _offset = 0
    _relations = {}
    _rows = []
    _scrollingRowSet = false
    _snapshotIndex = 0
    _snapshots = []
    _windowSize = {}

    constructor (layout, windowSize) {
        const {
            defaultInstanceId = null,
            focalInstanceId = null,
            goals = {},
            indexes = {},
            instances = {},
            offset = 0,
            relations = {},
            rows = []
        } = layout

        this._defaultInstanceId = defaultInstanceId || focalInstanceId
        this._focalInstanceId = focalInstanceId
        this._indexes = {...indexes}
        this._instances = {...instances}
        this._offset = offset
        this._previousRows = rows.slice()
        this._rows = rows.slice()
        this._relations = {...relations}
        this._windowSize = windowSize

        this.replaceGoals(goals)

        this.rebuild()

        this.takeSnapshot()
    }

    get canRedo () {
        return this._snapshotIndex < (this._snapshots.length - 1)
    }

    get canUndo () {
        return this._snapshotIndex > 0
    }

    get defaultInstanceId () {
        return this._defaultInstanceId
    }

    get dragBehaviour () {
        return this._dragBehaviour
    }

    get focalInstanceId () {
        return this._focalInstanceId
    }

    get goals () {
        return Object.keys(this._goals).reduce((carry, id) => ({
            ...carry,
            [id]: {
                ...this._goals[id],
                meta: Object.keys(this._instances).filter((instanceId) => this._instances[instanceId] === id).reduce((carry, instanceId) => ({
                    ...carry,
                    [instanceId]: {
                        relations: {
                            parents: this.countParentRelations(instanceId),
                            children: this.countChildRelations(instanceId),
                        },
                    },
                }), {}),

            },
        }), {})
    }

    get greedyBehaviour () {
        return this._greedyBehaviour
    }

    get instances () {
        return Object.keys(this._instances).filter((instanceId) => this._instances[instanceId]).reduce((carry, instanceId) => ({
            ...carry,
            [instanceId]: this._instances[instanceId],
        }), {})
    }

    get layout () {
        return {
            defaultInstanceId: this._defaultInstanceId,
            focalIndex: this._focalIndex,
            focalInstanceId: this._focalInstanceId,
            indexes: {
                ...this._indexes,
            },
            instances: {
                ...this._instances,
            },
            offset: this._offset,
            relations: {
                ...this._relations,
            },
            rows: [
                ...this._rows,
            ],
        }
    }

    get offset () {
        return this._offset
    }

    get orphanedInstances () {
        return Object.keys(this._instances).filter((instanceId) => {
            if (instanceId === this._focalInstanceId) {
                return false
            }

            if (instanceId === this._draggingInstanceId) {
                return false
            }

            if (! this._instances[instanceId]) {
                return false
            }

            if (this._goals[this._instances[instanceId]] && this._goals[this._instances[instanceId]].editingInstanceId === instanceId) {
                return false
            }

            if (! this._relations[instanceId]) {
                return true
            }

            if ((! this._relations[instanceId].children || ! this._relations[instanceId].children.length) && (! this._relations[instanceId].parents || ! this._relations[instanceId].parents.length)) {
                return true
            }

            return false
        })
    }

    get relations () {
        return Object.keys(this._relations).filter((instanceId) => this._instances[instanceId]).reduce((carry, instanceId) => ({
            ...carry,
            [instanceId]: {
                parents: [
                    ...this._relations[instanceId] && this._relations[instanceId].parents ? this._relations[instanceId].parents : [],
                ],
                children: [
                    ...this._relations[instanceId] && this._relations[instanceId].children ? this._relations[instanceId].children : [],
                ],
            },
        }), {})
    }

    get snapshotIndex () {
        return this._snapshotIndex
    }

    get stashableLayout () {
        return {
            focalInstanceId: this.focalInstanceId,
            indexes: {
                ...this._indexes,
            },
            offset: this.offset,
        }
    }

    get stashableMap () {
        return {
            defaultInstanceId: this.defaultInstanceId,
            goals: this.goals,
            instances: this.instances,
            relations: this.relations,
            ...this.stashLayout,
        }
    }

    static build (items, previousRow, fallbackFocalInstanceIndex, mapFocalInstanceId, windowSize, skipOffsetUpdate = false) {
        const focalInstanceId = items.includes(mapFocalInstanceId) ? mapFocalInstanceId : (previousRow && items.includes(previousRow.focalInstanceId) ? previousRow.focalInstanceId : this.focalInstanceIdFromFallbackIndex(items, fallbackFocalInstanceIndex))

        if (items.includes(mapFocalInstanceId)) {
            items = [mapFocalInstanceId]
        }

        return {
            offset: skipOffsetUpdate && previousRow ? previousRow.offset : computeRowOffset(items.findIndex((id) => focalInstanceId === id), windowSize),
            scrolling: previousRow && previousRow.scrolling,
            focalInstanceId, items,
        }
    }

    static focalInstanceIdFromFallbackIndex (items, index) {
        if (typeof index !== 'number') {
            return items[0]
        }

        if (index < 0) {
            return items[0]
        }

        if (items[index]) {
            return items[index]
        }

        if (items[index + 1]) {
            return items[index + 1]
        }

        if (items[index - 1]) {
            return items[index - 1]
        }

        return items[0]
    }

    addGhost (position) {
        this.insertGoal(position)
        this.rebuild()
    }

    beginDrag (position, instanceId) {
        this._draggingFocalIndex = this._focalIndex
        this._draggingInstanceId = instanceId
    }

    buildFocalRow (skipOffsetUpdates) {
        return RowsBuilder.build(
            [this._focalInstanceId],
            this._previousRows.find(({items}) => items.includes(this._focalInstanceId)),
            this._focalIndex,
            this._focalInstanceId,
            this._windowSize,
            skipOffsetUpdates.includes(this._focalIndex)
        )
    }

    buildChildren (instanceId, index, skipOffsetUpdates) {
        if (! this._relations[instanceId] || ! this._relations[instanceId].children || ! this._relations[instanceId].children.length) {
            return []
        }

        const row = RowsBuilder.build(
            this._relations[instanceId].children,
            this._previousRows.find(({focalInstanceId}) => this._relations[instanceId].children.includes(focalInstanceId)),
            this._indexes[instanceId],
            this._focalInstanceId,
            this._windowSize,
            skipOffsetUpdates.includes(index)
        )

        return [
            row,
            ...this.buildChildren(row.focalInstanceId, index + 1, skipOffsetUpdates),
        ]
    }

    buildParents (instanceId, index, skipOffsetUpdates) {
        if (! this._relations[instanceId] || ! this._relations[instanceId].parents || ! this._relations[instanceId].parents.length) {
            return []
        }

        const row = RowsBuilder.build(
            this._relations[instanceId].parents,
            this._previousRows.find(({focalInstanceId}) => this._relations[instanceId].parents.includes(focalInstanceId)),
            this._indexes[instanceId],
            this._focalInstanceId,
            this._windowSize,
            skipOffsetUpdates.includes(index)
        )

        return [
            ...this.buildParents(row.focalInstanceId, index - 1, skipOffsetUpdates),
            row,
        ]
    }

    cloneRelationInstances = (originalInstanceId, instanceId, mappedInstances = {}, {instances, relations} = {instances: {}, relations: {}}) => {
        if (this._relations[originalInstanceId] === undefined) {
            return {
                instances, relations,
            }
        }

        return this._relations[originalInstanceId].reduce((carry, id) => {
            const newInstanceId = uuid()

            return this.cloneRelationInstances(id, newInstanceId, mappedInstances, ({
                instances: {
                    ...carry.instances,
                    [newInstanceId]: this._instances[id]
                },
                relations: {
                    ...carry.relations,
                    [instanceId]: [
                        ...(carry.relations[instanceId] || []),
                        newInstanceId,
                    ],
                },
            }))
        }, ({
            instances: {...instances},
            relations: {...relations}
        }))
    }

    convertDrag ({rowIndex, columnIndex} = {}, instanceId, goalId) {
        if (this._dragBehaviour === 'clone') {
            instanceId = uuid()

            this._instances = {
                ...this._instances,
                [instanceId]: goalId,
            }
        }

        if (rowIndex === undefined || columnIndex === undefined) {
            return
        }

        const ghostInstanceId = this._rows[Math.ceil(rowIndex)] ? this._rows[Math.ceil(rowIndex)].items[Math.ceil(columnIndex)] : null
        if (! ghostInstanceId) {
            return
        }

        this._relations = Object.keys(this._relations).reduce((carry, id) => ({
            ...carry,
            [id === ghostInstanceId ? instanceId : id]: {
                children: this._relations[id].children ? this._relations[id].children.map((id) => id === ghostInstanceId ? instanceId : id) : [],
                parents: this._relations[id].parents ? this._relations[id].parents.map((id) => id === ghostInstanceId ? instanceId : id) : [],
            },
        }), {})

        this._instances = Object.keys(this._instances).reduce((carry, id) => id === ghostInstanceId ? carry : ({
            ...carry,
            [id]: this._instances[id],
        }), {})

        this.rebuild()

        this.takeSnapshot()
    }

    convertGhost (instanceId, id) {
        this._instances = {
            ...this._instances,
            [instanceId]: id,
        }

        this._goals = {
            ...this._goals,
            [id]: {
                editingInstanceId: instanceId,
                name: '',
                uri: id,
                id,
            },
        }

        this.takeSnapshot()
    }

    countChildRelations (instanceId) {
        return this.countRelations('children', instanceId)
    }

    countParentRelations (instanceId) {
        return this.countRelations('parents', instanceId)
    }

    countRelations (type, instanceId) {
        if (! this._instances[instanceId]) {
            return 0
        }

        return new Set(
            Object.keys(this._instances).filter((item) => this._instances[item] === this._instances[instanceId]).filter((item) => this._relations[item] && this._relations[item][type]).reduce((carry, item) => ([
                ...carry,
                ...this._relations[item][type].filter((item) => this._instances[item]),
            ]), [])
        ).size
    }

    deleteGoal (id, instanceId) {
        if (! this._instances[instanceId]) {
            return
        }

        this._instances = Object.keys(this._instances).filter((currentInstanceId) => currentInstanceId !== instanceId).reduce((carry, instanceId) => ({
            ...carry,
            [instanceId]: this._instances[instanceId],
        }), {})

        this._relations = Object.keys(this._relations).filter((currentInstanceId) => currentInstanceId !== instanceId).reduce((carry, currentInstanceId) => ({
            ...carry,
            [currentInstanceId]: {
                parents: (this._relations[currentInstanceId].parents || []).filter((currentInstanceId) => currentInstanceId !== instanceId),
                children: (this._relations[currentInstanceId].children || []).filter((currentInstanceId) => currentInstanceId !== instanceId),
            },
        }), {})

        if (! Object.values(this._instances).filter((goalId) => goalId === id).length) {
            this._goals = Object.keys(this._goals).filter((goalId) => goalId !== id).reduce((carry, id) => ({
                ...carry,
                [id]: this._goals[id],
            }), {})
        }

        this.rebuild()

        this.takeSnapshot()
    }

    editGoal (id, instanceId) {
        this._goals = {
            ...this._goals,
            [id]: {
                ...this._goals[id],
                lastName: this._goals[id] ? this._goals[id].name : null,
                editingInstanceId: instanceId,
            },
        }
    }

    editGoalComplete (id) {
        if (! isValidGoalName(this._goals[id].name) && this._goals[id].lastName === undefined) {
            return this.undo(true)
        }

        this._goals = {
            ...this._goals,
            [id]: {
                ...this._goals[id],
                name: isValidGoalName(this._goals[id].name) ? this._goals[id].name.slice(0, 60) : this._goals[id].lastName,
                editingInstanceId: null,
            },
        }

        if (this._goals[id].lastName !== undefined && this._goals[id].name !== this._goals[id].lastName) {
            this.takeSnapshot()
        } else {
            this.mergeSnapshot({
                goals: this._goals,
            })

        }
    }

    endDrag (position, instanceId) {
        this._draggingFocalIndex = 0
        this._draggingInstanceId = null
    }

    getGoalPosition (rowIndex, columnIndex) {
        if (rowIndex > this._rows.length) {
            return this._rejectPosition({rowIndex, columnIndex}, 'Row out of bounds')
        }

        if (rowIndex < (this._rows.length === 0 ? 0 : -1)) {
            return this._rejectPosition({rowIndex, columnIndex}, 'Row out of bounds')
        }

        if (rowIndex === this._focalIndex) {
            return this._rejectPosition({rowIndex, columnIndex}, 'Row out of bounds')
        }

        // if we're trying to move from a now empty last row to a later row
        if (rowIndex === (this._rows.length) && this._rows[this._rows.length - 1] && this._rows[this._rows.length - 1].items.filter((instanceId) => this._instances[instanceId]).length === 0) {
            console.debug(`Rejecting ${rowIndex},${columnIndex} layout position #3`)
            return false
        }

        // if we're trying to move from a now empty row to a row that's half above or below this row
        if ((Math.floor(rowIndex) !== rowIndex && this._rows[Math.floor(rowIndex)] && this._rows[Math.floor(rowIndex)].items.filter((instanceId) => this._instances[instanceId]).length === 0) || (Math.ceil(rowIndex) !== rowIndex && this._rows[Math.ceil(rowIndex)] && this._rows[Math.ceil(rowIndex)].items.length === 0)) {
            console.debug(`Rejecting ${rowIndex},${columnIndex} layout position #4`)
            return false
        }

        const rowItems = this._rows[rowIndex] ? this._rows[rowIndex].items.filter((instanceId) => this._instances[instanceId]) : []

        if (columnIndex < (rowItems.length ? -1 : 0)) {
            return this._rejectPosition({rowIndex, columnIndex}, 'Column out of bounds')
        }

        if (columnIndex > rowItems.length) {
            return this._rejectPosition({rowIndex, columnIndex}, 'Column out of bounds')
        }

        if (rowItems[columnIndex]) {
            return this._rejectPosition({rowIndex, columnIndex}, 'Space occupied')
        }

        // if we're trying to do a greedy insert into a focal column that isn't at the bottom
        // if (this._greedyBehaviour === 'greedy' && ((Math.ceil(rowIndex) !== rowIndex && Math.ceil(rowIndex) !== this._rows.filter(({items}) => items.filter((instanceId) => this._instances[instanceId] !== undefined).length).length) || rowIndex < 0)) {
        //     console.debug(`Rejecting ${rowIndex},${columnIndex} layout position #8`)
        //     return false
        // }

        this._acceptPosition({rowIndex, columnIndex})

        return {
            rowIndex, columnIndex,
        }
    }

    getGoalPositionFromDrag (offset, {rowIndex: ghostRowIndex, columnIndex: ghostColumIndex} = {}) {
        if (! offset) {
            return false
        }

        if (offset.y < CONTROLS_HEIGHT + HEADER_HEIGHT - (LAYOUT_NODE_HEIGHT * .33)) {
            return this._rejectPosition({offset}, 'Dragged off stage')
        }

        if (offset.y > this._windowSize.height - TRAY_HEIGHT - (LAYOUT_NODE_HEIGHT * .66)) {
            return this._rejectPosition({offset}, 'Dragged off stage')
        }

        let rowIndex = Math.round(((offset.y - this._offset - CONTROLS_HEIGHT - HEADER_HEIGHT) / (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y)) * 2) / 2
        if (Math.round(rowIndex) !== rowIndex && rowIndex < 0 || rowIndex > this._rows.length - 1) {
            rowIndex = Math.round(rowIndex)
        }

        let columnIndex = Math.round(((offset.x - (this._rows[rowIndex] ? this._rows[rowIndex].offset : ((this._windowSize.width - LAYOUT_NODE_WIDTH) / 2))) / (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) * 2) / 2
        if (Math.round(columnIndex) !== columnIndex && columnIndex < 0 || (this._rows[rowIndex] && columnIndex > (this._rows[rowIndex].items.filter((instanceId) => this._instances[instanceId]).length - 1))) {
            columnIndex = Math.round(columnIndex)
        }

        if (rowIndex === ghostRowIndex && columnIndex === ghostColumIndex) {
            return {
                rowIndex, columnIndex,
            }
        }

        return this.getGoalPosition(rowIndex, columnIndex)
    }

    getGoalPositionFromMouse (offset, {columnIndex: ghostColumIndex, rowIndex: ghostRowIndex} = {}) {
        if (! offset) {
            return false
        }

        const rowIndex = Math.floor((offset.y - this._offset - CONTROLS_HEIGHT - HEADER_HEIGHT) / (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y))

        const rowGapOffset = ((offset.y - this._offset - CONTROLS_HEIGHT - HEADER_HEIGHT - LAYOUT_NODE_SPACING_Y * rowIndex) / LAYOUT_NODE_HEIGHT) - rowIndex
        if (rowGapOffset < 0 || rowGapOffset > 1) {
            return this._rejectPosition({offset}, 'Cursor between rows', true)
        }

        const columnIndex = Math.floor((offset.x - (this._rows[rowIndex] ? this._rows[rowIndex].offset : ((this._windowSize.width - LAYOUT_NODE_WIDTH) / 2))) / (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X))

        const columnGapOffset = ((offset.x - (this._rows[rowIndex] ? (this._rows[rowIndex].offset + LAYOUT_NODE_SPACING_X * columnIndex) : (this._windowSize.width - LAYOUT_NODE_WIDTH) / 2)) / LAYOUT_NODE_WIDTH) - columnIndex
        if (columnGapOffset < 0 || columnGapOffset > 1) {
            return this._rejectPosition({offset}, 'Cursor between columns', true)
        }

        if (rowIndex === ghostRowIndex && columnIndex === ghostColumIndex) {
            return {
                rowIndex, columnIndex,
            }
        }

        return this.getGoalPosition(rowIndex, columnIndex)
    }

    insertGoal ({rowIndex, columnIndex}, instanceId, goalId) {
        if (this._dragBehaviour === 'clone' || instanceId === undefined) {
            instanceId = uuid()

            this._instances = {
                ...this._instances,
                [instanceId]: goalId,
            }
        }

        const parentRowIndex = Math.ceil(rowIndex - 1) >= 0 ? Math.abs(Math.ceil(rowIndex - 1)) : Math.ceil(rowIndex - 1)
        const childRowIndex = Math.abs(Math.floor(rowIndex + 1))

        const parentRowFocalInstanceId = this._rows[parentRowIndex] ? this._rows[parentRowIndex].focalInstanceId : null
        const childRowFocalInstanceId = this._rows[childRowIndex] ? this._rows[childRowIndex].focalInstanceId : null

        if (rowIndex !== Math.ceil(rowIndex)) {
            this._relations = {
                ...this._relations,
                [instanceId]: {
                    ...this._relations[instanceId],
                    parents: childRowFocalInstanceId && this._relations[childRowFocalInstanceId] && this._relations[childRowFocalInstanceId].parents ? this._relations[childRowFocalInstanceId].parents.filter((id) => id !== instanceId) : (parentRowFocalInstanceId ? [
                        parentRowFocalInstanceId,
                    ] : []),
                    children: parentRowFocalInstanceId && this._relations[parentRowFocalInstanceId] && this._relations[parentRowFocalInstanceId].children ? this._relations[parentRowFocalInstanceId].children.filter((id) => id !== instanceId) : (childRowFocalInstanceId ? [
                        childRowFocalInstanceId,
                    ] : []),
                },
                ...parentRowFocalInstanceId ? ({
                    [parentRowFocalInstanceId]: {
                        ...this._relations[parentRowFocalInstanceId],
                        children: [
                            instanceId,
                        ],
                    },
                }) : {},
                ...childRowFocalInstanceId ? ({
                    [childRowFocalInstanceId]: {
                        ...this._relations[childRowFocalInstanceId],
                        parents: [
                            instanceId,
                        ],
                    },
                }) : {},
            }
        } else if (rowIndex < this._focalIndex) {
            if (childRowFocalInstanceId) {
                this._relations = {
                    ...this._relations,
                    [instanceId]: {
                        ...this._relations[instanceId],
                        children: [
                            ...this._relations[instanceId] && this._relations[instanceId].children ? this._relations[instanceId].children.filter((id, index) => index < columnIndex) : [],
                            childRowFocalInstanceId,
                            ...this._relations[instanceId] && this._relations[instanceId].children ? this._relations[instanceId].children.filter((id, index) => index >= columnIndex) : [],
                        ],
                    },
                    [childRowFocalInstanceId]: {
                        ...this._relations[childRowFocalInstanceId],
                        parents: [
                            ...this._relations[childRowFocalInstanceId] && this._relations[childRowFocalInstanceId].parents ? this._relations[childRowFocalInstanceId].parents.filter((id, index) => index < columnIndex) : [],
                            instanceId,
                            ...this._relations[childRowFocalInstanceId] && this._relations[childRowFocalInstanceId].parents ? this._relations[childRowFocalInstanceId].parents.filter((id, index) => index >= columnIndex) : []
                        ],
                    },
                }
            }
        } else {
            if (parentRowFocalInstanceId) {
                this._relations = {
                    ...this._relations,
                    [instanceId]: {
                        ...this._relations[instanceId],
                        parents: [
                            ...this._relations[instanceId] && this._relations[instanceId].parents ? this._relations[instanceId].parents.filter((id, index) => index < columnIndex) : [],
                            parentRowFocalInstanceId,
                            ...this._relations[instanceId] && this._relations[instanceId].parents ? this._relations[instanceId].parents.filter((id, index) => index >= columnIndex) : [],
                        ],
                    },
                    [parentRowFocalInstanceId]: {
                        ...this._relations[parentRowFocalInstanceId],
                        children: [
                            ...this._relations[parentRowFocalInstanceId] && this._relations[parentRowFocalInstanceId].children ? this._relations[parentRowFocalInstanceId].children.filter((id, index) => index < columnIndex) : [],
                            instanceId,
                            ...this._relations[parentRowFocalInstanceId] && this._relations[parentRowFocalInstanceId].children ? this._relations[parentRowFocalInstanceId].children.filter((id, index) => index >= columnIndex) : [],
                        ],
                    },
                }
            }
        }
    }

    mergeSnapshot (data) {
        this._snapshots[this._snapshots.length - 1] = {
            ...this._snapshots[this._snapshots.length - 1],
            ...data,
        }
    }

    rebuild (skipRowOffsetUpdates = [], nextFocalInstanceId, skipParents = false, skipChildren = false) {
        if (nextFocalInstanceId && ! this._focalInstanceId) {
            this._focalInstanceId = nextFocalInstanceId
        }

        if (! this._focalInstanceId || ! this._instances[this._focalInstanceId]) {
            if (! Object.keys(this._instances).length) {
                this.reset()
                return
            }

            this._focalInstanceId = Object.keys(this._instances)[0]

        }

        if (! this._defaultInstanceId || ! this._instances[this._defaultInstanceId]) {
            this._defaultInstanceId = this._focalInstanceId
        }

        const previousFocalInstanceId = this._focalInstanceId
        if (nextFocalInstanceId && this._instances[nextFocalInstanceId]) {
            this._focalInstanceId = nextFocalInstanceId
        }

        const baseRows = this._focalInstanceId ? [
            ...skipParents ? [] : this.buildParents(this._focalInstanceId, this._focalIndex - 1, skipRowOffsetUpdates),
            this.buildFocalRow(skipRowOffsetUpdates),
        ] : []

        this._rows = [
            ...baseRows,
            ...baseRows.length && ! skipChildren ? this.buildChildren(baseRows[baseRows.length - 1].focalInstanceId, baseRows.length, skipRowOffsetUpdates) : [],
        ]

        this._indexes = {
            ...this._indexes,
            ...this._rows.reduce((carry, {focalInstanceId, items}, index) => index < 1 ? carry : ({
                ...carry,
                [this._rows[index - 1].focalInstanceId]: items.findIndex((id) => id === focalInstanceId),
            }), {})
        }

        const previousFocalIndex = this._focalIndex
        this._focalIndex = this._rows.findIndex(({focalInstanceId}) => focalInstanceId === this._focalInstanceId)

        if (this._focalInstanceId !== previousFocalInstanceId) {
            const previousRowIndex = this._previousRows.findIndex(({items}) => items.includes(this._focalInstanceId))
            if (previousRowIndex >= 0 && previousRowIndex !== this._focalIndex) {
                this._offset += (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y) * (previousRowIndex - this._focalIndex)
            }
        } else if (previousFocalIndex >= 0 && this._focalIndex !== previousFocalIndex) {
            this._offset += (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y) * (previousFocalIndex - this._focalIndex)
        }

        this._previousRows = this._rows.slice()
    }

    removeGhosts () {
        const ghostInstances = Object.keys(this._instances).filter((instanceId) => ! this._instances[instanceId])

        ghostInstances.forEach((instanceId) => {
            const rowIndex = this._rows.findIndex(({items}) => items.includes(instanceId))
            const columnIndex = this._rows[rowIndex] ? this._rows[rowIndex].items.findIndex((id) => id === instanceId) : undefined

            this.removeGoal({rowIndex, columnIndex}, instanceId)
        })

        this._instances = Object.keys(this._instances).filter((instanceId) => ! ghostInstances.includes(instanceId)).reduce((carry, instanceId) => ({
            ...carry,
            [instanceId]: this._instances[instanceId],
        }), {})

        this._relations = Object.keys(this._relations).filter((instanceId) => this._instances[instanceId]).reduce((carry, instanceId) => ({
            ...carry,
            [instanceId]: {
                parents: (this._relations[instanceId].parents || []).filter((instanceId) => this._instances[instanceId]),
                children: (this._relations[instanceId].children || []).filter((instanceId) => this._instances[instanceId]),
            },
        }), {})

        this.rebuild()
    }

    removeGoal ({rowIndex, columnIndex}, instanceId) {
        if (! this._rows[rowIndex] || ! this._rows[rowIndex].items[columnIndex]) {
            return
        }

        const focalIndex = this._rows[rowIndex].items.findIndex((instanceId) => instanceId === this._rows[rowIndex].focalInstanceId)

        const parentRowFocalInstanceId = this._rows[rowIndex - 1] ? this._rows[rowIndex - 1].focalInstanceId : null
        const childRowFocalInstanceId = this._rows[rowIndex + 1] ? this._rows[rowIndex + 1].focalInstanceId : null

        const parentRowBaseChildren = parentRowFocalInstanceId && columnIndex === focalIndex ? (this._relations[parentRowFocalInstanceId] && this._relations[parentRowFocalInstanceId].children && this._relations[parentRowFocalInstanceId].children.includes(childRowFocalInstanceId) ? [] : [childRowFocalInstanceId]) : []
        const childRowBaseChildren = childRowFocalInstanceId && columnIndex === focalIndex ? (this._relations[childRowFocalInstanceId] && this._relations[childRowFocalInstanceId].parents && this._relations[childRowFocalInstanceId].parents.includes(parentRowFocalInstanceId) ? [] : [parentRowFocalInstanceId]) : []

        this._relations = {
            ...this._relations,
            [instanceId]: {
                ...this._relations[instanceId],
                children: this._relations[instanceId] && this._relations[instanceId].children ? (this._greedyBehaviour === 'non-greedy' || rowIndex < this._focalIndex ? this._relations[instanceId].children.filter((id) => id !== childRowFocalInstanceId) : this._relations[instanceId].children) : [],
                parents: this._relations[instanceId] && this._relations[instanceId].parents ? (this._greedyBehaviour === 'non-greedy' || rowIndex > this._focalIndex ? this._relations[instanceId].parents.filter((id) => id !== parentRowFocalInstanceId) : this._relations[instanceId].parents) : [],
            },
            ...parentRowFocalInstanceId && (this._greedyBehaviour === 'non-greedy' || rowIndex > this._focalIndex) ? ({
                [parentRowFocalInstanceId]: {
                    ...this._relations[parentRowFocalInstanceId],
                    children: this._relations[parentRowFocalInstanceId] && this._relations[parentRowFocalInstanceId].children ? ([
                        ...this._relations[parentRowFocalInstanceId].children.filter((id) => id !== instanceId),
                        ...parentRowBaseChildren,
                    ]) : parentRowBaseChildren,
                },
            }) : {},
            ...childRowFocalInstanceId && (this._greedyBehaviour === 'non-greedy' || rowIndex < this._focalIndex) ? ({
                [childRowFocalInstanceId]: {
                    ...this._relations[childRowFocalInstanceId],
                    parents: this._relations[childRowFocalInstanceId] && this._relations[childRowFocalInstanceId].parents ? ([
                        ...this._relations[childRowFocalInstanceId].parents.filter((id) => id !== instanceId),
                        ...childRowBaseChildren,
                    ]) : childRowBaseChildren,
                },
            }) : {},
        }

        if (this._rows[rowIndex].items[columnIndex] === this._rows[rowIndex].focalInstanceId && this._rows[rowIndex].items.length === 1 && parentRowFocalInstanceId && childRowFocalInstanceId) {
            if (rowIndex < this._focalIndex) {
                this._relations = {
                    ...this._relations,
                    [childRowFocalInstanceId]: {
                        ...this._relations[childRowFocalInstanceId],
                        parents: [
                            parentRowFocalInstanceId,
                        ],
                    },
                }
            } else {
                this._relations = {
                    ...this._relations,
                    [parentRowFocalInstanceId]: {
                        ...this._relations[parentRowFocalInstanceId],
                        children: [
                            childRowFocalInstanceId,
                        ],
                    },
                }
            }
        }
    }

    redo () {
        if (this._snapshotIndex >= this._snapshots.length) {
            return
        }

        this._snapshotIndex += 1
        this.restoreSnapshot(this._snapshots[this._snapshotIndex])
    }

    replaceGoals (goals) {
        this._goals = Object.keys(goals).reduce((carry, id) => ({
            ...carry,
            [id]: {
                ...goals[id],
            },
        }), {})
    }

    reset () {
        this._focalIndex = -1
        this._focalInstanceId = null

        this._rows = []
    }

    resetFocalInstanceId () {
        this.rebuild([], this._defaultInstanceId)
    }

    restoreSnapshot (snapshot) {
        Object.keys(snapshot.layout).forEach((item) => this[`_${item}`] = snapshot.layout[item])
        this._goals = snapshot.goals
    }

    setFocalInstanceId (instanceId) {
        this.rebuild([], instanceId)
    }

    setGoalName (id, name) {
        this._goals = {
            ...this._goals,
            [id]: {
                ...this._goals[id],
                name,
            },
        }
    }

    setRowOffset (rowIndex, offset, final = false) {
        if (this._scrollingRowSet) {
            const realRowIndex = this._rows.findIndex(({scrolling}) => scrolling)
            rowIndex = realRowIndex >= 0 ? realRowIndex : rowIndex
        }

        if (final) {
            if (this._rows[rowIndex]) {
                this._rows[rowIndex].scrolling = false
            }

            this._scrollingRowSet = false
        }

        if (! this._rows[rowIndex]) {
            return
        }

        this._previousRows[rowIndex] = {
            ...this._rows[rowIndex],
            focalInstanceId: focalInstanceIdFromOffset(offset, this._rows[rowIndex], this._windowSize),
            offset,
        }

        this.rebuild([rowIndex])
    }

    setScrollingRowIndex (rowIndex) {
        if (! this._rows[rowIndex]) {
            return
        }

        this._rows[rowIndex].scrolling = true
        this._scrollingRowSet = true
    }

    setStageOffset (offset) {
        this._offset = offset
    }

    setWindowSize (windowSize) {
        this._windowSize = windowSize
        this.rebuild()
    }

    takeSnapshot () {
        if (this._snapshotIndex !== this._snapshots.length - 1) {
            this._snapshots = this._snapshots.slice(0, (this._snapshotIndex - 1) * -1)
        }

        this._snapshots = [
            ...this._snapshots,
            {
                goals: this.goals,
                layout: this.layout,
            },
        ]

        this._snapshotIndex = this._snapshots.length - 1
    }

    toggleDragBehaviour () {
        this._dragBehaviour = this._dragBehaviour === 'move' ? 'clone' : 'move'
    }

    toggleGreedyBehaviour () {
        this._greedyBehaviour = this._greedyBehaviour === 'greedy' ? 'non-greedy' : 'greedy'
    }

    undo (revert = false) {
        if (this._snapshotIndex === 0) {
            return
        }

        this._snapshotIndex -= 1
        this.restoreSnapshot(this._snapshots[this._snapshotIndex])

        if (revert) {
            this._snapshots = this._snapshots.slice(0, -1)
        }
    }

    _acceptPosition (data) {
        this._logPosition(true, data, '')
    }

    _logPosition (accept, {rowIndex, columnIndex, offset}, reason = '') {
        console.info(`%c${accept ? 'Accepting' : 'Rejecting'} position ${offset !== undefined ? `${offset.x},${offset.y}` : ''}${rowIndex !== undefined || columnIndex !== undefined ? `${rowIndex},${columnIndex}` : ''}${reason.length ? `%c\n${reason}` : ''}${accept ? '%c' : ''}`, `color: ${accept ? 'green' : 'red'}`, 'font-weight: bold')
    }

    _rejectPosition (data, reason, noisy) {
        if (noisy) {
            return false
        }

        this._logPosition(false, data, reason)

        return false
    }
}

const inactiveFocalParent = (id, goals, layout) => {
    const focalParentInstanceId = (layout.relations[id] && layout.relations[id].parents || []).find((id) => Object.keys(layout.indexes).includes(id))
    if (! focalParentInstanceId) {
        return false
    }

    if (! goals[layout.instances[focalParentInstanceId]].active) {
        return true
    }

    return inactiveFocalParent(layout.instances[focalParentInstanceId], goals, layout)
}

export const itemsForMap = (items, mapUri, callback) => Object.keys(items).filter((id) => items[id].map_uri === mapUri).reduce((carry, id) => ({
    ...carry,
    [id]: typeof callback === 'function' ? callback(items[id]) : items[id],
}), {})

export const computeBaseXOffset = ({width}) => (width / 2) - (LAYOUT_NODE_WIDTH / 2)
export const computeBaseYOffset = (windowHeight) => (windowHeight / 2) - (LAYOUT_NODE_HEIGHT / 2)
export const computeRowOffset = (focalInstanceIndex, windowSize) => computeBaseXOffset(windowSize) - ((LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X) * focalInstanceIndex)
export const focalInstanceIdFromOffset = (offset, row, windowSize) => row.items[Math.round((computeBaseXOffset(windowSize) - offset) / (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_Y))]
export const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)
export const isValidGoalName = (name) => name.replace(/ /g, '').length > 0
export const itemsSignature = (items) => items.sort(({order: aOrder}, {order: bOrder}) => aOrder - bOrder).map(({attributes, labels, milestones, parents, tasks, ...rest}) => [
    ...attributes,
    ...labels,
    ...milestones,
    ...parents,
    ...tasks,
    ...Object.values(rest),
].join('|')).join('.')
