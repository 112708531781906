import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../actions/stashes'

const SetDates = memo(({date_due: defaultDateDue, date_initiated: defaultDateInitiated, nodeUri}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [ dateDue, setDateDue ] = useState(defaultDateDue || null)
    const [ dateInitiated, setDateInitiated ] = useState(defaultDateInitiated || null)

    const _handleChangeDue = (date_due) => {
        setDateDue(date_due)

        dispatch(stashTarget(nodeUri, {
            date_due,
        }))
    }

    const _handleChangeInitiated = (date_initiated) => {
        setDateInitiated(date_initiated)

        dispatch(stashTarget(nodeUri, {
            date_initiated,
        }))
    }

    return (
        <div className={classes.wrapper}>
            <DatePicker
                label="Start date"
                onChange={_handleChangeInitiated}
                value={dateInitiated} />
            <DatePicker
                label="Due date"
                minDate={new Date(dateInitiated).setDate(new Date(dateInitiated).getDate() + 1)}
                minDateMessage="Due date must be after start date."
                onChange={_handleChangeDue}
                value={dateDue} />
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'grid',
        gridAutoColumns: 'max-content',
        gridRowGap: theme.spacing(3),
    },
}))

SetDates.propTypes = {
    date_due: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object,
    ]),
    date_initiated: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object,
    ]),
    nodeUri: PropTypes.string.isRequired,
}

export default SetDates
