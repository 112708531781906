import Attachment from '@material-ui/icons/Attachment'
import Avatar from '@material-ui/core/Avatar'
import React from 'react'
import PropTypes from 'prop-types'

import { COLOR_BRAND_PRIMARY, COLOR_GREY_LIGHT, COLOR_RED, COLOR_WHITE } from '../constants/colors'

class ResourcesIcon extends React.Component {
    render () {
        return (
            <div style={styles.container}>
                <Avatar style={styles.icon(this.props)}>
                    <Attachment />
                </Avatar>
                {this.props.unread ? (
                    <div style={styles.unread} />
                ) : null}
            </div>
        )
    }
}

ResourcesIcon.defaultProps = {
    size: 32,
    showBackground: true,
}

const styles = {
    container: {
        position: 'relative',
    },
    icon: ({showBackground, resources_count, size, style}) => ({
        backgroundColor: showBackground ? (resources_count ? COLOR_BRAND_PRIMARY : COLOR_GREY_LIGHT) : 'transparent',
        color: resources_count ? (showBackground ? COLOR_WHITE : COLOR_BRAND_PRIMARY) : COLOR_GREY_LIGHT,
        height: size,
        width: size,
        ...style,
    }),
    unread: {
        backgroundColor: COLOR_RED,
        borderRadius: 8,
        height: 8,
        position: 'absolute',
        right: 12,
        top: 0,
        width: 8,
    },
}

export default ResourcesIcon
