import ConfigureGoalPerformanceContainer from '../containers/Map/ConfigureGoalPerformanceContainer'
import ConfigureGoalTransformationContainer from '../containers/Map/ConfigureGoalTransformationContainer'
import ConfigurePrimaryKpiContainer from '../containers/Map/ConfigurePrimaryKpiContainer'
import PrimaryKpiContainer from '../containers/Map/PrimaryKpiContainer'
import GoalPerformanceContainer from '../containers/Goal/GoalPerformanceContainer'
import GoalTransformationContainer from '../containers/Goal/GoalTransformationContainer'

export const dashboardModules = {
    goal_performance: {
        label: 'Goal Performance',
        size: 'sm',
        component: GoalPerformanceContainer,
        configureComponent: ConfigureGoalPerformanceContainer,
        default: true,
    },
    transformation_rate: {
        label: 'Transformation Rate',
        size: 'sm',
        component: GoalTransformationContainer,
        configureComponent: ConfigureGoalTransformationContainer,
        default: true,
    },
    primary_kpi: {
        label: 'Primary KPI',
        size: 'sm',
        component: PrimaryKpiContainer,
        configureComponent: ConfigurePrimaryKpiContainer,
    },
}
