import React from 'react'
import axios from 'axios'
import { CancelToken } from 'axios'
import { connect } from 'react-redux'

import Dashboard from '../components/Dashboard'
import PageLoadingIndicator from '../components/PageLoadingIndicator'
import { dashboardModules } from '../constants/modules'
import { extractMapUri, fetchMap, mapFromUri } from '../actions/map'
import { nodesForMap } from '../actions/node'

class DashboardContainer extends React.Component {
    state = {
        currentModuleIndex: 0,
        isReady: false,
    }

    componentDidMount () {
        this._processChanges(this.props, this.state, true)
    }

    componentWillUnmount () {
        if (this.cancelSource) {
            this.cancelSource.cancel()
        }
    }

    componentDidUpdate (prevProps, prevState) {
        this._processChanges(this.props, this.state)
    }

    _processChanges = (nextProps, nextState, willMount) => {
        if (mapBeingCreated(nextProps, nextState)) {
            this.setState({isReady: false})

            return this.props.history.push(`/m/create`, this.props.location.state)
        }

        if (mapWasDeleted(this.props, nextProps)) {
            this.setState({isReady: false})

            return this.props.history.push(`/m/create`, this.props.location.state)
        }

        if (willMount || mapUriHasChanged(this.props, nextProps)) {
            const map = mapFromUri(nextProps.maps, nextProps.match.params.map)
            this.setState(mapIsReady(map, nextProps) ? {isReady: true} : {isReady: false})

            if (this.cancelSource) {
                this.cancelSource.cancel()
            }

            this.cancelSource = CancelToken.source()
            return this.props.fetch(extractMapUri(nextProps.match.params.map), this.cancelSource).then(({id, uri}) => this.setState({isReady: true})).catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    return
                }

                if (thrown.response && (thrown.response.status === 403 || thrown.response.status === 404)) {
                    this.setState({isReady: false})

                    return this.props.history.push(`/m/create`)
                }

                throw new Error(thrown)
            })
        }
    }

    render () {
        if (! this.props.maps[this.props.match.params.map] || ! this.state.isReady) {
            return (
                <PageLoadingIndicator />
            )
        }

        return (
            <Dashboard
                currentModuleIndex={this.state.currentModuleIndex}
                history={this.props.history}
                map={this.props.maps[this.props.match.params.map]}
                modules={modules(this.props)}
                onChangeModuleIndex={this._handleChangeModuleIndex}
                onShowMap={this._handleShowMap} />
        )
    }

    _handleChangeModuleIndex = (event) => this.setState({currentModuleIndex: parseInt(event.target.value)})
    _handleShowMap = () => this.props.history.push(`/m/${this.props.match.params.map}`)
}

const mapBeingCreated = ({match}) => ! match.params.map || match.params.map === 'create'
const mapIsReady = (map, {nodes}) => map && nodesForMap(nodes, map.id).length > 0
const mapUriHasChanged = (props, nextProps) => props.match.params.map !== nextProps.match.params.map
const mapWasDeleted = (props, nextProps) => props.match.params.map === nextProps.match.params.map && ! nextProps.maps[nextProps.match.params.map] && props.maps[props.match.params.map]
const modules = ({match, maps}) => Object.keys(dashboardModules).filter((key) => (dashboardModules[key].default && (! maps[match.params.map] || ! Object.keys(maps[match.params.map].config.modules).length)) || (maps[match.params.map].config.modules[key] && maps[match.params.map] && maps[match.params.map].config.modules[key].enabled)).map((key) => ({
    ...dashboardModules[key],
    key,
})).sort((a, b) => maps[match.params.map] && maps[match.params.map].config && maps[match.params.map].config.modules[a.key] && maps[match.params.map].config.modules[b.key] ?  maps[match.params.map].config.modules[a.key].order - maps[match.params.map].config.modules[b.key].order : 0)

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    comments: state.comments,
    maps: state.maps,
    nodes: state.nodes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: (uri, cancelSource) => dispatch(fetchMap(uri, cancelSource)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
