import Avatar from '@material-ui/core/Avatar'
import PropTypes from 'prop-types'
import React from 'react'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'

import { COLOR_BRAND_PRIMARY, COLOR_GREY_LIGHT, COLOR_WHITE } from '../constants/colors'

class WatchIcon extends React.Component {
    render () {
        return (
            <Avatar style={styles.icon(this.props)}>
                <RemoveRedEye />
            </Avatar>
        )
    }
}

WatchIcon.defaultProps = {
    size: 32,
    showBackground: true,
}

const styles = {
    icon: ({showBackground, size, style, watching}) => ({
        backgroundColor: showBackground ? (watching ? COLOR_BRAND_PRIMARY : COLOR_GREY_LIGHT) : 'transparent',
        color: watching ? (showBackground ? COLOR_WHITE : COLOR_BRAND_PRIMARY) : COLOR_GREY_LIGHT,
        height: size,
        width: size,
        ...style,
    }),
}

export default WatchIcon
