import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import React from 'react'

import FieldsContainer from '../../containers/Calculation/FieldsContainer'
import Modal from '../Shared/Modal'

const Edit = ({busy, match, onEscape, onSubmit}) => (
    <Modal onEscape={onEscape}>
        <DialogContent style={styles.container}>
            <FieldsContainer
                busy={busy}
                match={match}
                onSubmit={onSubmit}
                submitLabel="Update"
                title="Update a calculation" />
        </DialogContent>
    </Modal>
)

Edit.defaultProps = {
    busy: false,
}

Edit.propTypes = {
    busy: PropTypes.bool,
    match: PropTypes.object.isRequired,
    onEscape: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

const styles = {
    container: {
        display: 'grid',
        gridColumnGap: 12,
        gridRowGap: 12,
        gridTemplateColumns: '1fr auto',
    },
}

export default Edit
