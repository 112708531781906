import AppBar from '@material-ui/core/AppBar'
import BuildIcon from '@material-ui/icons/Build'
import CollectionsIcon from '@material-ui/icons/Collections'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Divider from '@material-ui/core/Divider'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import IconButton from '@material-ui/core/IconButton'
import LanguageIcon from '@material-ui/icons/Language'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Link, matchPath, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import UserIcon from './UserIcon'
import { mapFromUri } from '../actions/map'

const Nav = React.memo(({location}) => {
    const classes = useStyles()

    const [ userMenuAnchorEl, setUserMenuAnchorEl ] = React.useState(null)
    const [ currentView, setCurrentView ] = React.useState(null)
    const [ currentMap, setCurrentMap ] = React.useState(null)

    const isAuthed = useSelector(({auth}) => auth.isAuthed)
    const mapName = useSelector(({maps}) => mapFromUri(maps, currentMap) && mapFromUri(maps, currentMap).name)
    const userId = useSelector(({auth}) => auth.userId)

    const isUserMenuOpen = Boolean(userMenuAnchorEl)

    const handleUserMenuOpen = (event) => setUserMenuAnchorEl(event.currentTarget)
    const handleUserMenuClose = () => setUserMenuAnchorEl(null)

    React.useEffect(() => {
        const match = matchPath(location.pathname, {
            path: '/:view/:map'
        })

        if (! match || ! ['d', 'l', 'm'].includes(match.params.view)) {
            return
        }

        setCurrentMap(match.params.map)
        setCurrentView(
            Boolean(location.pathname.match(/build\/?$/)) ? 'b' : match.params.view
        )
    }, location)

    const ViewIcon = (() => {
        if (currentView === 'd') {
            return DashboardIcon
        }

        if (currentView === 'm') {
            return LanguageIcon
        }

        if (currentView === 'l') {
            return ViewListIcon
        }

        if (currentView === 'b') {
            return BuildIcon
        }
    })()

    return (
        <React.Fragment>
            <AppBar color="secondary" position="static">
                <Toolbar>
                    {isAuthed ? (
                        <React.Fragment>
                            <IconButton className={classes.iconContainer} compponent="div" edge="start" disabled>
                                {Boolean(currentView) && <ViewIcon className={classes.icon} />}
                            </IconButton>
                            <Typography className={classes.title} variant="h6" noWrap>
                                {Boolean(currentMap) && mapName}
                            </Typography>
                            <IconButton color="inherit" component={Link} to={{pathname: '/search', state: {modal: true}}}>
                                <SearchIcon />
                            </IconButton>
                            <IconButton edge="end" onClick={handleUserMenuOpen}>
                                <UserIcon size={36} userId={userId} />
                            </IconButton>
                        </React.Fragment>
                    ) : (
                        <IconButton className={classes.iconContainer} compponent="div" edge="start" role="none" disabled>
                            <picture className={classes.logo}>
                                <source media="(min-width: 768px)" srcSet="https://d1ot1inkwflex.cloudfront.net/images/goal-atlas-logo.png" />
                                <img alt="Goal Atlas" className={classes.logo} src="https://d1ot1inkwflex.cloudfront.net/images/goal-atlas-icon.png" />
                            </picture>
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Menu anchorEl={userMenuAnchorEl} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} getContentAnchorEl={null} open={isUserMenuOpen} onClose={handleUserMenuClose} keepMounted>
                <MenuItem component={Link} onClick={handleUserMenuClose} to={{pathname: '/m', state: {modal: true}}}>
                    <ListItemIcon>
                        <CollectionsIcon />
                    </ListItemIcon>
                    <ListItemText>My Goal Maps</ListItemText>
                </MenuItem>
                <MenuItem component={Link} onClick={handleUserMenuClose} to={{pathname: '/team', state: {modal: true}}}>
                    <ListItemIcon>
                        <GroupWorkIcon />
                    </ListItemIcon>
                    <ListItemText>Manage Teams</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} onClick={handleUserMenuClose} to={{pathname: '/profile', state: {modal: true}}}>
                    <ListItemIcon>
                        <PersonOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                </MenuItem>
                <MenuItem component={Link} onClick={handleUserMenuClose} to={{pathname: '/security', state: {modal: true}}}>
                    <ListItemIcon>
                        <LockOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>Security</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} onClick={handleUserMenuClose} to={{pathname: '/auth/logout'}}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}, (prevProps, nextProps) => prevProps.location.pathname === nextProps.location.pathname)

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        marginRight: theme.spacing(2),
    },
    icon: {
        color: theme.palette.common.white,
    },
    title: {
        color: theme.palette.common.white,
        flexGrow: 1,
    },
    titleIcon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    logo: {
        height: 36,
    },
}))

export default withRouter(Nav)
