import PropTypes from 'prop-types'
import React, { Fragment, memo } from 'react'
import Typography from '@material-ui/core/Typography'
import { format as formatDate } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'

import TargetValue from '../Value'
import { COLOR_GREY_LIGHTEST } from '../../../constants/colors'

const Confirm = memo(({date_due, date_initiated, format, prefix, service, start_value, suffix, value}) => {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            <div>
                <Typography variant="subtitle2">Format</Typography>
                <Typography color="primary" variant="h6">{formatLabel(format)}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2">Start date</Typography>
                <Typography color="primary" variant="h6">{formatDate(new Date(date_initiated), 'do MMMM y')}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2">Due date</Typography>
                <Typography color="primary" variant="h6">{formatDate(new Date(date_due), 'do MMMM y')}</Typography>
            </div>
            {service.label !== 'internal.aggregate' && ! ['boolean'].includes(format) && start_value !== undefined && (
                <Fragment>
                    <div>
                        <Typography variant="subtitle2">Start value</Typography>
                        <TargetValue
                            color="primary"
                            format={format}
                            prefix={prefix}
                            suffix={suffix}
                            value={start_value}
                            variant="h6" />
                    </div>
                    <div>
                        <Typography variant="subtitle2">Target value</Typography>
                        <TargetValue
                            color="primary"
                            format={format}
                            prefix={prefix}
                            suffix={suffix}
                            value={value}
                            variant="h6" />
                    </div>
                </Fragment>
            )}
        </div>
    )
})

const formatLabel = (format) => {
    switch (format) {
        case 'boolean':
            return 'Met / not met'
        case 'decimal':
            return 'Decimal number'
        case 'integer':
            return 'Whole number'
    }
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: COLOR_GREY_LIGHTEST,
        borderRadius: theme.shape.borderRadius,
        display: 'grid',
        gridRowGap: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}))

Confirm.propTypes = {
    date_due: PropTypes.oneOfType([
        PropTypes.object, PropTypes.string,
    ]),
    date_initiated: PropTypes.oneOfType([
        PropTypes.object, PropTypes.string,
    ]),
    format: PropTypes.oneOf([
        'boolean', 'decimal', 'integer',
    ]),
    prefix: PropTypes.string,
    start_value: PropTypes.oneOfType([
        PropTypes.number, PropTypes.string,
    ]),
    suffix: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number, PropTypes.string,
    ]),
}

export default Confirm
