import PropTypes from 'prop-types'
import React from 'react'

import PageLoadingIndicator from './PageLoadingIndicator'

const Welcome = ({html, isReady}) => isReady ? (
    <div dangerouslySetInnerHTML={{__html: html}} />
) : (
    <PageLoadingIndicator />
)

Welcome.defaultProps = {
    isReady: false,
}

Welcome.propTypes = {
    html: PropTypes.string,
    isReady: PropTypes.bool,
}

export default Welcome
