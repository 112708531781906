import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Chip from '../Label/Chip'

const Labels = memo(({className, labelsIndex, loading, uri}) => {
    const classes = useStyles()
    const theme = useTheme()

    const labels = useSelector(({labels}) => Object.values(labels).filter(({id}) => labelsIndex.includes(id))).sort(({name: aName}, {name: bName}) => aName.localeCompare(bName))

    const manageLabel = useMediaQuery(theme.breakpoints.up('sm')) ? 'Manage labels' : 'Manage'

    const _emptyValue = () => loading ? (
        <Skeleton className={classes.empty} />
    ) : (
        <Typography className={classes.empty} variant="body2">This goal does not have any associated labels.</Typography>
    )

    return (
        <div className={`${classes.wrapper} ${className}`}>
            {labels.length ? (
                <div className={classes.labels}>
                    {labels.map(({hex, id, name}) => (
                        <Chip
                            className={classes.label}
                            hex={hex}
                            key={`label-${id}`}
                            name={name} />
                    ))}
                </div>
            ) : _emptyValue()}
            <Button component={Link} size="small" to={{pathname: `${uri}/label`, state: {modal: true}}} variant="outlined">
                {manageLabel}
            </Button>
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        alignItems: 'flex-start',
        display: 'flex',
    },
    labels: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        marginBottom: theme.spacing(-1),
        marginLeft: theme.spacing(-.5),
        marginRight: theme.spacing(1.5),
    },
    label: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
    },
    empty: {
        alignSelf: 'center',
        flex: 1,
        fontWeight: '300',
        marginRight: theme.spacing(2),
    },
}))

Labels.defaultValues = {
    className: '',
    loading: false,
}

Labels.propTypes = {
    className: PropTypes.string,
    labelsIndex: PropTypes.array,
    loading: PropTypes.bool,
    uri: PropTypes.string.isRequired,
}

export default Labels
