import BuildIcon from '@material-ui/icons/Build'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DashboardIcon from '@material-ui/icons/Dashboard'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import LanguageIcon from '@material-ui/icons/Language'
import ViewListIcon from '@material-ui/icons/ViewList'
import axios from 'axios'

import hash from '../includes/hash'
import { LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_Y } from '../constants/layout'
import { RECEIVE_LABELS } from './label'

export const ADD_ROW_TO_MAP = 'ADD_ROW_TO_MAP'
export const RECEIVE_MAP = 'RECEIVE_MAP'
export const RECEIVE_MAPS = 'RECEIVE_MAPS'
export const REMOVE_MAP = 'REMOVE_MAP'
export const MAP_IS_EDITING = 'MAP_IS_EDITING'
export const MAP_IS_VIEWING = 'MAP_IS_VIEWING'
export const SET_CORE_NODE_ID = 'SET_CORE_NODE_ID'
export const SET_MAP_OFFSET = 'SET_MAP_OFFSET'
export const SET_ROW_CURRENT_INDEX = 'SET_ROW_CURRENT_INDEX'
export const UPDATE_MAP_DASHBOARD_LAYOUT = 'UPDATE_MAP_DASHBOARD_LAYOUT'

const receiveLabels = (labels) => ({
    type: RECEIVE_LABELS,
    labels,
})

const removeMap = (uri) => ({
    type: REMOVE_MAP,
    payload: {
        uri,
    },
})

export const putMap = (uri, map) => (dispatch, getState) => {
    dispatch(receiveMap({
        ...map,
        busy: true,
    }))

    return axios.put(`/map/${uri}`, map).then(({data}) => dispatch(receiveMap({
        ...data,
        busy: false,
    })))
}

const receiveMap = (map) => ({
    type: RECEIVE_MAP,
    payload: {
        map,
    },
})

const receiveMaps = (maps) => ({
    type: RECEIVE_MAPS,
    payload: {
        maps,
    },
})

export const addRowToMap = (id, index) => ({
    type: ADD_ROW_TO_MAP,
    id, index,
})

export const mapIsEditing = (id) => ({
    type: MAP_IS_EDITING,
    id,
})

export const mapIsViewing = (id) => ({
    type: MAP_IS_VIEWING,
    id,
})

const updateMapDashboardLayout = (id, layout) => ({
    type: UPDATE_MAP_DASHBOARD_LAYOUT,
    id, layout,
})

export const storeMap = (data) => (dispatch, getState) =>
    axios.post('/map', data, {
        timeout: 5000,
    }).then(({data}) => {
        dispatch(receiveMap(data))

        return data
    }).catch((response) => {

    })

export const updateMap = (uri, data) => (dispatch, getState) =>
    axios.put(`/map/${uri}`, data).then(({data}) => {
        dispatch(receiveMap(data))

        return data
    })

export const cloneMap = (uri, data) => (dispatch, getState) => axios.post(`/map/${uri}/clone`, data).then(({data}) => {
    dispatch(receiveMap(data))

    return data
})

export const fetchMapAsXls = (uri) => axios.get(`/map/${uri}`, {
    responseType: 'blob',
    params: {
        format: 'xls',
    },
}).then(({data}) => data)

export const setCoreNodeId = (id, nodeId) =>  ({
    type: SET_CORE_NODE_ID,
    id, nodeId,
})

export const setMapOffset = (id, offset) => ({
    type: SET_MAP_OFFSET,
    id, offset,
})

export const setRowCurrentIndex = (id, index, nodeIndex) => ({
    type: SET_ROW_CURRENT_INDEX,
    id, index, nodeIndex,
})

export const getCoreRowIndex = (map) => map ? map.rows.findIndex((row) => row.nodes.includes(map.core_node_id || map.default_node_id)) : null

export const deleteMap = (uri) => (dispatch, getState) => axios.delete(`/map/${uri}`).then(({data}) => dispatch(removeMap(data.uri))).catch(({response}) => {
    if (response.status === 404) {
        dispatch(removeMap(uri))
    }
})


export const configureMap = (uri, data) => (dispatch, getState) => axios.put(`/map/${uri}/configure`, data).then(({data}) => dispatch(receiveMap(data)))
export const configureMapModule = (uri, module, data, isDefault) => (dispatch, getState) => axios.put(`/map/${uri}/configure`, {options: {[module]: data}, isDefault}).then(({data}) => dispatch(receiveMap(data)))

export const replotMap = (id, offsetY) => (dispatch, getState) => {
    const map = plot(getState().maps[id], getState().nodes)

    const lastCoreRowIndex = getCoreRowIndex(getState().maps[id])
    const nextCoreRowIndex = getCoreRowIndex(map)

    if (nextCoreRowIndex !== lastCoreRowIndex) {
        map.offsetY = offsetY || getState().maps[id].offsetY - ((LAYOUT_NODE_HEIGHT + (LAYOUT_NODE_SPACING_Y * 2)) * (nextCoreRowIndex - lastCoreRowIndex))
    }

    dispatch(receiveMap(map))

    return map
}

const plot = (map, nodes) => {
    if (! map) {
        return map
    }

    return {
        ...map,
        rows: [
            ...buildRows(Object.values(nodes).filter((node) => node.map_id === map.id), map.rows, map.core_node_id || map.default_node_id, true).reverse(),
            {
                currentIndex: 0,
                nodes: [map.core_node_id || map.default_node_id],
            },
            ...buildRows(Object.values(nodes).filter((node) => node.map_id === map.id), map.rows, map.core_node_id || map.default_node_id),
        ]
    }
}

const buildRows = (nodes, lastState, nodeId, reverse = false, nextState = []) => {
    const row = buildRow(nodes, nodeId, lastState, reverse)

    return row.nodes.length ?
        buildRows(nodes, lastState, row.nodes[row.currentIndex], reverse, [...nextState, row], ) : nextState
}

const buildRow = (_nodes, nodeId, lastState, reverse = false) => {
    const nodes = _nodes.filter(({id, parents}) => reverse ? _nodes.find(({id}) => id === nodeId).parents.includes(id) : (nodeId ? parents.includes(nodeId) : ! parents.length)).sort((a,b) => a.order - b.order)

    return {
        currentIndex: currentNodeIndex(lastState, nodes),
        nodes: nodes.map(({id}) => id),
    }
}

const currentNodeIndex = (lastState, nodes) => {
    const nodeIds = nodes.filter(({exists}) => exists).map(({id}) => id)
    const nodeIdsString = nodeIds.join('|')

    const lastRow = lastState ? lastState.find((row) => row.nodes.filter((id) => nodeIds.includes(id)).join('|').indexOf(nodeIdsString) >= 0) : null

    return lastRow && lastRow.currentIndex < nodes.length ? lastRow.currentIndex: 0
}

export const fetchMap = (uri, cancelSource) =>
    (dispatch, getState) => {
        return axios.get(`/map/${uri}`, {cancelToken: cancelSource ? cancelSource.token : null}).then(({data}) => {
            const { labels, ...rest } = data

            dispatch(receiveMap(rest))

            if (labels !== undefined) {
                dispatch(receiveLabels(labels))
            }

            return rest
        })
    }

export const fetchMaps = () => (dispatch, getState) =>
    axios.get('/map').then(({data}) => dispatch(receiveMaps(data)))

export const mapFromUri = (maps, uri, index = 0) => maps[uri] || {
    name: '',
}

export const generateMapLinks = (state = {}, uri, view, viewOnly = false) => {
    const { auth, maps, users } = state

    return ([
        ...[
            {
                Icon: DashboardIcon,
                tooltip: 'Dashboard view',
                pathname: `/d/${uri}`,
            },
            {
                Icon: LanguageIcon,
                tooltip: 'Map view',
                pathname: `/m/${uri}`,
            },
            {
                Icon: ViewListIcon,
                tooltip: 'List view',
                pathname: `/l/${uri}`,
            },
        ],
        ...(viewOnly || ! mapFromUri(maps, uri).id || users[auth.userId].teams_index[mapFromUri(maps, uri).team_uri].includes('READ_ONLY') ? [] : [
            {
                Icon: BuildIcon,
                tooltip: 'Build view',
                readOnly: false,
                pathname: `/m/${uri}/build`,
            },
        ]),
        ...(viewOnly ? [] : [
            {
                Icon: LabelOutlinedIcon,
                tooltip: 'Manage labels',
                pathname: `/${view}/${uri}/label`,
                state: {
                    modal: true,
                },
            },
            {
                Icon: CloudDownloadIcon,
                tooltip: 'Export',
                pathname: `/${view}/${uri}/export`,
                state: {
                    modal: true,
                },
            },
        ]),
    ])
}

export const mapFromIdOrUri = (maps, key) => Object.values(maps).find(({id, uri}) => id === key || uri === key)

export const rowSignature = (nodes) => hash(nodes.sort((a,b) => a.order - b.order).map((node) => `${node.name}${node.active}${node.order}${node.priority}${node.watching}${node.assigned_user ? node.assigned_user.id : null}${node.comment_count}${node.labels.map(({id}) => id).join()}${node.target_progress}${node.unread_comment_count}${node.unread_resources}${node.parents.length}${node.resources_count}`).join())

export const extractMapUri = (uri, index = 0) => Array.isArray(uri) ? uri[index] : uri

export const setMapDashboardLayout = (id, layout = {}) => (dispatch, getState) => dispatch(updateMapDashboardLayout(id, layout))
