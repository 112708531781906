import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import UserIcon from '../UserIcon'

const AssignmentChip = memo(({assignedUser, loading, targetWarning, uri}) => {
    const classes = useStyles()

    const avatar = loading ? (
        <Skeleton
            variant="circle" />
    ) : (
        <UserIcon
            size={24}
            userId={assignedUser ? assignedUser.id : null} />
    )

    const label = loading ? (
        <Skeleton
            variant="text"
            width={60} />
    ) : (
        assignedUser === null ? 'Unassigned' : assignedUser.name
    )

    const showTargetWarning = targetWarning && assignedUser === null

    return (
        <React.Fragment>
            {showTargetWarning ? (
                <Alert className={classes.alert} severity="warning">
                    <AlertTitle>Unassigned target</AlertTitle>
                    This goal has an active target, but no one has been assigned to it yet.
                </Alert>
            ) : <div className={`${classes.alert} ${classes.placeholder}`} />}
            <Chip
                avatar={avatar}
                color="secondary"
                component={Link}
                label={label}
                to={{pathname: `${uri}/assignment`, state: {modal: true}}}
                clickable />
        </React.Fragment>
    )
})

const useStyles = makeStyles(theme => ({
    alert: {
        [theme.breakpoints.up('sm')]: {
            gridColumn: '1 / 3',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: `calc(100vw - ${theme.spacing(14)}px)`,
            width: 'max-content',
        },
    },
    placeholder: {
        position: 'absolute',
    },
}))

AssignmentChip.defaultProps = {
    loading: false,
    targetWarning: false,
}

AssignmentChip.propTypes = {
    assignedUser: PropTypes.shape({
        id: PropTypes.number.isRequired,
        profile_picture: PropTypes.string,
    }),
    loading: PropTypes.bool,
    targetWarning: PropTypes.bool,
    uri: PropTypes.string.isRequired,
}

export default AssignmentChip
