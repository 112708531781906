import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'
import Select from '@material-ui/core/Select'
import Skeleton from '@material-ui/lab/Skeleton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { VariableSizeList } from 'react-window'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import ActionsMenu from './ActionsMenu'
import CommentsIcon from '../CommentsIcon'
import Container from '../Shared/Container'
import Heading from '../Shared/Heading'
import LabelChip from '../Label/Chip'
import PageLoadingIndicator from '../PageLoadingIndicator'
import PriorityIcon from '../PriorityIcon'
import ResourcesIcon from '../ResourcesIcon'
import TargetIconContainer from '../../containers/Target/TargetIconContainer'
import UserIcon from '../UserIcon'
import WatchIcon from '../WatchIcon'
import { HEADER_HEIGHT } from '../../constants/layout'

const List = ({awaitingRows, currentFilters, currentSort, emptyMessage, filters, goals, isFiltersLoading, isReady, mapUri, onFiltersChange, onSortChange, users}) => {
    const [ windowHeight, setWindowHeight ] = React.useState(0)
    const [ titleHeight, setTitleHeight ] = React.useState()
    const [ titleNode, setTitleNode ] = React.useState()

    const classes = useStyles()
    const theme = useTheme()

    React.useLayoutEffect(() => {
        const measure = () => window.requestAnimationFrame(() => setWindowHeight(window.innerHeight))
        measure()

        window.addEventListener('resize', measure)

        return () => window.removeEventListener('resize', measure)
    }, [window])

    const titleRef = React.useCallback((titleNode) => setTitleNode(titleNode), [])
    React.useLayoutEffect(() => {
        if (! titleNode) {
            return
        }

        const measure = () => window.requestAnimationFrame(() => setTitleHeight(titleNode.getBoundingClientRect().height))
        measure()

        window.addEventListener('resize', measure)

        return () => window.removeEventListener('resize', measure)
    }, [titleNode])

    const Row = ({index, style}) => goals[index] ? (
        <ListItem ContainerComponent="div" ContainerProps={{style}} alignItems="flex-start" className={classes.item} component={Link} key={index} to={{pathname: `/l/${mapUri}/g/${goals[index].uri}`, state: {modal: true}}} button>
            <ListItemAvatar>
                {goals[index].enriched ? (
                    <UserIcon userId={goals[index].assigned} />
                ) : (
                    <Skeleton
                        height={40}
                        variant="circle"
                        width={40} />
                )}
            </ListItemAvatar>
            <ListItemText
                className={classes.nameContainer}
                primary={(
                    <Typography noWrap>{goals[index].name}</Typography>
                )}
                secondary={(
                    <React.Fragment>
                        <div className={classes.iconsContainer}>
                            {goals[index].enriched ? (
                                <React.Fragment>
                                    <PriorityIcon
                                        priority={goals[index].priority}
                                        showBackground={false}  />
                                    <WatchIcon
                                        showBackground={false}
                                        watching={goals[index].watching} />
                                    <CommentsIcon
                                        comments={goals[index].comment_count}
                                        showBackground={false}
                                        unread={goals[index].unread_comment_count > 0} />
                                    <ResourcesIcon
                                        showBackground={false}
                                        unread={goals[index].unread_resources} />
                                </React.Fragment>

                            ) : (
                                [...Array(4)].map((e, index) => (
                                    <Skeleton
                                        height={32}
                                        key={`si-${index}`}
                                        variant="circle"
                                        width={32} />
                                ))
                            )}
                        </div>
                        {Boolean(goals[index].labels.length > 0) && (
                            <div className={classes.labelsContainer}>
                                {goals[index].labels.map(({hex, id, name}) => (
                                    <LabelChip
                                        hex={hex}
                                        key={id}
                                        name={name}
                                        size="small" />
                                ))}
                            </div>
                        )}
                    </React.Fragment>
                )} />
            <ListItemSecondaryAction className={classes.actions}>
                {goals[index].enriched ? (
                    <TargetIconContainer
                        size={40}
                        target={goals[index].target} />
                ) : (
                    <Skeleton
                        height={40}
                        variant="circle"
                        width={40} />
                )}
            </ListItemSecondaryAction>
        </ListItem>
    ) : (
        <div className={classes.loadingRowWrapper} style={style}>
            <PageLoadingIndicator size={30} />
        </div>
    )

    const getItemSize = (index) => goals[index] && goals[index].labels.length > 0 ? 125 : 92
    const totalRows = awaitingRows ? goals.length + 1 : goals.length

    const listRef = React.createRef()
    React.useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0)
        }
    }, [goals])

    const [ filtersOpen, setFiltersOpen ] = React.useState(false)

    const handleFiltersClose = () => setFiltersOpen(false)
    const handleFiltersOpen = () => setFiltersOpen(true)

    return (
        <React.Fragment>
            <Container>

                {isReady ? (
                    <React.Fragment>
                        <div ref={titleRef}>
                            <Heading>Goals</Heading>
                            <div className={classes.filtersContainer}>
                                <Autocomplete
                                    className={classes.filter}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={({label}) => label}
                                    groupBy={({group}) => group}
                                    loading={isFiltersLoading}
                                    onChange={onFiltersChange}
                                    onClose={handleFiltersClose}
                                    onOpen={handleFiltersOpen}
                                    open={filtersOpen}
                                    options={filters}
                                    renderInput={(props) => (
                                        <Input
                                            {...props}
                                            loading={isFiltersLoading} />
                                    )}
                                    value={currentFilters}
                                    multiple />
                                <FormControl className={classes.sort} variant="outlined">
                                    <InputLabel>Sort</InputLabel>
                                    <Select onChange={onSortChange} value={currentSort}>
                                        <MenuItem value="updated_at">Recently Updated</MenuItem>
                                        <MenuItem value="name">Goal name</MenuItem>
                                        <MenuItem value="priority">Priority</MenuItem>
                                        <MenuItem value="target_due">Target Due Date</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {Boolean(goals.length || awaitingRows) ? (
                            <VariableSizeList height={windowHeight - HEADER_HEIGHT - titleHeight - theme.spacing(11)} itemCount={totalRows} itemSize={getItemSize} ref={listRef} width="100%">
                                {Row}
                            </VariableSizeList>
                        ) : (
                            <Typography className={classes.empty}>{emptyMessage}</Typography>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Heading ref={titleRef}>Goals</Heading>
                        <PageLoadingIndicator />
                    </React.Fragment>
                )}
            </Container>
            <ActionsMenu
                className={classes.actionsMenuTrigger}
                uri={mapUri} />
        </React.Fragment>
    )
}

const Input = ({loading, ...rest}) => (
    <TextField
        {...rest}
        InputProps={{
            ...rest.InputProps,
            endAdornment: (
                <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {rest.InputProps.endAdornment}
                </React.Fragment>
            ),
        }}
        label="Filter"
        variant="outlined" />
)

const useStyles = makeStyles((theme) => ({
    filtersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    filter: {
        flex: 1,
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(8),
    },
    sort: {
        minWidth: 200,
        marginBottom: theme.spacing(2),
    },
    item: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    actions: {
        transform: 'none',
        top: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            right: 0,
        },
    },
    iconsContainer: {
        display: 'grid',
        gridAutoColumns: 'min-content',
        gridAutoFlow: 'column',
        gridColumnGap: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    labelsContainer: {
        display: 'grid',
        gridAutoColumns: 'min-content',
        gridAutoFlow: 'column',
        gridColumnGap: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    actionsMenuTrigger: {
        bottom: theme.spacing(2),
        position: 'absolute',
        right: theme.spacing(2),
    },
    empty: {
        fontWeight: 300,
        marginTop: theme.spacing(2),
    },
    loadingRowWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
}))

List.defaultProps = {
    awaitingRows: false,
    isFiltersLoading: false,
    isReady: true,
}

List.propTypes = {
    awaitingRows: PropTypes.bool,
    currentFilters: PropTypes.array.isRequired,
    currentSort: PropTypes.oneOf([
        'name', 'priority', 'target_due', 'updated_at',
    ]).isRequired,
    emptyMessage: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    goals: PropTypes.array.isRequired,
    isFiltersLoading: PropTypes.bool,
    isReady: PropTypes.bool,
    mapUri: PropTypes.string.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    users: PropTypes.object.isRequired,
}

export default List
