import React from 'react'
import { connect } from 'react-redux'

import TeamForm from '../components/TeamForm'
import { storeTeam } from '../actions/team'

class TeamCreateContainer extends React.Component {
    state = {
        isBusy: false,
        hasChanged: false,
        isValid: false,
    }

    render () {
        return (
            <TeamForm
                canSubmit={Boolean(this.state.hasChanged && this.state.isValid)}
                isBusy={this.state.isBusy}
                onChange={this._handleChange}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                saveLabel="Add"
                title="Add a Team" />
        )
    }

    _handleSubmit = ({name}, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.store(name).then(this.props.history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => this.setState({
            isBusy: false,
        }))
    }

    _handleChange = (formData, hasChanged) => this.setState({hasChanged})
    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    store: (name) => dispatch(storeTeam(name)),
})

export default connect(null, mapDispatchToProps)(TeamCreateContainer)
