import PropTypes from 'prop-types'
import React from 'react'
import { withFormsy } from 'formsy-react'

const FormsyHidden = ({
    defaultValue, disabled, errorMessage, errorMessages, hasValue, innerRef, isFormDisabled, isFormSubmitted, isPristine, isRequired, isValid, isValidValue, resetValue, setValidations, setValue, showError, showRequired, validationError, validationErrors, validations, value, ...rest
}) => {
    React.useEffect(() => setValue(defaultValue), [defaultValue])

    return (
        <input
            {...rest}
            disabled={isFormDisabled || disabled}
            type="hidden"
            value={value === undefined ? defaultValue : value} />
    )
}

FormsyHidden.defaultProps = {
    defaultValue: '',
}

FormsyHidden.propTypes = {
    disabled: PropTypes.bool,
}

export default withFormsy(FormsyHidden)
