import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'

import FormsyText from '../formsy/FormsyText'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'

class Edit extends React.Component {
    static propTypes = {
        busy: PropTypes.bool,
        canSubmit: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        onInvalid: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onValid: PropTypes.func.isRequired,
    }

    static defaultProps = {
        busy: false,
        canSubmit: false,
    }

    render () {
        return (
            <Modal>
                <DialogTitle>Edit the resource</DialogTitle>
                <DialogContent>
                    <DialogContentText>Edit this resource&rsquo;s label and description.</DialogContentText>
                    <Formsy onValid={this.props.onValid} onValidSubmit={this.props.onSubmit} onInvalid={this.props.onInvalid}>
                        <fieldset>
                            <FormsyText
                                defaultValue={this.props.resource.label}
                                label="Label"
                                maxLength={60}
                                name="label"
                                fullWidth required />
                        </fieldset>
                        <fieldset>
                            <FormsyText
                                defaultValue={this.props.resource.description}
                                label="Description"
                                maxLength={120}
                                name="description"
                                rows="2"
                                fullWidth multiline />
                        </fieldset>
                        <div style={styles.button}>
                            <LoadingButton busy={this.props.busy} color="primary" disabled={! this.props.canSubmit} type="submit" variant="contained" fullWidth>
                                Update
                            </LoadingButton>
                        </div>
                    </Formsy>
                </DialogContent>
            </Modal>
        )
    }
}

const styles = {
    button: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
}

export default Edit
