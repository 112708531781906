import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { COLOR_WHITE } from '../../constants/colors'

const Overlay = ({children, onEscape}) => {
    const classes = useStyles()

    return (
        <div className={classes.outer}>
            <div className={classes.backdrop} onClick={onEscape} />
            <Dialog PaperProps={{className: classes.container}} onClose={onEscape} open>
                {typeof children === 'function' ? children(onEscape) : children}
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    outer: {
        alignItems: 'center',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 1300,
    },
    backdrop: {
        backgroundColor: 'rgba(255,255,255,.7)',
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: -1,
    },
    container: {
        backgroundColor: COLOR_WHITE,
        maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
        maxWidth: 'none',
        minWidth: 280,
        overflowY: 'auto',
    },
}))

Overlay.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.element, PropTypes.bool,
        ])),
    ]).isRequired,
    onEscape: PropTypes.func.isRequired,
}

export default Overlay
