import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import React, { memo, useRef, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import FormsyFile from '../formsy/FormsyFile'
import FormsyText from '../formsy/FormsyText'
import LoadingButton from '../LoadingButton'
import { COLOR_GREY_LIGHT } from '../../constants/colors'
import { storeComment } from '../../actions/comment'

const Create = memo(({className, nodeUri}) => {
    const classes = useStyles()

    const [ hasChanged, setHasChanged ] = useState(false)
    const [ isBusy, setIsBusy ] = useState(false)
    const [ isValid, setIsValid ] = useState(false)

    const canSubmit = Boolean(hasChanged && isValid)

    const dispatch = useDispatch()

    const _handleSubmit = (formData, resetForm, invalidateForm) => {
        setIsBusy(true)

        dispatch(storeComment(nodeUri, formData)).then(() => resetForm()).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setIsBusy(false))
    }

    const _handleChange = (formData, hasChanged) => setHasChanged(hasChanged)
    const _handleInvalid = () => setIsValid(false)
    const _handleValid = () => setIsValid(true)

    const node = useRef(null)

    return (
        <Formsy className={`${classes.wrapper} ${className}`} onChange={_handleChange} onInvalid={_handleInvalid} onValid={_handleValid} onValidSubmit={_handleSubmit}>
            <FormsyText
                InputProps={{
                    classes: useStylesInput(),
                    disableUnderline: true,
                }}
                className={classes.inputWrapper}
                label="Add a comment or resource&hellip;"
                name="comment"
                rows={3}
                variant="filled"
                fullWidth multiline required />
            <Typography variant="caption" paragraph>We support <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">markdown</a> to format your comments.</Typography>
            <div className={classes.controls}>
                <FormsyFile
                    ButtonProps={{size: 'small'}}
                    label="Attach"
                    name="resource"
                    previewClass={classes.preview}
                    previewNode={node.current} />
                <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} size="small" type="submit" variant="contained">
                    Add
                </LoadingButton>
            </div>
            <div className={classes.previewWrapper} ref={node} />
        </Formsy>
    )
})

Create.defaultProps = {
    className: '',
}

Create.propTypes = {
    className: PropTypes.string,
    nodeUri: PropTypes.string.isRequired,
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
    },
    inputWrapper: {
        marginTop: 0,
    },
    previewWrapper: {
        minWidth: '100%',
        width: 200,
    },
    preview: {
        marginLeft: 0,
        marginTop: theme.spacing(1),
    },
    controls: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
}))

const useStylesInput = makeStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(255, 255, 255, 1)',

        '&:before': {
            content: 'none',
        },

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .9)',
        },

        '&$focused': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    focused: {},
}))

export default Create
