import React from 'react'
import { connect } from 'react-redux'

import { logout } from '../../actions/auth'

class Logout extends React.Component {
    componentDidMount () {
        this.props.logout().finally(() => localStorage.setItem('logout', Math.random()))
    }

    render () {
        return null
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch(logout()),
})

export default connect(null, mapDispatchToProps)(Logout)
