import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React, { Fragment, memo, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { compareDesc, parseISO } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Item from '../Comment/Item'
import Create from '../Comment/Create'
import { COLOR_GREY_LIGHTER, COLOR_GREY_LIGHTEST, COLOR_WHITE } from '../../constants/colors'
import { fetchCommentsForNode } from '../../actions/comment'

const Comments = memo(({className, uri}) => {
    const classes = useStyles()

    const comments = useSelector(({comments}) => Object.values(comments).filter(({node}) => node && node.uri === uri)).sort(({created_at: aCreatedAt, pinned: aPinned}, {created_at: bCreatedAt, pinned: bPinned}) => ! aPinned && bPinned ? 1 : (aPinned && ! bPinned ? -1 : compareDesc(parseISO(aCreatedAt), parseISO(bCreatedAt))))
    const dispatch = useDispatch()

    const [ isReady, setIsReady ] = useState(Boolean(comments.length))
    const [ lastPage, setLastPage ] = useState(1)
    const [ page, setPage ] = useState(1)

    useEffect(() => {
        dispatch(fetchCommentsForNode(uri, page)).then(({data, meta}) => {
            setLastPage(meta.last_page)
            setIsReady(true)
        })
    }, [page])

    return (
        <div className={className}>
            <Typography variant="h6">Comments &amp; Resources</Typography>
            {isReady && (
                <Fragment>
                    <div className={classes.wrapper}>
                        {comments.map((item) => (
                            <Item
                                className={classes.item}
                                key={item.id}
                                {...item} />
                        ))}
                        {Boolean(! comments.length) && (
                            <div className={classes.item}>
                                <Typography className={classes.emptyText} color="secondary" variant="subtitle2">Start the conversation by adding the first comment or resource to this goal.</Typography>
                            </div>
                        )}
                    </div>
                    <div className={classes.createWrapper}>
                        <Paper className={classes.createPaper} elevation={0}>
                            <Create
                                nodeUri={uri} />
                        </Paper>
                    </div>
                </Fragment>
            )}
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: COLOR_GREY_LIGHTEST,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        display: 'grid',
        flex: 1,
        gridAutoRows: 'min-content',
        gridRowGap: theme.spacing(1),
        overflowY: 'auto',
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    item: {
        border: `${COLOR_GREY_LIGHTER} 1px solid`,
        backgroundColor: COLOR_WHITE,
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    createWrapper: {
        backgroundColor: COLOR_GREY_LIGHTEST,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    createPaper: {
        backgroundColor: '#d9f3f1',
        border: `${COLOR_GREY_LIGHTER} 1px solid`,
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    emptyText: {
        textAlign: 'center',
    },
}))

Comments.defaultProps = {
    className: '',
}

Comments.propTypes = {
    className: PropTypes.string,
}

export default Comments
