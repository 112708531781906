import Button from '@material-ui/core/Button'
import React, { Fragment, memo, useEffect, useState } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import StepContent from '@material-ui/core/StepContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Confirm from './Steps/Confirm'
import Container from '../Shared/Container'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import SelectAggregate from './Steps/SelectAggregate'
import SelectService from './Steps/SelectService'
import SelectUnits from './Steps/SelectUnits'
import SetDates from './Steps/SetDates'
import SetLabel from './Steps/SetLabel'
import SetValues from './Steps/SetValues'
import { fetchTargetForNode, storeTarget } from '../../actions/target'
import { historyShape, nodeMatchShape } from '../../constants/types'

const Manage = memo(({history, match}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const stash = useSelector(({stashes}) => stashes.targets[match.params.node])
    const storedTarget = useSelector(({targets}) => Object.values(targets).find(({node}) => node && node.uri === match.params.node))

    const target = {
        ...({
            service: {},
        }),
        ...storedTarget,
        ...stash,
    }

    const [ activeStep, setActiveStep ] = useState(0)
    const [ completedSteps, setCompletedSteps ] = useState([])
    const [ isReady, setIsReady ] = useState(Boolean(target.id))
    const [ isBusy, setIsBusy ] = useState(false)

    const steps = getSteps(target)

    useEffect(() => {
        dispatch(fetchTargetForNode(match.params.node)).then(() => setIsReady(true)).catch((error) => {
            if (error.response && error.response.status === 404) {
                return setIsReady(true)
            }

            throw new Error(error)
        })
    }, [])

    useEffect(() => {
        const currentCompletedSteps = _updateCompletedSteps()

        if (activeStep === 0 && currentCompletedSteps.includes(0)) {
            setActiveStep(currentCompletedSteps.length ? (currentCompletedSteps[currentCompletedSteps.length - 1] >= steps.length - 1 ? steps.length - 1 : currentCompletedSteps[currentCompletedSteps.length - 1] + 1) : 0)
        }
    }, [
        target.date_due, target.date_initiated, target.description, target.format, target.start_value, target.service.configs, target.service.label, target.value,
    ])

    const _handleNextStep = () => {
        if (steps.length - 1 <= activeStep) {
            return _handleSubmit()
        }

        setActiveStep(activeStep + 1)
    }

    const _handlePreviousStep = () => {
        if (activeStep === 0) {
            return
        }

        setActiveStep(activeStep - 1)
    }

    const _handleSubmit = () => {
        setIsBusy(true)

        dispatch(storeTarget(match.params.node, {
            date_due: target.date_due,
            date_initiated: target.date_initiated,
            description: target.description,
            format: target.format,
            prefix: target.prefix,
            service: target.service,
            start_value: target.start_value,
            suffix: target.suffix,
            value: target.value,
        })).then(history.goBack).finally(() => setIsBusy(false))
    }

    const _updateCompletedSteps = () => {
        if (! target) {
            setCompletedSteps([])
        }

        const currentCompletedSteps = steps.map((item, index) => index).reduce((carry, index) => ([
            ...carry,
            ...(index === 0 || carry.includes(index - 1)) && stepShouldComplete(steps[index].key, target) ? [index] : [],
        ]), [])

        setCompletedSteps(currentCompletedSteps)

        return currentCompletedSteps
    }

    return (
        <Modal>
            <Container modal>
                <Heading>Manage Target</Heading>
                {isReady ? (
                    <Stepper activeStep={activeStep} className={classes.wrapper} orientation="vertical">
                        {steps.map(({description, key, label}, index) => {
                            const handleClick = () => setActiveStep(index)

                            return (
                                <Step completed={completedSteps.includes(index)} disabled={stepDisabled(index, completedSteps)} key={`step-${index}`}>
                                    <StepButton onClick={handleClick}>
                                        {label}
                                    </StepButton>
                                    <StepContent>
                                        <Typography className={classes.description} variant="subtitle2">{description}</Typography>
                                        {getStepContent(key, target, match.params.node, classes)}
                                        <div className={classes.controls}>
                                            <Button color="secondary" disabled={index === 0} onClick={_handlePreviousStep}>
                                                Back
                                            </Button>
                                            <LoadingButton busy={isBusy} color="primary" disabled={stepDisabled(index + 1, completedSteps)} onClick={_handleNextStep} >
                                                {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                                            </LoadingButton>
                                        </div>
                                    </StepContent>
                                </Step>
                            )
                        })}
                    </Stepper>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
})

const getSteps = (target = {}) => {
    const steps = ([
        {
            label: 'Select target data source',
            description: 'Choose from our available integrations to populate your target data:',
            key: 'service',
        },
    ])

    steps.push(...[
        {
            label: 'Set target dates',
            description: 'Choose the dates this target should run from and to:',
            key: 'dates',
        },
        {
            label: 'Select target format',
            description: 'Choose how target values are formatted:',
            key: 'units',
        },
    ])

    if (target.service.label === 'internal.aggregate') {
        steps.push({
            label: 'Select aggregate data',
            description: 'Choose which target values are used to form the aggregate value:',
            key: 'aggregate',
        })
    }

    if (target.service.label !== 'internal.aggregate' && ! ['boolean'].includes(target.format)) {
        steps.push({
            label: 'Set target values',
            description: 'Provide the current and target values:',
            key: 'values',
        })
    }

    steps.push({
        label: 'Label and confirm',
        description: 'Label your target and confirm everything is correct:',
        key: 'confirm',
    })

    return steps
}

const getStepContent = (key, target, nodeUri, classes) => {
    switch (key) {
        case 'service':
            return (
                <SelectService
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'dates':
            return (
                <SetDates
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'units':
            return (
                <SelectUnits
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'aggregate':
            return (
                <SelectAggregate
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'values':
            return (
                <SetValues
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'confirm':
            return (
                <Fragment>
                    <SetLabel
                        nodeUri={nodeUri}
                        {...target} />
                    <div className={classes.divider} />
                    <Confirm
                        {...target} />
                </Fragment>
            )
    }
}

const stepDisabled = (index, completedSteps) => completedSteps.length ? completedSteps[completedSteps.length - 1] < index - 1 : index > 0

const stepShouldComplete = (key, target = {}) => {
    switch (key) {
        case 'service':
            return Boolean(target.service && target.service.label)
        case 'dates':
            return Boolean(target.date_initiated && target.date_due) && new Date(target.date_due).getTime() > new Date(target.date_initiated).getTime()
        case 'units':
            return Boolean(target.format)
        case 'aggregate':
            return Boolean(target.service && target.service.configs && target.service.configs && target.service.configs.filter(({key}) => key === 'node').length)
        case 'values':
            return Boolean((target.start_value === 0 || target.start_value) && (target.value === 0 || target.value))
        case 'confirm':
            return Boolean(target.description)
    }
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingBottom: 0,
    },
    controls: {
        display: 'grid',
        gridAutoColumns: 'min-content',
        gridAutoFlow: 'column',
        gridColumnGap: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    description: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
}))

Manage.propTypes = {
    history: historyShape.isRequired,
    match: nodeMatchShape.isRequired,
}

export default Manage
