import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Tutorial from '../../components/Map/Build/Tutorial'
import { setTutorialEventHandled } from '../../actions/user'

class TutorialContainer extends React.Component {
    static propTypes = {
        event: PropTypes.oneOf([
            'droppedItLikeItsHot', 'firstTimeBeingSoGreedy', 'firstTimeInDrag', 'playingWithBoxes', 'triggerHappy',
        ]),
    }

    state = {
        handledEvents: [],
        open: false,
    }

    componentDidMount () {
        this._setInitialHandled()
        setTimeout(this._handleEvent)
    }

    componentDidUpdate (prevProps) {
        if (prevProps.event === this.props.event) {
            return
        }

        this._handleEvent()
    }

    shouldComponentUpdate (nextProps, nextState) {
        return nextState.open !== this.state.open || nextProps.event !== this.props.event
    }

    render () {
        return (
            <Tutorial
                closeLabel={closeLabel()}
                description={description(this.props.event)}
                preview={preview(this.props.event)}
                onClose={this._handleClose}
                open={this.state.open}
                title={title(this.props.event)} />
        )
    }

    _handleEvent = () => {
        if (this.props.event === null || this.state.handledEvents.includes(this.props.event)) {
            return this.setState({open: false})
        }

        const handledEvents = [
            ...this.state.handledEvents,
            this.props.event,
        ]

        if (process.env.REACT_APP_ROUTES_HANDLER === 'sandbox') {
            document.cookie = `tutorials_handled=${JSON.stringify(handledEvents)}; path=/`
        } else {
            this.props.setHandled(this.props.auth.userId)
        }

        this.setState({
            open: true,
            handledEvents,
        })
    }

    _setInitialHandled = () => {
        if (process.env.REACT_APP_ROUTES_HANDLER === 'sandbox') {
            const matches = document.cookie.match(/tutorials_handled=(\[[^\]]+\])/)
            if (! matches) {
                return
            }

            return this.setState({handledEvents: JSON.parse(matches[1])})
        }

        this.setState({handledEvents: this.props.users[this.props.auth.userId].tutorials_handled})
    }

    _handleClose = () => this.setState({open: false})
}

const closeLabel = () => ['Got it', 'Aye Cap\u2019n', 'Thanks Chief', 'Gotcha', 'Understood', 'Roger that', '10\u20124'][Math.floor(Math.random()*7)]

const description = (event) => {
    switch (event) {
        case 'droppedItLikeItsHot':
            return [
                'Goals dropped onto the tray are removed from the goal map, but stay available should you want to add them back later. This is particularly helpful if you\u2019re trying to perform a complex movement on your goal map.',
                'If you\u2019re in greedy mode, any children of the goal you\u2019ve removed from the goal map will come too. If you\u2019re not familiar with greedy mode yet, try giving the greedy button a push!',
                'Whilst goals are in the tray you can click them to inspect their children and make changes to their goal map branch.',
            ]

        case 'firstTimeBeingSoGreedy':
            return [
                'The greedy toggle lets you choose what happens to children of goals you\u2019re dragging around the goal map.',
                'When the toggle is set to greedy, any children of the dragged goal come too and will end up wherever you drop them.',
                'When you\u2019ve popped the toggle over to non-greedy the goal\u2019s children will be left behind and they\u2019ll integrate themselves into your goal map.',
            ]

        case 'firstTimeInDrag':
            return [
                'Toggling between clone and move changes what happens when you drag existing goals around your goal map.',
                'Whilst you\u2019ve got the toggle set to move, existing goals will just put themselves down wherever you take them.',
                'When you switch the toggle over to clone, existing goals will stay still as you move them, but another instance of the same goal will become available for you to take elsewhere.',
                'Cloned goals are linked, so changing the name of one will change the name of all its other instances on your goal map.',
            ]

        case 'playingWithBoxes':
            return canHover ? 'Move your mouse around the screen to see possible goal locations. Click into these to add a new goal. You\u2019ll need to start in the middle and work your way out.' : 'Tap the screen to add a new goal. You\u2019ll need to start in the middle and work your way out.'

        case 'triggerHappy':
            return 'Made a mistake? Just use the undo button to go back to the previous goal map state. And if you go back too far, you can always use the redo button.'
    }
}

const preview = (event) => {
    switch (event) {
        case 'playingWithBoxes':
            return '<svg width="242px" height="152px" viewBox="0 0 242 152" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="2,3" stroke-linecap="square"><path d="M1,1 L241,1 L241,151 L1,151 L1,1 Z" stroke="%23474747" stroke-width="2"></path></g></svg>'

        case 'triggerHappy':
            return '<svg width="92px" height="40px" viewBox="0 0 92 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><circle fill="%2307BAAB" cx="20" cy="20" r="20"></circle><circle fill="%2307BAAB" cx="72" cy="20" r="20"></circle><g transform="translate(60.000000, 8.000000)"><polygon points="0 0 24 0 24 24 0 24"></polygon><path d="M18.4,10.6 C16.55,8.99 14.15,8 11.5,8 C6.85,8 2.92,11.03 1.54,15.22 L3.9,16 C4.95,12.81 7.95,10.5 11.5,10.5 C13.45,10.5 15.23,11.22 16.62,12.38 L13,16 L22,16 L22,7 L18.4,10.6 Z" fill="%23FFFFFF" fill-rule="nonzero"></path></g><g transform="translate(8.000000, 8.000000)"><polygon points="0 0 24 0 24 24 0 24"></polygon><path d="M12.5,8 C9.85,8 7.45,8.99 5.6,10.6 L2,7 L2,16 L11,16 L7.38,12.38 C8.77,11.22 10.54,10.5 12.5,10.5 C16.04,10.5 19.05,12.81 20.1,16 L22.47,15.22 C21.08,11.03 17.15,8 12.5,8 Z" fill="%23FFFFFF" fill-rule="nonzero"></path></g></g></svg>'
    }
}

const title = (event) => {
    switch (event) {
        case 'droppedItLikeItsHot':
            return 'Take a quick break\u2026'

        case 'firstTimeBeingSoGreedy':
            return 'How greedy are you\u2026'

        case 'firstTimeInDrag':
            return 'To move or to clone\u2026'

        case 'playingWithBoxes':
            return 'Add your goal, any goal\u2026'

        case 'triggerHappy':
            return 'We\u2019re all human\u2026'
    }
}

const canHover = window.matchMedia('(any-hover: hover)').matches

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setHandled: (userId) => dispatch(setTutorialEventHandled(userId, ownProps.event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TutorialContainer)
