import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const PageLoadingIndicator = ({className, ...rest}) => {
    const classes = useStyles()

    return (
        <CircularProgress
            className={`${classes.progress} ${className}`}
            {...rest} />
    )
}

const useStyles = makeStyles((theme) => ({
    progress: () => ({
        alignSelf: 'center',
        margin: 'auto',
        justifySelf: 'center',
    }),
}))

PageLoadingIndicator.defaultProps = {
    className: '',
}

PageLoadingIndicator.propTypes = {
    className: PropTypes.string,
}

export default PageLoadingIndicator
