import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const Heading = React.forwardRef(({className, color, ...rest}, ref) => {
    const classes = useStyles({color})

    return (
        <Typography
            {...rest}
            className={`${classes.heading} ${className}`}
            color={color}
            ref={ref} />
    )
})

Heading.defaultProps = {
    className: '',
    color: 'primary',
    noWrap: true,
    variant: 'h4',
}

Heading.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf([
        'primary', 'secondary', 'textPrimary', 'textSecondary', 'error',
    ]),
    noWrap: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
    heading: ({color}) => ({
        borderLeftColor: color.indexOf('text') === 0 ? theme.palette.text[color] : theme.palette[color].main,
        borderLeftStyle: 'solid',
        borderLeftWidth: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(-4),
        paddingLeft: theme.spacing(3),
    }),
}))

export default Heading
