import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route as BaseRoute } from 'react-router-dom'
import { connect } from 'react-redux'

const Route = ({auth, component: Component, ...rest}) => (
    <BaseRoute {...rest} render={(props) => (
        authed(auth) ? (
            <Component {...props} />
        ) : (
            <Redirect to="/auth/login" />
        )
    )} />
)

const authed = (auth) => auth && auth.isAuthed

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
})

Route.propTypes = {
    auth: PropTypes.object.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.func, PropTypes.object,
    ]).isRequired,
    location: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(Route)
