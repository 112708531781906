import React from 'react'
import { connect } from 'react-redux'

import Welcome from '../../components/Sandbox/Welcome'
import randomString from '../../includes/randomString'
import { fetchSandboxCollections } from '../../actions/sandboxCollection'
import { storeMap } from '../../actions/map'

class WelcomeContainer extends React.Component {
    state = {
        isBusy: false,
        isReady: false,
    }

    componentDidMount () {
        if (this.props.sandboxCollections.length) {
            this.setState({isReady: true})
        }

        this.props.fetch().then(() => this.setState({isReady: true}))
    }

    render () {
        return (
            <Welcome
                isBusy={this.state.isBusy}
                isReady={this.state.isReady}
                onStart={this._handleStart}
                sandboxCollections={Object.values(this.props.sandboxCollections)} />
        )
    }

    _handleStart = (event) => {
        this.setState({isBusy: true})

        const uri = randomString(8)

        this.props.store({
            ...(event.currentTarget.value ? {map_id: event.currentTarget.value} : {}),
            uri,
        }).then(() => this.props.history.push(`/${uri}`))
    }
}

const mapStateToProps = (state, ownProps) => ({
    sandboxCollections: state.sandboxCollections,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchSandboxCollections()),
    store: (data) => dispatch(storeMap(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeContainer)
