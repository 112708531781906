import { REHYDRATE } from 'redux-persist'

import { RECEIVE_SANDBOX_COLLECTION, RECEIVE_SANDBOX_COLLECTIONS } from '../actions/sandboxCollection'

const initialState = {
    id: null,
    name: '',
    maps: [],
}

const sandboxCollectionReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_SANDBOX_COLLECTION]: () => ({
            ...state,
            ...action.payload.sandboxCollection,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const sandboxCollectionsReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_SANDBOX_COLLECTIONS]: () => action.payload.sandboxCollections.reduce((carry, sandboxCollection) => ({
            ...carry,
            [sandboxCollection.id]: sandboxCollectionReducer(state[sandboxCollection.id], {type: RECEIVE_SANDBOX_COLLECTION, payload: {sandboxCollection}}),
        }), {}),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default sandboxCollectionsReducer
