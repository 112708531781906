import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import React, { memo, useEffect, useState } from 'react'
import { withFormsy } from 'formsy-react'

const FormsyText = memo(({
    defaultValue, disabled, errorMessage, errorMessages, hasValue, innerRef, isFormDisabled, isFormSubmitted, isPristine, isRequired, isValid, isValidValue, onBlur, onChange, onFocus, resetValue, setValidations, setValue, showError, showRequired, validationError, validationErrors, validations, value, ...rest
}) => {
    const [ errored, setErrored ] = useState(false)

    useEffect(() => setValue(defaultValue), [])

    const handleBlur = (event) => {
        setValue(event.currentTarget.value)
        setErrored(true)

        if (onBlur) {
            onBlur(event)
        }
    }

    const handleChange = (event) => {
        setValue(event.currentTarget.value)

        if (! showError) {
            setErrored(false)
        }

        if (onChange) {
            onChange(event, event.currentTarget.value, isValidValue(event.currentTarget.value))
        }
    }

    const handleFocus = (event) => {
        setErrored(showError)

        if (onFocus) {
            onFocus(event)
        }
    }

    const errorActive = Boolean(showError && errored)

    return (
        <TextField
            {...rest}
            disabled={isFormDisabled || disabled}
            error={errorActive}
            helperText={errorActive && errorMessage}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            value={value === undefined ? defaultValue : value} />
    )
})

FormsyText.defaultProps = {
    InputLabelProps: {
        required: false,
    },
    defaultValue: '',
    margin: 'normal',
}

FormsyText.propTypes = {
    InputLabelProps: PropTypes.object,
    autoFocus: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string.isRequired,
    margin: PropTypes.oneOf([
        'none', 'normal', 'dense',
    ]),
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
}

export default withFormsy(FormsyText)
