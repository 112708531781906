import React from 'react'
import { connect } from 'react-redux'

import Edit from '../../components/Calculation/Edit'
import { updateCalculation } from '../../actions/calculation'

class EditContainer extends React.Component {
    state = {
        busy: false,
    }

    render () {
        return (
            <Edit
                busy={this.state.busy}
                match={this.props.match}
                onEscape={this._handleClose}
                onSubmit={this._handleSubmit} />
        )
    }

    _handleClose = () => this.props.history.goBack()

    _handleSubmit = (values) => {
        this.setState({busy: true})

        this.props.update({values}).then(() => this._handleClose())
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    update: (values) => dispatch(updateCalculation(ownProps.match.params.calculation, ownProps.match.params.node, values)),
})

export default connect(null, mapDispatchToProps)(EditContainer)
