import CalculatorIcon from 'mdi-material-ui/Calculator'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import LanguageIcon from '@material-ui/icons/Language'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import SitemapIcon from 'mdi-material-ui/Sitemap'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

import { COLOR_BRAND_PRIMARY, COLOR_WHITE } from '../../constants/colors'

const PrimaryKpi = ({canCalculate, childNodes, label, isReady, mapUri, node, onSetView, prefix, suffix, value, view}) => (
    <div style={styles.container}>
        {isReady ? (
            canCalculate ? (
                <React.Fragment>
                    {view === 'summary' && (
                        <div style={styles.block}>
                            <Typography style={styles.amount} variant="h5">
                                {Boolean(prefix) && (
                                    <span>{prefix}</span>
                                )}
                                <span>{value}</span>
                                {Boolean(suffix) && (
                                    <span>{suffix}</span>
                                )}
                            </Typography>
                            {Boolean(label) && (
                                <Typography style={styles.text} variant="h6">{label}</Typography>
                            )}
                        </div>
                    )}
                    {view === 'items' && (
                        <div style={styles.items}>
                            {Boolean(node) && (
                                <Paper style={styles.node}>
                                    <Typography variant="h5">{node.name}</Typography>
                                    <Typography variant="h6">&pound;{value}</Typography>
                                </Paper>
                            )}
                            {childNodes.map(({id, name, value}) => (
                                <Paper key={id} style={styles.node}>
                                    <Typography variant="h5">{name}</Typography>
                                    {Boolean(value) && (
                                        <Typography variant="h6">&pound;{value}</Typography>
                                    )}
                                </Paper>
                            ))}
                        </div>
                    )}
                    <div style={styles.iconBlock}>
                        <Fab color="secondary" onClick={onSetView} value={view === 'summary' ? 'items' : 'summary'}>
                            {view === 'summary' ? (
                                <SitemapIcon />
                            ) : (
                                <CalculatorIcon />
                            )}
                        </Fab>
                        <Fab color="secondary" component={Link} to={`/m/${mapUri}`}>
                            <LanguageIcon />
                        </Fab>
                    </div>
                </React.Fragment>
            ) : (
                <Typography variant="h5" style={styles.empty}>A calculation is not yet available.</Typography>
            )
        ) : (
            <CircularProgress style={styles.busy} />
        )}
    </div>
)

const styles = {
    container: {
        alignItems: 'center',
        backgroundColor: COLOR_BRAND_PRIMARY,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingBottom: 24,
        paddingTop: 24,
        justifyContent: 'space-between',
        width: '100%',
    },
    block: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    iconBlock: {
        display: 'grid',
        gridColumnGap: 24,
        gridTemplateColumns: 'repeat(2, auto)',
        paddingBottom: 114,
    },
    amount: {
        color: COLOR_WHITE,
        fontSize: 70,
        textAlign: 'center',
    },
    text: {
        color: COLOR_WHITE,
    },
    items: {
        display: 'grid',
        flex: 1,
        gridRowGap: 24,
        justifyContent: 'center',
        overflowY: 'auto',
        marginBottom: 24,
        width: '100%',
    },
    node: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 180,
        justifyContent: 'space-between',
        paddingBottom: 24,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 24,
        textAlign: 'center',
        width: 270,
    },
    empty: {
        color: COLOR_WHITE,
        margin: 'auto',
        paddingLeft: 12,
        paddingRight: 12,
        textAlign: 'center',
    },
    busy: {
        color: COLOR_WHITE,
        margin: 'auto',
    },
}

PrimaryKpi.defaultProps = {
    canCalculate: false,
    isReady: false,
}

PrimaryKpi.propTypes = {
    canCalculate: PropTypes.bool,
    childNodes: PropTypes.array,
    label: PropTypes.string,
    isReady: PropTypes.bool,
    mapUri: PropTypes.string.isRequired,
    node: PropTypes.object,
    onSetView: PropTypes.func.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    value: PropTypes.string,
    view: PropTypes.oneOf([
        'items', 'summary',
    ]).isRequired,
}

export default PrimaryKpi
