import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { connect } from 'react-redux'

import Overlay from './Shared/Overlay'
import { COLOR_GREY } from '../constants/colors'
import { confirmPrompt, dismissPrompt } from '../actions/ui'

const Prompt = ({confirm, dismiss, ui}) => ui.prompt.show ? (
    <Overlay onEscape={dismiss}>
        {ui.prompt.title !== null && (
            <DialogTitle>{ui.prompt.title}</DialogTitle>
        )}
        <DialogContent>
            <DialogContentText>
                {Array.isArray(ui.prompt.message) ? (
                    <React.Fragment>
                        {ui.prompt.message.map((item, i) => <p key={i} style={styles.line}>{item}</p>)}
                    </React.Fragment>
                ) : ui.prompt.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {ui.prompt.type === 'prompt' && (
                <Button color="primary" onClick={dismiss}>{ui.prompt.options && ui.prompt.options.abortLabel || 'Cancel'}</Button>
            )}
            <Button color="primary" onClick={confirm}>{ui.prompt.options && ui.prompt.options.confirmLabel || 'OK'}</Button>
        </DialogActions>
    </Overlay>
) : null

const styles = {
    heading: {
        color: COLOR_GREY,
        fontSize: 18,
        marginBottom: 12,
    },
    line: {
        marginBottom: 6,
    },
    actions: {
        display: 'flex',
    },
    action: {
        marginRight: 20,
    },
}

const mapStateToProps = (state, ownProps) => ({
    ui: state.ui,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    confirm: () => dispatch(confirmPrompt()),
    dismiss: () => dispatch(dismissPrompt()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Prompt)
