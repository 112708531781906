import PropTypes from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import { useDragLayer } from 'react-dnd'

import Goal from './Goal'
import Row from './Row'
import Stage from '../Stage'
import usePrevious from '../../../hooks/usePrevious'
import { COLOR_GREY, COLOR_GREY_DARK, COLOR_WHITE } from '../../../constants/colors'
import { CONTROLS_HEIGHT, HEADER_HEIGHT, LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH, TRAY_HEIGHT } from '../../../constants/layout'
import { RowsBuilder, isEqual } from '../../../actions/build'

const GoalDragLayer = ({dragBehaviour, goals, greedyBehaviour, layout, onDrag, windowSize}) => {
    const { currentOffset, isDragging, item } = useDragLayer((monitor) => ({
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    	item: monitor.getItem(),
    }))

    const previousOffset = usePrevious(currentOffset)
    const wasDragging = usePrevious(isDragging)

    const [ internalLayout, setInternalLayout ] = useState({})

    useEffect(() => {
        if (! isDragging || ! currentOffset) {
            return
        }

        if (wasDragging && isEqual(currentOffset, previousOffset)) {
            return
        }

        setTimeout(() => onDrag(currentOffset, item), 100)
    }, [currentOffset, isDragging])

    useEffect(() => {
        if (! item || ! item.instanceId || greedyBehaviour !== 'greedy') {
            return
        }

        const rowsBuilder = new RowsBuilder({
            focalInstanceId: item.instanceId,
            instances: layout.instances,
            relations: layout.relations,
        }, windowSize)

        rowsBuilder.rebuild([], null, item.rowIndex > layout.focalIndex, item.rowIndex < layout.focalIndex)

        setInternalLayout(rowsBuilder.layout)
    }, [
        item ? item.instanceId : null
    ])

    if (! isDragging) {
        return null
    }

    return (
        <div style={styles.container}>
            {Boolean(currentOffset) && (
                Boolean(item.instanceId && greedyBehaviour === 'greedy' && internalLayout.rows) ? (
                    <div style={{transform: `translate(${currentOffset.x - ((windowSize.width - LAYOUT_NODE_WIDTH) / 2)}px, ${currentOffset.y - internalLayout.offset}px)`}}>
                        <Stage
                            GoalComponent={Goal}
                            RowComponent={Row}
                            goals={goals}
                            highlightFocal={false}
                            layout={internalLayout}
                            hideControls hideGhosts />
                    </div>
                ) : (
                    <Goal
                        {...item}
                        style={{...item.style, transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`}} />
                )
            )}
        </div>
    )
}

GoalDragLayer.propTypes = {
    dragBehaviour: PropTypes.oneOf([
        'clone', 'move',
    ]).isRequired,
    goals: PropTypes.object.isRequired,
    greedyBehaviour: PropTypes.oneOf([
        'greedy', 'non-greedy',
    ]).isRequired,
    layout: PropTypes.shape({
        offset: PropTypes.number.isRequired,
    }).isRequired,
    onDrag: PropTypes.func.isRequired,
    windowSize: PropTypes.shape({
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
}

const styles = {
    container: {
        bottom: 0,
        left: 0,
        pointerEvents: 'none',
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 99,
    },
    outline: {
        borderColor: COLOR_GREY,
        borderStyle: 'dashed',
        borderWidth: 1,
        height: LAYOUT_NODE_HEIGHT,
        position: 'absolute',
        width: LAYOUT_NODE_WIDTH,
    },
}

export default GoalDragLayer
