import Avatar from '@material-ui/core/Avatar'
import PropTypes from 'prop-types'
import React from 'react'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import StarHalf from '@material-ui/icons/StarHalf'
import { makeStyles } from '@material-ui/core/styles'

import { COLOR_AMBER, COLOR_GREY_DARK, COLOR_GREY_LIGHT, COLOR_WHITE } from '../constants/colors'

const PriorityIcon = ({priority, showBackground, ...rest}) => {
    const classes = useStyles({priority, showBackground, ...rest})

    const _renderIcon = () => {
        if (priority === 0) {
            return <StarBorder className={classes.shape} />
        } else if (priority === 1) {
            return <StarHalf className={classes.shape} />
        } else if (priority === 2) {
            return <Star className={classes.shape} />
        }

        return <Star className={classes.shape} />
    }

    return (
        <Avatar className={classes.icon}>
            {_renderIcon()}
        </Avatar>
    )
}

const useStyles = makeStyles(theme => ({
    icon: ({priority, showBackground, size, style}) => ({
        backgroundColor: showBackground ? (priority === null || priority === '' ? COLOR_GREY_DARK : COLOR_AMBER) : 'transparent',
        color: priority === null || priority === '' ? COLOR_GREY_LIGHT : (showBackground ? COLOR_WHITE : COLOR_AMBER),
        height: size,
        marginRight: showBackground ? -4 : 0,
        width: size,
        ...style,
    }),
    shape: (showBackground) => ({
        height: showBackground ? 19 : 24,
        width: showBackground ? 19 : 24,
    }),
}))

PriorityIcon.defaultProps = {
    showBackground: true,
    size: 32,
}

PriorityIcon.requiredProps = {
    priority: PropTypes.isRequired,
    showBackground: PropTypes.bool,
    size: PropTypes.number,
}

export default PriorityIcon
