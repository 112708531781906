import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import React from 'react'

import FormsyText from '../formsy/FormsyText'
import Modal from '../Shared/Modal'
import { COLOR_GREY_DARKER, COLOR_WHITE } from '../../constants/colors'

const Security = ({canSubmit, onInvalid, onSubmit, onValid, user}) => (
    <Modal>
        <DialogTitle>Security</DialogTitle>
        <Formsy onInvalid={onInvalid} onValid={onValid} onValidSubmit={onSubmit}>
            <DialogContent>
                <fieldset>
                    <input autoComplete="username" defaultValue={user.username} name="username" style={styles.hidden} type="text" />
                </fieldset>
                <fieldset>
                    <FormsyText
                        autoComplete="new-password"
                        label="Your new password"
                        name="password"
                        type="password"
                        validations={{matchRegexp: /^(?=.*[^0-9a-zA-Z])(?=.*[0-9])(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z]).{8,}$/}}
                        validationError="The password must be at least 8 characters, containing at least 1 number and 1 special character."
                        fullWidth required />
                </fieldset>
                <fieldset>
                    <FormsyText
                        autoComplete="new-password"
                        label="Confirm your new password"
                        name="password_confirmation"
                        type="password"
                        validations="equalsField:password"
                        validationError="The passwords do not match."
                        fullWidth required />
                </fieldset>
                <br />
                <fieldset>
                    <FormsyText
                        autoComplete="current-password"
                        label="Your current password"
                        name="current_password"
                        type="password"
                        fullWidth required />
                </fieldset>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={! canSubmit} type="submit">
                    Update
                </Button>
            </DialogActions>
        </Formsy>
    </Modal>
)

Security.defaultProps = {
    canSubmit: false,
}

Security.propTypes = {
    canSubmit: PropTypes.bool,
    onInvalid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
}

const styles = {
    container: {
        paddingBottom: '24px',
        paddingTop: '24px',
        width: '100%',
    },
    headerContainer: {
        backgroundColor: COLOR_GREY_DARKER,
    },
    formContainer: {
        margin: '0 auto',
        paddingBottom: '24px',
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        maxWidth: '820px',
    },
    buttonsContainer: {
        display: 'flex',
        marginTop: '24px',
    },
    toolbarTitle: {
        color: COLOR_WHITE,
    },
    hidden: {
        display: 'none',
    },
}

export default Security
