import axios from 'axios'

import { receiveUser } from './user'

export const RECEIVE_TEAM = 'RECEIVE_TEAM'
export const RECEIVE_TEAMS = 'RECEIVE_TEAMS'
export const REMOVE_TEAM = 'REMOVE_TEAM'

const receiveTeam = (team) => ({
    type: RECEIVE_TEAM,
    payload: {
        team,
    },
})

export const receiveTeams = (teams) => ({
    type: RECEIVE_TEAMS,
    payload: {
        teams,
    },
})

const removeTeam = (id) => ({
    type: REMOVE_TEAM,
    payload: {
        id,
    },
})

export const fetchTeams = () =>
    (dispatch, getState) =>
        axios.get('/team').then(({data}) => {
            dispatch(receiveTeams(data))

            return data
        })

export const fetchTeam = (uri) =>
    (dispatch, getState) =>
        axios.get(`/team/${uri}`).then(({data}) => {
            dispatch(receiveTeam(data))

            return data
        })


export const addUserToTeam = (uri, data) =>
    (dispatch, getState) =>
        axios.put(`/team/${uri}`, data).then(({data}) => {
            dispatch(receiveTeam(data))

            return data
        })

export const removeUserFromTeam = (uri, id) =>
    (dispatch, getState) =>
        axios.delete(`/team/${uri}/user/${id}`).then(({data}) => {
            dispatch(receiveUser(data))

            return data
        })

export const storeTeam = (name) =>
    (dispatch, getState) =>
        axios.post('/team', {name}).then(({data}) => {
            dispatch(receiveTeam(data))

            return data
        })

export const updateTeam = (uri, data) =>
    (dispatch, getState) =>
        axios.put(`/team/${uri}`, data).then(({data}) => {
            dispatch(receiveTeam(data))

            return data
        })

export const destroyTeam = (uri) =>
    (dispatch, getState) =>
        axios.delete(`/team/${uri}`).then(({data}) => {
            dispatch(removeTeam(data.id))

            return data
        })

export const teamFromUri = (teams, needle) => Object.values(teams).find(({uri}) => uri === needle) || {
    name: '',
}

export const teamUsers = (users, teamUri) => Object.values(users).filter(({id, teams_index}) => Object.keys(teams_index).includes(teamUri))
