import PropTypes from 'prop-types'
import React from 'react'

import Goal from './Goal'
import { COLOR_GREY } from '../../../constants/colors'
import { LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_WIDTH } from '../../../constants/layout'

const Row = ({
    GoalComponent, goals, hideGhosts, highlightFocal, index, items, layout, offset, onGhostClick, onGoalBlur, onGoalChange, onGoalClick, onGoalDoubleClick, ...rest
}) => (
    <div style={styles.container(offset)}>
        {items.map((instanceId, itemIndex) => {
            const CurrentGoalComponent = index === layout.focalIndex ? Goal : GoalComponent

            return (
                layout.instances[instanceId] ? (
                    <CurrentGoalComponent
                        columnIndex={itemIndex}
                        instanceId={instanceId}
                        focal={highlightFocal && index === layout.focalIndex}
                        key={instanceId}
                        onBlur={onGoalBlur}
                        onChange={onGoalChange}
                        onClick={onGoalClick}
                        onDoubleClick={onGoalDoubleClick}
                        rowIndex={index}
                        {...goals[layout.instances[instanceId]]}
                        {...rest} />
                ) : Boolean(! hideGhosts) && (
                    <Ghost
                        columnIndex={itemIndex}
                        instanceId={instanceId}
                        key={instanceId}
                        onGhostClick={onGhostClick}
                        rowIndex={index} />
                )
            )
        })}
    </div>
)

const Ghost = ({columnIndex, instanceId, onGhostClick, rowIndex}) => {
    const onClick = () => onGhostClick(instanceId, {rowIndex, columnIndex})

    return (
        <div onClick={onClick} style={styles.outline} />
    )
}

const styles = {
    container: (offset) => ({
        display: 'grid',
        gridAutoColumns: LAYOUT_NODE_WIDTH,
        gridAutoFlow: 'column',
        gridColumnGap: LAYOUT_NODE_SPACING_X,
        position: 'relative',
        transform: `translateX(${offset}px)`,
    }),
    outline: {
        borderColor: COLOR_GREY,
        borderStyle: 'dashed',
        borderWidth: 1,
        height: LAYOUT_NODE_HEIGHT,
        width: LAYOUT_NODE_WIDTH,
    },
}

Row.defaultProps = {
    hideGhosts: false,
    highlightFocal: true,
}

Row.propTypes = {
    GoalComponent: PropTypes.oneOfType([
        PropTypes.element, PropTypes.func, PropTypes.object,
    ]).isRequired,
    goals: PropTypes.object.isRequired,
    hideGhosts: PropTypes.bool,
    highlightFocal: PropTypes.bool,
    index: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    layout: PropTypes.shape({
        instances: PropTypes.object.isRequired,
    }).isRequired,
    offset: PropTypes.number.isRequired,
    onGhostClick: PropTypes.func,
    onGoalBlur: PropTypes.func,
    onGoalChange: PropTypes.func,
    onGoalClick: PropTypes.func,
    onGoalDoubleClick: PropTypes.func,
}

export default Row
