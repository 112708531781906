import { REHYDRATE } from 'redux-persist'

import { RECEIVE_CALCULATION, RECEIVE_CALCULATIONS } from '../actions/calculation'

const initialState = {
    id: null,
    node_id: null,
}

const calculationReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_CALCULATION]: () => ({
            ...state,
            ...action.payload.calculation,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_CALCULATION]: () => ({
            ...state,
            [action.payload.calculation.id]: calculationReducer(state[action.payload.calculation.id], action),
        }),
        [RECEIVE_CALCULATIONS]: () => ({
            ...state,
            ...action.payload.calculations.reduce((carry, calculation) => ({
                ...carry,
                [calculation.id]: calculationReducer(state[calculation.id], {type: RECEIVE_CALCULATION, payload: {calculation}}),
            }), {})
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}
