import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const Container = React.forwardRef(({className, modal, width, ...rest}, ref) => {
    const classes = useStyles({modal, width})

    return (
        <Paper
            {...rest}
            className={`${classes.container} ${className}`}
            ref={ref} />
    )
})

const useStyles = makeStyles((theme) => ({
    container: ({modal, width}) => ({
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        maxWidth: '100%',
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(4),
        width: width === undefined ? (modal ? theme.breakpoints.values.sm : theme.breakpoints.values.xl) : width,
    }),
}))

Container.defaultProps = {
    elevation: 0,
    className: '',
    modal: false,
}

Container.propTypes = {
    className: PropTypes.string,
    modal: PropTypes.bool,
    width: PropTypes.number,
}

export default Container
