import PropTypes from 'prop-types'
import React from 'react'

import { COLOR_BRAND_PRIMARY, COLOR_GREY_DARKER, COLOR_RED, COLOR_RED_LIGHT, COLOR_WHITE } from '../../../constants/colors'
import { LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH } from '../../../constants/layout'

class GoalTextArea extends React.Component {
    static defaultProps = {
        focal: false,
        style: {},
    }

    static propTypes = {
        focal: PropTypes.bool,
        id: PropTypes.string,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        style: PropTypes.object,
        value: PropTypes.string,
    }

    state = {
        focused: false,
        rows: 1,
    }

    inputRef = React.createRef()
    shadowRef = React.createRef()

    componentDidMount () {
        this.setState({
            rows: calcRows(this.shadowRef.current),
        })
    }

    componentDidUpdate (prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                rows: calcRows(this.shadowRef.current),
            })
        }
    }

    render () {
        const currentCount = count(this.props.value)

        return (
            <div style={{...styles.container(this.state.focused), ...this.props.style}}>
                <textarea
                    ref={this.shadowRef}
                    rows={1}
                    style={{...styles.textarea(this.props.focal), ...styles.shadowTextarea}}
                    value={this.props.value}
                    readOnly />
                <textarea
                    onBlur={this._handleBlur}
                    onChange={this._handleChange}
                    onFocus={this._handleFocus}
                    onKeyDown={this._handleKeydown}
                    ref={this.inputRef}
                    rows={this.state.rows}
                    style={styles.textarea(this.props.focal)}
                    value={this.props.value}
                    autoFocus />
                <span style={styles.count(currentCount, this.props.focal)}>{currentCount}</span>
            </div>
        )
    }

    _handleKeydown = (event) => {
        if (! this.inputRef.current || ! event.metaKey ||  event.keyCode !== 13) {
            return
        }

        this.inputRef.current.blur()
    }

    _handleBlur = () => {
        this.setState({focused: false})
        this.props.onBlur(this.props.id)
    }

    _handleFocus = () => this.setState({focused: true})
    _handleChange = (event) => this.props.onChange(this.props.id, event.target.value)
}

const calcRows = (ref) => ref ? Math.round(ref.scrollHeight / 16) : 1
const count = (value) => typeof value === 'string' ? 60 - value.length : 60

const styles = {
    container: (focused) => ({
        height: LAYOUT_NODE_HEIGHT,
        outlineColor: focused ? COLOR_BRAND_PRIMARY : 'transparent',
        outlineOffset: 0,
        outlineStyle: 'solid',
        outlineWidth: 4,
        position: 'relative',
        width: LAYOUT_NODE_WIDTH,
    }),
    count: (currentCount, focal) => ({
        bottom: 5,
        color: currentCount < 0 ? (focal ? COLOR_RED_LIGHT : COLOR_RED) : (focal ? COLOR_WHITE : COLOR_GREY_DARKER),
        fontSize: 12,
        opacity: .6,
        position: 'absolute',
        right: 5,
    }),
    textarea: (focal) => ({
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        color: focal ? COLOR_WHITE : COLOR_GREY_DARKER,
        left: LAYOUT_NODE_SPACING_X / 2,
        maxHeight: `calc(100% - ${LAYOUT_NODE_SPACING_Y}px)`,
        outline: 'none',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        resize: 'none',
        textAlign: 'center',
        top: '50%',
        transform: 'translateY(-50%)',
        width: `calc(100% - ${LAYOUT_NODE_SPACING_X}px)`,
    }),
    shadowTextarea: {
        pointerEvents: 'none',
        visibility: 'hidden',
    },
}


export default GoalTextArea
