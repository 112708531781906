import { REHYDRATE } from 'redux-persist'

import { RECEIVE_TARGET, RECEIVE_TARGETS, REMOVE_TARGET } from '../actions/target'

const initialState = {}

const targetReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_TARGET]: () => ({
            ...state,
            ...action.payload.target,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const targetsReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_TARGET]: () => ({
            ...state,
            [action.payload.target.id]: targetReducer(state[action.payload.target.id], action)
        }),
        [RECEIVE_TARGETS]: () => {
            const targets = {}
            action.payload.targets.forEach((target) => targets[target.id] = targetReducer(state[target.id], {
                type: RECEIVE_TARGET,
                payload: {
                    target,
                }
            }))

            return {
                ...state, ...targets
            }
        },
        [REMOVE_TARGET]: () => {
            const nextState = Object.assign({}, state)
            delete nextState[action.payload.id]

            return nextState
        },
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default targetsReducer
