import BaseChip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { getContrastRatio } from '@material-ui/core/styles/colorManipulator'
import { makeStyles } from '@material-ui/core/styles'

import { COLOR_GREY_DARKER, COLOR_WHITE } from '../../constants/colors'

const Chip = memo(({className, hex, name, ...rest}) => {
    const classes = useStyles({hex})

    return (
        <BaseChip
            className={`${classes.wrapper} ${className}`}
            label={name}
            {...rest} />
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: ({hex}) => ({
        ...theme.typography.body1,
        backgroundColor: `#${hex}`,
        borderRadius: theme.shape.borderRadius,
        color: hex.length === 6 || hex.length === 3 ? getContrastRatio(`#${hex}`, COLOR_WHITE) > 2 ? COLOR_WHITE : COLOR_GREY_DARKER : COLOR_GREY_DARKER,
    }),
}))

Chip.defaultProps = {
    className: '',
}

Chip.propTypes = {
    className: PropTypes.string,
    hex: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default Chip
