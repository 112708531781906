import axios from 'axios'

import { receiveTeams } from './team'
import { receiveUser } from './user'

export const INVALIDATE_TOKEN = 'INVALIDATE_TOKEN'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const UPDATE_LAST_MAP_URI = 'UPDATE_LAST_MAP_URI'
export const UPDATE_TEAM_URI = 'UPDATE_TEAM_URI'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'

const loginRequest = () => ({
    type: LOGIN_REQUEST
})

const loginSuccess = (token, userId, clientUri, teamUri) => ({
    type: LOGIN_SUCCESS,
    payload: {
        clientUri, token, teamUri, userId,
    },
})

const loginFailure = () => ({
    type: LOGIN_FAILURE
})

export const logoutSuccess = () => ({
    type: LOGOUT
})

const updateLastMapUri = (lastMapUri) => ({
    type: UPDATE_LAST_MAP_URI,
    payload: {
        lastMapUri,
    },
})

export const updateTeamUri = (teamUri) => ({
    type: UPDATE_TEAM_URI,
    payload: {
        teamUri,
    },
})

export const handleLogin = (email, password) =>
    (dispatch, getState) => {
        dispatch(loginRequest())

        return axios.post('/auth/login', { email, password }).then(({data}) => {
            // window.Echo.connector.pusher.config.auth.headers['Authorization'] = `Bearer: ${data.token}`

            dispatch(receiveUser(data.user))
            dispatch(receiveTeams(data.teams))

            dispatch(loginSuccess(data.token, data.user.id, data.client_uri, data.teams.length ? data.teams[0].uri : null))

            return data
        }).catch((error) => {
            console.error(error)
            dispatch(loginFailure())

            throw error
        })
    }

export const logout = () => (dispatch, getState) =>
    axios.post('/auth/logout').then(() => dispatch(logoutSuccess())).catch(() => dispatch(logoutSuccess()))

export const handleResetLink = (email) => axios.post(`${process.env.REACT_APP_APP_URL}/password/email`, {email}).then(({data}) => data)

export const handleReset = (data) => axios.post(`${process.env.REACT_APP_APP_URL}/password/reset`, data).then(({data}) => data)

export const handleUpdateLastMapUri = (lastMapUri) => (dispatch, getState) => dispatch(updateLastMapUri(lastMapUri))
