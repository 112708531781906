import axios from 'axios'

export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE'
export const RECEIVE_RESOURCES = 'RECEIVE_RESOURCES'
export const REMOVE_RESOURCE = 'REMOVE_RESOURCE'

const receiveResource = (resource) => ({
    type: RECEIVE_RESOURCE,
    payload: {
        resource,
    },
})

export const receiveResources = (resources) => ({
    type: RECEIVE_RESOURCES,
    payload: {
        resources,
    },
})

const removeResource = (id) => ({
    type: REMOVE_RESOURCE,
    payload: {
        id,
    },
})

export const fetchResources = (nodeUri) =>
    (dispatch, getState) =>
        axios.get(`/node/${nodeUri}/resource`).then(({data}) => {
            dispatch(receiveResources(data))

            return data
        })

export const storeResource = (nodeUri, resource) =>
    (dispatch, getState) =>
        axios.post(`/node/${nodeUri}/resource`, Object.keys(resource).filter((key) => typeof resource[key] !== 'undefined' && resource[key] !== null).reduce((formData, key) => {
            formData.append(key, resource[key])
            return formData
        }, new FormData())).then(({data}) => {
            dispatch(receiveResource(data))

            return data
        })

export const updateResource = (nodeUri, id, resource) =>
    (dispatch, getState) =>
        axios.put(`/node/${nodeUri}/resource/${id}`, resource).then(({data}) => {
            dispatch(receiveResource(data))

            return data
        })

export const updateResourceOrder = (nodeUri, items) =>
    (dispatch, getState) => {
        const resources = getState().resources

        dispatch(receiveResources(items.map(({id, order}) => ({
            id,
            pivot: {
                ...resources[id].pivot,
                order,
            },
        }))))

        return axios.put(`/node/${nodeUri}/resource/${items.map(({id}) => id).join(',')}`, {order: items})
    }

export const destroyResource = (nodeUri, id) =>
    (dispatch, getState) => {
        dispatch(removeResource(id))

        return axios.delete(`/node/${nodeUri}/resource/${id}`)
    }

export const resourcesForNode = (resources, nodeId) => Object.values(resources).filter(({pivot}) => (pivot.resourceable_type === 'App\\Node' && pivot.resourceable_id === nodeId)).sort((a,b) => a.pivot.order - b.pivot.order)
