import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FolderIcon from '@material-ui/icons/Folder'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router'

import PageLoadingIndicator from '../PageLoadingIndicator'

const Welcome = ({isBusy, isReady, onStart, sandboxCollections}) => (
    <div style={styles.container}>
        <Typography variant="h5" style={styles.wide}>Build something amazing&hellip;</Typography>
        <Paper style={{...styles.block, ...styles.wide}}>
            <Typography variant="subtitle1" paragraph>A blank canvas to map out anything and everything. Start with an empty goal map that you can shape however you want.</Typography>
            <Button color="primary" disabled={isBusy} onClick={onStart} style={styles.button} variant="contained">Get started</Button>
            <Typography variant="subtitle2">Not sure where to begin? Why not try one of our pre&#8208;made examples below.</Typography>
        </Paper>
        <Typography variant="h5" style={styles.wide}>Get started quickly with one of our pre&#8208;made examples&hellip;</Typography>
        {isReady ? (
            sandboxCollections.map(({id, maps, name}) => (
                <Paper key={id} style={styles.block}>
                    <Typography color="primary" variant="h6">{name}</Typography>
                    <List>
                        {maps.map(({id, name}) => (
                            <ListItem key={id}>
                                <ListItemText primary={name} />
                                <ListItemSecondaryAction>
                                    <IconButton color="primary" disabled={isBusy} onClick={onStart} value={id}>
                                        <PlayCircleOutlineIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            ))
        ) : (
            <PageLoadingIndicator />
        )}
    </div>
)

Welcome.defaultProps = {
    isBusy: false,
    isReady: false,
}

Welcome.propTypes = {
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    onStart: PropTypes.func.isRequired,
    sandboxCollections: PropTypes.array,
}

const styles = {
    container: {
        display: 'grid',
        gridGap: 24,
        gridTemplateColumns: 'repeat(2, 1fr)',
        padding: 24,
    },
    block: {
        padding: 24,
    },
    wide: {
        gridColumn: 'span 2',
    },
    button: {
        marginBottom: 12,
    },
}

export default Welcome
