import React from 'react'
import { Route } from 'react-router-dom'

import Routes from './Routes'
import SandboxRoutes from './SandboxRoutes'

class RoutesHandler extends React.Component {
    handler = 'default'

    constructor (props) {
        super(props)

        if (process.env.REACT_APP_ROUTES_HANDLER !== undefined) {
            this.handler = process.env.REACT_APP_ROUTES_HANDLER
        }
    }

    render () {
        return (
            <Route component={routingComponent(this.handler)} />
        )
    }
}

const routingComponent = (handler) => {
    if (handler === 'sandbox') {
        return SandboxRoutes
    }

    return Routes
}

export default RoutesHandler
