export default (length) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

    let string = ''
    for (let i = 0; i < length; i++) {
        string += chars.charAt(Math.floor(Math.random() * 62))
    }

    return string
}
