export const COLOR_BRAND_PRIMARY = '#0abaab'

export const COLOR_BLUE_DARK = '#17375e'
export const COLOR_BLUE = '#0577a2'
export const COLOR_BLUE_LIGHT = '#c1edfe'

export const COLOR_BLACK = '#000000'
export const COLOR_WHITE = '#ffffff'

export const COLOR_GREY_LIGHTEST = '#fafafa'
export const COLOR_GREY_LIGHTER = '#f3f3f3'
export const COLOR_GREY_LIGHT = '#e0e0e0'
export const COLOR_GREY = '#d9d9d9'
export const COLOR_GREY_DARK = '#757575'
export const COLOR_GREY_DARKER = '#474747'

export const COLOR_RED = '#ef476f'
export const COLOR_RED_LIGHT = '#ffadd5'

export const COLOR_GREEN = '#29bf12'

export const COLOR_AMBER = '#f3a800'
