import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import React, { Fragment, memo, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import PageLoadingIndicator from '../../PageLoadingIndicator'
import TargetIcon from '../Icon'
import { fetchNode } from '../../../actions/node'
import { fetchTargetsForMap } from '../../../actions/target'
import { stashTarget } from '../../../actions/stashes'

const SelectAggregate = memo(({format, id, nodeUri, service}) => {
    const [ aggregatedProgress, setAggregatedProgress ] = useState(0)
    const [ isReady, setIsReady ] = useState(false)
    const [ selectedValues, setSelectedValues ] = useState(service.configs ? service.configs.filter(({key}) => key === 'node').map(({value}) => value) : [])

    const classes = useStyles({selectedValues})
    const dispatch = useDispatch()

    const node = useSelector(({nodes}) => nodes[nodeUri]) || {}
    const targets = useSelector(({targets}) => Object.values(targets).filter((item) => item.node.map_uri === node.map_uri && item.id !== id))

    useEffect(() => {
        if (node.map_uri) {
            return
        }

        dispatch(fetchNode(nodeUri))
    }, [])

    useEffect(() => {
        if (! node.map_uri) {
            return
        }

        dispatch(fetchTargetsForMap(node.map_uri)).then(() => setIsReady(true))
    }, [node.map_uri])

    useEffect(() => {
        const items = selectedValues.map((item) => targets.find(({node}) => node.uri === item)).filter((item) => item)

        setAggregatedProgress(items.length ? items.reduce((carry, {progress}) => carry + (Math.max(Math.min(progress, 100), 0)), 0) / items.length : 0)
    }, [selectedValues])

    const _handleChange = (uri, value) => {
        const selected = [
            ...selectedValues.filter((item) => item !== uri),
            ...value ? [uri] : [],
        ]

        setSelectedValues(selected)

        dispatch(stashTarget(nodeUri, {
            service: {
                ...service,
                configs: selected.reduce((carry, value) => ([
                    ...carry,
                    {
                        key: 'node',
                        type: 'string',
                        value,
                    },
                ]), []),
            },
        }))
    }

    return (
        <div className={classes.wrapper}>
            {isReady ? (
                targets.length ? (
                    <Fragment>
                        <List>
                            {targets.map(({description, id, node, value, ...rest}) => {
                                const onChange = (event, value) => _handleChange(node.uri, value)
                                return (
                                    <ListItem key={id}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selectedValues.includes(node.uri)}
                                                onChange={onChange}
                                                disableRipple />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={description} />
                                        <TargetIcon
                                            size={40}
                                            type="progress"
                                            {...rest} />
                                    </ListItem>
                                )
                            })}
                        </List>
                        <Divider />
                        <div className={classes.preview}>
                            <Typography variant="h6">Aggregate value</Typography>
                            <TargetIcon
                                progress={aggregatedProgress}
                                size={80}
                                type="progress" />
                        </div>
                    </Fragment>
                ) : (
                    <Alert className={classes.alert} severity="warning">
                        <AlertTitle>No targets available</AlertTitle>
                        This goal map does not have any targets to aggregate against. You will need to create some other targets first.
                    </Alert>
                )
            ) : (
                <PageLoadingIndicator className={classes.loading} />
            )}
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    loading: {
        alignSelf: 'center',
        marginTop: theme.spacing(2),
    },
    alert: {
        marginTop: theme.spacing(2),
    },
    preview: ({selectedValues}) => ({
        display: 'grid',
        gridRowGap: theme.spacing(2),
        justifyContent: 'center',
        justifyItems: 'center',
        marginTop: theme.spacing(2),
        opacity: selectedValues.length ? 1 : theme.palette.action.disabledOpacity,
        transition: theme.transitions.create('opacity', {duration: theme.transitions.duration.short}),
    }),
}))

SelectAggregate.propTypes = {
    format: PropTypes.oneOf([
        'boolean', 'decimal', 'integer',
    ]),
    id: PropTypes.number,
    nodeUri: PropTypes.string.isRequired,
    service: PropTypes.shape({
        configs: PropTypes.array,
    }),
}

export default SelectAggregate
