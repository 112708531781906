import { REHYDRATE } from 'redux-persist'

import {
    ADD_NODE, INCREMENT_NODE_COMMENT_COUNT, NODE_IS_EDITING, NODE_IS_VIEWING, RECEIVE_NODE, RECEIVE_NODES, REMOVE_NODE, SET_NODE_WATCHING,
} from '../actions/node'
import { RECEIVE_MAP } from '../actions/map'
import { RECEIVE_RESOURCE } from '../actions/resource'
import { RECEIVE_TARGET, RECEIVE_TARGETS } from '../actions/target'

const initialState = {
    active: true,
    active_target: false,
    assigned_user: null,
    attributes: [],
    busy: false,
    comment_count: 0,
    created_at: null,
    editing: false,
    enhanced: false,
    exists: true,
    id: null,
    labels: [],
    labels_index: [],
    map_id: null,
    map_name: null,
    map_team_uri: null,
    map_uri: null,
    milestones: [],
    name: '',
    order: 0,
    parents: [],
    priority: null,
    resources_count: 0,
    target_due: null,
    target_duration: null,
    target_progress: null,
    tasks: [],
    updated_at: null,
    unread_comment_count: 0,
    unread_resources_count: 0,
    uri: null,
    watching: false,
}

const nodeReducer = (state = initialState, action) => {
    const actions = {
        [ADD_NODE]: () => ({
            ...state,
            ...action.node,
        }),
        [INCREMENT_NODE_COMMENT_COUNT]: () => ({
            ...state,
            comment_count: state.comment_count + 1,
        }),
        [NODE_IS_EDITING]: () => ({
            ...state,
            editing: true,
        }),
        [NODE_IS_VIEWING]: () => ({
            ...state,
            editing: false,
        }),
        [RECEIVE_NODE]: () => ({
            ...state,
            ...action.payload.node,
            ...(Array.isArray(action.payload.node.labels) ? ({
                labels_index: action.payload.node.labels.map(({id}) => id),
            }) : {})
        }),
        [SET_NODE_WATCHING]: () => ({
            ...state,
            watching: action.watching,
        })
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const nodesReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [ADD_NODE]: () => ({
            ...state,
            [action.node.uri]: nodeReducer(state[action.node.uri], action),
        }),
        [INCREMENT_NODE_COMMENT_COUNT]: () => ({
            ...state,
            [action.payload.uri]: nodeReducer(state[action.payload.uri], action),
        }),
        [NODE_IS_EDITING]: () => ({
            ...state,
            [action.uri]: nodeReducer(state[action.uri], action),
        }),
        [NODE_IS_VIEWING]: () => ({
            ...state,
            [action.uri]: nodeReducer(state[action.uri], action),
        }),
        [RECEIVE_MAP]: () => action.payload.map && action.payload.map.nodes ? nodesReducer(state, {
            type: RECEIVE_NODES,
            payload: {
                nodes: action.payload.map.nodes.map((item) => ({
                    ...item,
                    map_id: action.payload.map.id,
                    map_uri: action.payload.map.uri,
                }))
            },
        }) : state,
        [RECEIVE_NODE]: () => ({
            ...state,
            [action.payload.node.uri]: nodeReducer(state[action.payload.node.uri], action),
        }),
        [RECEIVE_NODES]: () => {
            const nodes = {}
            action.payload.nodes.forEach((node) => nodes[node.uri] = nodeReducer(state[node.uri], {
                ...action,
                type: RECEIVE_NODE,
                payload: {
                    node,
                },
            }))

            return {
                ...state, ...nodes
            }
        },
        [RECEIVE_TARGET]: () => ({
            ...state,
            ...action.payload.target.node ? ({
                [action.payload.target.node.uri]: nodeReducer(state[action.payload.target.node.uri], {
                    type: RECEIVE_NODE,
                    payload: {
                        node: {
                            ...action.payload.target.node,
                            active_target: true,
                            target_due: action.payload.target.due,
                            target_duration: action.payload.target.duration,
                            target_progress: action.payload.target.progress,
                        },
                    },
                }),
            }) : {},
        }),
        [RECEIVE_TARGETS] : () => ({
            ...state,
            ...action.payload.targets.filter(({node}) => node).reduce((carry, {due, duration, node, progress}) => ({
                ...carry,
                [node.uri]: nodeReducer(state[node.uri], {
                    type: RECEIVE_NODE,
                    payload: {
                        node: {
                            ...node,
                            active_target: true,
                            target_due: due,
                            target_duration: duration,
                            target_progress: progress,
                        },
                    },
                }),
            }), {}),
        }),
        [RECEIVE_RESOURCE]: () => action.payload.resource.pivot.resourceable_type === 'App\\Node' ? ({
            ...state,
            [action.payload.resource.pivot.resourceable_uri]: nodeReducer(state[action.payload.resource.pivot.resourceable_uri], {
                type: RECEIVE_NODE,
                payload: {
                    node: {
                        resources_count: state[action.payload.resource.pivot.resourceable_id].resources_count + 1,
                    },
                },
            }),
        }) : state,
        [REMOVE_NODE]: () => {
            const nextState = Object.assign({}, state)
            delete nextState[action.uri]

            return nextState
        },
        [SET_NODE_WATCHING]: () => ({
            ...state,
            [action.uri]: nodeReducer(state[action.uri], action),
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default nodesReducer
