import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Chip from './Chip'
import FormsyText from '../formsy/FormsyText'

const Fields = memo(({label}) => {
    const classes = useStyles()

    const [ hex, setHex ] = useState(label.hex || (Math.random().toString(16) + '000000').slice(2, 8))
    const [ name, setName ] = useState(label.name)

    const _handleHexChange = (event) => setHex(event.currentTarget.value)
    const _handleNameChange = (event) => setName(event.currentTarget.value)

    return (
        <React.Fragment>
            <Chip
                className={classes.preview}
                hex={hex}
                name={name || 'Preview'} />
            <fieldset>
                <FormsyText
                    defaultValue={name}
                    inputProps={{maxLength: 50}}
                    label="Name"
                    name="name"
                    onChange={_handleNameChange}
                    validationError="The label requires a name"
                    autoFocus fullWidth required />
            </fieldset>
            <fieldset>
                <FormsyText
                    InputProps={{
                        startAdornment: <InputAdornment position="start">#</InputAdornment>,
                    }}
                    defaultValue={hex}
                    inputProps={{maxLength: 6}}
                    label="Hex"
                    onChange={_handleHexChange}
                    name="hex"
                    validations={{matchRegexp: /^([a-f0-9]{6}|[a-f0-9]{3})$/i}}
                    validationError="The label requires a valid hex code"
                    fullWidth required />
            </fieldset>
        </React.Fragment>
    )
})

const useStyles = makeStyles((theme) => ({
    preview: {
        marginBottom: theme.spacing(2),
    },
}))

Fields.defaultProps = {
    label: {
        hex: '',
        name: '',
    }
}

Fields.propTypes = {
    label: PropTypes.shape({
        hex: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
}

export default Fields
