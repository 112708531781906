import AttachFile from '@material-ui/icons/AttachFile'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import React, { Fragment, memo, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { v1 as uuid } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import { withFormsy } from 'formsy-react'

import usePrevious from '../../hooks/usePrevious'

const FormsyFile = memo(({
    ButtonProps, defaultValue, disabled, errorMessage, errorMessages, hasValue, innerRef, isFormDisabled, isFormSubmitted, isPristine, isRequired, isValid, isValidValue, label, multiple, onChange, previewClass, previewNode, resetValue, setValidations, setValue, showError, showRequired, validationError, validationErrors, validations, value, ...rest
}) => {
    const [ file, setFile ] = useState(null)

    const classes = useStyles()
    const id = uuid()

    const previousIsPristine = usePrevious(isPristine)

    const handleChange = (event) => {
        if (event.target.files.length) {
            setValue(multiple ? event.target.files : event.target.files[0])
            setFile(event.target.files[0])
        }

        if (onChange) {
            onChange(event)
        }
    }

    useEffect(() => {
        if (! isPristine || previousIsPristine) {
            return
        }

        setFile(null)
    }, [isPristine])

    const defaultPreviewNode = useRef(null)

    return [
        <Fragment key={0}>
            <input className={classes.input} id={id} type="file" {...rest} onChange={handleChange} />
            <div className={classes.container}>
                <label htmlFor={id}>
                    <Button component="span" disabled={isFormDisabled || disabled} variant="outlined" {...ButtonProps}>
                        {label}
                    </Button>
                </label>
                <div ref={defaultPreviewNode} />
            </div>
        </Fragment>,
        <Preview
            previewClass={previewClass}
            previewNode={previewNode || defaultPreviewNode.current}
            file={file}
            key={1} />
    ]
})

const Preview = memo(({file, previewClass, previewNode}) => {
    const classes = useStyles()

    if (! file || ! previewNode) {
        return null
    }

    return (
        createPortal((
            <div className={`${classes.preview} ${previewClass}`}>
                <Icon>
                    <AttachFile />
                </Icon>
                <Typography className={classes.label} variant="body2" noWrap>{file.name}</Typography>
            </div>
        ), previewNode)
    )
})

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        height: 0,
        position: 'absolute',
        visibility: 'hidden',
        width: 0,
    },
    preview: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme.spacing(3),
        overflow: 'hidden',
    },
    label: {
        marginLeft: theme.spacing(.5),
    },
}))

FormsyFile.defaultProps = {
    ButtonProps: {},
    multiple: false,
    previewClass: '',
}

FormsyFile.propTypes = {
    ButtonProps: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    previewClass: PropTypes.string,
    previewNode: PropTypes.object,
}

export default withFormsy(FormsyFile)
