import React from 'react'
import { connect } from 'react-redux'

import TeamMembers from '../components/TeamMembers'
import { fetchTeam, removeUserFromTeam, teamFromUri } from '../actions/team'

class TeamMembersContainer extends React.Component {
    state = {
        hasFetched: false,
    }

    componentDidMount () {
        this.props.fetch().then(() => this.setState({
            hasFetched: true,
        }))
    }

    render () {
        const team = teamFromUri(this.props.teams, this.props.match.params.team)

        return (
            <TeamMembers
                hasFetched={this.state.hasFetched}
                isReady={Boolean(this.state.hasFetched || team.id)}
                onAdd={this._handleAdd}
                onDelete={this._handleDelete}
                onEdit={this._handleEdit}
                team={team}
                users={enrichUsers(this.props)} />
        )
    }

    _handleAdd = () => this.props.history.push(`/team/${this.props.match.params.team}/user/add`, {modal: true})
    _handleDelete = (id) => this.props.destroy(id)
    _handleEdit = (id) => this.props.history.push(`/team/${this.props.match.params.team}/user/${id}/edit`, {modal: true})
}

const enrichUsers = ({auth, match, users}) => Object.values(users).filter(({teams_index}) => match.params.team && Object.keys(teams_index).includes(match.params.team)).map((item) => ({
    ...item,
    canDelete: auth.userId !== item.id,
}))

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    teams: state.teams,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchTeam(ownProps.match.params.team)),
    destroy: (id) => dispatch(removeUserFromTeam(ownProps.match.params.team, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembersContainer)
