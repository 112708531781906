import Formsy from 'formsy-react'
import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Container from '../Shared/Container'
import FormsyText from '../formsy/FormsyText'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import SplashImage from '../../assets/images/splash.jpg'
import { handleLogin } from '../../actions/auth'

const Login = memo(({history}) => {
    const [ disabled, setDisabled ] = useState(true)

    const dispatch = useDispatch()
    const auth = useSelector(({auth}) => auth)

    const classes = useStyles()

    const handleInvalid = () => setDisabled(true)
    const handleValid = () => setDisabled(false)
    const handleSubmit = ({email, password}, resetForm, invalidateForm) => dispatch(handleLogin(email, password)).then(() => history.push('/')).catch((error) => {
        if (error.response && error.response.status === 422) {
            return invalidateForm(error.response.data.errors)
        }

        throw new Error(error)
    })

    return (
        <div className={classes.wrapper}>
            <Container className={classes.container} elevation={9} modal>
                <Heading>Login</Heading>
                <Formsy onInvalid={handleInvalid} onValid={handleValid} onValidSubmit={handleSubmit}>
                    <fieldset>
                        <FormsyText
                            autoComplete="email"
                            label="E-mail address"
                            name="email"
                            type="email"
                            validations="isEmail"
                            validationError="Check the e-mail address is valid."
                            autoFocus fullWidth required />
                    </fieldset>
                    <fieldset>
                        <FormsyText
                            autoComplete="current-password"
                            label="Password"
                            name="password"
                            type="password"
                            required fullWidth />
                    </fieldset>
                    <div className={classes.buttonsWrapper}>
                        <LoadingButton busy={auth.isBusy} color="primary" disabled={disabled} type="submit" variant="contained">Login</LoadingButton>
                        <Link className={classes.forgotLink} to="/password/reset">Forgotten your password?</Link>
                    </div>
                </Formsy>
            </Container>
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        alignItems: 'center',
        backgroundImage: `url(${SplashImage})`,
        backgroundSize: 'cover',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    container: {
        backgroundColor: 'rgba(255, 255, 255, .95)',
    },
    buttonsWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(3),
    },
    forgotLink: {
        color: theme.palette.text.secondary,
        fontWeight: '300',
        marginTop: theme.spacing(5),
    },
}))

export default Login
