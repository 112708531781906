import axios from 'axios'

import { incrementNodeCommentCount } from './node'

export const RECEIVE_COMMENT = 'RECIEVE_COMMENT'
export const RECEIVE_COMMENTS = 'RECIEVE_COMMENTS'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'

export const receiveComment = (comment) => ({
    type: RECEIVE_COMMENT,
    payload: {
        comment,
    },
})

export const receiveComments = (comments) => ({
    type: RECEIVE_COMMENTS,
    payload: {
        comments,
    },
})

const removeComment = (id) => ({
    type: REMOVE_COMMENT,
    payload: {
        id,
    },
})

export const toggleCommentLike = (id) =>
    (dispatch, getState) => {
        const liked = ! getState().comments[id].liked
        const likes = getState().comments[id].likes
        const userId = getState().auth.userId

        axios.put(`/comment/${id}`, {liked})

        if (liked) {
            likes.push(userId)
        } else {
            likes.splice(likes.indexOf(userId), 1)
        }

        return dispatch(receiveComment({
            id, liked, likes,
        }))
    }

export const toggleCommentPinned = (id) =>
    (dispatch, getState) => {
        const pinned = ! getState().comments[id].pinned

        dispatch(receiveComment({
            id, pinned,
        }))

        return axios.put(`/comment/${id}`, {
            pinned,
        })
    }

export const storeComment = (nodeUri, comment) =>
    (dispatch, getState) =>
        axios.post(`/node/${nodeUri}/comment`, Object.keys(comment).filter((key) => typeof comment[key] !== 'undefined' && comment[key] !== null).reduce((formData, key) => {
            formData.append(key, comment[key])
            return formData
        }, new FormData())).then(({data}) => {
            dispatch(receiveComment(data))
            dispatch(incrementNodeCommentCount(data.node.uri))

            return data
        })

export const updateComment = (id, comment) =>
    (dispatch, getState) =>
        axios.put(`/comment/${id}`, comment).then(({data}) => dispatch(receiveComment(data)))

export const deleteComment = (id) =>
    (dispatch, getState) =>
        axios.delete(`/comment/${id}`).then(({data}) => dispatch(removeComment(id)))

export const fetchCommentsForNode = (nodeUri, page = 1, sort='created_at', context = {}, cancelToken = null) =>
    (dispatch, getState) =>
        axios.get(`/node/${nodeUri}/comment`, {
            page, sort,
        }).then(({data}) => {
            dispatch(receiveComments(data.data))

            return data
        })

export const commentsForNode = (comments, nodeId) => Object.values(comments).filter(({node_id}) => node_id === nodeId)

const mapContextForRequest = (context) => Object.keys(context).filter((item) => ['labels', 'scopes', 'users'].includes(item)).reduce((carry, key, index) => {
    if (Array.isArray(context[key])) {
        context[key].forEach((item, index) => {
            carry[`context[${key}][${index}]`] = item
        })
    }

    return carry
}, {})
