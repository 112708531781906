import React from 'react'
import { connect } from 'react-redux'

import Search from '../components/Search'
import { mapFromUri } from '../actions/map'
import { search } from '../actions/search'

class SearchContainer extends React.Component {
    state = {
        currentTerm: '',
        lastTerm: '',
        isBusy: false,
        isBusyMaps: false,
        isBusyNodes: false,
        results: {},
        showResults: false,
    }

    componentDidMount () {
        const currentTerm = new URLSearchParams(this.props.location.search).get('q')

        if (currentTerm && currentTerm.length) {
            this._processChange(currentTerm)
        }
    }

    render () {
        return (
            <Search
                currentTerm={this.state.currentTerm}
                goals={getResults(this.state, 'nodes')}
                isBusy={this.state.isBusy}
                isBusyGoals={this.state.isBusyNodes}
                isBusyMaps={this.state.isBusyMaps}
                maps={getResults(this.state, 'maps')}
                onChange={this._handleChange}
                onClickNext={this._handleClickNext}
                showResults={this.state.showResults}
                remainingGoals={remainingResults(this.state, 'nodes')}
                remainingMaps={remainingResults(this.state, 'maps')} />
        )
    }

    _handleClickNext = (scope) => {
        const { current_page: currentPage } = getMeta(this.state, scope)

        this.setState({
            [`isBusy${scope.charAt(0).toUpperCase()}${scope.slice(1)}`]: true,
        })

        this._runSearch([scope], currentPage + 1)
    }

    _handleSearch = () => {
        this._runSearch()

        updateHistory(this.props.location, this.state.currentTerm)
    }

    _processChange = (currentTerm) => {
        if (this.state.currentTerm === currentTerm) {
            return
        }

        this.setState({
            lastTerm: currentTerm.length < 1 ? '' : (this.state.results[currentTerm] ? currentTerm : this.state.lastTerm),
            isBusy: Boolean(currentTerm.length && ! this.state.results[currentTerm]),
            isBusyMaps: false,
            isBusyNodes: false,
            showResults: Boolean(currentTerm.length && (this.state.results[this.state.lastTerm] || this.state.results[currentTerm])),
            currentTerm,
        })

        if (typeof this._searchTimeout === 'number') {
            clearTimeout(this._searchTimeout)
        }

        if (currentTerm.length < 1 || this.state.results[currentTerm]) {
            return updateHistory(this.props.location, currentTerm)
        }

        this._searchTimeout = setTimeout(this._handleSearch, 250)
    }

    _runSearch = (scopes = ['maps', 'nodes'], page = 1) => {
        if (! this.state.currentTerm.length) {
            return
        }

        const term = this.state.currentTerm
        this.props.search(term, scopes, page).then(({data: result}) => term === this.state.currentTerm && this.setState({
            isBusy: false,
            isBusyMaps: false,
            isBusyNodes: false,
            lastTerm: term,
            showResults: true,
            results: {
                ...this.state.results,
                [term]: {
                    ...this.state.results[term],
                    ...Object.keys(result).reduce((carry, item) => ({
                        ...carry,
                        [item]: {
                            ...result[item],
                            data: [
                                ...(this.state.results[term] && this.state.results[term][item] ? this.state.results[term][item].data : []),
                                ...result[item].data,
                            ],
                        },
                    }), {}),
                },
            },
        }))
    }

    _handleChange = (event) => this._processChange(event.target.value)
}

const getMeta = ({lastTerm, results}, scope) => results[lastTerm] ? results[lastTerm][scope].meta : {current_page: 0, to: 0, total: 0}
const getResults = ({lastTerm, results}, scope) => results[lastTerm] ? results[lastTerm][scope].data : []
const remainingResults = ({lastTerm, results}, scope) => results[lastTerm] ? results[lastTerm][scope].meta.total - results[lastTerm][scope].meta.to : 0
const updateHistory = (location, term) => {
    const params = new URLSearchParams(location.search)

    if (term.length) {
        params.set('q', term)
    } else {
        params.delete('q')
    }

    window.history.replaceState({}, '', `${location.pathname}${term.length ? '?' : ''}${params}`)
}

const mapStateToProps = (state, ownProps) => ({
    maps: state.maps,
    nodes: state.nodes,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    search: (term, scopes, page) => dispatch(search(term, scopes, page))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)
