import React from 'react'
import { connect } from 'react-redux'

import Clone from '../../components/Map/Clone'
import { cloneMap, fetchMap, mapFromUri } from '../../actions/map'

class CloneContainer extends React.Component {
    state = {
        hasFetched: false,
        isBusy: false,
        isValid: false,
    }

    componentDidMount () {
        this.props.fetch().then(() => this.setState({
            hasFetched: true,
        }))
    }

    render () {
        const map = mapFromUri(this.props.maps, this.props.match.params.map)

        return (
            <Clone
                canSubmit={this.state.isValid}
                isBusy={this.state.isBusy}
                isReady={Boolean((this.state.hasFetched || map.id) && Object.keys(this.props.teams).length)}
                map={map}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                teams={Object.values(this.props.teams)} />
        )
    }

    _handleSubmit = (formData, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.store(formData).then(({uri}) => this.props.history.push(`/d/${uri}`)).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => this.setState({
            isBusy: false,
        }))
    }

    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const mapStateToProps = (state, ownProps) => ({
    maps: state.maps,
    teams: state.teams,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchMap(ownProps.match.params.map)),
    store: (data) => dispatch(cloneMap(ownProps.match.params.map, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloneContainer)
