import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const UserIcon = React.memo(({className, fontSize, size, userId, ...rest}) => {
    const user = useSelector(({users}) => users[userId])
    const theme = useTheme()

    const classes = useStyles({
        hex: user === undefined ? (theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[600]) : user.hex,
        fontSize, size,
    })

    if (user === undefined) {
        return (
            <Avatar className={`${classes.photoIcon} ${className}`} {...rest}>
                <PersonIcon />
            </Avatar>
        )
    }

    if (user.profile_picture) {
        return (
            <Avatar
                alt={user.name}
                className={`${classes.photoIcon} ${className}`}
                src={user.profile_picture}
                {...rest} />
        )
    }

    return (
        <Avatar alt={user.name} className={`${classes.icon} ${className}`} {...rest}>
            {user.initials}
        </Avatar>
    )
})

const useStyles = makeStyles((theme) => ({
    icon: ({fontSize, size, hex}) => ({
        backgroundColor: hex,
        color: 'rgba(255, 255, 255, .7)',
        display: 'inline-flex',
        height: size,
        width: size,
        fontSize,
    }),
    photoIcon: ({size}) => ({
        height: size,
        width: size,
    }),
}))

UserIcon.defaultProps = {
    className: '',
    fontSize: 12,
    size: 40,
}

UserIcon.propTypes = {
    className: PropTypes.string,
    fontSize: PropTypes.number,
    size: PropTypes.number,
    userId: PropTypes.number,
}

export default UserIcon
