import axios from 'axios'

export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_USERS = 'RECEIVE_USERS'

export const receiveUsers = (users) => ({
    type: RECEIVE_USERS,
    payload: {
        users,
    },
})

export const receiveUser = (user) => ({
    type: RECEIVE_USER,
    payload: {
        user,
    },
})

export const storeUserForTeam = (user, teamUri) =>
    (dispatch, getState) =>
        axios.post(`/team/${teamUri}/user`, user).then(({data}) => {
            dispatch(receiveUser(data))

            return data
        })

export const updateUser = (userId, data, photo = null) =>
    (dispatch, getState) => {
        let formData = data

        if (photo !== null) {
            formData = new FormData()
            Object.keys(data).forEach((item) => formData.append(item, data[item]))

            formData.append('_method', 'put') // spoof PUT method due to long standing symfony issue - https://github.com/laravel/framework/issues/13457
            formData.append('profile_picture', photo)
        }

        return axios({
            method: photo === null ? 'put' : 'post',
            url: `/user/${userId}`,
            data: formData,
        }).then(({data}) => {
            dispatch(receiveUser(data))

            return data
        })
    }

export const updateUserPhoto = (userId, file) =>
    (dispatch, getState) => {
        const data = new FormData()
        data.append('_method', 'put') // spoof PUT method due to long standing symfony issue - https://github.com/laravel/framework/issues/13457
        data.append('profile_picture', file)

        return axios.post('/user/' + userId, data).then(({data}) => {
            dispatch(receiveUser(data))

            return data
        })
    }

export const updateUserForTeam = (user, teamUri, userId) =>
    (dispatch, getState) =>
        axios.put(`/team/${teamUri}/user/${userId}`, user).then(({data}) => {
            dispatch(receiveUser(data))

            return data
        })

export const fetchUser = (id) => (dispatch, getState) =>
    axios.get(`/user/${id}`).then(({data}) => {
        dispatch(receiveUser(data))

        return data
    })

export const fetchUsersForTeam = (teamUri) =>
    (dispatch, getState) =>
        axios.get(`/team/${teamUri}/user`).then(({data}) => {
            dispatch(receiveUsers(data))

            return data
        })

export const fetchUsersForClient = (clientUri) =>
    (dispatch, getState) =>
        axios.get(`/client/${clientUri}/user`).then(({data}) => {
            dispatch(receiveUsers(data))

            return data
        })

export const setTutorialEventHandled = (id, handledEvent) =>
    (dispatch, getState) =>
        axios.put(`/user/${id}`, {tutorial_handled: handledEvent}).then(({data}) => {
            dispatch(receiveUser(data))

            return data
        })

export const userFromId = (users, id) => users[id] || {
    name: '',
    teams_index: [],
}
