import { REHYDRATE } from 'redux-persist'

import { RECEIVE_INSTANCE, RECEIVE_INSTANCES } from '../actions/instance'
import { RECEIVE_MAP } from '../actions/map'

const initialState = {
    id: null,
    map_uri: null,
    node_uri: null,
}

const instanceReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_INSTANCE]: () => ({
            ...state,
            ...action.payload.instance,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const instancesReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_INSTANCE]: () => ({
            ...state,
            [action.payload.instance.id]: instanceReducer(state[action.payload.instance.id], action),
        }),
        [RECEIVE_INSTANCES]: () => action.payload.instances.reduce((carry, instance) => ({
            ...carry,
            [instance.id]: instanceReducer(state[instance.id], {
                type: RECEIVE_INSTANCE,
                payload: {
                    instance,
                },
            }),
        }), {}),
        [RECEIVE_MAP]: () => action.payload.map && action.payload.map.instances ? instancesReducer(state, {
            type: RECEIVE_INSTANCES,
            payload: {
                instances: action.payload.map.instances.map((item) => ({
                    ...item,
                    map_uri: action.payload.map.uri,
                }))
            },
        }) : state,
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default instancesReducer
