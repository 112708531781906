import Echo from 'laravel-echo'
import Pusher from  'pusher-js'
import PusherBatchAuthorizer from 'pusher-js-auth'
import React from 'react'
import axios from 'axios'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { addValidationRule } from 'formsy-react'
import { defaults as chartDefaults } from 'react-chartjs-2'
import { isBefore } from 'date-fns'

import Main from './components/Main'
import { COLOR_GREY_DARKER } from './constants/colors'
import { INVALIDATE_TOKEN, UPDATE_TOKEN } from './actions/auth'
import { fetchTeams } from './actions/team'
import { logout } from './actions/auth'
import { store, persistor } from './configureStore'

class App extends React.Component {
    componentDidMount () {
        addValidationRule('isAfterDateInitiated', (values, value) => isBefore(values.date_initiated, value))

        window.addEventListener('storage', this._handleStorageEvent)

        axios.defaults.baseURL = `${process.env.REACT_APP_APP_URL}/${process.env.REACT_APP_API_URL}`

        chartDefaults.global.defaultFontColor = COLOR_GREY_DARKER
        chartDefaults.global.defaultFontFamily = 'Roboto'
        chartDefaults.global.defaultFontSize = 12

        this.axiosRequestInterceptor = axios.interceptors.request.use(axiosHandleRequest)
        this.axiosResponseInterceptor = axios.interceptors.response.use(axiosHandleResponse, axiosHandleError)
    }

    componentWillUnmount () {
        window.removeEventListener('storage', this._handleStorageEvent)

        axios.interceptors.request.eject(this.axiosHandleRequest)
        axios.interceptors.response.eject(this.axiosResponseInterceptor)
    }

    render () {
        return (
            <Provider store={store}>
                <PersistGate loading={this._loadingView()} onBeforeLift={this._rehydrated} persistor={persistor}>
                    <Main />
                </PersistGate>
            </Provider>
        )
    }

    _handleStorageEvent = (event) => {
        if (event.key === 'logout') {
            store.dispatch(logout())
        }
    }

    _loadingView = () => (
        <div className="state-loading">
            <img src="https://d1ot1inkwflex.cloudfront.net/images/goal-atlas-icon.png" alt="Goal Atlas"/>
        </div>
    )

    _rehydrated = () => {
        // this._setupEcho()

        if (store.getState().auth.isAuthed) {
            this._fetchTeams()
        }
    }

    _fetchTeams = () => {
        store.dispatch(fetchTeams())
    }

    _setupEcho = () => {
        window.Echo = new Echo({
            auth: {
                headers: {'Authorization': `Bearer ${store.getState().auth.token}`}
            },
            authorizer: PusherBatchAuthorizer,
            authEndpoint: `${process.env.REACT_APP_APP_URL}/${process.env.REACT_APP_AUTH_URL}`,
            authDelay: 50,
            broadcaster: 'pusher',
            cluster: 'eu',
            encrypted: true,
            key: process.env.REACT_APP_PUSHER_KEY,
        })
    }
}

const axiosHandleRequest = (config) => {
    config.headers.Authorization = `Bearer ${store.getState().auth.token}`

    return config
}

const axiosHandleResponse = (response) => {
    if (response.headers.authorization) {
        store.dispatch({
            type: UPDATE_TOKEN,
            payload: {
                token: response.headers.authorization.replace(/^Bearer /, ''),
            },
        })

        // window.Echo.connector.pusher.config.auth.headers['Authorization'] = response.headers.authorization
    }

    return response
}

const axiosHandleError = (error) => {
    if (error.response && error.response.status === 401) {
        store.dispatch({
            type: INVALIDATE_TOKEN,
        })

        error.handled = true
    } else if (error.response && error.response.headers.authorization) {
        store.dispatch({
            type: UPDATE_TOKEN,
            payload: {
                token: error.response.headers.authorization.replace(/^Bearer /, '')
            },
        })

        // window.Echo.connector.pusher.config.auth.headers['Authorization'] = error.response.headers.authorization
    }

    if (error.response && error.response.status === 422 && Object.keys(error.response.data).length) {
        error.validation = {}
        Object.keys(error.response.data).forEach((prop) => {
            error.validation[prop] = error.response.data[prop]
        })
    }

    return Promise.reject(error)
}

export default App
