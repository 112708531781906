import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import NodeFinder from '../components/NodeFinder'
import { searchNodes } from '../actions/node'

class NodeFinderContainer extends React.Component {
    static defaultProps = {
        exclude: [],
        include: null,
        withSearch: false,
    }

    static propTypes = {
        exclude: PropTypes.array,
        include: PropTypes.array,
        initialValue: PropTypes.string,
        mapId: PropTypes.string.isRequired,
        nodes: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        withSearch: PropTypes.bool,
    }

    state = {
        busy: false,
    }

    componentRef = React.createRef()

    clear = () => {
        this.componentRef.current.clear()
    }

    render () {
        const { exclude, include, mapId, nodes, onChange, ...props } = this.props

        return (
            <NodeFinder
                busy={this.state.busy}
                items={items(this.props)}
                onChange={this._handleChange}
                onSelect={this._handleSelect}
                placeholder={this.props.placeholder}
                ref={this.componentRef}
                {...props} />
        )
    }

    _handleChange = (event) => {
        const value = event.currentTarget.value

        if (this.props.onChange) {
            this.props.onChange(value)
        }

        if (! this.props.withSearch) {
            return
        }

        if (! value.length) {
            return this.setState({
                busy: false,
            })
        }

        this.setState({
            busy: true,
        })

        if (typeof this._handleUpdateTimeout !== 'undefined') {
            clearTimeout(this._handleUpdateTimeout)
        }

        this._handleUpdateTimeout = setTimeout(() => {
            this.props.search(value).then((data) => {
                this.setState({
                    busy: false,
                })
            })
        }, 250)
    }

    _handleSelect = (item) => {
        this.props.onNodeSelected(item.id)
    }
}

const items = ({exclude, include, mapId, nodes}) => Object.values(nodes).filter(({map_id, id}) => mapId === map_id && (include ? include.includes(id) : ! exclude.includes(id))).sort((a,b) => a.name - b.name).map(({id, name, map_id}) => ({id, label: `${name}`}))

const mapStateToProps = (state, ownProps) => ({
    nodes: state.nodes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    search: (term) => dispatch(searchNodes(term)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(NodeFinderContainer)
