import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import ReorderIcon from '@material-ui/icons/Reorder'
import SettingsIcon from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'

import Modal from '../Shared/Modal'
import { ReorderableList, ReorderableListItem } from '../../includes/ReorderableList'

const DashboardConfigure = ({isBusy, isReady, modules, onConfigure, onMoveItem, onSave, onToggleItem, scope}) => (
    <Modal>
        <DialogTitle>Configure {scope}</DialogTitle>
        <DialogContent style={styles.container}>
            {isReady ? (
                <React.Fragment>
                    <Typography variant="subtitle1">Modules</Typography>
                    <ReorderableList>
                        {modules.map(({configurable, enabled, key, label}, index) => (
                            <ReorderableListItem
                                index={index}
                                key={key}
                                leftIcon={<ReorderIcon />}
                                moveItem={onMoveItem}
                                primaryText={label}
                                rightIconButton={(
                                    <div style={styles.icons}>
                                        {configurable ? (
                                            <IconButton onClick={onConfigure} value={key}>
                                                <SettingsIcon />
                                            </IconButton>
                                        ) : null}
                                        <Checkbox checked={enabled} color="secondary" onChange={onToggleItem} value={key}/>
                                    </div>
                                )} />
                        ))}
                    </ReorderableList>
                    <Button color="primary" disabled={isBusy} onClick={onSave} variant="contained" fullWidth>Update</Button>
                </React.Fragment>
            ) : <CircularProgress size={30} style={styles.busy} />}
        </DialogContent>
    </Modal>
)

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    busy: {
        alignSelf: 'center',
        marginTop: 10,
    },
    icons: {
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: 96,
    },
}

DashboardConfigure.defaultProps = {
    isBusy: false,
    isReady: false,
}

DashboardConfigure.propTypes = {
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    modules: PropTypes.arrayOf(PropTypes.shape({
        enabled: PropTypes.bool.isRequired,
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
    })).isRequired,
    onMoveItem: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onToggleItem: PropTypes.func.isRequired,
    scope: PropTypes.string.isRequired,
}

export default DashboardConfigure
