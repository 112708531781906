import ListItem from '@material-ui/core/ListItem'
import PropTypes from 'prop-types'
import React from 'react'

import CommentsIcon from '../CommentsIcon'
import LabelChip from '../Label/Chip'
import NodeOwnerContainer from '../../containers/Node/NodeOwnerContainer'
import PriorityIcon from '../PriorityIcon'
import ResourcesIcon from '../ResourcesIcon'
import TargetIconContainer from '../../containers/Target/TargetIconContainer'
import WatchIcon from '../WatchIcon'
import { COLOR_GREY_LIGHTER } from '../../constants/colors'

class Item extends React.Component {
    render () {
        return (
            <ListItem button onClick={this.props.onClick} style={{...styles.container, ...this.props.style}}>
                <div style={styles.row}>
                    <div style={styles.iconContainer}>
                        <NodeOwnerContainer id={this.props.id} />
                    </div>
                    <div style={styles.nameContainer}>
                        {this.props.name}
                    </div>
                    <div style={styles.iconContainer}>
                        <TargetIconContainer target={extractTargetFromNode(this.props)} />
                    </div>
                </div>
                <div style={{...styles.row, ...styles.icons}}>
                    <PriorityIcon priority={this.props.priority} showBackground={false} size={32} style={styles.icon} />
                    <WatchIcon watching={this.props.watching} showBackground={false} size={32} style={styles.icon} />
                    <CommentsIcon comments={this.props.comment_count} showBackground={false} size={32} style={styles.icon} unread={this.props.unread_comment_count > 0} />
                    <ResourcesIcon resources={this.props.resources} showBackground={false} size={32} style={styles.icon} unread={this.props.unread_resources} />
                </div>
                {this.props.labels.length ? (
                    <div style={{...styles.row, ...styles.labelsContainer}}>
                        {this.props.labels.map(({hex, id, name}) => (
                            <LabelChip dense={true} hex={hex} key={id} name={name} style={styles.label} />
                        ))}
                    </div>
                ) : null}
            </ListItem>
        )
    }
}

Item.defaultProps = {
    style: {},
}

Item.propTypes = {
    labels: PropTypes.array.isRequired,
    style: PropTypes.object,
}

const extractTargetFromNode = ({target_duration, target_progress}) => ({
    duration: target_duration,
    progress: target_progress,
})

const styles = {
    container: {
        alignItems: 'stretch',
        borderBottomColor: COLOR_GREY_LIGHTER,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        justifyContent: 'center',
    },
    row: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    icons: {
        marginTop: 16,
        paddingLeft: 48,
        justifyContent: 'initial',
    },
    labelsContainer: {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginTop: 10,
        paddingLeft: 48,
    },
    label: {
        marginTop: 6,
        marginRight: 12,
    },
    nameContainer: {
        flex: 1,
        fontSize: 16,
        marginLeft: 16,
        marginRight: 16,
    },
    iconContainer: {
        width: 32,
    },
    icon: {
        marginRight: 6,
    }
}

export default Item
