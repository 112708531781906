import Downshift from 'downshift'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

class Autocomplete extends React.Component {
    state = {
        inputValue: this.props.initialValue || '',
    }

    clear = () => {
        this.setState({inputValue: ''})
    }

    render () {
        return (
            <div style={this.props.containerStyle}>
                <Downshift itemToString={(item) => item ? item[this.props.label] : null} onChange={this.props.onSelect} onSelect={this.props.onSelect} onStateChange={({inputValue}) => inputValue !== null ? this.setState({inputValue}) : null}>
                    {({getInputProps, getItemProps, highlightedIndex, inputValue, isOpen, selectedItem}) => {
                        const combinedInputProps = {
                            ...getInputProps(this.props.inputProps),
                            onChange: (event) => this._handleChange(event, getInputProps().onChange),
                            value: this.state.inputValue,
                        }

                        return (
                            <div style={styles.container}>
                                <TextField {...combinedInputProps} />
                                {isOpen ? (
                                    <div style={styles.itemsContainer}>
                                        {filteredItems(this.props.items, this.props.label, inputValue, getItemProps, highlightedIndex, selectedItem)}
                                    </div>
                                ) : null}
                            </div>
                        )
                    }}
                </Downshift>
            </div>
        )
    }

    _handleChange = (event, internalOnChange) => {
        internalOnChange(event)

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }
}

Autocomplete.defaultProps = {
    containerStyle: {},
    inputProps: {},
    label: 'label',
}

Autocomplete.propTypes = {
    containerStyle: PropTypes.object,
    inputProps: PropTypes.object,
    items: PropTypes.array.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
}

const filteredItems = (items, label, inputValue, getItemProps, highlightedIndex, selectedItem) => items.filter((item) => inputValue && inputValue.length && item[label] && item[label].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1).map((item, index) => (
    <MenuItem component="div" {...getItemProps({index, item, key: item.id, style: {...(highlightedIndex === index ? styles.highlight : null)}})}>
        {parse(item[label], match(item[label], inputValue, true)).map(({highlight, text}, index) => (
            <span key={index} style={styles.label(highlight)}>{text}</span>
        ))}
    </MenuItem>
))

const styles = {
    label: (highlight) => ({
        fontWeight: highlight ? '700' : '400',
        whiteSpace: 'pre-wrap',
    }),
    itemsContainer: {
        maxHeight: 300,
        overflowY: 'auto',
    },
}

export default Autocomplete
