import React from 'react'
import { connect } from 'react-redux'

import UserAdd from '../components/UserAdd'
import { addUserToTeam, fetchTeam, teamFromUri } from '../actions/team'

class UserAddContainer extends React.Component {
    state = {
        isBusy: false,
        hasFetched: false,
        selectedUser: '',
        isValid: false,
    }

    componentDidMount () {
        this.props.fetch().then(() => this.setState({
            hasFetched: true,
        }))
    }

    render () {
        const team = teamFromUri(this.props.teams, this.props.match.params.team)

        return (
            <UserAdd
                canSubmit={this.state.isValid}
                clientUri={team.client_uri}
                ignoredUsers={ignoredUsers(this.props)}
                isBusy={this.state.isBusy}
                isReady={Boolean(this.state.hasFetched || team.id)}
                isValid={this.state.isValid}
                onChange={this._handleChange}
                onClose={this._handleClose}
                onSubmit={this._handleSubmit}
                onInvalid={this._handleInvalid}
                onValid={this._handleValid}
                selectedUser={this.state.selectedUser}
                teamUri={this.props.match.params.team} />
        )
    }

    _handleSubmit = (formData, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.store(formData).then(this.props.history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => this.setState({
            isBusy: false,
        }))
    }

    _handleChange = (event, user) => this.setState({selectedUser: user === null ? '' : user.id})
    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const ignoredUsers = ({match, teams, users}) => Object.values(users).filter(({teams_index}) => Object.keys(teams_index).includes(match.params.team)).map(({id}) => id)

const mapStateToProps = (state, ownProps) => ({
    teams: state.teams,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchTeam(ownProps.match.params.team)),
    store: (user) => dispatch(addUserToTeam(ownProps.match.params.team, user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAddContainer)
