import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { FixedSizeList } from 'react-window'
import { makeStyles } from '@material-ui/core/styles'

import Container from './Shared/Container'
import Heading from './Shared/Heading'
import Modal from './Shared/Modal'
import PageLoadingIndicator from './PageLoadingIndicator'
import UserIcon from './UserIcon'

const TeamMembers = ({hasFetched, isReady, onAdd, onDelete, onEdit, team, users}) => {
    const classes = useStyles()
    const rowHeight = 56

    const Row = ({index, style}) => {
        const handleDelete = () => onDelete(users[index].id)
        const handleEdit = () => onEdit(users[index].id)

        return (
            <ListItem ContainerComponent="div" ContainerProps={{style}} key={index}>
                <ListItemAvatar>
                    <UserIcon userId={users[index].id} />
                </ListItemAvatar>
                <ListItemText>{users[index].name}</ListItemText>
                <ListItemSecondaryAction>
                    <Tooltip title="Edit">
                        <IconButton onClick={handleEdit}>
                            <CreateIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={users[index].canDelete ? 'Delete' : 'You are not able to remove yourself from a team.'}>
                        <span> {/* Excess component to prevent warning when tooltip wrapping disabled node */}
                            <IconButton disabled={! users[index].canDelete} onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>{team.name}</Heading>
                        {users.length ? (
                            <React.Fragment>
                                <FixedSizeList height={Math.min(8, users.length) * rowHeight} itemCount={users.length} itemSize={rowHeight} width="100%">
                                    {Row}
                                </FixedSizeList>
                                <div className={classes.addButtonWrapper}>
                                    <Fab color="primary" onClick={onAdd}>
                                        <AddIcon />
                                    </Fab>
                                </div>
                            </React.Fragment>
                        ) : (
                            hasFetched ? (
                                <Typography>Well this is embarassing! You don&rsquo;t appear to have any team members and that shouldn&rsquo;t be possible! Perhaps a page refresh would help?</Typography>
                            ) : (
                                <PageLoadingIndicator className={classes.loadingIndicator} />
                            )
                        )}
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

TeamMembers.defaultProps = {
    hasFetched: false,
    isReady: false,
}

TeamMembers.propTypes = {
    hasFetched: PropTypes.bool,
    isReady: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    team: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
        canDelete: PropTypes.bool,
        name: PropTypes.string.isRequired,
    })).isRequired,
}

const useStyles = makeStyles((theme) => ({
    addButtonWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        paddingTop: theme.spacing(4),
    },
    loadingIndicator: {
        alignSelf: 'center',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
}))

export default TeamMembers
