import axios from 'axios'

export const RECEIVE_CALCULATION = 'RECEIVE_CALCULATION'
export const RECEIVE_CALCULATIONS = 'RECEIVE_CALCULATIONS'

const receiveCalculations = (calculations) => ({
    type: RECEIVE_CALCULATIONS,
    payload: {
        calculations,
    },
})

const receiveCalculation = (calculation) => ({
    type: RECEIVE_CALCULATION,
    payload: {
        calculation,
    },
})

export const fetchCalculationsForMap = (map) => (dispatch, getState) =>
    axios.get(`/map/${map}/calculation`).then(({data}) => dispatch(receiveCalculations(data)))

export const fetchCalculationsForNode = (node) => (dispatch, getState) =>
    axios.get(`/node/${node}/calculation`).then(({data}) => dispatch(receiveCalculations(data)))

export const storeCalculation = (node, data) => (dispatch, getState) =>
    axios.post(`/node/${node}/calculation`, data).then(({data}) => dispatch(receiveCalculation(data)))

export const updateCalculation = (id, node, data) => (dispatch, getState) =>
    axios.put(`/node/${node}/calculation/${id}`, data).then(({data}) => dispatch(receiveCalculation(data)))

export const calculationsForNode = (calculations, nodeId) => Object.values(calculations).filter(({node_id}) => node_id === nodeId)

export const sumCalculation = (values, calculations) => values.length ? values.reduce((carry, {operator}, index) => {
    if (! values[index + 1] || operator === 'sum') {
        return carry
    }

    const value = calculationValue(values[index + 1].value, calculations)

    if (operator === 'add') {
        return carry + value
    }

    if (operator === 'subtract') {
        return carry - value
    }

    if (operator === 'multiply') {
        return carry * value
    }

    if (operator === 'divide') {
        return carry / value
    }

    return carry
}, calculationValue(values[0].value, calculations)) : 0

export const operatorToHex = (operator) => {
    if (operator === 'add') {
        return '\u002B'
    }

    if (operator === 'subtract') {
        return '\u2212'
    }

    if (operator === 'multiply') {
        return '\u00D7'
    }

    if (operator === 'divide') {
        return '\u00F7'
    }

    if (operator === 'sum') {
        return '\u003D'
    }
}

const calculationValue = (value, calculations) => value ? (typeof value === 'string' && value.match(/^[a-z0-9]{8}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{12}$/) ? (Object.values(calculations).find(({node_id}) => node_id === value) ? sumCalculation(Object.values(calculations).find(({node_id}) => node_id === value).values, calculations) : 0) : parseFloat(value)) : 0
