import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import GoalPerformance from '../../components/Goal/GoalPerformance'
import { getTargetPerformance } from '../../actions/target'
import { mapFromUri } from '../../actions/map'

class GoalPerformanceContainer extends React.Component {
    static propTypes = {
        mapUri: PropTypes.string.isRequired,
    }

    state = {
        active: 0,
        completed: 0,
        due: 0,
        isFetching: false,
        missed: 0,
        total: 0,
    }

    componentDidMount () {
        this._fetchData(this.props)
    }

    componentDidUpdate (prevProps) {
        if (configHasChanged(this.props, prevProps)) {
            this._fetchData(this.props)
        }
    }

    render () {
        return (
            <GoalPerformance
                active={this.state.active}
                completed={this.state.completed}
                due={this.state.due}
                isFetching={this.state.isFetching}
                missed={this.state.missed}
                total={this.state.total} />
        )
    }

    _fetchData = (props) => {
        this.setState({
            isFetching: true,
        })

        getTargetPerformance(props.mapUri, config(props)).then((data) => this.setState({
            ...data,
            isFetching: false,
        }))
    }
}

const config = ({auth, maps, mapUri}) => mapFromUri(maps, mapUri).config.modules.goal_performance ? (mapFromUri(maps, mapUri).config.modules.goal_performance.options || defaultConfig(auth)) : defaultConfig(auth)
const configHasChanged = (props, nextProps) => configSignature(props) !== configSignature(nextProps)
const configSignature = (props) => [Array.isArray(config(props).scopes) ? config(props).scopes.sort().join('|') : '', Array.isArray(config(props).users) ? config(props).users.sort().join('|') : '', Array.isArray(config(props).labels) ? config(props).labels.sort().join('|') : ''].join('|')
const defaultConfig = ({userId}) => ({
    labels: [],
    scopes: ['watching'],
    users: [userId],
})

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    maps: state.maps,
})

export default connect(mapStateToProps)(GoalPerformanceContainer)
