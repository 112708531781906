import React from 'react'
import { connect } from 'react-redux'

import UserForm from '../components/UserForm'
import { storeUserForTeam } from '../actions/user'

class UserCreateContainer extends React.Component {
    state = {
        isBusy: false,
        hasChanged: false,
        isValid: false,
    }

    render () {
        return (
            <UserForm
                canSubmit={Boolean(this.state.hasChanged && this.state.isValid)}
                isBusy={this.state.isBusy}
                onChange={this._handleChange}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                saveLabel="Add"
                title="Add a Team Member" />
        )
    }

    _handleSubmit = (formData, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.store(formData).then(() => this.props.history.push(`/team/${this.props.match.params.team}/user`)).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => this.setState({
            isBusy: false,
        }))
    }

    _handleChange = (formData, hasChanged) => this.setState({hasChanged})
    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    store: (user) => dispatch(storeUserForTeam(user, ownProps.match.params.team)),
})

export default connect(null, mapDispatchToProps)(UserCreateContainer)
