import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import React from 'react'
import Slide from '@material-ui/core/Slide'

const Tutorial = ({closeLabel, description, onClose, open, preview, title}) => open ? (
    <Dialog TransitionComponent={(props) => <Slide direction="down" {...props} />} onClose={onClose} open>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={styles.content}>
            {(Array.isArray(description) ? description : [description]).map((text, i) => (
                <DialogContentText key={i} gutterBottom>{text}</DialogContentText>
            ))}
            {Boolean(preview) && (
                <img src={`data:image/svg+xml;utf8,${preview}`} style={styles.preview} />
            )}
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={onClose}>{closeLabel}</Button>
        </DialogActions>
    </Dialog>
) : null

Tutorial.defaultProps = {
    open: false,
}

Tutorial.propTypes = {
    closeLabel: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
        PropTypes.array, PropTypes.string,
    ]),
    preview: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
}

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    preview: {
        justifySelf: 'center',
        marginTop: 24,
    },
}

export default Tutorial
