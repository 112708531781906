import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const Value = ({format, prefix, suffix, value, ...rest}) => {
    const classes = useStyles()

    const valueOutput = format === 'boolean' ? (value ? 'Met' : 'Not met') : parseFloat(value).toLocaleString(undefined, {
        maximumFractionDigits: format === 'decimal' ? 2 : 0,
    })

    return (
        <Typography {...rest}>
            {shouldShowPrefix(format) && (
                prefix
            )}
            {valueOutput}
            {Boolean(suffix && shouldShowSuffix(format) && suffix !== '%') && ' '}
            {shouldShowSuffix(format) && (
                <span className={classes.suffix}>{suffix}</span>
            )}
        </Typography>
    )
}

const shouldShowPrefix = (format) => ['decimal', 'integer'].includes(format)
const shouldShowSuffix = (format) => ['decimal', 'integer'].includes(format)

const useStyles = makeStyles((theme) => ({
    prefix: {
        fontSize: '.65em',
        fontWeight: '300',
    },
    suffix: {
        fontSize: '.65em',
        fontWeight: '300',
    },
}))

Value.propTypes = {
    format: PropTypes.oneOf([
        'boolean', 'decimal', 'integer',
    ]).isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.bool, PropTypes.number, PropTypes.string,
    ]).isRequired,
}

export default Value
