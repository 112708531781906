import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Formsy from 'formsy-react'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React, { Fragment, memo, useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Container from '../Shared/Container'
import FormsySelect from '../formsy/FormsySelect'
import FormsyText from '../formsy/FormsyText'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import UserIcon from '../UserIcon'
import { fetchMap, updateMap } from '../../actions/map'
import { fetchTeams } from '../../actions/team'
import { fetchUsersForClient } from '../../actions/user'
import { historyShape, mapMatchShape } from '../../constants/types'

const Edit = memo(({history, match}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const map = useSelector(({maps}) => maps[match.params.map]) || {}
    const { clientUri, userId: authId } = useSelector(({auth}) => auth)
    const [ teamUri, setTeamUri ] = useState(map.team_uri)

    const teams = useSelector(({teams}) => Object.values(teams).filter(({client_uri}) => client_uri === clientUri))
    const users = useSelector(({users}) => Object.values(users).filter(({teams_index}) => Object.keys(teams_index).includes(teamUri)))

    const [ isBusy, setIsBusy ] = useState(false)
    const [ hasChanged, setHasChanged ] = useState(false)
    const [ isReady, setIsReady ] = useState(Boolean(map.id !== undefined))
    const [ isValid, setIsValid ] = useState(false)

    const canSubmit = Boolean(hasChanged && isValid)
    const isOwner = authId === map.created_by

    useEffect(() => {
        dispatch(fetchMap(match.params.map)).then(({team_uri}) => {
            setIsReady(true)
            setTeamUri(team_uri)

            if (! isOwner) {
                return
            }

            _fetchTeams()
            _fetchUsers()
        }).catch((error) => {
            if (error.response && error.response.status === 404) {
                return history.push('/m')
            }

            throw new Error(error)
        })
    }, [])

    const _fetchTeams = () => dispatch(fetchTeams())
    const _fetchUsers = () => dispatch(fetchUsersForClient(clientUri))

    const _handleSubmit = (formData, resetForm, invalidateForm) => {
        setIsBusy(true)

        dispatch(updateMap(match.params.map, formData)).then(history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setIsBusy(false))
    }

    const _handleChange = (formData, hasChanged) => setHasChanged(hasChanged)
    const _handleInvalid = () => setIsValid(false)
    const _handleValid = () => setIsValid(true)

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <Fragment>
                        <Heading>{map.name}</Heading>
                        <Formsy onChange={_handleChange} onInvalid={_handleInvalid} onValid={_handleValid} onValidSubmit={_handleSubmit}>
                            <fieldset>
                                <FormsyText
                                    defaultValue={map.name}
                                    label="Name"
                                    name="name"
                                    validationError="You must provide a name for the map"
                                    fullWidth required />
                            </fieldset>
                            <Tooltip title={isOwner ? '' : 'Only the goal map owner can change the team.'}>
                                <fieldset>
                                    <FormsySelect defaultValue={map.team_uri} disabled={! isOwner} label="Team" name="team_uri" required={isOwner} fullWidth>
                                        {teams.map(({name, uri}) => (
                                            <MenuItem key={uri} value={uri}>{name}</MenuItem>
                                        ))}
                                    </FormsySelect>
                                </fieldset>
                            </Tooltip>
                            <Tooltip title={isOwner ? '' : 'Only the goal map owner can change the owner.'}>
                                <fieldset>
                                    <FormsySelect defaultValue={map.created_by} disabled={! isOwner} label="Owner" name="created_by" required={isOwner} fullWidth>
                                        {users.map(({id, name}) => (
                                            <MenuItem key={id} value={id}>
                                                <div className={classes.user}>
                                                    <UserIcon
                                                        className={classes.userPhoto}
                                                        size={30}
                                                        userId={id} />
                                                    {name}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </FormsySelect>
                                </fieldset>
                            </Tooltip>
                            <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                Update
                            </LoadingButton>
                        </Formsy>
                    </Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
})

const useStyles = makeStyles((theme) => ({
    user: {
        alignItems: 'center',
        display: 'flex',
    },
    userPhoto: {
        marginRight: 12,
    },
    button: {
        marginTop: theme.spacing(4),
    },
}))

Edit.propsTypes = {
    history: historyShape.isRequired,
    match: mapMatchShape.isRequired,
}

const LegacyEdit = ({canSubmit, createdBy, name, onChange, onClose, onInvalid, onSubmit, onValid, teams, teamUri, users}) => (
    <Modal>
        <DialogTitle>Edit Goal Map</DialogTitle>
        <Formsy onChange={onChange} onValid={onValid} onValidSubmit={onSubmit} onInvalid={onInvalid}>
            <DialogContent>
                <fieldset>
                    <FormsyText
                        defaultValue={name}
                        label="Name"
                        name="name"
                        validationError="You must provide a name for the map"
                        fullWidth required />
                </fieldset>
                <fieldset>
                    <FormsySelect defaultValue={teamUri} label="Team" name="team_uri" fullWidth required>
                        {teams.map((team) => <MenuItem key={team.uri} value={team.uri}>{team.name}</MenuItem>)}
                    </FormsySelect>
                </fieldset>
                <fieldset>
                    <FormsySelect defaultValue={createdBy} label="Owner" name="created_by" renderValue={(value) => userSelectValue(users, value)} fullWidth required>
                        {users.map(({id, name}) => (
                            <MenuItem key={id} value={id} style={legacyStyles.userItem}>
                                <UserIcon size={30} style={legacyStyles.userPhoto} userId={id} />
                                {name}
                            </MenuItem>
                        ))}
                    </FormsySelect>
                </fieldset>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={! canSubmit} type="submit">
                    Update
                </Button>
            </DialogActions>
        </Formsy>
    </Modal>
)

const disabled = (busy, valid, dirty) => busy || ! valid || ! dirty

const userSelectValue = (users, value) => {
    const user = users.find(({id}) => id === value)
    if (! user) {
        return ''
    }

    return user.name
}

const legacyStyles = {
    userItem: {
        alignItems: 'center',
        display: 'flex',
    },
    userPhoto: {
        marginRight: 12,
    },
}

export default Edit
