import React from 'react'
import { connect } from 'react-redux'

import TeamForm from '../components/TeamForm'
import { teamFromUri } from '../actions/team'
import { fetchTeam, updateTeam } from '../actions/team'

class TeamEditContainer extends React.Component {
    state = {
        isBusy: false,
        hasChanged: false,
        hasFetched: false,
        isValid: false,
    }

    componentDidMount () {
        this.props.fetch().then(() => this.setState({
            hasFetched: true,
        }))
    }

    render () {
        const team = teamFromUri(this.props.teams, this.props.match.params.team)

        return (
            <TeamForm
                canSubmit={Boolean(this.state.hasChanged && this.state.isValid)}
                isBusy={this.state.isBusy}
                isReady={Boolean(this.state.hasFetched || team.id)}
                onChange={this._handleChange}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                saveLabel="Update"
                team={team}
                title={`Edit ${team.name}`} />
        )
    }

    _handleSubmit = (formData, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.update(formData).then(this.props.history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => this.setState({
            isBusy: false,
        }))
    }

    _handleChange = (formData, hasChanged) => this.setState({hasChanged})
    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const mapStateToProps = (state, ownProps) => ({
    teams: state.teams,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchTeam(ownProps.match.params.team)),
    update: (team) => dispatch(updateTeam(ownProps.match.params.team, team)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamEditContainer)
