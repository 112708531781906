import BuildIcon from '@material-ui/icons/Build'
import Fab from '@material-ui/core/Fab'
import PinDropIcon from '@material-ui/icons/PinDrop'
import PropTypes from 'prop-types'
import React from 'react'
import UpdateIcon from '@material-ui/icons/Update'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Link } from 'react-router-dom'

import ActionsMenu from '../ActionsMenu'
import RowCanvas from './RowCanvas'
import Stage from '../../../containers/Map/StageContainer'
import { COLOR_BLUE } from '../../../constants/colors'
import { LAYOUT_NODE_SPACING_X, LAYOUT_NODE_WIDTH } from '../../../constants/layout'

const Layout = ({
    defaultInstanceId, layout, onResetFocalInstance, showBuildTrigger, uri, ...rest
}) => (
    <div style={styles.container}>
        <div style={styles.content}>
            <div style={styles.guide} />
            <Stage
                RowComponent={RowCanvas}
                layout={layout}
                {...rest} />
            <Fab color="primary" disabled={layout.focalInstanceId === defaultInstanceId} onClick={onResetFocalInstance} style={styles.resetFocalInstanceTrigger}>
                <PinDropIcon />
            </Fab>
            <ActionsMenu
                style={styles.listViewTrigger}
                uri={uri} />
        </div>
    </div>
)

Layout.defaultProps = {
    showBuildTrigger: false,
}

Layout.propTypes = {
    defaultInstanceId: PropTypes.string,
    layout: PropTypes.shape({
        focalInstanceId: PropTypes.string,
    }).isRequired,
    onResetFocalInstance: PropTypes.func.isRequired,
    showBuildTrigger: PropTypes.bool,
    uri: PropTypes.string.isRequired,
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    content: {
        flex: 1,
        position: 'relative',
    },
    guide: {
        backgroundColor: COLOR_BLUE,
        bottom: 0,
        left: `calc(50% - ${(LAYOUT_NODE_WIDTH / 2) + (LAYOUT_NODE_SPACING_X / 2)}px)`,
        position: 'absolute',
        top: 0,
        width: LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X,
    },
    resetFocalInstanceTrigger: {
        bottom: 20,
        left: 20,
        position: 'absolute',
    },
    listViewTrigger: {
        bottom: 20,
        position: 'absolute',
        right: 20,
    },
    buildViewTrigger: {
        bottom: 100,
        position: 'absolute',
        right: 20,
    },
}

export default Layout
