import AlarmOffIcon from '@material-ui/icons/AlarmOff'
import CheckIcon from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import React, { cloneElement, memo } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { COLOR_AMBER, COLOR_BRAND_PRIMARY, COLOR_GREY, COLOR_GREY_DARK, COLOR_RED, COLOR_WHITE } from '../../constants/colors'

const Icon = memo(({className, duration, progress, showValue, size, type}) => {
    const percentage = type === 'duration' ? duration : progress
    const classes = useStyles({percentage, size, type})

    return (
        <div className={`${classes.wrapper} ${className}`}>
            <div className={classes.shapeWrapper}>
                <div className={classes.item}>
                    <div className={classes.line} />
                    <div className={classes.fill} />
                </div>
            </div>
            <div className={classes.background} />
            {percentage >= 100 ? (
                cloneElement(type === 'duration' ? <AlarmOffIcon /> : <CheckIcon />, {className: classes.icon})
            ) : (showValue && (
                <Typography className={classes.value} variant="h4">
                    {Math.round(percentage)}%
                </Typography>
            ))}
        </div>
    )
})

Icon.defaultProps = {
    className: '',
    showValue: true,
    size: 32,
}

Icon.propTypes = {
    className: PropTypes.string,
    duration: PropTypes.number,
    progress: PropTypes.number,
    showValue: PropTypes.bool,
    size: PropTypes.number,
    type: PropTypes.oneOf([
        'duration', 'progress',
    ]),
}

const useStyles = makeStyles((theme) => ({
    wrapper: ({size}) => ({
        flexShrink: 0,
        fontSize: size,
        height: '1em',
        position: 'relative',
        width: '1em',
    }),
    shapeWrapper: {
        backgroundColor: COLOR_GREY,
        borderRadius: '50%',
        height: '1em',
        position: 'absolute',
        width: '1em',
    },
    background: {
        backgroundColor: COLOR_WHITE,
        borderRadius: '50%',
        left: '.08em',
        height: '.84em',
        position: 'absolute',
        top: '.08em',
        width: '.84em',
    },
    value: {
        alignSelf: 'center',
        fontSize: '.35em',
        lineHeight: '2.93em',
        position: 'absolute',
        textAlign: 'center',
        width: '2.857em',
        zIndex: 1,
    },
    item: ({percentage}) => ({
        boxSizing: 'content-box',
        clip: percentage > 50 ? 'none' : 'rect(0em, 1em, 1em, .5em)',
        height: '1em',
        position: 'absolute',
        width: '1em',
    }),
    line: ({percentage, type}) => ({
        borderColor: type === 'duration' ? (percentage < 75 ? COLOR_GREY_DARK : (percentage >= 100 ? COLOR_RED : COLOR_AMBER)) : COLOR_BRAND_PRIMARY,
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: '.08em',
        boxSizing: 'content-box',
        clip: 'rect(0em, 0.5em, 1em, 0em)',
        height: '.84em',
        position: 'absolute',
        transform: `rotate(${percentage >= 100 ? '360' : 360 * (percentage / 100)}deg)`,
        width: '.84em',
    }),
    fill: ({percentage, type}) => percentage > 50 ? ({
        borderColor: type === 'duration' ? (percentage < 75 ? COLOR_GREY_DARK : (percentage >= 100 ? COLOR_RED : COLOR_AMBER)) : COLOR_BRAND_PRIMARY,
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: '.08em',
        boxSizing: 'content-box',
        clip: 'rect(0em, 0.5em, 1em, 0em)',
        height: '.84em',
        position: 'absolute',
        transform: 'rotate(180deg)',
        width: '.84em',
    }) : {},
    icon: ({size, type}) => ({
        color: type === 'duration' ? COLOR_RED : COLOR_BRAND_PRIMARY,
        height: size / 2,
        left: '50%',
        marginLeft: size / -4,
        marginTop: size / -4,
        position: 'absolute',
        top: '50%',
        width: size / 2,
        zIndex: 1,
    }),
}))

export default Icon
