import { combineReducers } from 'redux'

import auth from './authReducer'
import calculations from './calculationsReducer'
import comments from './commentsReducer'
import instances from './instancesReducer'
import labels from './labelsReducer'
import maps from './mapsReducer'
import nodes from './nodesReducer'
import resources from './resourcesReducer'
import sandboxCollections from './sandboxCollectionsReducer'
import stashes from './stashesReducer'
import targets from './targetsReducer'
import teams from './teamsReducer'
import ui from './uiReducer'
import users from './usersReducer'
import { LOGOUT } from '../actions/auth'

const app = combineReducers({
    auth, calculations, comments, instances, labels, maps, nodes, resources, sandboxCollections, stashes, targets, teams, ui, users,
})

const root = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined
    }

    return app(state, action)
}

export default root
