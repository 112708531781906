import axios from 'axios'

export const RECEIVE_SANDBOX_COLLECTION = 'RECEIVE_SANDBOX_COLLECTION'
export const RECEIVE_SANDBOX_COLLECTIONS = 'RECEIVE_SANDBOX_COLLECTIONS'

const receiveSandboxCollections = (sandboxCollections) => ({
    type: RECEIVE_SANDBOX_COLLECTIONS,
    payload: {
        sandboxCollections,
    },
})

export const fetchSandboxCollections = () =>
    (dispatch, getState) =>
        axios.get(`/sandbox-collection`).then(({data}) => {
            dispatch(receiveSandboxCollections(data))

            return data
        })
