import axios from 'axios'

export const RECEIVE_LABEL = 'RECEIVE_LABEL'
export const RECEIVE_LABELS = 'RECEIVE_LABELS'
export const REMOVE_LABEL = 'REMOVE_LABEL'

const receiveLabel = (label) => ({
    type: RECEIVE_LABEL,
    payload: {
        label,
    },
})

const receiveLabels = (labels) => ({
    type: RECEIVE_LABELS,
    payload: {
        labels,
    },
})

const removeLabel = (id) => ({
    type: REMOVE_LABEL,
    payload: {
        id,
    },
})

export const fetchLabel = (mapUri, id) =>
    (dispatch, getState) =>
        axios.get(`/map/${mapUri}/label/${id}`).then(({data}) => {
            dispatch(receiveLabel(data))

            return data
        })

export const fetchLabels = (mapUri) =>
    (dispatch, getState) =>
        axios.get(`/map/${mapUri}/label`).then(({data}) => {
            dispatch(receiveLabels(data))

            return data
        })

export const storeLabel = (mapUri, label) =>
    (dispatch, getState) =>
        axios.post(`/map/${mapUri}/label`, label).then(({data}) => {
            dispatch(receiveLabel(data))

            return data
        })

export const updateLabel = (mapUri, id, label) =>
    (dispatch, getState) =>
        axios.put(`/map/${mapUri}/label/${id}`, label).then(({data}) => {
            dispatch(receiveLabel(data))

            return data
        })

export const deleteLabel = (mapUri, id) =>
    (dispatch, getState) => {
        dispatch(removeLabel(id))

        return axios.delete(`/map/${mapUri}/label/${id}`)
    }

export const labelsForMap = (labels, mapId) => Object.values(labels).filter(({map_id}) => map_id === mapId)
