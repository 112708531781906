import DialogContent from '@material-ui/core/DialogContent'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import React from 'react'

import FormsyText from '../formsy/FormsyText'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'

class CommentEdit extends React.Component {
    render () {
        return (
            <Modal>
                <DialogContent>
                    <Formsy onValidSubmit={this.props.onSubmit} onValid={this.props.onValid} onInvalid={this.props.onInvalid}>
                        <fieldset>
                            <FormsyText
                                defaultValue={this.props.comment}
                                label="Edit your comment&hellip;"
                                name="comment"
                                rows={4}
                                rowsMax={4}
                                style={styles.textInput}
                                autoFocus fullWidth multiline required />
                        </fieldset>
                        <fieldset style={styles.buttonsContainer}>
                            <LoadingButton busy={this.props.isBusy} color="primary" disabled={! this.props.isValid} type="submit" variant="contained" fullWidth>
                                Update
                            </LoadingButton>
                        </fieldset>
                    </Formsy>
                </DialogContent>
            </Modal>
        )
    }
}

CommentEdit.defaultProps = {
    isBusy: false,
    isValid: false,
}

CommentEdit.propTypes = {
    comment: PropTypes.string.isRequired,
    isBusy: PropTypes.bool,
    isValid: PropTypes.bool,
    onInvalid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
}

const styles = {
    buttonsContainer: {
        display: 'flex',
        marginTop: 24,
    },
    textInput: {
        marginBottom: 16,
    },
}

export default CommentEdit
