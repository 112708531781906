const migrateToVersion1 = (state) => typeof state === 'object' && typeof state.auth === 'object' ? ({
    ...state,
    auth: {
        ...state.auth,
        clientUri: convertTeamIdToUri(state.auth.teamId),
        teamUri: convertTeamIdToUri(state.auth.teamId),
    },
    users: Object.keys(state.users).reduce((rest, userId) => ({
        ...rest,
        [userId]: {
            ...state.users[userId],
            teams_index: Object.keys(state.users[userId].teams_index).reduce((rest, teamId) => ({
                ...rest,
                [convertTeamIdToUri(teamId)]: state.users[userId].teams_index[teamId],
            }), {}),
        },
    }), {})
}) : state

const convertTeamIdToUri = (teamId) => teamIdUriMappings[teamId] === undefined ? null : teamIdUriMappings[teamId]

const teamIdUriMappings = {
    1: 'goal-atlas',
    2: 'archive',
    3: 'demo',
    4: 'scribbler',
    5: 'brilliant-noise',
    6: 'icaew',
    7: 'swansea-university',
    8: 'biogen',
    9: 'mkp',
    10: 'great',
    11: 'joachim',
    12: 'bucks',
}

export default migrateToVersion1
