import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'
import Select from '@material-ui/core/Select'
import SettingsIcon from '@material-ui/icons/Settings'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FixedSizeList } from 'react-window'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Container from '../Shared/Container'
import Heading from '../Shared/Heading'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import UserIcon from '../UserIcon'
import { generateMapLinks } from '../../actions/map'

const Maps = ({emptyMessage, isReady, loading, maps, onChangeSearch, onChangeTeam, onDelete, readOnly, teams, teamUri, users}) => {
    const state = useSelector((state) => state)

    const classes = useStyles()
    const theme = useTheme()

    const Row = ({index, style}) => {
        const handleDelete = () => onDelete(maps[index].uri)

        return maps[index] ? (
            <ListItem ContainerComponent="div" ContainerProps={{className: classes.itemContainer, style}} className={classes.item} key={index}>
                <ListItemAvatar>
                    <UserIcon userId={maps[index].created_by} />
                </ListItemAvatar>
                <ListItemText>
                    <Typography noWrap>{maps[index].name}</Typography>
                </ListItemText>
                <ListItemSecondaryAction className={classes.actions}>
                    {generateMapLinks(state, maps[index].uri, 'm').map(({Icon, pathname, state = {}, tooltip}, subIndex) => (
                        <Tooltip key={`ml-${index}-${subIndex}`} title={tooltip}>
                            <IconButton component={Link} to={{pathname, state}}>
                                <Icon />
                            </IconButton>
                        </Tooltip>
                    ))}
                    {Boolean(! maps[index].readOnly) && (
                        <React.Fragment>
                            <Divider className={classes.divider} orientation="vertical" flexItem />
                            <Tooltip title="Configure goal map">
                                <IconButton component={Link} to={`/m/${maps[index].uri}/configure`}>
                                    <SettingsIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clone goal map">
                                <IconButton component={Link} to={`/m/${maps[index].uri}/clone`}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={maps[index].canEdit ? 'Edit goal map name and owner' : 'Edit goal map name'}>
                                <IconButton component={Link} to={`/m/${maps[index].uri}/edit`}>
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={maps[index].canEdit ? 'Delete goal map' : 'Only the goal map creator may perform this action'}>
                                <span> {/* Excess component to prevent warning when tooltip wrapping disabled node */}
                                    <IconButton disabled={! maps[index].canEdit} onClick={handleDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </React.Fragment>
                    )}
                </ListItemSecondaryAction>
            </ListItem>
        ) : (
            <div className={classes.loadingRowWrapper} style={style}>
                <PageLoadingIndicator size={30} />
            </div>
        )
    }

    const rowHeight = useMediaQuery(theme.breakpoints.up('sm')) ? 56 : 104
    const totalRows = loading ? maps.length + 1 : maps.length

    return (
        <Modal>
            <Container width={theme.breakpoints.values.lg} modal>
                <Heading>Goal Maps</Heading>
                {isReady ? (
                    <React.Fragment>
                        <div className={classes.filtersContainer}>
                            {Boolean(teams.length > 1) ? (
                                <FormControl className={classes.teamSelectContainer}>
                                    <InputLabel>Team</InputLabel>
                                    <Select onChange={onChangeTeam} value={teamUri}>
                                        {teams.map(({name, uri}) => (
                                            <MenuItem key={uri} value={uri}>{name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : <div />}
                            <TextField
                                className={classes.searchInput}
                                label="Search"
                                onChange={onChangeSearch} />
                        </div>
                        {maps.length ? (
                            <FixedSizeList height={Math.min(6, totalRows) * rowHeight} itemCount={totalRows} itemSize={rowHeight} width="100%">
                                {Row}
                            </FixedSizeList>
                        ) : (
                            <Typography className={classes.empty}>{emptyMessage}</Typography>
                        )}
                        {! readOnly && (
                            <div className={classes.addButtonWrapper}>
                                <Fab component={Link} color="primary" to="/m/create">
                                    <AddIcon />
                                </Fab>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    filtersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    teamSelectContainer: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(8),
    },
    searchInput: {
        marginBottom: theme.spacing(2),
    },
    addButtonWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        paddingTop: theme.spacing(4),
    },
    itemContainer: {
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '100%',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'minmax(0, 1fr) auto',
        },
    },
    item: {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    actions: {
        display: 'flex',
        height: 48,
        position: 'relative',
        right: 'auto',
        top: 'auto',
        transform: 'none',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(-1),
            marginRight: theme.spacing(-1),
            overflowY: 'auto',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
        },
    },
    divider: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    empty: {
        fontWeight: 300,
        marginTop: theme.spacing(2),
    },
    loadingRowWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
}))

Maps.defaultProps = {
    isReady: true,
    loading: false,
    readOnly: false,
}

Maps.propTypes = {
    emptyMessage: PropTypes.string.isRequired,
    isReady: PropTypes.bool,
    loading: PropTypes.bool,
    maps: PropTypes.arrayOf(PropTypes.shape({
        created_by: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })),
    onChangeSearch: PropTypes.func.isRequired,
    onChangeTeam: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    teams: PropTypes.array.isRequired,
    teamUri: PropTypes.string.isRequired,
    users: PropTypes.object.isRequired,
}

export default Maps
