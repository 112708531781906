import React from 'react'
import { connect } from 'react-redux'

import PrimaryKpi from '../../components/Map/PrimaryKpi'
import { calculationsForNode, fetchCalculationsForMap, sumCalculation } from '../../actions/calculation'
import { mapFromUri } from '../../actions/map'
import { nodesForMap } from '../../actions/node'

class PrimaryKpiContainer extends React.Component {
    state = {
        isReady: false,
        view: 'summary',
    }

    componentDidMount () {
        this._fetch()
    }

    render () {
        const node = this.props.nodes[nodeId(this.props)]
        const childNodes = node ? Object.values(this.props.nodes).filter(({parents}) => parents.includes(node.id)).sort((a, b) => a.order - b.order).map((item) => ({
            ...item,
            value: value(item.id, this.props),
        })) : []

        const { label, prefix, suffix } = config(this.props)

        return (
            <PrimaryKpi
                canCalculate={Boolean(calculation(nodeId(this.props), this.props))}
                childNodes={childNodes}
                label={label}
                isReady={this.state.isReady}
                mapUri={this.props.mapUri}
                node={node}
                onSetView={this._handleSetView}
                prefix={prefix}
                suffix={suffix}
                value={value(nodeId(this.props), this.props)}
                view={this.state.view} />
        )
    }

    _fetch = () => {
        this.props.fetchCalculations(this.props.mapUri).then(() => this.setState({isReady: true}))
    }

    _handleSetView = (event) => this.setState({view: event.currentTarget.value})
}

const calculation = (nodeId, {calculations}) => calculationsForNode(calculations, nodeId)[0]
const config = ({maps, mapUri}) => mapFromUri(maps, mapUri).config.modules.primary_kpi ? (mapFromUri(maps, mapUri).config.modules.primary_kpi.options || {}) : {}
const format = (value, props) => value.toFixed(config(props).format === 'integer' ? 0 : 2)
const nodeId = ({maps, mapUri}) => config({maps, mapUri}).nodeId || mapFromUri(maps, mapUri).default_node_id
const value = (nodeId, props) => format(parseFloat(calculation(nodeId, props) ? sumCalculation(calculation(nodeId, props).values, props.calculations) : 0), props)

const mapStateToProps = (state, ownProps) => ({
    calculations: state.calculations,
    maps: state.maps,
    nodes: state.nodes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchCalculations: (uri) => dispatch(fetchCalculationsForMap(uri)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryKpiContainer)
