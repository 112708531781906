import React from 'react'
import { compareDesc, parseISO } from 'date-fns'
import { connect } from 'react-redux'

import Maps from '../../components/Map/Maps'
import { deleteMap, fetchMaps } from '../../actions/map'
import { prompt } from '../../actions/ui'
import { fetchUsersForClient } from '../../actions/user'

class MapsContainer extends React.Component {
    state = {
        hasFetched: false,
        filter: '',
        teamUri: defaultTeamUri(this.props.teams),
    }

    componentDidMount () {
        this.props.fetch().then(() => {
            this.setState({hasFetched: true})

            this.props.fetchUsers(this.props.auth.clientUri)
        })
    }

    componentDidUpdate () {
        if (this.state.teamUri === '' && Object.keys(this.props.teams).length) {
            this.setState({
                teamUri: defaultTeamUri(this.props.teams)
            })
        }
    }

    render () {
        return (
            <Maps
                emptyMessage={emptyMessage(this.state.filter)}
                isReady={Boolean((this.state.hasFetched || Object.keys(this.props.maps).length) && this.state.teamUri)}
                loading={! this.state.hasFetched}
                maps={enrichMaps(this.props.maps, this.props, this.state)}
                onChangeSearch={this._handleChangeSearch}
                onChangeTeam={this._handleChangeTeam}
                onDelete={this._handleDelete}
                readOnly={readOnlyMap(this.state.teamUri, this.props)}
                teams={orderedTeams(this.props.teams)}
                teamUri={this.state.teamUri}
                users={this.props.users} />
        )
    }

    _handleChangeTeam = (event) => this.setState({
        teamUri: event.target.value,
    })

    _handleChangeSearch = (event) => this.setState({
        filter: event.target.value,
    })

    _handleDelete = (uri) => this.props.delete(uri)
}

const defaultTeamUri = (teams) => {
    const currentTeams = orderedTeams(teams)

    return currentTeams.length ? currentTeams[0].uri : ''
}

const enrichMaps = (maps, props, {filter, teamUri}) => {
    const re = new RegExp(filter, 'i')

    return Object.values(maps).filter(({name, team_uri}) => team_uri === teamUri && (! filter.length || name.match(re))).map((item) => ({
        ...item,
        canEdit: canEditMap(item.created_by, props),
        readOnly: readOnlyMap(item.team_uri, props),
    })).sort(({created_at: aCreatedAt, last_viewed: aLastViewed}, {created_at: bCreatedAt, last_viewed: bLastViewed}) => compareDesc(parseISO(aLastViewed || aCreatedAt), parseISO(bLastViewed || bCreatedAt)))
}

const canEditMap = (userId, {auth}) => userId === auth.userId
const emptyMessage = (filter) => filter.length ? 'No goals maps match your search query.' : 'This team doesn’t have any goal maps yet.'
const orderedTeams = (teams) => Object.values(teams).sort(({name: aName}, {name: bName}) => aName.localeCompare(bName))
const readOnlyMap = (teamUri, {auth, users}) => teamUri ? users[auth.userId].teams_index[teamUri].includes('READ_ONLY') : false

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    maps: state.maps,
    teams: state.teams,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    delete: (uri) => dispatch(deleteMap(uri)),
    fetch: () => dispatch(fetchMaps()),
    fetchUsers: (uri) => dispatch(fetchUsersForClient(uri)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MapsContainer)
