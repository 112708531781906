import localForage from 'localforage'
import thunk from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import { createMigrate, persistReducer, persistStore } from 'redux-persist'

import rootReducer from './reducers'

const migrations = {
    1: require('./migrations/migrateToVersion1').default,
}

const rootConfig = {
    key: 'root',
    migrate: createMigrate(migrations, {debug: true}),
    storage: localForage,
    throttle: 50,
    version: 1,
}

const logger = store => next => action => {
    console.groupCollapsed(action.type)
    console.info('Dispatching...', action)
    console.trace()
    console.groupEnd(action.type)

    return next(action)
}

const middleware = [thunk]
if (process.env.NODE_ENV === 'dev') {
    middleware.push(logger)
}

export const store = createStore(persistReducer(rootConfig, rootReducer), {}, compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
))

export const persistor = persistStore(store)
