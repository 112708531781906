import React from 'react'
import { connect } from 'react-redux'

import Create from '../../components/Calculation/Create'
import { storeCalculation } from '../../actions/calculation'

class CreateContainer extends React.Component {
    state = {
        busy: false,
    }

    render () {
        return (
            <Create
                busy={this.state.busy}
                match={this.props.match}
                onEscape={this._handleClose}
                onSubmit={this._handleSubmit} />
        )
    }

    _handleClose = () => this.props.history.goBack()

    _handleSubmit = (values) => {
        this.setState({busy: true})

        this.props.store({values}).then(() => this._handleClose())
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    store: (values) => dispatch(storeCalculation(ownProps.match.params.node, values)),
})

export default connect(null, mapDispatchToProps)(CreateContainer)
