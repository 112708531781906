import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import ConfigureGoalTransformation from '../../components/Map/ConfigureGoalTransformation'
import { configureMapModule, mapFromUri } from '../../actions/map'
import { labelsForMap } from '../../actions/label'
import { teamUsers } from '../../actions/team'

class ConfigureGoalTransformationContainer extends React.Component {
    state = {
        isBusy: false,
        selected: config(this.props),
    }

    render () {
        return (
            <ConfigureGoalTransformation
                isBusy={this.state.isBusy}
                labels={labels(this.props)}
                onSubmit={this._handleSubmit}
                onChange={this._handleChange}
                scopes={scopes}
                selected={this.state.selected}
                users={teamUsers(this.props.users, mapFromUri(this.props.maps, this.props.match.params.map).team_uri)} />
        )
    }

    _handleChange = (event, value) => this.setState({selected: {
        ...this.state.selected,
        [event.target.name]: value,
    }})

    _handleSubmit = (data, resetForm, invalidateForm) => {
        this.setState({isBusy: true})

        this.props.save(data, this.props.useDefault).then(this.props.onClose).catch(() => this.setState({isBusy: false}))
    }
}

const config = ({maps, match, useDefault}) => mapFromUri(maps, match.params.map)[useDefault ? 'default_config' : 'config'].modules.goal_transformation ? (mapFromUri(maps, match.params.map)[useDefault ? 'default_config' : 'config'].modules.goal_transformation.options || {}) : {}
const labels = ({labels, maps, match}) => labelsForMap(labels, mapFromUri(maps, match.params.map).id).sort((a, b) => a.name.localeCompare(b.name))
const scopes = ([
    {id: 'watching', name: 'My watched goals'},
    {id: 'unassigned', name: 'Unassigned goals'},
])

const mapStateToProps = (state, ownProps) => ({
    labels: state.labels,
    maps: state.maps,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    save: (data, isDefault) => dispatch(configureMapModule(ownProps.match.params.map, 'goal_transformation', data, isDefault)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfigureGoalTransformationContainer))
