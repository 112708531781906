import { REHYDRATE } from 'redux-persist'

import { MAP_IS_EDITING, MAP_IS_VIEWING, RECEIVE_MAP, RECEIVE_MAPS, REMOVE_MAP, SET_CORE_NODE_ID, SET_MAP_OFFSET, SET_ROW_CURRENT_INDEX, UPDATE_MAP_DASHBOARD_LAYOUT } from '../actions/map'

const initialState = {
    busy: false,
    config: {
        modules: {},
    },
    core_node_id: null,
    created_at: {},
    creator: null,
    created_by: null,
    dashboard: {},
    default_config: {
        modules: {},
    },
    default_instance_id: null,
    editing: false,
    exists: true,
    id: null,
    offsetY: 0,
    name: '',
    nodes_index: [],
    team_uri: null,
    rows: [],
    uri: null,
}

const mapReducer = (state = initialState, action) => {
    const actions = {
        [MAP_IS_EDITING]: () => ({
            ...state,
            editing: true,
        }),
        [MAP_IS_VIEWING]: () => ({
            ...state,
            editing: false,
        }),
        [RECEIVE_MAP]: () => ({
            ...state,
            ...Object.keys(action.payload.map).filter((item) => ! ['instances', 'nodes'].includes(item)).reduce((carry, item) => ({
                ...carry,
                [item]: action.payload.map[item],
            }), {}),
        }),
        [SET_CORE_NODE_ID]: () => ({
            ...state,
            core_node_id: action.nodeId,
        }),
        [SET_MAP_OFFSET]: () => ({
            ...state,
            offsetY: action.offset,
        }),
        [SET_ROW_CURRENT_INDEX]: () => ({
            ...state,
            rows: [
                ...state.rows.slice(0, action.index),
                {
                    ...state.rows[action.index],
                    currentIndex: action.nodeIndex,
                },
                ...state.rows.slice(action.index + 1),
            ]
        }),
        [UPDATE_MAP_DASHBOARD_LAYOUT]: () => ({
            ...state,
            dashboard: {
                ...state.dashboard,
                ...action.layout,
            }
        })
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const mapsReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [MAP_IS_EDITING]: () => ({
            ...state,
            [action.uri]: mapReducer(state[action.uri], action)
        }),
        [MAP_IS_VIEWING]: () => ({
            ...state,
            [action.uri]: mapReducer(state[action.uri], action)
        }),
        [RECEIVE_MAP]: () => ({
            ...state,
            [action.payload.map.uri]: mapReducer(state[action.payload.map.uri] || undefined, action)
        }),
        [RECEIVE_MAPS]: () => {
            const maps = {}
            action.payload.maps.forEach((map) => maps[map.uri] = mapReducer(state[map.uri], {...action, type: RECEIVE_MAP, payload: { map }}))

            return {
                ...state, ...maps
            }
        },
        [REMOVE_MAP]: () => {
            const nextState = Object.assign({}, state)
            delete nextState[action.payload.uri]

            return nextState
        },
        [SET_CORE_NODE_ID]: () => ({
            ...state,
            [action.uri]: mapReducer(state[action.uri], action),
        }),
        [SET_MAP_OFFSET]: () => ({
            ...state,
            [action.uri]: mapReducer(state[action.uri], action)
        }),
        [SET_ROW_CURRENT_INDEX]: () => ({
            ...state,
            [action.uri]: mapReducer(state[action.uri], action)
        }),
        [UPDATE_MAP_DASHBOARD_LAYOUT]: () => ({
            ...state,
            [action.uri]: mapReducer(state[action.uri], action)
        })
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default mapsReducer
