import Button from '@material-ui/core/Button'
import Formsy from 'formsy-react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Container from './Shared/Container'
import FormsyCheckbox from './formsy/FormsyCheckbox'
import FormsyHidden from './formsy/FormsyHidden'
import Heading from './Shared/Heading'
import LoadingButton from './LoadingButton'
import Modal from './Shared/Modal'
import PageLoadingIndicator from './PageLoadingIndicator'
import UserFinder from './UserFinder'

const UserAdd = ({canSubmit, clientUri, ignoredUsers, isBusy, isReady, onChange, onInvalid, onSubmit, onValid, selectedUser, teamUri}) => {
    const classes = useStyles()

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>Add a Team Member</Heading>
                        <Formsy onInvalid={onInvalid} onValid={onValid} onValidSubmit={onSubmit}>
                            <fieldset>
                                <UserFinder
                                    clientUri={clientUri}
                                    ignoredUsers={ignoredUsers}
                                    inputLabel="Select a user&hellip;"
                                    onChange={onChange}
                                    noOptionsText="All users have already been added to this team." />
                                <FormsyHidden
                                    defaultValue={selectedUser}
                                    name="user.id"
                                    required />
                            </fieldset>
                            <List>
                                <ListSubheader>Permissions</ListSubheader>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormsyCheckbox
                                            name="user.permissions[MANAGE_MEMBERS]" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Manage team members"
                                        secondary="Allowed to add and remove members from the team." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormsyCheckbox
                                            name="user.permissions[MANAGE_TARGETS]" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Manage targets"
                                        secondary="Allowed to add, remove and update targets for any goal." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormsyCheckbox
                                            name="user.permissions[READ_ONLY]" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Read only user"
                                        secondary="Not allowed to create or edit goal maps." />
                                </ListItem>
                            </List>
                            <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                Add to Team
                            </LoadingButton>
                            <div className={classes.footerLink}>
                                <Button color="primary" component={Link} to={`/team/${teamUri}/user/create`}>Looking to add a new user?</Button>
                            </div>
                        </Formsy>
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(4),
    },
    footerLink: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

UserAdd.defaultProps = {
    canSubmit: false,
    isBusy: false,
    isReady: true,
}

UserAdd.propTypes = {
    canSubmit: PropTypes.bool,
    clientUri: PropTypes.string,
    ignoredUsers: PropTypes.array.isRequired,
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onInvalid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
    selectedUser: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
    ]).isRequired,
    teamUri: PropTypes.string.isRequired,
}

export default UserAdd
