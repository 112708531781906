import AttachFile from '@material-ui/icons/AttachFile'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Formsy from 'formsy-react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import FormsyFile from '../formsy/FormsyFile'
import FormsyHidden from '../formsy/FormsyHidden'
import FormsyText from '../formsy/FormsyText'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'

class ResourceCreate extends React.Component {
    static propTypes = {
        busy: PropTypes.bool,
        canSubmit: PropTypes.bool,
        existingResourceName: PropTypes.string,
        existingResources: PropTypes.array.isRequired,
        method: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        onInvalid: PropTypes.func.isRequired,
        onSetExistingResource: PropTypes.func.isRequired,
        onToggleExisting: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onValid: PropTypes.func.isRequired,
    }

    static defaultProps = {
        busy: false,
        canSubmit: false,
        existingMenuAnchorEl: null,
        existingResourceId: '',
        existingResourceName: null,
    }

    render () {
        return (
            <Modal>
                <DialogTitle>Create a new resource</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select or upload a document, or link to an external website.</DialogContentText>
                    <Formsy onValid={this.props.onValid} onValidSubmit={this.props.onSubmit} onInvalid={this.props.onInvalid}>
                        <fieldset>
                            <FormsyText
                                label="Label"
                                maxLength={60}
                                name="label"
                                autoFocus fullWidth required />
                        </fieldset>
                        <fieldset>
                            <FormsyText
                                label="Description"
                                maxLength={120}
                                name="description"
                                rows="2"
                                fullWidth multiline />
                        </fieldset>
                        <fieldset>
                            <RadioGroup onChange={this.props.onMethodChange} value={this.props.method} style={styles.radioGroup}>
                                <FormControlLabel control={<Radio />} label="Attach new" value="attach" />
                                <FormControlLabel control={<Radio />} label="External link" value="link" />
                                <FormControlLabel control={<Radio />} disabled={! this.props.existingResources.length} label="Existing resource" value="existing" />
                            </RadioGroup>
                        </fieldset>
                        <fieldset>
                            {this.props.method === 'attach' ? (
                                <div style={styles.attachInput}>
                                    <FormsyFile label="Attach" name="file" required />
                                </div>
                            ) : null}
                            {this.props.method === 'link' ? (
                                <FormsyText label="URL" name="uri" fullWidth required />
                            ) : null}
                            {this.props.method === 'existing' && (
                                <React.Fragment>
                                    <div style={styles.existingInput}>
                                        <Button onClick={this.props.onToggleExisting} variant="contained">Select resource</Button>
                                        {this.props.existingResourceId ? (
                                            <div style={styles.existingContainer}>
                                                <AttachFile />
                                                <Typography style={styles.existingName}>{this.props.existingResourceName}</Typography>
                                            </div>
                                        ) : null}
                                        <FormsyHidden
                                            name="attachment_id"
                                            value={this.props.existingResourceId} 
                                            required />
                                    </div>
                                    <Menu anchorEl={this.props.existingMenuAnchorEl} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} getContentAnchorEl={null} onClose={this.props.onToggleExisting} open={Boolean(this.props.existingMenuAnchorEl)} PaperProps={{style: {maxHeight: 450}}}>
                                        {this.props.existingResources.map(({id, resourceable}) => (
                                            <MenuItem key={`resource-${id}`} onClick={() => this.props.onSetExistingResource(resourceable.id)}>{resourceable.name}</MenuItem>
                                        ))}
                                    </Menu>
                                </React.Fragment>
                            )}
                        </fieldset>
                        <div style={styles.buttonContainer}>
                            <LoadingButton busy={this.props.busy} color="primary" disabled={! this.props.canSubmit} type="submit" variant="contained" fullWidth>
                                Add
                            </LoadingButton>
                        </div>
                    </Formsy>
                </DialogContent>
            </Modal>
        )
    }
}

const styles = {
    buttonContainer: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    existingContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        marginLeft: 24,
    },
    radioGroup: {
        flexDirection: 'row',
    },
    attachInput: {
        marginTop: 36,
    },
    existingInput: {
        alignItems: 'center',
        display: 'flex',
        marginTop: 36,
        paddingRight: 24,
        width: 'calc(100% - 24px)',
    },
    existingName: {
        flex: 1,
        marginLeft: 4,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
}

export default ResourceCreate
