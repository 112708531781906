import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { COLOR_AMBER, COLOR_BLACK, COLOR_BLUE_DARK, COLOR_BRAND_PRIMARY, COLOR_GREY_DARK, COLOR_GREY_DARKER, COLOR_GREY, COLOR_GREY_LIGHT, COLOR_RED, COLOR_WHITE } from '../../../constants/colors'
import { LAYOUT_NODE_HEIGHT, LAYOUT_NODE_PADDING, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH, LAYOUT_SHADOW_SPACING } from '../../../constants/layout'
import { SVG_ALARM_OFF, SVG_CALCULATOR_ICON, SVG_CHECK_ICON, SVG_COMMENTS_ICON, SVG_DEFAULT_NODE_ICON, SVG_FILES_ICON, SVG_LOCATION, SVG_NUMBER_ICON, SVG_PERSON, SVG_SCHOOL_ICON, SVG_STAR_BORDER_ICON, SVG_STAR_HALF_ICON, SVG_STAR_ICON, SVG_WATCH_ICON } from '../../../constants/svgs'
import { itemsSignature } from '../../../actions/build'

class RowCanvas extends React.Component {
    static propTypes = {
        goals: PropTypes.object.isRequired,
        items: PropTypes.array.isRequired,
        layout: PropTypes.shape({
            defaultInstanceId: PropTypes.string,
            focalInstanceId: PropTypes.string,
            instances: PropTypes.object.isRequired,
        }).isRequired,
        offset: PropTypes.number.isRequired,
        onGoalDoubleClick: PropTypes.func,
        onGoalClick: PropTypes.func,
    }

    _canvasRef = React.createRef()
    _ratio = getRatio()

    componentDidMount () {
        this._handleDraw()
    }

    componentDidUpdate (prevProps) {
        if (shouldRedraw(this.props, prevProps)) {
            this._handleDraw()
        }
    }

    render () {
        return (
            <div style={styles.container}>
                <canvas
                    height={(LAYOUT_NODE_HEIGHT + (LAYOUT_SHADOW_SPACING * 2)) * this._ratio}
                    onClick={this._handleClick}
                    ref={this._canvasRef}
                    style={styles.canvas(this.props.items.length, this.props.offset)}
                    width={(((LAYOUT_NODE_WIDTH) * this.props.items.length) + (LAYOUT_NODE_SPACING_X * (this.props.items.length - 1)) + (LAYOUT_SHADOW_SPACING * 2)) * this._ratio} />
            </div>
        )
    }

    _handleClick = (event) => {
        const index = Math.floor(event.nativeEvent.offsetX / (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X))

        if (event.nativeEvent.offsetX > ((LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X) * (index + 1)) - LAYOUT_NODE_SPACING_X) {
            return
        }

        if (this.props.items.length <= index) {
            return
        }

        if (typeof this._clickTimeout !== undefined) {
            clearTimeout(this._clickTimeout)
        }

        if (this._pendingDoubleClick) {
            this._pendingDoubleClick = false

            if (typeof this.props.onGoalDoubleClick === 'function') {
                this.props.onGoalDoubleClick(this.props.layout.instances[this.props.items[index]], this.props.items[index])
            }
        } else {
            this._pendingDoubleClick = true
            this._clickTimeout = setTimeout(() => {
                this._pendingDoubleClick = false

                if (typeof this.props.onGoalClick === 'function') {
                    this.props.onGoalClick(this.props.goals[this.props.layout.instances[this.props.items[index]]].uri, this.props.items[index])
                }
            }, 400)
        }
    }

    _handleDraw = () => {
        if (this._canvasRef.current === null) {
            return
        }

        const ctx = this._canvasRef.current.getContext('2d')
        resetCanvas(ctx, this._canvasRef.current.width, this._canvasRef.current.height, this._ratio)

        this.props.items.map((instanceId) => goal(instanceId, this.props.goals, this.props.layout)).forEach((item, itemIndex) => {
            ctx.globalAlpha = item.active ? 1 : .7

            drawRect(this._canvasRef.current.getContext('2d'), itemIndex, item)
            drawText(this._canvasRef.current.getContext('2d'), itemIndex, item)
            drawRelations(this._canvasRef.current.getContext('2d'), itemIndex, item)
            drawLabels(ctx, itemIndex, item, this.props.labels)

            makeIcons(freshSource(item.defaultInstance ? 140 : 110, this._ratio, item.active), item).then((source) =>{
                ctx.drawImage(
                    source,
                    (itemIndex * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + ((LAYOUT_NODE_WIDTH / 2) - (source.width / this._ratio / 2)),
                    LAYOUT_NODE_HEIGHT - (LAYOUT_NODE_PADDING * 2) - LAYOUT_SHADOW_SPACING - 20
                )
            })

            makeOwner(freshSource(36, this._ratio, item.active), item).then((source) =>
                ctx.drawImage(
                    source,
                    (itemIndex * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + LAYOUT_NODE_PADDING,
                    LAYOUT_NODE_PADDING
                )
            )

            makeTargetIfExists(freshSource(34, this._ratio, item.active), item).then((source) =>
                ctx.drawImage(
                    source,
                    (itemIndex * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + LAYOUT_NODE_WIDTH - LAYOUT_SHADOW_SPACING - (source.width / this._ratio),
                    LAYOUT_NODE_PADDING
                )
            )
        })
    }
}

const drawLabels = (ctx, itemIndex, {active, labels_index}, rawLabels) => {
    const labels = labels_index.filter((id) => rawLabels[id]).map((id) => rawLabels[id])
    if (! labels.length) {
        return
    }

    labels.slice(0, 4).forEach(({hex}, index) => {
        ctx.fillStyle = `#${hex}`
        ctx.fillRect(
            (index * 35) + 2.5 + ((itemIndex * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + (LAYOUT_NODE_WIDTH / 2) - (35 * (labels.length > 4 ? 2 : (labels.length / 2)))) + LAYOUT_SHADOW_SPACING,
            (LAYOUT_NODE_PADDING * 3) - LAYOUT_SHADOW_SPACING,
            30,
            10
        )
    })
}

const drawRect = (ctx, index, {focalInstance}) => {
    ctx.fillStyle = focalInstance ? COLOR_BLUE_DARK : COLOR_WHITE

    ctx.shadowColor = 'rgba(0, 0, 0, .156863)'
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 3
    ctx.shadowBlur = 10

    ctx.fillRect(
        (index * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + LAYOUT_SHADOW_SPACING,
        LAYOUT_SHADOW_SPACING,
        LAYOUT_NODE_WIDTH,
        LAYOUT_NODE_HEIGHT
    )
}

const drawRelations = (ctx, index, {focalInstance, instanceId, meta}) => {
    ctx.fillStyle = focalInstance ? COLOR_WHITE : COLOR_GREY_DARKER

    const startX = (index * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + (LAYOUT_NODE_WIDTH / 2) + LAYOUT_SHADOW_SPACING
    for (let i = 0; i < meta[instanceId].relations.parents; i++) {
        drawRelation(
            ctx,
            startX - (4 * (meta[instanceId].relations.parents - 1)),
            LAYOUT_NODE_PADDING + LAYOUT_SHADOW_SPACING,
            i
        )
    }

    for (let i = 0; i < meta[instanceId].relations.children; i++) {
        drawRelation(
            ctx,
            startX - (4 * (meta[instanceId].relations.children - 1)),
            LAYOUT_NODE_HEIGHT - LAYOUT_NODE_PADDING + LAYOUT_SHADOW_SPACING,
            i
        )
    }
}

const drawRelation = (ctx, x, y, index) => {
    ctx.beginPath()
    ctx.arc(
        x + (index * 8),
        y,
        3, 0, 2 * Math.PI, false
    )
    ctx.fill()
}

const drawStepValue = (ctx, index, value) => {
    if (value === undefined) {
        return
    }

    ctx.fillStyle = COLOR_BRAND_PRIMARY
    ctx.font = `14px "Roboto"`
    ctx.textAlign = 'center'

    ctx.fillText(value, (LAYOUT_NODE_WIDTH / 2) + (index * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + LAYOUT_SHADOW_SPACING, LAYOUT_NODE_HEIGHT - (LAYOUT_NODE_PADDING * 2) - LAYOUT_SHADOW_SPACING, LAYOUT_NODE_WIDTH - LAYOUT_NODE_SPACING_X)
}

const drawText = (ctx, index, {focalInstance, name}) => {
    ctx.fillStyle = focalInstance ? COLOR_WHITE : COLOR_BLACK
    ctx.font = `14px "Roboto"`
    ctx.textAlign = 'center'

    wrapText(ctx, name, (LAYOUT_NODE_WIDTH / 2) + (index * (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) + LAYOUT_SHADOW_SPACING)
}

const freshSource = (width, ratio, active) => {
    const source = document.createElement('canvas')

    const ctx = source.getContext('2d')
    ctx.globalAlpha = active ? 1 : .7

    ctx.scale(ratio, ratio)
    source.width = width * ratio

    return source
}

const makeTargetIfExists = (source, item) => {
    if (item.target_duration !== null && item.target_progress !== null) {
        return makeTarget(source, item)
    }

    return new Promise((resolve, reject) => {})
}

const makeIcons = (source, {active, comment_count, defaultInstance, priority, resources_count, unread_comments, unread_resources, watching}) => new Promise((resolve, reject) => {
    const ctx = source.getContext('2d')

    const total = defaultInstance ? 5 : 4
    let loaded = 0

    if (defaultInstance) {
        makeIcon(
            ctx,
            0,
            SVG_LOCATION.replace(/%23fff/, COLOR_BRAND_PRIMARY.replace('#', '%23'))
        ).then(() => Boolean(++loaded >= total) && resolve(source))
    }

    makeIcon(
        ctx,
        defaultInstance ? 30 : 0,
        (priority === 0 ? SVG_STAR_BORDER_ICON : (priority === 1 ? SVG_STAR_HALF_ICON : SVG_STAR_ICON)).replace(/%23fff/, priority === null ? COLOR_GREY_LIGHT.replace('#', '%23') : COLOR_AMBER.replace('#', '%23'))
    ).then(() => Boolean(++loaded >= total) && resolve(source))

    makeIcon(
        ctx,
        defaultInstance ? 60 : 30,
        SVG_WATCH_ICON.replace(/%23fff/, watching ? COLOR_BRAND_PRIMARY.replace('#', '%23') : COLOR_GREY_LIGHT.replace('#', '%23'))
    ).then(() => Boolean(++loaded >= total) && resolve(source))

    makeIcon(
        ctx,
        defaultInstance ? 90 : 60,
        SVG_COMMENTS_ICON.replace(/%23fff/, comment_count < 1 ? COLOR_GREY_LIGHT.replace('#', '%23') : COLOR_BRAND_PRIMARY.replace('#', '%23')),
        unread_comments
    ).then(() => Boolean(++loaded >= total) && resolve(source))

    makeIcon(
        ctx,
        defaultInstance ? 120 : 90,
        SVG_FILES_ICON.replace(/%23fff/, resources_count < 1 ? COLOR_GREY_LIGHT.replace('#', '%23') : COLOR_BRAND_PRIMARY.replace('#', '%23')),
        unread_resources
    ).then(() => Boolean(++loaded >= total) && resolve(source))
})

const makeIcon = (ctx, x, source, notify = false) => new Promise((resolve, reject) => {
    const image = new Image

    image.onload = () => {
        ctx.drawImage(image, x, 0, 20, 20)

        if (notify) {
            ctx.fillStyle = COLOR_RED
            ctx.beginPath()
            ctx.arc(x + 16, 4, 4, 0, 2*Math.PI)
            ctx.fill()
        }

        resolve()
    }

    image.src = `data:image/svg+xml;charset=utf-8,${source}`
})

const makeOwner = (source, {active, assigned_user, focalInstance}) => new Promise((resolve, reject) => {
    const ctx = source.getContext('2d')

    ctx.font = `13px "Roboto"`
    ctx.textAlign = 'center'

    ctx.fillStyle = assigned_user ? assigned_user.hex : COLOR_WHITE
    ctx.arc(17, 17, 17, 0, 2*Math.PI)
    ctx.fill()

    if (assigned_user) {
        if (assigned_user.profile_picture) {
            const image = new Image

            image.onload = () => {
                ctx.beginPath()
                ctx.arc(17, 17, 17, 0, 2*Math.PI)
                ctx.clip()

                ctx.drawImage(image, 0, 0, 36, 36)

                resolve(source)
            }

            return image.src = assigned_user.profile_picture
        } else {
            ctx.fillStyle = COLOR_WHITE
            ctx.fillText(assigned_user.initials, 17, 21.5)
        }

        resolve(source)
    } else {
        const image = new Image

        image.onload = () => {
            ctx.drawImage(image, 5.5, 6)
            resolve(source)
        }

        image.src = `data:image/svg+xml;charset=utf-8,${SVG_PERSON.replace(/%23fff/, COLOR_GREY.replace('#', '%23'))}`
    }
})

const makeTarget = (source, {active, target_duration, target_progress}) => new Promise((resolve, reject) => {
    const ctx = source.getContext('2d')

    const duration = target_duration > 100 ? 1 : Math.round(target_duration) / 100
    const progress = target_progress > 100 ? 1 : Math.round(target_progress) / 100

    ctx.lineWidth = 3
    ctx.strokeStyle = COLOR_GREY

    ctx.beginPath()
    ctx.arc(17, 17, 15, 0, 2*Math.PI, true)
    ctx.stroke()

    ctx.strokeStyle = duration < .75 ? COLOR_GREY_DARK : (duration >= 1 ? COLOR_RED : COLOR_AMBER)

    ctx.beginPath()
    ctx.arc(17, 17, 15, Math.PI * 1.5, Math.PI * 1.5 + ((duration * 360) * (Math.PI / 180)))
    ctx.stroke()

    ctx.lineWidth = 2.5
    ctx.strokeStyle = COLOR_BRAND_PRIMARY

    ctx.beginPath()
    ctx.arc(17, 17, 10.5, Math.PI * 1.5, Math.PI * 1.5 + ((progress * 360) * (Math.PI / 180)))
    ctx.stroke()

    const image = new Image

    if (progress >= 1) {
        image.onload = () => {
            ctx.drawImage(image, 10, 10, 14, 14)
            resolve(source)
        }

        return image.src = `data:image/svg+xml;charset=utf-8,${SVG_CHECK_ICON.replace(/%23fff/, COLOR_BRAND_PRIMARY.replace('#', '%23'))}`
    }

    if (duration >= 1) {
        image.onload = () => {
            ctx.drawImage(image, 9, 9, 16, 16)
            resolve(source)
        }

        return image.src = `data:image/svg+xml;charset=utf-8,${SVG_ALARM_OFF.replace(/%23fff/, COLOR_RED.replace('#', '%23'))}`
    }

    resolve(source)
})

const resetCanvas = (ctx, width, height, ratio) => {
    ctx.restore()
    ctx.save()

    ctx.clearRect(0, 0, width, height)
    ctx.scale(ratio, ratio)
}

const wrapText = (ctx, text, x) => {
    const words = text.split(' ')
    const width = LAYOUT_NODE_WIDTH - LAYOUT_NODE_SPACING_X
    const lines = []

    let line = {y: 0, words: []}
    for (let i = 0; i < words.length; i++) {
        line.words.push(words[i])

        if (ctx.measureText(line.words.join(' ')).width > width) {
            if (line.words.length > 0) {
                line.words.pop()
            }

            lines.push(line)

            line = {y: line.y + 20, words: [words[i]]}
        }
    }

    if (line.words.length) {
        lines.push(line)
    }

    const baseY = (LAYOUT_NODE_HEIGHT / 2) - LAYOUT_SHADOW_SPACING - ((lines.length - 1) * 10) + 10

    lines.forEach((line) => ctx.fillText(
        line.words.join(' '),
        x,
        baseY + line.y,
        width
    ))
}

const goal = (instanceId, goals, {defaultInstanceId, focalInstanceId, instances}) => ({
    ...goals[instances[instanceId]],
    defaultInstance: instanceId === defaultInstanceId,
    focalInstance: instanceId === focalInstanceId,
    instanceId,
})

const getRatio = () => {
    const ctx = document.createElement('canvas').getContext('2d')

    return (window.devicePixelRatio || 1) / (ctx.backingStorePixelRatio || ctx.webkitBackingStorePixelRatio || ctx.mozBackingStorePixelRatio || ctx.msBackingStorePixelRatio || ctx.oBackingStorePixelRatio || 1)
}

const shouldRedraw = (props, prevProps) => {
    if (prevProps.layout.defaultInstanceId !== props.layout.defaultInstanceId) {
        return true
    }

    if (prevProps.layout.focalInstanceId !== props.layout.focalInstanceId) {
        return true
    }

    if (prevProps.items.length !== props.items.length || props.items.filter((id) => ! prevProps.items.includes(id)).length || prevProps.items.filter((id) => ! props.items.includes(id)).length) {
        return true
    }

    if (Object.keys(prevProps.labels).length !== Object.keys(props.labels).length || Object.keys(props.labels).filter((id) => ! Object.keys(prevProps.labels).includes(id)).length) {
        return true
    }

    return itemsSignature(props.items.map((id) => props.goals[props.layout.instances[id]])) !== itemsSignature(props.items.map((id) => prevProps.goals[props.layout.instances[id]]))
}

const styles = {
    container: {
        position: 'relative',
    },
    canvas: (itemCount, offset) => ({
        height: LAYOUT_NODE_HEIGHT + (LAYOUT_SHADOW_SPACING * 2),
        left: -3,
        position: 'absolute',
        top: -3,
        transform: `translateX(${offset}px)`,
        width: (LAYOUT_NODE_WIDTH * itemCount) + (LAYOUT_NODE_SPACING_X * (itemCount - 1)) + (LAYOUT_SHADOW_SPACING * 2),
    }),
}

const mapStateToProps = (state, ownProps) => ({
    labels: state.labels,
})

export default connect(mapStateToProps)(RowCanvas)
