import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import ConfigurePrimaryKpi from '../../components/Map/ConfigurePrimaryKpi'
import { configureMapModule, mapFromUri } from '../../actions/map'

class ConfigurePrimaryKpiContainer extends React.Component {
    state = {
        isBusy: false,
        isReady: false,
        nodeId: null,
    }

    componentDidMount () {
        this.setState({
            nodeId: config(this.props).nodeId || mapFromUri(this.props.maps, this.props.match.params.map).default_node_id,
            isReady: true,
        })
    }

    render () {
        const { format, label, prefix, suffix } = config(this.props)

        return (
            <ConfigurePrimaryKpi
                format={format}
                initialValue={this.props.nodes[this.state.nodeId] ? this.props.nodes[this.state.nodeId].name : ''}
                isBusy={this.state.isBusy}
                isReady={this.state.isReady}
                label={label}
                mapId={mapFromUri(this.props.maps, this.props.match.params.map).id}
                onSelected={this._handleSelected}
                onSubmit={this._handleSubmit}
                prefix={prefix}
                suffix={suffix} />
        )
    }

    _handleSubmit = (data, resetForm, invalidateForm) => {
        if (! this.state.nodeId) {
            return
        }

        this.setState({isBusy: true})

        this.props.save({
            ...data,
            nodeId: this.state.nodeId,
        }, this.props.useDefault).then(this.props.onClose).catch(() => this.setState({isBusy: true}))
    }

    _handleSelected = (nodeId) => this.setState({nodeId})
}

const config = ({maps, match, useDefault}) => mapFromUri(maps, match.params.map)[useDefault ? 'default_config' : 'config'].modules.primary_kpi ? (mapFromUri(maps, match.params.map)[useDefault ? 'default_config' : 'config'].modules.primary_kpi.options || {}) : {}

const mapStateToProps = (state, ownProps) => ({
    nodes: state.nodes,
    maps: state.maps,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    save: (data, isDefault) => dispatch(configureMapModule(ownProps.match.params.map, 'primary_kpi', data, isDefault)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfigurePrimaryKpiContainer))
