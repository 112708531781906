import React from 'react'

import Export from '../../components/Map/Export'
import { fetchMapAsXls } from '../../actions/map'
import { fetchTargetsForMapAsIcs } from '../../actions/target'

class ExportContainer extends React.Component {
    state = {
        isMapBusy: false,
        isTargetsBusy: false,
    }

    render () {
        return (
            <Export
                isMapBusy={this.state.isMapBusy}
                isTargetsBusy={this.state.isTargetsBusy}
                onMapExport={this._handleMapExport}
                onTargetsExport={this._handleTargetsExport} />
        )
    }

    _handleMapExport = () => {
        this.setState({
            isMapBusy: true,
        })

        fetchMapAsXls(this.props.match.params.map).then((output) => {
            const elem = window.document.createElement('a')
            elem.href = window.URL.createObjectURL(new Blob([output], {type: 'application/vnd.ms-excel'}))
            elem.download = `${this.props.match.params.map}.xlsx`
            document.body.appendChild(elem)
            elem.click()

            window.URL.revokeObjectURL(elem.href)
            document.body.removeChild(elem)
        }).finally(() => this.setState({
            isMapBusy: false,
        }))
    }

    _handleTargetsExport = () => {
        this.setState({
            isTargetsBusy: true,
        })

        fetchTargetsForMapAsIcs(this.props.match.params.map).then((output) => {
            const elem = window.document.createElement('a')
            elem.href = window.URL.createObjectURL(new Blob([output], {type: 'text/calendar'}))
            elem.download = `${this.props.match.params.map}-targets.ics`
            document.body.appendChild(elem)
            elem.click()

            window.URL.revokeObjectURL(elem.href)
            document.body.removeChild(elem)
        }).finally(() => this.setState({
            isTargetsBusy: false,
        }))
    }
}

export default ExportContainer
