import Autocomplete from './Shared/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'

const NodeFinder = React.forwardRef(({busy, fullWidth, initialValue, items, label, onChange, onSelect, placeholder, style}, ref) => (
    <div style={{...styles.container, ...style}}>
        <Autocomplete
            containerStyle={styles.input}
            inputProps={{
                fullWidth, label, placeholder,
            }}
            initialValue={initialValue}
            items={items}
            onChange={onChange}
            onSelect={onSelect}
            ref={ref} />
        {busy ? <CircularProgress size={20} style={styles.progress} /> : null}
    </div>
))

NodeFinder.defaultProps = {
    busy: false,
    fullWidth: false,
    style: {},
}

NodeFinder.propTypes = {
    busy: PropTypes.bool,
    fullWidth: PropTypes.bool,
    initialValue: PropTypes.string,
    items: PropTypes.array.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.object,
}

const styles = {
    container: {
        display: 'flex',
    },
    input: {
        flex: 1,
    },
    progress: {
        marginLeft: 6,
        marginTop: 10,
    },
}

export default NodeFinder
