import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'

import { fetchUsersForClient, fetchUsersForTeam } from '../actions/user'

const UserFinder = ({clientUri, fetchForClient, fetchForTeam, ignoredUsers, inputLabel, teamUri, ...rest}) => {
    const [ loading, setLoading ] = React.useState(true)
    const [ open, setOpen ] = React.useState(false)
    const [ options, setOptions ] = React.useState([])

    const fetch = teamUri === undefined ? fetchForClient : fetchForTeam

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    React.useEffect(() => {
        let active = true

        fetch().then((data) => {
            if (! active) {
                return
            }

            setLoading(false)
            setOptions(data.filter(({id}) => ! ignoredUsers.includes(id)))
        })

        return () => {
            active = false
        }
    }, [])

    return (
        <Autocomplete
            {...rest}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={({name}) => name}
            loading={loading}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            options={options}
            renderInput={(props) => (
                <Input
                    {...props}
                    loading={loading}
                    inputLabel={inputLabel} />
            )} />
    )
}

const Input = ({loading, inputLabel, ...rest}) => (
    <TextField
        {...rest}
        InputProps={{
            ...rest.InputProps,
            endAdornment: (
                <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {rest.InputProps.endAdornment}
                </React.Fragment>
            ),
        }}
        label={inputLabel}
        variant="outlined" />
)

UserFinder.defaultProps = {
    ignoredUsers: [],
}

UserFinder.propTypes = {
    clientUri: PropTypes.string,
    ignoredUsers: PropTypes.array,
    inputLabel: PropTypes.string.isRequired,
    teamUri: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => ({
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchForClient: () => dispatch(fetchUsersForClient(ownProps.clientUri)),
    fetchForTeam: () => dispatch(fetchUsersForTeam(ownProps.teamUri)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserFinder)
