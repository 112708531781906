import Fab from '@material-ui/core/Fab'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { v1 as uuid } from 'uuid'

import { LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_Y } from '../../constants/layout'

const Stage = memo(({RowComponent, hideControls, hoveringIndex, layout, onMouseEnter, onMouseLeave, onMouseMove, onNudgeRow, onNudgeStage, onTouchEnd, onTouchMove, onTouchStart, onWheel, ...rest}) => {
    const classes = useStyles({layout})

    const onNudgeStageDown = () => onNudgeStage()
    const onNudgeStageUp = () => onNudgeStage(true)

    return (
        <div className={classes.container} onMouseMove={onMouseMove} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove} onTouchStart={onTouchStart} onWheel={onWheel}>
            <div className={classes.inner}>
                {layout.rows.map(({items, offset}, index) => {
                    const handleMouseEnter = () => onMouseEnter(index)
                    const handleMouseLeave = () => onMouseLeave(index)
                    const handleNudgeRowLeft = () => onNudgeRow(index, true)
                    const handleNudgeRowRight = () => onNudgeRow(index)

                    return (
                        <div className={classes.rowWrapper} key={index} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <RowComponent
                                index={index}
                                items={items}
                                layout={layout}
                                offset={offset}
                                {...rest} />
                            {nudgeTriggers(hoveringIndex, index, items, layout, hideControls) && (
                                <React.Fragment>
                                    {leftNudgeTrigger(index, layout) && (
                                        <Fab className={`${classes.nudgeRowTrigger} ${classes.nudgeRowLeftTrigger}`} color="primary" onClick={handleNudgeRowLeft} size="small">
                                            <KeyboardArrowLeftIcon />
                                        </Fab>
                                    )}
                                    {rightNudgeTrigger(index, layout, hideControls) && (
                                        <Fab className={`${classes.nudgeRowTrigger} ${classes.nudgeRowRightTrigger}`} color="primary" onClick={handleNudgeRowRight} size="small">
                                            <KeyboardArrowRightIcon />
                                        </Fab>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    )
                })}
            </div>
            {Boolean(! hideControls && isScrollDevice) && (
                <React.Fragment>
                    <Fab className={`${classes.nudgeStageTrigger} ${classes.nudgeStageUpTrigger}`} color="primary" onClick={onNudgeStageUp} size="small">
                        <KeyboardArrowUpIcon />
                    </Fab>
                    <Fab className={`${classes.nudgeStageTrigger} ${classes.nudgeStageDownTrigger}`} color="primary" onClick={onNudgeStageDown} size="small">
                        <KeyboardArrowDownIcon />
                    </Fab>
                </React.Fragment>
            )}
        </div>
    )
})

const isScrollDevice = Boolean((! ('ontouchstart' in window)) && (window.DocumentTouch === undefined || ! document instanceof window.DocumentTouch))
const leftNudgeTrigger = (index, layout) => Boolean(isScrollDevice && layout.rows[index].focalInstanceId !== layout.rows[index].items.filter((item) => layout.instances[item] !== undefined)[0])
const nudgeTriggers = (hoveringIndex, index, items, layout, hideControls) => Boolean(! hideControls && hoveringIndex === index && items.filter((item) => layout.instances[item] !== undefined).length > 1)

const rightNudgeTrigger = (index, layout, hideControls) => {
    if (hideControls) {
        return false
    }

    const validItems = layout.rows[index].items.filter((item) => layout.instances[item] !== undefined)

    return Boolean(isScrollDevice && layout.rows[index].focalInstanceId !== validItems[validItems.length - 1])
}

const useStyles = makeStyles((theme) => ({
    container: {
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    inner: ({layout}) => ({
        display: 'grid',
        gridAutoRows: LAYOUT_NODE_HEIGHT,
        gridRowGap: LAYOUT_NODE_SPACING_Y,
        transform: `translateY(${layout.offset}px)`,
        // transition: 'transform 2s',
    }),
    rowWrapper: {
        position: 'relative',
    },
    nudgeRowTrigger: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
    },
    nudgeRowLeftTrigger: {
        left: theme.spacing(2),
    },
    nudgeRowRightTrigger: {
        right: theme.spacing(2),
    },
    nudgeStageTrigger: {
        position: 'absolute',
        left: 'calc(50% - 20px)',
    },
    nudgeStageUpTrigger: {
        top: theme.spacing(2),
    },
    nudgeStageDownTrigger: {
        bottom: theme.spacing(2),
    },
}))

Stage.defaultProps = {
    hideControls: false,
}

Stage.propTypes = {
    RowComponent: PropTypes.oneOfType([
        PropTypes.element, PropTypes.func, PropTypes.object,
    ]).isRequired,
    hoveringIndex: PropTypes.number,
    hideControls: PropTypes.bool,
    layout: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.shape({
            items: PropTypes.array.isRequired,
            offset: PropTypes.number.isRequired,
        })).isRequired,
    }).isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseMove: PropTypes.func,
    onNudgeRow: PropTypes.func,
    onNudgeStage: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTouchMove: PropTypes.func,
    onTouchStart: PropTypes.func,
    onWheel: PropTypes.func,
}

export default Stage
