import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Fields from '../../components/Calculation/Fields'
import { fetchCalculationsForMap, sumCalculation } from '../../actions/calculation'
import { nodeFromUri } from '../../actions/node'

class FieldsContainer extends React.Component {
    static propTypes = {
        busy: PropTypes.bool,
        match: PropTypes.shape({
            params: PropTypes.shape({
                map: PropTypes.number,
                map: PropTypes.string.isRequired,
                node: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        onSubmit: PropTypes.func.isRequired,
        submitLabel: PropTypes.string,
        title: PropTypes.string.isRequired,
    }

    state = {
        editingIndex: null,
        nodeId: null,
        shouldSetValuesAfterFetch: false,
        sum: 0,
        summed: false,
        validNode: false,
        validValue: false,
        value: null,
        values: [],
    }

    componentRef = React.createRef()

    componentDidMount () {
        this._setInitialValues()

        this.props.fetch().then(() => this._setInitialValues(true))
    }

    render () {
        const node = nodeFromUri(this.props.nodes, this.props.match.params.node)

        return (
            <Fields
                busy={this.props.busy}
                editingIndex={this.state.editingIndex}
                isValid={isValid(this.state)}
                node={node}
                onChange={this._handleChange}
                onDelete={this._handleDelete}
                onEdit={this._handleEdit}
                onOperator={this._handleOperator}
                onSelect={this._handleSelect}
                onSubmit={this._handleSubmit}
                ref={this.componentRef}
                submitLabel={this.props.submitLabel}
                sum={this.state.sum}
                summed={this.state.summed}
                title={this.props.title}
                validNodeIds={Object.values(this.props.calculations).filter(({node_id}) => node_id !== node.id).map(({node_id}) => node_id)}
                values={this.state.values} />
        )
    }

    _handleChange = (value) => {
        this.setState({validValue: Boolean(value.match(/^[\d\.]{1,}$/)), value})
    }

    _handleDelete = (event) => {
        const values = this.state.values.filter((item, index) => index !== parseInt(event.currentTarget.value))

        this.setState({
            sum: sumCalculation(values, this.props.calculations),
            summed: isSummed(values),
            values,
        })
    }

    _handleEdit = (event) => this.setState({editingIndex: parseInt(event.currentTarget.value), value: this.state.values[event.currentTarget.value].value, validValue: true})

    _handleOperator = (event) => {
        const values = this.state.values
        const index = this.state.editingIndex === null ? values.length : this.state.editingIndex

        if (this.state.validValue) {
            values.splice(index, 1, {label: this.state.value, operator: event.currentTarget.value, value: parseFloat(this.state.value)})
        }

        if (this.state.validNode) {
            values.splice(index, 1, {label: this.props.nodes[this.state.nodeId].name, operator: event.currentTarget.value, value: this.state.nodeId})
        }

        if (this.componentRef.current) {
            this.componentRef.current.clear()
        }

        this.setState({
            editingIndex: null,
            sum: sumCalculation(values, this.props.calculations),
            summed: isSummed(values),
            validNode: false,
            validValue: false,
            values,
        })
    }

    _handleSelect = (nodeId) => this.setState({
        validNode: true,
        nodeId,
    })

    _handleSubmit = () => this.props.onSubmit(this.state.values)

    _setInitialValues = (fromFetch) => {
        if (fromFetch && ! this.state.shouldSetValuesAfterFetch) {
            return
        }

        if (! this.props.match.params.calculation) {
            return
        }

        if (! this.props.calculations[this.props.match.params.calculation]) {
            return this.setState({shouldSetValuesAfterFetch: true})
        }

        const values = this.props.calculations[this.props.match.params.calculation].values.map((item) => ({
            ...item,
            label: this.props.nodes[item.value] ? this.props.nodes[item.value].name : item.value,
        }))

        this.setState({
            sum: sumCalculation(values, this.props.calculations),
            summed: isSummed(values),
            values,
        })
    }
}

const isSummed = (values) => values[values.length - 1] && values[values.length - 1].operator === 'sum'
const isValid = ({validNode, validValue}) => validNode || validValue

const mapStateToProps = (state, ownProps) => ({
    calculations: state.calculations,
    nodes: state.nodes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchCalculationsForMap(ownProps.match.params.map)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FieldsContainer)
