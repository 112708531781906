import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import Item from './Item'

const Fields = React.forwardRef(({busy, editingIndex, isValid, node, onChange, onDelete, onEdit, onOperator, onSelect, onSubmit, submitLabel, sum, summed, title, validNodeIds, values}, ref) => (
    <React.Fragment>
        <div style={styles.heading}>
            <DialogTitle style={styles.titleInset}>{title}</DialogTitle>
            <Typography>Combine multiple values to calculate an output value for this goal.</Typography>
        </div>
        <Paper style={styles.preview}>
            <Typography variant="caption">Preview</Typography>
            <Typography variant="h5">{sum}</Typography>
        </Paper>
        <Divider style={styles.divider} />
        <div style={styles.values}>
            {values.map(({label, operator}, i) => (
                <Item
                    editing={editingIndex === i}
                    index={i}
                    key={i}
                    label={label}
                    isValid={isValid}
                    mapId={node.map_id}
                    onChange={onChange}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onOperator={onOperator}
                    onSelect={onSelect}
                    operator={operator}
                    validNodeIds={validNodeIds} />
            ))}
            {summed || editingIndex !== null ? null : (
                <Item
                    editing={true}
                    isValid={isValid}
                    mapId={node.map_id}
                    onChange={onChange}
                    onEdit={onEdit}
                    onOperator={onOperator}
                    onSelect={onSelect}
                    ref={ref}
                    validNodeIds={validNodeIds} />
            )}
        </div>
        {summed && editingIndex === null ? (
            <Button color="primary" disabled={busy} onClick={onSubmit} style={styles.submit} variant="contained">{submitLabel}</Button>
        ) : null}
    </React.Fragment>
))

Fields.defaultProps = {
    busy: false,
    isValid: false,
    summed: false,
    submitLabel: 'Save',
}

Fields.propTypes = {
    busy: PropTypes.bool,
    editingIndex: PropTypes.number,
    isValid: PropTypes.bool,
    node: PropTypes.shape({
        map_id: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onOperator: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.string,
    sum: PropTypes.number.isRequired,
    summed: PropTypes.bool,
    title: PropTypes.string.isRequired,
    validNodeIds: PropTypes.array.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        operator: PropTypes.oneOf(['add', 'subtract', 'multiply', 'divide', 'sum']).isRequired,
    })),
}

const styles = {
    heading: {
        gridArea: '1 / 1 / 2 / 3',
    },
    preview: {
        display: 'flex',
        flexDirection: 'column',
        gridArea: '1 / 3 / 2 / 4',
        justifyContent: 'center',
        padding: 12,
    },
    divider: {
        gridColumn: '1 / 4',
        marginBottom: 12,
        marginTop: 12,
    },
    values: {
        alignItems: 'center',
        display: 'grid',
        gridColumn: '1 / 4',
        gridColumnGap: 12,
        gridRowGap: 12,
        gridTemplateColumns: 'auto auto 1fr auto',
    },
    titleInset: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
    },
    submit: {
        gridColumn: '1 / 4',
    },
}

export default Fields
