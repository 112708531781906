import FormControlLabel from '@material-ui/core/FormControlLabel'
import Formsy from 'formsy-react'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Container from '../Shared/Container'
import FormsyHidden from '../formsy/FormsyHidden'
import FormsySelect from '../formsy/FormsySelect'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import { fetchNode, updateNode } from '../../actions/node'
import { fetchUsersForTeam } from '../../actions/user'
import { historyShape, nodeMatchShape } from '../../constants/types'
import usePrevious from '../../hooks/usePrevious'

const Assignment = ({history, match}) => {
    const classes = useStyles()

    const goal = useSelector(({nodes}) => nodes[match.params.node]) || {}
    const users = useSelector(({users}) => Object.values(users).filter(({teams_index}) => Object.keys(teams_index).includes(goal.map_team_uri)))
    const dispatch = useDispatch()

    const [ hasChanged, setHasChanged ] = useState(false)
    const [ isAssigned, setIsAssigned ] = useState(Boolean(goal.assigned_user !== null))
    const [ isBusy, setIsBusy ] = useState(false)
    const [ isReady, setIsReady ] = useState(Boolean(goal.id !== undefined))
    const [ isValid, setIsValid ] = useState(false)

    const canSubmit = Boolean(hasChanged && isValid)
    const previousMapTeamUri = usePrevious(goal.map_team_uri)

    useEffect(() => {
        dispatch(fetchNode(match.params.node)).then(({assigned_user}) => {
            if (! isReady) {
                setIsAssigned(Boolean(assigned_user !== null))
            }
        }).catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                return history.push(`/m/${match.params.map}`)
            }

            throw new Error(error)
        })
    }, [])

    useEffect(() => {
        if (goal.map_team_uri === undefined || goal.map_team_uri === previousMapTeamUri) {
            return
        }

        dispatch(fetchUsersForTeam(goal.map_team_uri)).then(() => setIsReady(true))
    }, [
        goal.map_team_uri,
    ])

    const _handleSubmit = (formData, resetForm, invalidateForm) => {
        setIsBusy(true)

        dispatch(updateNode(goal.uri, formData)).then(history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setIsBusy(false))
    }

    const _handleAssignedToggle = (event) => setIsAssigned(event.target.checked)
    const _handleChange = (formData, hasChanged) => setHasChanged(hasChanged)
    const _handleInvalid = () => setIsValid(false)
    const _handleValid = () => setIsValid(true)

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>{goal.name}</Heading>
                        <Formsy onChange={_handleChange} onInvalid={_handleInvalid} onValid={_handleValid} onValidSubmit={_handleSubmit}>
                            <fieldset>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={isAssigned}
                                            color="primary"
                                            onChange={_handleAssignedToggle} />
                                    )}
                                    label="Assigned?" />
                            </fieldset>
                            <fieldset>
                                {isAssigned ? (
                                    <FormsySelect defaultValue={goal.assigned_user ? goal.assigned_user.id : ''} label="Assign user" name="assigned_user" fullWidth required>
                                        {users.map(({id, name}) => (
                                            <MenuItem key={id} value={id}>{name}</MenuItem>
                                        ))}
                                    </FormsySelect>
                                ) : (
                                    <FormsyHidden defaultValue="" name="assigned_user" />
                                )}
                            </fieldset>
                            <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                Update
                            </LoadingButton>
                        </Formsy>
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4),
    },
}))

Assignment.propTypes = {
    history: historyShape.isRequired,
    match: nodeMatchShape.isRequired,
}

export default Assignment
