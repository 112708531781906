import Checkbox from '@material-ui/core/Checkbox'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Formsy from 'formsy-react'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'

import FormsySelect from '../formsy/FormsySelect'
import LoadingButton from '../LoadingButton'

const ConfigureGoalPerformance = ({isBusy, labels, onChange, onSubmit, scopes, selected, users}) => (
    <div style={styles.container}>
        <DialogTitle>Configure Goal Performance</DialogTitle>
        <DialogContent>
            <Formsy onValidSubmit={onSubmit} style={styles.content}>
                <fieldset>
                    <FormsySelect
                        children={menuItems(users, selected.users)}
                        defaultValue={selected.users}
                        label="Goal Owners"
                        name="users"
                        onChange={onChange}
                        renderValue={(selected) => selected.map((id) => users.find(({id: userId}) => userId === parseInt(id)).name).join(', ')}
                        fullWidth multiple required />
                </fieldset>
                <fieldset>
                    <FormsySelect
                        children={menuItems(scopes, selected.scopes)}
                        defaultValue={selected.scopes}
                        label="Filters"
                        name="scopes"
                        onChange={onChange}
                        renderValue={(selected) => selected.map((id) => scopes.find(({id: scopeId}) => scopeId === id).name).join(', ')}
                        fullWidth multiple required />
                </fieldset>
                <fieldset>
                    <FormsySelect
                        children={menuItems(labels, selected.labels)}
                        defaultValue={selected.labels}
                        label="Labels"
                        name="labels"
                        onChange={onChange}
                        renderValue={(selected) => selected.map((id) => labels.find(({id: labelId}) => labelId === parseInt(id)).name).join(', ')}
                        fullWidth multiple required />
                </fieldset>
                <LoadingButton busy={isBusy} color="primary" containerStyle={styles.submit} type="submit" variant="contained">
                    Save
                </LoadingButton>
            </Formsy>
        </DialogContent>
    </div>
)

const menuItems = (items, selected) => items.map(({id, name}) => (
    <MenuItem key={id} value={id}>
        <Checkbox checked={Array.isArray(selected) && selected.includes(id)} />
        <ListItemText primary={name} />
    </MenuItem>
))

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        alignSelf: 'center',
        marginTop: 40,
    },
}

ConfigureGoalPerformance.defaultProps = {
    isBusy: false,
}

ConfigureGoalPerformance.propTypes = {
    isBusy: PropTypes.bool,
    labels: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    scopes: PropTypes.array.isRequired,
    selected: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
}

export default ConfigureGoalPerformance
