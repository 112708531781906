import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import PriorityIcon from '../PriorityIcon'

const PriorityChip = memo(({loading, priority, uri}) => {
    const _labelText = () => {
        if (priority === 0) {
            return 'Low priority'
        } else if (priority === 1) {
            return 'Medium priority'
        } else if (priority === 2) {
            return 'High priority'
        }

        return 'Priority not set'
    }

    const avatar = loading ? (
        <Skeleton
            variant="circle" />
    ) : (
        <PriorityIcon
            priority={priority} />
    )

    const label = loading ? (
        <Skeleton
            variant="text"
            width={60} />
    ) : _labelText()

    return (
        <Chip
            avatar={avatar}
            color="secondary"
            component={Link}
            label={label}
            to={{pathname: `${uri}/priority`, state: {modal: true}}}
            clickable />
    )
})

PriorityChip.defaultProps = {
    loading: false,
}

PriorityChip.propTypes = {
    loading: PropTypes.bool,
    priority: PropTypes.oneOfType([
        PropTypes.number, PropTypes.string,
    ]),
    uri: PropTypes.string.isRequired,
}

export default PriorityChip
