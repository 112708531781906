import { REHYDRATE } from 'redux-persist'

import { RECEIVE_RESOURCE, RECEIVE_RESOURCES, REMOVE_RESOURCE } from '../actions/resource'

const initialState = {
    created_at: null,
    description: null,
    id: null,
    label: null,
    pivot: {
        order: 0,
        resourceable_id: null,
        resourceable_type: null,
    },
    resourceable: {
        id: null,
    },
    resourceable_type: null,
}

const resourceReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_RESOURCE]: () => ({
            ...state,
            ...action.payload.resource,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const resourcesReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_RESOURCE]: () => ({
            ...state,
            [action.payload.resource.id]: resourceReducer(state[action.payload.resource.id], action),
        }),
        [RECEIVE_RESOURCES]: () => ({
            ...state,
            ...action.payload.resources.reduce((carry, resource) => ({
                ...carry,
                [resource.id]: resourceReducer(state[resource.id], {
                    type: RECEIVE_RESOURCE,
                    payload: {
                        resource,
                    },
                }),
            }), {}),
        }),
        [REMOVE_RESOURCE]: () => {
            const nextState = Object.assign({}, state)
            delete nextState[action.payload.id]

            return nextState
        },
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default resourcesReducer
