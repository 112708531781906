import { REHYDRATE } from 'redux-persist'

import { HIDE_PROMPT, SHOW_ALERT, SHOW_PROMPT } from '../actions/ui'

const initialState = {
    prompt: {
        confirmMethod: null,
        dismissMethod: null,
        message: '',
        options: {},
        show: false,
        title: '',
        type: 'prompt',
    },
}

export default (state = initialState, action) => {
    const actions = {
        [REHYDRATE]: () => ({
            ...state,
        }),
        [HIDE_PROMPT]: () => ({
            ...state,
            prompt: initialState.prompt,
        }),
        [SHOW_ALERT]: () => ({
            ...state,
            prompt: {
                ...action.payload,
                show: true,
                type: 'alert',
            },
        }),
        [SHOW_PROMPT]: () => ({
            ...state,
            prompt: {
                ...action.payload,
                show: true,
                type: 'prompt',
            },
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}
