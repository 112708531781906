import React from 'react'
import { connect } from 'react-redux'

import Profile from '../../components/Auth/Profile'
import { updateUser } from '../../actions/user'

class ProfileContainer extends React.Component {
    state = {
        hasChanged: false,
        isBusy: false,
        isValid: false,
        profilePhotoFile: null,
        profilePhotoPreview: null,
    }

    componentDidMount () {
        this.setState({
            profilePhotoPreview: this.props.users[this.props.auth.userId].profile_picture
        })
    }

    componentWillUnmount () {
        if (this.state.profilePhotoPreview) {
            URL.revokeObjectURL(this.state.profilePhotoPreview)
        }
    }

    render () {
        return (
            <Profile
                canSubmit={canSubmit(this.state)}
                hasChanged={this.state.hasChanged}
                isInvalid={this.state.isInvalid}
                isValid={this.state.isValid}
                onChange={this._handleChange}
                onDrop={this._handleDrop}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                profilePhotoPreview={this.state.profilePhotoPreview}
                user={this.props.users[this.props.auth.userId]} />
        )
    }

    _handleDrop = (files) => {
        if (files.length) {
            this.setState({
                hasChanged: true,
                profilePhotoFile: files[0],
                profilePhotoPreview: URL.createObjectURL(files[0]),
            })
        }
    }

    _handleSubmit = (data, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.update(this.props.auth.userId, data, this.state.profilePhotoFile).then(() => this.setState({hasChanged: false})).finally(() => this.setState({isBusy: false}))
    }

    _handleChange = (formData, hasChanged) => this.setState({hasChanged})
    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const canSubmit = ({hasChanged, isBusy, isValid}) => ! isBusy && hasChanged && isValid

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    update: (id, data, photo) => dispatch(updateUser(id, data, photo)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
