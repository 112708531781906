import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { withFormsy } from 'formsy-react'

const FormsyCheckbox = ({
    defaultValue, disabled, errorMessage, errorMessages, hasValue, innerRef, isFormDisabled, isFormSubmitted, isPristine, isRequired, isValid, isValidValue, onChange, resetValue, setValidations, setValue, showError, showRequired, validationError, validationErrors, validations, value, ...rest
}) => {
    useEffect(() => setValue(defaultValue), [])

    const handleChange = (event, value) => {
        setValue(value)

        if (onChange) {
            onChange(event, value)
        }
    }

    return (
        <Checkbox
            {...rest}
            checked={value === undefined ? defaultValue : value}
            disabled={isFormDisabled || disabled}
            onChange={handleChange} />
    )
}

FormsyCheckbox.defaultProps = {
    defaultValue: false,
}

FormsyCheckbox.propTypes = {
    defaultValue: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.bool,
}

export default withFormsy(FormsyCheckbox)
