import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Doughnut } from 'react-chartjs-2'

import { COLOR_AMBER, COLOR_BRAND_PRIMARY, COLOR_GREEN, COLOR_GREY_DARKER, COLOR_RED } from '../../constants/colors'

const GoalPerformance = ({isFetching, total, ...rest}) => (
    <div style={styles.outer}>
        <Toolbar>
            <Typography color="inherit" variant="h6">Goal Performance</Typography>
        </Toolbar>
        {isFetching ? (
            <CircularProgress style={styles.busy} />
        ) : (
            <React.Fragment>
                <div style={{...styles.container, ...(total ? {} : styles.empty)}}>
                    <Doughnut data={parseProps({total, ...rest})} options={options()} />
                </div>
                {total ? null : (
                    <Typography style={styles.emptyState}>Once you&apos;ve created some targets, you can monitor their progress here.</Typography>
                )}
            </React.Fragment>
        )}
    </div>
)

GoalPerformance.defaultProps = {
    hasFetched: false,
    isFetching: false,
}

GoalPerformance.propTypes = {
    active: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
    due: PropTypes.number.isRequired,
    hasFetched: PropTypes.bool,
    isFetching: PropTypes.bool,
    missed: PropTypes.number.isRequired,
    style: PropTypes.object,
    total: PropTypes.number.isRequired,
}

const options = () => ({
    cutoutPercentage: 40,
    legend: {
        position: 'bottom',
        labels: {
            boxWidth: 12,
        },
    },
    maintainAspectRatio: false,
    tooltips: {
        callbacks: {
            label: (tooltipItem, data) => ` ${data.labels[tooltipItem.index].substr(0, data.labels[tooltipItem.index].length - 4)}`
        },
    },
})

const parseProps = ({active, completed, due, hasFetched, missed, total}) => {
    const counts = {
        active: total ? active : (hasFetched ? 11 : 0),
        completed: total ? completed : (hasFetched ? 9 : 0),
        due: total ? due : (hasFetched ? 4 : 0),
        missed: total ? missed : (hasFetched ? 2 : 0),
    }

    return {
        labels: [
            `Target achieved (${counts.completed})`, `Target due soon (${counts.due})`, `Target missed (${counts.missed})`, `Target in progress (${counts.active})`,
        ],
        datasets: [{
            data: [
                counts.completed, counts.due, counts.missed, counts.active
            ],
            backgroundColor: [
                COLOR_GREEN, COLOR_AMBER, COLOR_RED, COLOR_BRAND_PRIMARY,
            ],
        }],
    }
}

const styles = {
    outer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
    },
    container: {
        padding: 12,
        marginBottom: 24,
    },
    empty: {
        filter: 'blur(4px)',
        opacity: .5,
    },
    busy: {
        margin: 'auto',
    },
    emptyState: {
        backgroundColor: 'rgba(255, 255, 255, .7)',
        bottom: 0,
        color: COLOR_GREY_DARKER,
        fontSize: 14,
        fontWeight: '300',
        left: 0,
        position: 'absolute',
        paddingLeft: 24,
        paddingTop: 24,
        paddingRight: 24,
        right: 0,
        textAlign: 'center',
        top: 64,
    },
}

export default GoalPerformance
