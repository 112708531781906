import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import GoalTextArea from './GoalTextArea'
import { COLOR_BLUE_DARK, COLOR_GREY_DARKER, COLOR_WHITE } from '../../../constants/colors'
import { LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH } from '../../../constants/layout'

const Goal = React.forwardRef(({canDelete, editingInstanceId, focal, id, instanceId, meta, name, onBlur, onChange, onClick, onDelete, onDoubleClick, style}, ref) => {
    const _clickTimeout = useRef(false)
    const _pendingDoubleClick = useRef(false)

    const handleClick = () => {
        if (typeof _clickTimeout.current !== undefined) {
            clearTimeout(_clickTimeout.current)
        }

        if (_pendingDoubleClick.current) {
            _pendingDoubleClick.current = false

            if (typeof onDoubleClick === 'function') {
                onDoubleClick(id, instanceId)
            }
        } else {
            _pendingDoubleClick.current = true
            _clickTimeout.current = setTimeout(() => {
                _pendingDoubleClick.current = false

                if (typeof onClick === 'function') {
                    onClick(id, instanceId)
                }
            }, 400)
        }
    }
    const handleDelete = (event) => onDelete(event, id, instanceId)

    return (
        <div onClick={handleClick} ref={ref} style={{...styles.container(focal), ...style}}>
            {instanceId === editingInstanceId ? (
                <GoalTextArea
                    focal={focal}
                    id={id}
                    onBlur={onBlur}
                    onChange={onChange}
                    style={style}
                    value={name} />
            ) : (
                <React.Fragment>
                    {canDelete && (
                        <IconButton onClick={handleDelete} style={styles.deleteIcon}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                    <div style={{...styles.relations, ...styles.parentRelations}}>
                        {Boolean(meta && meta[instanceId]) && (
                            [...Array(meta[instanceId].relations.parents)].map((item, index) => (
                                <span key={`parent-relation-${index}`} style={styles.relation(focal)} />
                            ))
                        )}
                    </div>
                    <div style={styles.name(focal)}>{name}</div>
                    <div style={{...styles.relations, ...styles.childRelations}}>
                        {Boolean(meta && meta[instanceId]) && (
                            [...Array(meta[instanceId].relations.children)].map((item, index) => (
                                <span key={`child-relation-${index}`} style={styles.relation(focal)} />
                            ))
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
})

const styles = {
    container: (focal) => ({
        alignItems: 'center',
        backgroundColor: focal ? COLOR_BLUE_DARK : COLOR_WHITE,
        boxShadow: '0 3px 10px rgba(0, 0, 0, .156863)',
        display: 'flex',
        height: LAYOUT_NODE_HEIGHT,
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        width: LAYOUT_NODE_WIDTH,
    }),
    name: (focal) => ({
        color: focal ? COLOR_WHITE : COLOR_GREY_DARKER,
        fontSize: 14,
        paddingLeft: LAYOUT_NODE_SPACING_X / 2,
        paddingRight: LAYOUT_NODE_SPACING_X / 2,
        textAlign: 'center',
    }),
    relations: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridColumnGap: LAYOUT_NODE_SPACING_X / 10,
        position: 'absolute',
    },
    parentRelations: {
        top: LAYOUT_NODE_SPACING_Y / 4,
    },
    childRelations: {
        bottom: LAYOUT_NODE_SPACING_Y / 4,
    },
    relation: (focal) => ({
        borderRadius: 6,
        backgroundColor: focal ? COLOR_WHITE : COLOR_GREY_DARKER,
        height: 6,
        width: 6,
    }),
    deleteIcon: {
        position: 'absolute',
        right: LAYOUT_NODE_SPACING_X / 4,
        top: LAYOUT_NODE_SPACING_Y / 4,
    },
}

Goal.defaultProps = {
    canDelete: false,
    editingInstanceId: null,
    meta: {},
    style: {},
}

Goal.propTypes = {
    canDelete: PropTypes.bool,
    editingInstanceId: PropTypes.string,
    id: PropTypes.string,
    instanceId: PropTypes.string,
    meta: PropTypes.object,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    onDoubleClick: PropTypes.func,
    style: PropTypes.object,
}

export default Goal
