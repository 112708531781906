import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React, { Fragment, memo, useEffect, useState } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link } from 'react-router-dom'
import { format as formatDate } from 'date-fns'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import PageLoadingIndicator from '../PageLoadingIndicator'
import TargetIcon from '../Target/Icon'
import TargetValue from '../Target/Value'
import TargetUpdateManual from '../Target/UpdateManual'
import { COLOR_GREY_LIGHTEST } from '../../constants/colors'
import { fetchTargetForNode }  from '../../actions/target'

const Target = memo(({className, nodeUri, uri}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    const target = useSelector(({targets}) => Object.values(targets).find(({node}) => node && node.uri === nodeUri)) || {}

    const [ currentValue, setCurrentValue ] = useState()
    const [ isReady, setIsReady ] = useState(Boolean(target.id))

    const iconSize = useMediaQuery(theme.breakpoints.up('sm')) ? 120 : 60
    const manageLabel = useMediaQuery(theme.breakpoints.up('sm')) ? 'Manage target' : 'Manage'

    useEffect(() => {
        dispatch(fetchTargetForNode(nodeUri)).then(() => setIsReady(true)).catch((error) => {
            if (error.response && error.response.status === 404) {
                return setIsReady(true)
            }

            throw new Error(error)
        })
    }, [])

    useEffect(() => {
        setCurrentValue(target.data && target.data.length ? target.data[0].value : 0)
    }, [target.data])

    return (
        <div className={`${classes.wrapper} ${className}`}>
            <div className={classes.heading}>
                <Typography variant="h6">
                    {isReady ? (
                        target ? target.description : 'Set target'
                    ) : (
                        <Skeleton
                            variant="text"
                            width={120} />
                    )}
                </Typography>
                <Button component={Link} size="small" to={{pathname: `${uri}/target`, state: {modal: true}}} variant="outlined">
                    {manageLabel}
                </Button>
            </div>
            {isReady ? (
                <div className={classes.content}>
                    {Boolean(target.id) ? (
                        <Fragment>
                            <div className={classes.itemWrapper}>
                                <Typography className={classes.itemHeading} variant="subtitle1">Progress to target</Typography>
                                <TargetIcon
                                    className={classes.icon}
                                    size={iconSize}
                                    type="progress"
                                    {...target} />
                                {serviceOutputsValue(target.service) && (
                                    <Fragment>
                                        <Typography color="secondary" variant="subtitle2">Now</Typography>
                                        <TargetValue
                                            format={target.format}
                                            prefix={target.prefix}
                                            suffix={target.suffix}
                                            value={currentValue}
                                            variant="h6" />
                                        {formatSupportsProjections(target.format) && (
                                            <Fragment>
                                                <Typography color="secondary" variant="subtitle2">Target</Typography>
                                                <TargetValue
                                                    format={target.format}
                                                    prefix={target.prefix}
                                                    suffix={target.suffix}
                                                    value={target.value}
                                                    variant="h6" />
                                                <Typography color="secondary" variant="subtitle2">Starting</Typography>
                                                <TargetValue
                                                    format={target.format}
                                                    prefix={target.prefix}
                                                    suffix={target.suffix}
                                                    value={target.start_value}
                                                    variant="h6" />
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </div>
                            <div className={classes.itemWrapper}>
                                <Typography className={classes.itemHeading} variant="subtitle1">Elapsed time to deadline</Typography>
                                <TargetIcon
                                    className={classes.icon}
                                    size={iconSize}
                                    type="duration"
                                    {...target} />
                                <Typography color="secondary" variant="subtitle2">Now</Typography>
                                <Typography variant="h6">{formatDate(new Date(), 'do MMMM')}</Typography>
                                <Typography color="secondary" variant="subtitle2">Due</Typography>
                                <Typography variant="h6">{formatDate(new Date(target.date_due), 'do MMMM y')}</Typography>
                                <Typography color="secondary" variant="subtitle2">Started</Typography>
                                <Typography variant="h6">{formatDate(new Date(target.date_initiated), 'do MMMM y')}</Typography>
                            </div>
                            {target.service.label === 'internal.manual' && (
                                <TargetUpdateManual
                                    className={classes.inputWrapper}
                                    id={target.id}
                                    format={target.format} />
                            )}
                        </Fragment>
                    ) : (
                        <div className={classes.emptyWrapper}>
                            <Typography color="secondary" variant="subtitle2" paragraph>This goal does not currently have a target.</Typography>
                            <Button color="primary" component={Link} to={{pathname: `${uri}/target`, state: {modal: true}}} variant="contained">
                                Manage Target
                            </Button>
                        </div>
                    )}
                </div>
            ) : (
                <PageLoadingIndicator />
            )}
        </div>
    )
})

const serviceOutputsValue = ({label}) => label !== 'internal.aggregate'
const formatSupportsProjections = ({format}) => ['decimal', 'integer'].includes(format)

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    heading: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    content: {
        display: 'grid',
        flex: 1,
        gridColumnGap: theme.spacing(2),
        gridRowGap: theme.spacing(2),
        gridAutoRows: 'min-content',

        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    itemWrapper: {
        alignItems: 'center',
        borderColor: COLOR_GREY_LIGHTEST,
        borderRadius: theme.shape.borderRadius,
        borderStyle: 'solid',
        borderWidth: theme.spacing(1),
        display: 'grid',
        gridAutoRows: 'min-content',
        gridColumnGap: theme.spacing(3),
        gridRowGap: theme.spacing(2),
        gridTemplateColumns: 'auto 1fr',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    itemHeading: {
        fontWeight: '500',
        gridColumn: '1 / 3',
    },
    icon: {
        gridColumn: '1 / 3',
        justifySelf: 'center',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    inputWrapper: {
        borderColor: COLOR_GREY_LIGHTEST,
        borderRadius: theme.shape.borderRadius,
        borderStyle: 'solid',
        borderWidth: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            gridColumn: '1 / 3',
        },
    },
    emptyWrapper: {
        alignItems: 'center',
        borderColor: COLOR_GREY_LIGHTEST,
        borderRadius: theme.shape.borderRadius,
        borderStyle: 'solid',
        borderWidth: theme.spacing(1),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gridColumn: '1 / 3',
        justifyContent: 'center',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
}))

Target.defaultProps = {
    className: '',
}

Target.propTypes = {
    className: PropTypes.string,
    nodeUri: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
}

export default Target
