import React from 'react'
import { connect } from 'react-redux'

import CommentCreate from '../../components/Comment/CommentCreate'
import { storeComment } from '../../actions/comment'

class CommentCreateContainer extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            isBusy: false,
            isValid: false,
            selectedFile: null,
        }
    }

    render () {
        return (
            <CommentCreate
                isBusy={this.state.isBusy}
                isValid={this.state.isValid}
                onFileInputChange={this._handleFileInputChange}
                onInvalid={this._handleInvalid}
                onClose={this._handleClose}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                selectedFile={this.state.selectedFile} />
        )
    }

    _handleClose = () => {
        if (this.props.location.state && this.props.location.state.modal) {
            this.props.history.goBack()
        } else {
            this.props.history.push('/')
        }
    }

    _handleFileInputChange = (event) => {
        if (! event.target.files.length) {
            return
        }

        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    _handleSubmit = (data, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.create({
            ...data,
            file: this.state.selectedFile,
            node: this.props.match.params.node,
        }).then((response) => {
            resetForm()

            this.setState({
                isBusy: false,
                isValid: false,
                selectedFile: null
            })

            this._handleClose()
        })
    }

    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const mapStateToProps = (state, ownProps) => ({
    nodes: state.nodes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    create: (comment) => dispatch(storeComment(comment)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentCreateContainer)
