import Formsy from 'formsy-react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Container from './Shared/Container'
import FormsyCheckbox from './formsy/FormsyCheckbox'
import FormsyText from './formsy/FormsyText'
import Heading from './Shared/Heading'
import LoadingButton from './LoadingButton'
import Modal from './Shared/Modal'
import PageLoadingIndicator from './PageLoadingIndicator'

const UserForm = ({canSubmit, isBusy, isReady, onChange, onInvalid, onSubmit, onValid, saveLabel, teamPermissions, title, user}) => {
    const classes = useStyles()

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>{title}</Heading>
                        <Formsy onChange={onChange} onInvalid={onInvalid} onValid={onValid} onValidSubmit={onSubmit}>
                            <fieldset>
                                <FormsyText
                                    defaultValue={user.first_name}
                                    label="First name"
                                    name="first_name"
                                    validationError="You must provide their first name"
                                    fullWidth required />
                            </fieldset>
                            <fieldset>
                                <FormsyText
                                    defaultValue={user.last_name}
                                    label="Last name"
                                    name="last_name"
                                    validationError="You must provide their last name"
                                    fullWidth required />
                            </fieldset>
                            <fieldset>
                                <FormsyText
                                    defaultValue={user.username}
                                    label="Username"
                                    name="username"
                                    validationError="You must provide their username"
                                    fullWidth required />
                            </fieldset>
                            <fieldset>
                                <FormsyText
                                    defaultValue={user.email}
                                    label="E-mail"
                                    name="email"
                                    validationError="You must provide a valid email address"
                                    fullWidth required />
                            </fieldset>
                            <fieldset>
                                <FormsyText
                                    defaultValue={user.department}
                                    label="Department"
                                    name="department"
                                    validationError="You must provide their department"
                                    fullWidth required />
                            </fieldset>
                            <List>
                                <ListSubheader>Permissions</ListSubheader>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormsyCheckbox
                                            defaultValue={teamPermissions.includes('MANAGE_MEMBERS')}
                                            name="permissions[MANAGE_MEMBERS]" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Manage team members"
                                        secondary="Allowed to add and remove members from the team." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormsyCheckbox
                                            defaultValue={teamPermissions.includes('MANAGE_TARGETS')}
                                            name="permissions[MANAGE_TARGETS]" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Manage targets"
                                        secondary="Allowed to add, remove and update targets for any goal." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormsyCheckbox
                                            defaultValue={teamPermissions.includes('READ_ONLY')}
                                            name="permissions[READ_ONLY]" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Read only user"
                                        secondary="Not allowed to create or edit goal maps." />
                                </ListItem>
                            </List>
                            <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                {saveLabel}
                            </LoadingButton>
                        </Formsy>
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4),
    },
}))

UserForm.defaultProps = {
    isBusy: false,
    canSubmit: false,
    isReady: true,
    teamPermissions: [],
    user: {},
}

UserForm.propTypes = {
    canSubmit: PropTypes.bool,
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onInvalid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
    saveLabel: PropTypes.string.isRequired,
    teamPermissions: PropTypes.array,
    title: PropTypes.string.isRequired,
    user: PropTypes.object,
}

export default UserForm
