import DateFnsLocale from 'date-fns/locale/en-GB'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { LocalizationProvider } from '@material-ui/pickers'
import { connect } from 'react-redux'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import Nav from './Nav'
import Prompt from './Prompt'
import RoutesHandler from './RoutesHandler'

import { COLOR_BLUE_DARK, COLOR_BRAND_PRIMARY, COLOR_GREY_DARK, COLOR_GREY_DARKER, COLOR_WHITE } from '../constants/colors'

class Main extends React.Component {
    render () {
        return (
            <Router>
                <MuiThemeProvider theme={muiTheme}>
                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={DateFnsLocale}>
                        <div style={styles.container}>
                            <Nav />
                            <Route component={RoutesHandler} />
                        </div>
                        <Prompt />
                    </LocalizationProvider>
                </MuiThemeProvider>
            </Router>
        )
    }
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    }
}

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#5feddd',
            main: COLOR_BRAND_PRIMARY,
            dark: '#00897c',
            contrastText: COLOR_WHITE,
        },
        secondary: {
            light: '#727272',
            main: COLOR_GREY_DARK,
            dark: '#202020',
            contrastText: COLOR_WHITE,
        },
        text: {
            primary: COLOR_GREY_DARKER,
            secondary: 'rgba(0,0,0,.3)',
        },
    },
})

export default Main
