import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Item from '../../components/Goal/Item'
import { nodeFromUri } from '../../actions/node'

class ItemContainer extends React.Component {
    render () {
        const node = nodeFromUri(this.props.nodes, this.props.uri)

        return (
            <Item
                labels={sortLabels(this.props)}
                onClick={this._handleClick}
                style={this.props.style}
                {...node} />
        )
    }

    _handleClick = () => this.props.history.push({pathname: `/${this.props.match.params.view || (this.props.dashboard ? 'd' : 'm')}/${this.props.mapUri}/g/${this.props.uri}`, state: {modal: true}})
}

ItemContainer.defaultProps = {
    style: {},
}

ItemContainer.propTypes = {
    dashboard: PropTypes.bool,
    mapUri: PropTypes.string.isRequired,
    style: PropTypes.object,
    uri: PropTypes.string.isRequired,
}

const sortLabels = ({labels, nodes, uri}) => Object.values(labels).filter(({id}) => nodeFromUri(nodes, uri).labels.map(({id}) => id).includes(id)).sort((a, b) => a.name.localeCompare(b.name))

const mapStateToProps = (state, ownProps) => ({
    labels: state.labels,
    nodes: state.nodes,
})

export default withRouter(connect(mapStateToProps)(ItemContainer))
