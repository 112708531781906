import ActionsMenu from '../ActionsMenu'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import FlipToBackIcon from '@material-ui/icons/FlipToBack'
import FlipToFrontIcon from '@material-ui/icons/FlipToFront'
import GridOffIcon from '@material-ui/icons/GridOff'
import GridOnIcon from '@material-ui/icons/GridOn'
import PropTypes from 'prop-types'
import React from 'react'
import RedoIcon from '@material-ui/icons/Redo'
import SaveIcon from '@material-ui/icons/Save'
import Typography from '@material-ui/core/Typography'
import UndoIcon from '@material-ui/icons/Undo'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import GoalDragLayer from './GoalDragLayer'
import GoalDraggable from './GoalDraggable'
import PageLoadingIndicator from '../../PageLoadingIndicator'
import Row from './Row'
import Stage from '../../../containers/Map/StageContainer'
import StageDropable from './StageDropable'
import Tutorial from '../../../containers/Map/TutorialContainer'
import { COLOR_BLUE, COLOR_GREY_LIGHT, COLOR_WHITE } from '../../../constants/colors'
import { CONTROLS_HEIGHT, HEADER_HEIGHT, LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH, TRAY_HEIGHT } from '../../../constants/layout'

const Layout = ({
    canRedo, canUndo, dragBehaviour, errorMessage, goals, greedyBehaviour, hasError, isBusy, isReady, isSyncing, layout, onClose, onDismissError, onDrag, onDrop, onMouseMove, onMouseOut, onOrphanDelete, onPickup, onRedo, onSave, onToggleDragBehaviour, onToggleGreedyBehaviour, onUndo, orphanedInstances, pristine, showActionsMenu, tutorialEvent, uri, windowSize, ...rest
}) => {
    const classes = useStyles({isSyncing})
    const theme = useTheme()

    const buttonLabels = useMediaQuery(theme.breakpoints.up('sm'))

    if (! isReady) {
        return (
            <PageLoadingIndicator />
        )
    }

    return (
        <React.Fragment>
            <DndProvider backend={'ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch) ? TouchBackend : HTML5Backend}>
                <StageDropable className={classes.container}>
                    <div className={classes.controls}>
                        {/*<div>
                            <Fab color="primary" onClick={onToggleGreedyBehaviour} size="small" title={greedyBehaviourTitle(greedyBehaviour)} variant={buttonLabels ? 'extended' : 'round'}>
                                <GreedyBehaviourIcon greedyBehaviour={greedyBehaviour} className={classes.extendedIcon} />
                                {buttonLabels && greedyBehaviourName(greedyBehaviour)}
                            </Fab>
                        </div>*/}
                        <Fab color="primary" disabled={isBusy || ! canUndo} onClick={onUndo} size="small" title="Undo">
                            <UndoIcon />
                        </Fab>
                        <Fab color="primary" disabled={isBusy || ! canRedo} onClick={onRedo} size="small" title="Redo">
                            <RedoIcon />
                        </Fab>
                        <div />
                        <div>
                            <Fab color="primary" onClick={onToggleDragBehaviour} size="small" title={dragBehaviourTitle(dragBehaviour)} variant={buttonLabels ? 'extended' : 'round'}>
                                <DragBehaviourIcon dragBehaviour={dragBehaviour} className={classes.extendedIcon} />
                                {buttonLabels && dragBehaviourName(dragBehaviour)}
                            </Fab>
                        </div>
                        <div />
                        <Fab className={classes.controlSpacing} color="primary" disabled={isBusy || pristine} onClick={onSave} size="small" variant={buttonLabels ? 'extended' : 'round'}>
                            <SaveIcon className={classes.extendedIcon} />
                            {buttonLabels && 'Save'}
                        </Fab>
                        <Fab color="default" disabled={isBusy} onClick={onClose} size="small" variant={buttonLabels ? 'extended' : 'round'}>
                            <CancelIcon className={classes.extendedIcon} />
                            {buttonLabels && (
                                pristine ? 'Close' : 'Discard'
                            )}
                        </Fab>
                    </div>
                    <div onMouseMove={onMouseMove} onMouseOut={onMouseOut} className={classes.content}>
                        <div className={classes.guide} />
                        <Stage
                            GoalComponent={GoalDraggable}
                            RowComponent={Row}
                            disabled={isSyncing}
                            dragBehaviour={dragBehaviour}
                            goals={goals}
                            greedyBehaviour={greedyBehaviour}
                            layout={layout}
                            onDrop={onDrop}
                            onPickup={onPickup}
                            {...rest} />
                        <GoalDragLayer
                            dragBehaviour={dragBehaviour}
                            goals={goals}
                            greedyBehaviour={greedyBehaviour}
                            layout={layout}
                            onDrag={onDrag}
                            windowSize={windowSize} />
                    </div>
                    {Boolean(orphanedInstances.length) ? (
                        <div className={classes.tray}>
                            {orphanedInstances.map((instanceId) => (
                                <GoalDraggable
                                    canDelete={true}
                                    dragBehaviour={dragBehaviour}
                                    greedyBehaviour={greedyBehaviour}
                                    instanceId={instanceId}
                                    key={instanceId}
                                    onDelete={onOrphanDelete}
                                    onDrop={onDrop}
                                    onPickup={onPickup}
                                    {...goals[layout.instances[instanceId]]} />
                            ))}
                        </div>
                    ) : (
                        <div className={classes.emptyTray}>
                            <Typography noWrap variant="h6" gutterBottom>This is the goal tray - you can drop goals down here.</Typography>
                            <Typography gutterBottom noWrap>It can be used to store goals you don&rsquo;t want on the goal map currently, and is where you need to drop goals before you can delete them.</Typography>
                            <Typography noWrap>It can be helpful when moving goals around the goal map, or when you want to work on a small branch of the goal map.</Typography>
                        </div>
                    )}
                    {showActionsMenu && (
                        <ActionsMenu pristine={pristine} className={classes.actionsMenuTrigger} uri={uri} />
                    )}
                </StageDropable>
            </DndProvider>
            <Tutorial
                event={tutorialEvent} />
            <Backdrop open={isSyncing} className={classes.backdrop}>
                {hasError ? (
                    <Dialog onClose={onDismissError} open>
                        <DialogTitle>Sorry, something went wrong.</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{errorMessage}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={onSave} autofocus>Retry</Button>
                        </DialogActions>
                    </Dialog>
                ) : <CircularProgress color="inherit" />}
            </Backdrop>
        </React.Fragment>
    )
}

const DragBehaviourIcon = ({dragBehaviour, ...rest}) => dragBehaviour === 'move' ? <FlipToFrontIcon {...rest} /> : <FlipToBackIcon {...rest} />
const dragBehaviourName = (dragBehaviour) => dragBehaviour === 'move' ? 'Move' : 'Clone'
const dragBehaviourTitle = (dragBehaviour) => dragBehaviour === 'move' ? 'Goals will be moved as you drag them' : 'Goals will be cloned as you drag them'

const GreedyBehaviourIcon = ({greedyBehaviour, ...rest}) => greedyBehaviour === 'greedy' ? <GridOnIcon {...rest} /> : <GridOffIcon {...rest} />
const greedyBehaviourName = (greedyBehaviour) => greedyBehaviour === 'greedy' ? 'Greedy' : 'Non-Greedy'
const greedyBehaviourTitle = (greedyBehaviour) => greedyBehaviour === 'greedy' ? 'The selected goal and its children will be dragged' : 'Only the selected goal will be dragged'


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    content: {
        flex: 1,
        position: 'relative',
    },
    guide: {
        backgroundColor: COLOR_BLUE,
        bottom: 0,
        left: `calc(50% - ${(LAYOUT_NODE_WIDTH / 2) + (LAYOUT_NODE_SPACING_X / 2)}px)`,
        position: 'absolute',
        top: 0,
        width: LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X,
    },
    controls: {
        backgroundColor: COLOR_WHITE,
        borderBottomColor: COLOR_GREY_LIGHT,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        display: 'grid',
        gridColumnGap: theme.spacing(1),
        gridTemplateColumns: 'repeat(4, auto) 1fr repeat(2, auto)',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        position: 'relative',
        zIndex: 1,

        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, auto) 30px auto 1fr repeat(3, auto)',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    extendedIcon: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
        },
    },
    controlSpacing: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
        },
    },
    return: {
        left: 20,
        position: 'absolute',
        top: HEADER_HEIGHT + CONTROLS_HEIGHT + 20,
    },
    emptyTray: {
        backgroundColor: COLOR_WHITE,
        borderTopColor: COLOR_GREY_LIGHT,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        display: 'flex',
        flexDirection: 'column',
        height: TRAY_HEIGHT,
        justifyContent: 'center',
        paddingBottom: LAYOUT_NODE_SPACING_Y,
        paddingLeft: LAYOUT_NODE_SPACING_X,
        paddingRight: LAYOUT_NODE_SPACING_X + 80,
        paddingTop: LAYOUT_NODE_SPACING_Y,
        position: 'relative',
        zIndex: 1,
    },
    backdrop: ({isSyncing}) => isSyncing ? ({
        color: COLOR_WHITE,
        zIndex: 1101,
    }) : {},
    actionsMenuTrigger: {
        bottom: 20,
        position: 'absolute',
        right: 20,
    },
    tray: {
        backgroundColor: COLOR_WHITE,
        borderTopColor: COLOR_GREY_LIGHT,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        display: 'grid',
        gridAutoColumns: LAYOUT_NODE_WIDTH,
        gridAutoRows: LAYOUT_NODE_HEIGHT,
        gridAutoFlow: 'column',
        gridColumnGap: LAYOUT_NODE_SPACING_X,
        gridRowGap: LAYOUT_NODE_SPACING_Y,
        gridTemplateColumns: 0,
        height: TRAY_HEIGHT,
        overflowX: 'auto',
        paddingBottom: LAYOUT_NODE_SPACING_Y,
        paddingTop: LAYOUT_NODE_SPACING_Y,
        position: 'relative',
        zIndex: 1,

        '&:before': {
            content: '""',
            width: 1,
        },

        '&:after': {
            content: '""',
            width: 1,
        },
    },
}))

Layout.defaultProps = {
    canRedo: false,
    canUndo: false,
    errorMessage: '',
    hasError: false,
    isBusy: false,
    isReady: false,
    isSyncing: false,
    pristine: true,
    showActions: true,
}

Layout.propTypes = {
    busy: PropTypes.bool,
    canRedo: PropTypes.bool,
    canUndo: PropTypes.bool,
    dragBehaviour: PropTypes.oneOf([
        'clone', 'move',
    ]).isRequired,
    errorMessage: PropTypes.string,
    goals: PropTypes.object.isRequired,
    greedyBehaviour: PropTypes.oneOf([
        'greedy', 'non-greedy',
    ]),
    hasError: PropTypes.bool,
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    isSyncing: PropTypes.bool,
    layout: PropTypes.shape({
        instances: PropTypes.object.isRequired,
        rows: PropTypes.arrayOf(PropTypes.shape({
            items: PropTypes.array.isRequired,
            offset: PropTypes.number.isRequired,
        })).isRequired,
        offset: PropTypes.number.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    onDismissError: PropTypes.func,
    onDrag: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    onOrphanDelete: PropTypes.func.isRequired,
    onPickup: PropTypes.func.isRequired,
    onRedo: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onToggleDragBehaviour: PropTypes.func.isRequired,
    onToggleGreedyBehaviour: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
    orphanedInstances: PropTypes.array.isRequired,
    pristine: PropTypes.bool,
    showActionsMenu: PropTypes.bool,
    tutorialEvent: PropTypes.string,
    windowSize: PropTypes.shape({
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
}

export default Layout
