import PropTypes from 'prop-types'

export const historyShape = PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
})

export const labelMatchShape = PropTypes.shape({
    params: PropTypes.shape({
        label: PropTypes.string.isRequired,
        map: PropTypes.string.isRequired,
    }).isRequired,
})

export const mapMatchShape = PropTypes.shape({
    params: PropTypes.shape({
        map: PropTypes.string.isRequired,
    }).isRequired,
})

export const nodeMatchShape = PropTypes.shape({
    params: PropTypes.shape({
        map: PropTypes.string.isRequired,
        node: PropTypes.string.isRequired,
    }).isRequired,
})
