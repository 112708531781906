import React from 'react'
import { connect } from 'react-redux'

import Edit from '../../components/Resource/Edit'
import { updateResource } from '../../actions/resource'

class EditContainer extends React.Component {
    state = {
        busy: false,
        canSubmit: false,
    }

    render () {
        return (
            <Edit
                busy={this.state.busy}
                canSubmit={this.state.canSubmit}
                onClose={this._handleClose}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                resource={this.props.resources[this.props.match.params.resource]} />
        )
    }

    _handleClose = () => {
        if (this.props.location.state && this.props.location.state.modal) {
            this.props.history.goBack()
        } else {
            this.props.history.push(`${this.props.match.params.view}/${this.props.match.params.map}/g/${this.props.match.params.node}/resource`)
        }
    }

    _handleSubmit = (formData) => {
        this.setState({busy: true})

        this.props.update(formData).then(() => this._handleClose()).catch(({response}) => {
            this.setState({busy: false})
            alert(response.data.errors)
        })
    }

    _handleInvalid = () => this.setState({canSubmit: false})
    _handleValid = () => this.setState({canSubmit: true})
}

const mapStateToProps = (state, ownProps) => ({
    resources: state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    update: (data) => dispatch(updateResource(ownProps.match.params.node, ownProps.match.params.resource, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContainer)
