import { REHYDRATE } from 'redux-persist'

import { RECEIVE_COMMENT, RECEIVE_COMMENTS, REMOVE_COMMENT } from '../actions/comment'

const initialState = {
    comment: '',
    created_at: null,
    created_by: null,
    id: null,
    liked: false,
    pinned: false,
    resources: [],
}

const commentReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_COMMENT]: () => ({
            ...state,
            ...action.payload.comment,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const commentsReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_COMMENT]: () => ({
            ...state,
            [action.payload.comment.id]: commentReducer(state[action.payload.comment.id], action),
        }),
        [RECEIVE_COMMENTS]: () => action.payload.comments.reduce((carry, comment) => ({
            ...carry,
            [comment.id]: commentReducer(state[comment.id], {
                type: RECEIVE_COMMENT,
                payload: {
                    comment,
                },
            }),
        }), {}),
        [REMOVE_COMMENT]: () => {
            const nextState = Object.assign({}, state)
            delete nextState[action.payload.id]

            return nextState
        },
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default commentsReducer
