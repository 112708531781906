import React from 'react'
import { connect } from 'react-redux'

import ResourcesManage from '../../components/Goal/ResourcesManage'
import { nodeFromUri } from '../../actions/node'
import { prompt } from '../../actions/ui'
import { destroyResource, resourcesForNode, updateResourceOrder } from '../../actions/resource'

class ResourcesManageContainer extends React.Component {
    state = {
        resourceIds: [],
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (! nodeFromUri(nextProps.nodes, nextProps.match.params.node)) {
            return null
        }

        if (! Array.isArray(prevState.resources) || prevState.resources.length !== resourcesForNode(nextProps.resources, nodeFromUri(nextProps.nodes, nextProps.match.params.node).id).length) {
            return {
                resourceIds: resourcesForNode(nextProps.resources, nodeFromUri(nextProps.nodes, nextProps.match.params.node).id).map(({id}) => id)
            }
        }

        return null
    }

    render () {
        return (
            <ResourcesManage
                mapUri={this.props.match.params.map}
                nodeUri={this.props.match.params.node}
                onDelete={this._handleDelete}
                onDropItem={this._handleDropItem}
                onMoveItem={this._handleMoveItem}
                resources={mappedResources(this.props, this.state)}
                view={this.props.match.params.view} />
        )
    }

    _handleMoveItem = (source, dragIndex, hoverIndex) => {
        const resources = this.state[source].filter((id, i) => i !== dragIndex)

        this.setState({
            [source]: [
                ...resources.slice(0, hoverIndex),
                this.state[source][dragIndex],
                ...resources.slice(hoverIndex),
            ]
        })
    }

    _handleDelete = (event) => this.props.destroy(event.currentTarget.value)
    _handleDropItem = (source) => this.props.updateOrder(this.state[source].map((id, order) => ({id, order})))
}

const mappedResources = ({resources}, {resourceIds}) => resourceIds.map((id) => resources[id])

const mapStateToProps = (state, ownProps) => ({
    nodes: state.nodes,
    resources: state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    destroy: (id) => dispatch(prompt('Delete this resource?', 'Are you sure you want to delete this resource? This action cannot be undone', destroyResource(ownProps.match.params.node, id))),
    updateOrder: (items) => dispatch(updateResourceOrder(ownProps.match.params.node, items)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesManageContainer)
