import React from 'react'
import { connect }  from 'react-redux'

import Layout from '../../components/Map/View/Layout'
import PageLoadingIndicator from '../../components/PageLoadingIndicator'
import { HEADER_HEIGHT } from '../../constants/layout'
import { RowsBuilder, computeBaseYOffset, focalInstanceIdFromOffset, itemsForMap } from '../../actions/build'
import { alert } from '../../actions/ui'
import { fetchLabels } from '../../actions/label'
import { fetchMap } from '../../actions/map'
import { fetchNodesForMap } from '../../actions/node'
import { fetchUsersForTeam } from '../../actions/user'
import { isEqual } from 'lodash-es'
import { stashMapLayout } from '../../actions/stashes'

class ViewContainer extends React.Component {
    state = {
        isReady: false,
        mapId: null,
        windowSize: {
            height: window.innerHeight,
            width: window.innerWidth,
        },
    }

    _handlingResize = false

    componentDidMount () {
        window.addEventListener('resize', this._handleResize)

        this._processChanges(this.props, this.state, true)
    }

    componentDidUpdate (prevProps, prevState) {
        this._processChanges(prevProps, prevState)
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this._handleResize)
    }

    render () {
        const map = this.props.maps[this.props.match.params.map] || {}

        if (! this.state.isReady) {
            return (
                <PageLoadingIndicator />
            )
        }

        return (
            <Layout
                defaultInstanceId={map.default_instance_id}
                goals={this.state.goals}
                layout={this.state.layout}
                onGoalClick={this._handleGoalClick}
                onGoalDoubleClick={this._handleGoalDoubleClick}
                onResetFocalInstance={this._handleResetFocalInstance}
                onSetRowOffset={this._handleSetRowOffset}
                onSetScrollingRowIndex={this._handleSetScrollingRowIndex}
                onSetStageOffset={this._handleSetStageOffset}
                showBuildTrigger={showBuildTrigger(this.props)}
                uri={this.props.match.params.map} />
        )
    }

    _handleGoalClick = (uri) => {
        this.props.history.push(`/m/${this.props.match.params.map}/g/${uri}`)
    }

    _handleGoalDoubleClick = (uri, instanceId) => {
        this._rowsBuilder.setFocalInstanceId(instanceId)

        this.setState({
            layout: this._rowsBuilder.layout,
        })

        this._handleStashLayout()
    }

    _handleResetFocalInstance = () => {
        this._rowsBuilder.resetFocalInstanceId()

        this.setState({
            layout: this._rowsBuilder.layout,
        })

        this._handleStashLayout()
    }

    _handleResize = (event) => {
        if (this._handlingResize) {
            return
        }

        this._handlingResize = true
        this._windowSize = {
            height: event.target.innerHeight,
            width: event.target.innerWidth,
        }

        window.requestAnimationFrame(this._handleResizeDebounced)
    }

    _handleResizeDebounced = () => {
        this._rowsBuilder.setWindowSize(this._windowSize)

        this.setState({
            layout: {
                ...this.state.layout,
                ...this._rowsBuilder.layout,
            },
            windowSize: this._windowSize,
        })

        this._handlingResize = false
    }

    _handleSetRowOffset = (rowIndex, offset, final = false) => {
        this._rowsBuilder.setRowOffset(rowIndex, offset, final)

        this.setState({
            layout: this._rowsBuilder.layout,
        })

        this._handleStashLayout()
    }

    _handleSetScrollingRowIndex = (rowIndex) => this._rowsBuilder.setScrollingRowIndex(rowIndex)

    _handleSetStageOffset = (offset) => {
        this._rowsBuilder.setStageOffset(offset)

        this.setState({
            layout: this._rowsBuilder.layout,
        })

        this._handleStashLayout()
    }

    _handleStashLayout = () => {
        if (this._handlingStashLayout) {
            clearTimeout(this._handlingStashLayout)
        }

        this._handlingStashLayout = setTimeout(this._handleStashLayoutDebounced, 100)
    }

    _handleStashLayoutDebounced = () => {
        if (! this._rowsBuilder) {
            return
        }

        this.props.stashLayout(this._rowsBuilder.stashableLayout)
    }

    _processChanges = (prevProps, prevState, willMount) => {
        if (willMount || mapUriHasChanged(this.props, prevProps)) {
            this.setState({
                isReady: false,
            })

            this.props.fetch().then(({uri}) => {
                this._processMap(uri)
                this._processLabelsForMap()
                this._processNodesForMap()
                this._processUsersForMap(uri)
            }).catch((error) => {
                if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                    return this.props.alert('This goal map does not exist.', () => this.props.history.push('/m'))
                }

                throw new Error(error)
            })
        }

        if (this._rowsBuilder && ! isEqual(this.props.nodes, prevProps.nodes)) {
            const goals = itemsForMap(this.props.nodes, this.props.match.params.map)

            this._rowsBuilder.replaceGoals(Object.keys(goals).reduce((carry, uri) => ({
                ...carry,
                [goals[uri].id]: goals[uri],
            }), {}))

            this.setState({
                goals: this._rowsBuilder.goals,
            })
        }
    }

    _processMap = (uri) => {
        if (! this.props.maps[uri]) {
            return
        }

        const goals = itemsForMap(this.props.nodes, uri)
        const stash = this.props.stashes.mapLayouts[uri]

        this._rowsBuilder = new RowsBuilder({
            ...this.state.layout,
            focalInstanceId: stash ? stash.focalInstanceId : this.props.maps[uri].default_instance_id,
            goals: Object.keys(goals).reduce((carry, uri) => ({
                ...carry,
                [goals[uri].id]: goals[uri],
            }), {}),
            indexes: stash ? stash.indexes : [],
            instances: itemsForMap(this.props.instances, uri, ({node_id}) => node_id),
            offset: stash ? stash.offset : computeBaseYOffset(window.innerHeight - HEADER_HEIGHT),
            relations: itemsForMap(this.props.instances, uri, ({parents, children}) => ({
                parents, children,
            })),
        }, this.state.windowSize)

        this._rowsBuilder.rebuild()

        this.setState({
            goals: this._rowsBuilder.goals,
            isReady: true,
            layout: this._rowsBuilder.layout,
        })
    }

    _processLabelsForMap = () => {
        this.props.fetchLabels()
    }

    _processNodesForMap = (page = 1) => {
        this.props.fetchNodes(page).then(({meta}) => {
            if (meta.current_page >= meta.last_page) {
                return
            }

            this._processNodesForMap(meta.current_page + 1)
        })
    }

    _processUsersForMap = (uri) => {
        this.props.fetchUsers(this.props.maps[uri].team_uri)
    }
}

const goals = ({match, nodes}) => itemsForMap(nodes, match.params.map)
const mapUriHasChanged = (props, prevProps) => props.match.params.map !== prevProps.match.params.map
const showBuildTrigger = ({auth, maps, match}) => maps[match.params.map] && maps[match.params.map].created_by === auth.userId

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    instances: state.instances,
    maps: state.maps,
    nodes: state.nodes,
    stashes: state.stashes,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    alert: (message, dismissMethod) => dispatch(alert(null, message, dismissMethod)),
    fetch: () => dispatch(fetchMap(ownProps.match.params.map)),
    fetchLabels: () => dispatch(fetchLabels(ownProps.match.params.map)),
    fetchNodes: (page) => dispatch(fetchNodesForMap(ownProps.match.params.map, page, 100)),
    fetchUsers: (teamUri) => dispatch(fetchUsersForTeam(teamUri)),
    stashLayout: (data) => dispatch(stashMapLayout(ownProps.match.params.map, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewContainer)
