import { REHYDRATE } from 'redux-persist'

import {
    STASH_MAP, UNSTASH_MAP, STASH_MAP_LAYOUT, UNSTASH_MAP_LAYOUT, STASH_TARGET, UNSTASH_TARGET,
} from '../actions/stashes'

const initialState = {
    mapLayouts: {},
    maps: {},
    targets: {},
}

export default (state = initialState, action) => {
    const actions = {
        [REHYDRATE]: () => ({
            ...state,
            ...(action.payload && action.payload.stashes ? action.payload.stashes : {}),
        }),
        [STASH_MAP]: () => ({
            ...state,
            maps: {
                ...state.maps,
                [action.payload.uri]: action.payload.data,
            },
        }),
        [UNSTASH_MAP]: () => ({
            ...state,
            maps: Object.keys(state.maps).filter((item) => item !== action.payload.uri).reduce((carry, item) => ({
                ...carry,
                [item]: state.maps[item],
            }), {})
        }),
        [STASH_MAP_LAYOUT]: () => ({
            ...state,
            mapLayouts: {
                ...state.mapLayouts,
                [action.payload.uri]: action.payload.data,
            },
        }),
        [UNSTASH_MAP_LAYOUT]: () => ({
            ...state,
            mapLayouts: Object.keys(state.mapLayouts).filter((item) => item !== action.payload.uri).reduce((carry, item) => ({
                ...carry,
                [item]: state.mapLayouts[item],
            }), {})
        }),
        [STASH_TARGET]: () => ({
            ...state,
            targets: {
                ...state.targets,
                [action.payload.nodeUri]: ({
                    ...state.targets[action.payload.nodeUri],
                    ...action.payload.data,
                })
            },
        }),
        [UNSTASH_TARGET]: () => ({
            ...state,
            targets: Object.keys(state.targets).filter((item) => item !== action.payload.nodeUri).reduce((carry, item) => ({
                ...carry,
                [item]: state.targets[item],
            }), {})
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}
