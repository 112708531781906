import PropTypes from 'prop-types'
import React, { memo, useEffect } from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDrag } from 'react-dnd'

import Goal from './Goal'
import { LAYOUT_NODE_HEIGHT, LAYOUT_NODE_WIDTH } from '../../../constants/layout'

const GoalDraggable = ({columnIndex, dragBehaviour, id, instanceId, name, onDrop, onPickup, rowIndex, ...rest}) => {
    const [ { isDragging }, drag, preview ] = useDrag({
        item: {
            type: 'goal',
            columnIndex, id, instanceId, name, rowIndex,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: (monitor) => {
            setTimeout(() => onPickup({rowIndex, columnIndex}, instanceId), 100)
        },
        end: (item, monitor) => onDrop(monitor.getDropResult(), item),
    })

    useEffect(() => {
        preview(getEmptyImage())
    }, [])

    return Boolean(isDragging && dragBehaviour === 'move') ? null : (
        <Goal
            columnIndex={columnIndex}
            dragBehaviour={dragBehaviour}
            id={id}
            instanceId={instanceId}
            ref={drag}
            name={name}
            rowIndex={rowIndex}
            {...rest} />
    )
}

const styles = {
    filler: {
        height: LAYOUT_NODE_HEIGHT,
        width: LAYOUT_NODE_WIDTH,
    },
}

GoalDraggable.propTypes = {
    columnIndex: PropTypes.number,
    dragBehaviour: PropTypes.oneOf([
        'clone', 'move',
    ]).isRequired,
    id: PropTypes.string.isRequired,
    instanceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    onPickup: PropTypes.func.isRequired,
    rowIndex: PropTypes.number,
}

export default GoalDraggable
