import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import React, { Fragment, memo, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import FormsyCheckbox from '../formsy/FormsyCheckbox'
import FormsyText from '../formsy/FormsyText'
import LoadingButton from '../LoadingButton'
import { updateTarget } from '../../actions/target'

const UpdateManual = memo(({className, id, format}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [ hasChanged, setHasChanged ] = useState(false)
    const [ isBusy, setIsBusy ] = useState(false)
    const [ isValid, setIsValid ] = useState(false)

    const canSubmit = Boolean(hasChanged && isValid)

    const _handleSubmit = (formData, resetForm, invalidateForm) => {
        setIsBusy(true)

        dispatch(updateTarget(id, formData)).then(() => resetForm()).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setIsBusy(false))
    }

    const _handleChange = (formData, hasChanged) => setHasChanged(hasChanged)
    const _handleInvalid = () => setIsValid(false)
    const _handleValid = () => setIsValid(true)

    return (
        <div className={className}>
            <Typography className={classes.heading} variant="subtitle1">Set the current target value:</Typography>
            <Formsy className={classes.wrapper} onChange={_handleChange} onInvalid={_handleInvalid} onValid={_handleValid} onValidSubmit={_handleSubmit}>
                <Input
                    format={format}
                    label="Current value"
                    margin="none"
                    name="value" />
                <LoadingButton busy={isBusy} color="primary" disabled={! canSubmit} type="submit" variant="contained">
                    Update
                </LoadingButton>
            </Formsy>
        </div>
    )
})

const Input = ({format, ...rest}) => {
    if (format === 'boolean') {
        return (
            <FormsyCheckbox
                {...rest} />
        )
    }

    return (
        <FormsyText
            {...rest}
            required />
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        alignItems: 'center',
        display: 'grid',
        gridColumnGap: theme.spacing(4),
        gridRowGap: theme.spacing(2),
        gridTemplateColumns: 'repeat(auto-fit, minmax(10px, max-content))',
    },
    heading: {
        fontWeight: '500',
        marginBottom: theme.spacing(2),
    },
}))

UpdateManual.defaultProps = {
    className: '',
}

UpdateManual.propTypes = {
    className: PropTypes.string,
    id: PropTypes.number.isRequired,
    format: PropTypes.oneOf([
        'boolean', 'decimal', 'integer',
    ]).isRequired,
}

export default UpdateManual
