import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const LoadingButton = ({busy, containerClassName, containerStyle, disabled, fullWidth, hideWhenBusy, ...rest}) => {
    const classes = useStyles({fullWidth})

    return (
        <div className={`${classes.wrapper} ${containerClassName}`} style={containerStyle}>
            {Boolean(! busy || ! hideWhenBusy) && (
                <Button
                    {...rest}
                    disabled={disabled || busy}
                    fullWidth />
            )}
            {busy && <CircularProgress className={classes.progress} size={24} />}
        </div>
    )
}

LoadingButton.defaultProps = {
    busy: false,
    containerClassName: '',
    containerStyle: {},
    disabled: false,
    fullWidth: false,
    hideWhenBusy: false,
}

LoadingButton.propTypes = {
    busy: PropTypes.bool,
    containerClassName: PropTypes.string,
    containerStyle: PropTypes.object,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideWhenBusy: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
    wrapper: ({fullWidth}) => ({
        display: 'inline-block',
        position: 'relative',
        width: fullWidth ? '100%' : 'auto',
    }),
    progress: {
        left: '50%',
        marginLeft: -12,
        marginTop: -12,
        position: 'absolute',
        top: '50%',
    },
}))

export default LoadingButton
