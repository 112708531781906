import React from 'react'
import { connect } from 'react-redux'

import UserForm from '../components/UserForm'
import { fetchUser, updateUserForTeam, userFromId } from '../actions/user'

class UserEditContainer extends React.Component {
    state = {
        hasChanged: false,
        hasFetched: false,
        isBusy: false,
        isValid: false,
    }

    componentDidMount () {
        this.props.fetch().then(() => this.setState({
            hasFetched: true,
        }))
    }

    render () {
        const user = userFromId(this.props.users, this.props.match.params.user)

        return (
            <UserForm
                canSubmit={Boolean(this.state.hasChanged && this.state.isValid)}
                isBusy={this.state.isBusy}
                isReady={Boolean(this.state.hasFetched || user.id)}
                onChange={this._handleChange}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                saveLabel="Update"
                teamPermissions={user.teams_index[this.props.match.params.team]}
                title={`Edit ${user.name}`}
                user={user} />
        )
    }

    _handleSubmit = (formData, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.update(formData).then(this.props.history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => this.setState({
            isBusy: false,
        }))
    }

    _handleChange = (formData, hasChanged) => this.setState({hasChanged})
    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const mapStateToProps = (state, ownProps) => ({
    teams: state.teams,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: (user) => dispatch(fetchUser(ownProps.match.params.user)),
    update: (user) => dispatch(updateUserForTeam(user, ownProps.match.params.team, ownProps.match.params.user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer)
