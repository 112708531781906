import React from 'react'
import { compareDesc, isEqual, parseISO } from 'date-fns'
import { connect } from 'react-redux'

import List from '../../components/Map/List'
import { fetchLabels, labelsForMap } from '../../actions/label'
import { fetchMap, mapFromUri, setMapDashboardLayout } from '../../actions/map'
import { fetchNodesForMap } from '../../actions/node'
import { fetchUsersForTeam } from '../../actions/user'
import { teamUsers } from '../../actions/team'

class ListContainer extends React.Component {
    defaultState = {
        currentFilters: [],
        currentSort: 'updated_at',
        currentPage: 1,
        hasFetched: false,
        hasEnriched: false,
    }

    state = {
        ...this.defaultState,
        enrichedGoalIds: [],
        mapsLabelsFetchedFor: [],
        teamsUsersFetchedFor: [],
    }

    componentDidMount () {
        this.setup()
    }

    componentDidUpdate (prevProps) {
        if (prevProps.match.params.map !== this.props.match.params.map) {
            this.setup()
        }
    }

    setup () {
        this.setState(this.defaultState)

        this.props.fetch().then(({team_uri, uri}) => {
            this.setState({hasFetched: true})

            this.getPage()

            if (! this.state.mapsLabelsFetchedFor.includes(uri)) {
                this.props.fetchLabels().then(() => this.setState({
                    mapsLabelsFetchedFor: [
                        ...this.state.mapsLabelsFetchedFor,
                        uri,
                    ]
                }))
            }

            if (! this.state.teamsUsersFetchedFor.includes(team_uri)) {
                this.props.fetchUsers(team_uri).then(() => this.setState({
                    teamsUsersFetchedFor: [
                        ...this.state.teamsUsersFetchedFor,
                        team_uri,
                    ],
                }))
            }
        }).catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                return this.props.history.push('/m')
            }

            throw new Error(error)
        })
    }

    getPage (page = 1) {
        this.props.fetchGoals(page).then(({data, meta}) => {
            this.setState({
                enrichedGoalIds: [
                    ...this.state.enrichedGoalIds,
                    ...data.map(({id}) => id),
                ],
            })

            if (meta.last_page > meta.current_page) {
                this.getPage(meta.current_page + 1)
            } else {
                this.setState({hasEnriched: true})
            }
        })
    }

    render () {
        return (
            <List
                awaitingRows={Boolean(! this.state.hasEnriched && this.state.currentFilters.length)}
                currentFilters={this.state.currentFilters}
                currentSort={this.state.currentSort}
                emptyMessage={emptyMessage(this.state.currentFilters)}
                filters={filters(this.props)}
                goals={enrichedGoals(this.props, this.state)}
                isFiltersLoading={Boolean(! this.state.mapsLabelsFetchedFor.includes(this.props.match.params.map) || ! this.state.teamsUsersFetchedFor.includes(mapFromUri(this.props.maps, this.props.match.params.map).team_uri))}
                isReady={this.state.hasFetched}
                mapUri={this.props.match.params.map}
                onFiltersChange={this._handleFiltersChange}
                onSortChange={this._handleSortChange}
                users={this.props.users} />
        )
    }

    _handleFiltersChange = (event, currentFilters) => this.setState({currentFilters})
    _handleSortChange = (event) => this.setState({currentSort: event.target.value})
}

const enrichedGoals = ({labels: rawLabels, match, nodes}, {currentFilters, currentSort, enrichedGoalIds}) => Object.values(nodes).filter(({assigned_user, labels, map_uri, watching}) => {
    if (map_uri !== match.params.map) {
        return false
    }

    if (! currentFilters.length) {
        return true
    }

    const currentFilterIds = currentFilters.map(({id}) => id)

    return labels.filter(({id}) => currentFilterIds.filter((id) => id.indexOf('label') === 0).map((id) => parseInt(id.substr(6))).includes(id)).length > 0 || currentFilterIds.filter((id) => id.indexOf('owner') === 0).map(({id}) => parseInt(id.substr(6))).includes(assigned_user) || (currentFilterIds.includes('extras.watching') && watching) || (currentFilterIds.includes('extras.unassigned') && assigned_user === null)
}).sort((a, b) => {
    if (['target_due', 'updated_at'].includes(currentSort)) {
        return isEqual(parseISO(a[currentSort]), parseISO(b[currentSort])) ? a.name.localeCompare(b.name) : compareDesc(parseISO(a[currentSort]), parseISO(b[currentSort]))
    }

    if (['priority'].includes(currentSort)) {
        return a[currentSort] < b[currentSort] ? 1 : -1
    }

    return a[currentSort].localeCompare(b[currentSort])
}).map(({labels, target_duration: duration, target_progress: progress, ...rest}) => ({
    ...rest,
    enriched: enrichedGoalIds.includes(rest.id),
    labels: labels.filter(({id}) => rawLabels[id] !== undefined).map(({id}) => rawLabels[id]),
    target: {
        duration, progress,
    },
}))

const filters = ({labels, users}) => [
    ...(Object.values(users).sort(({name: aName}, {name: bName}) => aName.localeCompare(bName))).map(({id, name}) => ({
        group: 'Users',
        id: `owner.${id}`,
        label: name,
    })),
    ...(Object.values(labels).sort(({name: aName}, {name: bName}) => aName.localeCompare(bName))).map(({id, name}) => ({
        group: 'Labels',
        id: `label.${id}`,
        label: name,
    })),
    {
        group: 'Extras',
        id: `extras.watching`,
        label: 'Watched Goals',
    },
    {
        group: 'Extras',
        id: `extras.unassigned`,
        label: 'Unassigned Goals',
    },
]

const emptyMessage = (filters) => filters.length ? 'No goals match your selected filters.' : 'This goal map doesn’t have any goals.'

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    labels: state.labels,
    maps: state.maps,
    nodes: state.nodes,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchMap(ownProps.match.params.map)),
    fetchGoals: (page) => dispatch(fetchNodesForMap(ownProps.match.params.map, page, 20)),
    fetchLabels: () => dispatch(fetchLabels(ownProps.match.params.map)),
    fetchUsers: (uri) => dispatch(fetchUsersForTeam(uri)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
