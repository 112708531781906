export const LAYOUT_NODE_HEIGHT = 150
export const LAYOUT_NODE_WIDTH = 240
export const LAYOUT_NODE_SPACING_X = 30
export const LAYOUT_NODE_SPACING_Y = 30
export const LAYOUT_NODE_PADDING = 10
export const LAYOUT_SHADOW_SPACING = 3

export const CONTROLS_HEIGHT = 61
export const HEADER_HEIGHT = 66
export const TRAY_HEIGHT = 211
