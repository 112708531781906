import React from 'react'
import { connect } from 'react-redux'
import { v1 as uuid } from 'uuid'

import Layout from '../../components/Map/Build/Layout'
import { CONTROLS_HEIGHT, HEADER_HEIGHT, LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH, TRAY_HEIGHT } from '../../constants/layout'
import { RowsBuilder, computeBaseXOffset, computeBaseYOffset, isEqual, itemsForMap, isValidGoalName } from '../../actions/build'
import { fetchMap, storeMap, updateMap } from '../../actions/map'
import { stashMap, stashMapLayout, unstashMap } from '../../actions/stashes'
import { prompt } from '../../actions/ui'
import { store } from '../../configureStore'

class BuildContainer extends React.Component {
    _handlingResize = false
    _hoverPosition = false
    _routesHandler = process.env.REACT_APP_ROUTES_HANDLER === 'undefined' ? 'default' : process.env.REACT_APP_ROUTES_HANDLER
    _windowSize = {
        height: window.innerHeight,
        width: window.innerWidth,
    }

    _rowsBuilder = new RowsBuilder({
        offset: computeBaseYOffset(window.innerHeight - CONTROLS_HEIGHT - HEADER_HEIGHT - TRAY_HEIGHT),
    }, this._windowSize)

    state = {
        errorMessage: '',
        goals: this._rowsBuilder.goals,
        hasError: false,
        hoverPosition: false,
        isBusy: false,
        isReady: false,
        isSyncing: false,
        layout: this._rowsBuilder.layout,
        pristineIndex: 0,
        tutorialEvent: null,
        windowSize: this._windowSize,
    }

    componentDidMount () {
        window.addEventListener('resize', this._handleResize)

        if (this.props.match.params.map !== undefined) {
            this._processChanges(this.props, this.state, true)
        }

        this.setState({
            isReady: this.props.match.params.map === undefined,
            tutorialEvent: this.props.match.params.map === undefined ? 'playingWithBoxes' : null,
        })
    }

    componentDidUpdate (prevProps, prevState) {
        this._processChanges(prevProps, prevState)
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this._handleResize)
    }

    render () {
        return (
            <Layout
                canRedo={this._rowsBuilder.canRedo}
                canUndo={this._rowsBuilder.canUndo}
                dragBehaviour={this._rowsBuilder.dragBehaviour}
                errorMessage={this.state.errorMessage}
                goals={this.state.goals}
                greedyBehaviour={this._rowsBuilder.greedyBehaviour}
                hasError={this.state.hasError}
                isBusy={this.state.isBusy}
                isReady={this.state.isReady}
                isSyncing={this.state.isSyncing}
                layout={this.state.layout}
                onClose={this._handleClose}
                onDismissError={this._handleDismissError}
                onDrag={this._handleDrag}
                onDrop={this._handleDrop}
                onGhostClick={this._handleGhostClick}
                onGoalBlur={this._handleGoalBlur}
                onGoalChange={this._handleGoalChange}
                onGoalClick={this._handleGoalClick}
                onGoalDoubleClick={this._handleGoalDoubleClick}
                onMouseMove={this._handleMouseMove}
                onMouseOut={this._handleMouseOut}
                onOrphanDelete={this._handleOrphanDelete}
                onPickup={this._handlePickup}
                onRedo={this._handleRedo}
                onSave={this._handleSave}
                onSetRowOffset={this._handleSetRowOffset}
                onSetScrollingRowIndex={this._handleSetScrollingRowIndex}
                onSetStageOffset={this._handleSetStageOffset}
                onToggleDragBehaviour={this._handleToggleDragBehaviour}
                onToggleGreedyBehaviour={this._handleToggleGreedyBehaviour}
                onUndo={this._handleUndo}
                orphanedInstances={this._rowsBuilder.orphanedInstances}
                pristine={this._rowsBuilder.snapshotIndex === this.state.pristineIndex}
                showActionsMenu={Boolean(this._routesHandler !== 'sandbox' && this.props.match.params.map)}
                tutorialEvent={this.state.tutorialEvent}
                uri={this.props.match.params.map}
                windowSize={this.state.windowSize} />
        )
    }

    _confirmClose = () => this.props.history.push(`${this._routesHandler === 'sandbox' ? '/' : (this.props.match.params.map ? `/m/${this.props.match.params.map}` : '/m')}`)

    _handleClose = () => {
        if (this._rowsBuilder.snapshotIndex === this.state.pristineIndex) {
            return this._confirmClose()
        }

        this.props.prompt('Discard all changes to this goal map?', 'Discarding will lose all changes you\'ve made to this goal map since it was last saved.', this._confirmClose)
    }

    _handleDismissError = () => this.setState({
        errorMessage: '',
        hasError: false,
        isBusy: false,
        isSyncing: false,
    })

    _handleDrag = (offset, item) => this._handleGhosts(
        this._rowsBuilder.getGoalPositionFromDrag(offset, this._hoverPosition)
    )

    _handleDrop = ({offset}, {id, instanceId}) => {
        const position = this._rowsBuilder.getGoalPositionFromDrag(offset, this._hoverPosition)

        if (position === false) {
            this.setState({tutorialEvent: 'droppedItLikeItsHot'})
        }

        this._rowsBuilder.convertDrag(position, instanceId, id)
        this._hoverPosition = false

        this._rowsBuilder.endDrag()

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleGhostClick = (instanceId) => {
        const id = uuid()

        this._hoverPosition = false

        this._rowsBuilder.convertGhost(instanceId, id)

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleGhosts = (hoverPosition) => {
        const normalizedHoverPosition = hoverPosition === false ? false : {...hoverPosition}
        if (normalizedHoverPosition !== false) {
            if (normalizedHoverPosition.rowIndex < 0) {
                normalizedHoverPosition.rowIndex = 0
            }
            if (normalizedHoverPosition.columnIndex < 0) {
                normalizedHoverPosition.columnIndex = 0
            }

            normalizedHoverPosition.rowIndex = Math.ceil(normalizedHoverPosition.rowIndex)
            normalizedHoverPosition.columnIndex = Math.ceil(normalizedHoverPosition.columnIndex)
        }

        if (! isEqual(normalizedHoverPosition, this._hoverPosition)) {
            if (this._hoverPosition !== false) {
                this._rowsBuilder.removeGhosts(this._hoverPosition)
            }

            if (hoverPosition !== false) {
                this._rowsBuilder.addGhost(hoverPosition)
            }

            this.setState({
                goals: this._rowsBuilder.goals,
                layout: this._rowsBuilder.layout,
            })
        }

        this._hoverPosition = normalizedHoverPosition
    }

    _handleGoalBlur = (id) => {
        this._rowsBuilder.editGoalComplete(id)

        if (Object.values(this._rowsBuilder.goals).filter(({name}) => name !== undefined && name.length > 0).length > 2) {
            this.setState({tutorialEvent: 'triggerHappy'})
        }

        this.setState({
            isBusy: false,
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleGoalChange = (id, name) => {
        this._rowsBuilder.setGoalName(id, name)

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleGoalClick = (id, instanceId) => {
        this._rowsBuilder.editGoal(id, instanceId)

        this.setState({
            goals: this._rowsBuilder.goals,
            isBusy: true,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleGoalDoubleClick = (id, instanceId) => {
        this._rowsBuilder.setFocalInstanceId(instanceId)

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleMouseMove = (event) => {
        if (this.state.isBusy) {
            return
        }

        this._handleGhosts(
            this._rowsBuilder.getGoalPositionFromMouse(event.nativeEvent, this._hoverPosition)
        )
    }

    _handleMouseOut = (event) => {
        if (this._hoverPosition === false) {
            return
        }

        if (event.nativeEvent.y > HEADER_HEIGHT + CONTROLS_HEIGHT && event.nativeEvent.y < this.state.windowSize.height - TRAY_HEIGHT) {
            return
        }

        this._rowsBuilder.removeGhosts()

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })

        this._hoverPosition = false
    }

    _handleOrphanDelete = (event, id, instanceId) => {
        event.stopPropagation()

        this._rowsBuilder.deleteGoal(id, instanceId)

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handlePickup = (position = {}, instanceId) => {
        if (this._rowsBuilder.dragBehaviour === 'clone') {
            return
        }

        this._rowsBuilder.beginDrag(position, instanceId)
        this._rowsBuilder.removeGoal(position, instanceId)
        this._rowsBuilder.rebuild()

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleRedo = () => {
        this._rowsBuilder.redo()

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _handleResize = (event) => {
        if (this._handlingResize) {
            return
        }

        this._handlingResize = true
        this._windowSize = {
            height: event.target.innerHeight,
            width: event.target.innerWidth,
        }

        window.requestAnimationFrame(this._handleResizeDebounced)
    }

    _handleResizeDebounced = () => {
        this._rowsBuilder.setWindowSize(this._windowSize)

        this.setState({
            layout: {
                ...this.state.layout,
                ...this._rowsBuilder.layout,
            },
            windowSize: this._windowSize,
        })

        this._handlingResize = false
    }

    _handleSave = () => {
        this.setState({
            errorMessage: '',
            hasError: false,
            isBusy: true,
            isSyncing: true,
        })

        const data = {
            default_instance_id: this._rowsBuilder.defaultInstanceId,
            instances: this._rowsBuilder.instances,
            nodes: Object.values(this._rowsBuilder.goals).filter(({name}) => isValidGoalName(name)).map(({id, name}) => ({id, name})),
            relations: this._rowsBuilder.relations,
        }

        this.props[this.props.match.params.map ? 'update' : 'store']({
            ...data,
            ...(this.props.match.params.map ? {} : {
                team_uri: this.props.auth.teamUri,
            }),
        }).then(({uri}) => {
            this.setState({
                isBusy: false,
                pristineIndex: this._rowsBuilder.snapshotIndex,
                isSyncing: false,
            })

            this.props.unstash()

            this.props.stashLayout(this._rowsBuilder.stashableLayout)

            if (! this.props.match.params.map) {
                this.props.history.push(`/m/${uri}/build`)
            }
        }).catch((error) => {
            this.props.stash({
                ...this._rowsBuilder.stashableMap,
                uri: this.props.match.params.map,
            })

            let errorMessage = 'An unknown error has occurred'

            if (error.response && error.response.data) {
                if (typeof error.response.data.errors === 'object') {
                    errorMessage = [...new Set(Object.values(error.response.data.errors).map((item) => item.join(', ')))].join(', ')
                } else if (error.response.data.message) {
                    errorMessage = error.response.data.message
                }
            }

            this.setState({
                hasError: true,
                errorMessage,
            })
        })
    }

    _handleSetRowOffset = (rowIndex, offset, final) => {
        this._rowsBuilder.setRowOffset(rowIndex, offset, final)

        this.setState({
            layout: this._rowsBuilder.layout,
        })
    }

    _handleSetScrollingRowIndex = (rowIndex) => this._rowsBuilder.setScrollingRowIndex(rowIndex)

    _handleSetStageOffset = (offset) => {
        this._rowsBuilder.setStageOffset(offset)

        this.setState({
            layout: this._rowsBuilder.layout,
        })
    }

    _handleToggleDragBehaviour = () => {
        this._rowsBuilder.toggleDragBehaviour()

        this.setState({
            tutorialEvent: 'firstTimeInDrag',
        })
    }

    _handleToggleGreedyBehaviour = () => {
        this._rowsBuilder.toggleGreedyBehaviour()

        this.setState({
            tutorialEvent: 'firstTimeBeingSoGreedy',
        })
    }

    _handleUndo = () => {
        this._rowsBuilder.undo()

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })
    }

    _processChanges = (prevProps, prevState, willMount) => {
        if (willMount || mapUriHasChanged(this.props, prevProps)) {
            this.setState({
                isReady: false,
            })

            this.props.fetch().then(({uri}) => this._processExistingMap(uri)).catch((error) => {
                if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                    if (this._routesHandler === 'sandbox') {
                        return this.setState({isReady: true, tutorialEvent: 'playingWithBoxes'})
                    }

                    return this.props.history.push('/m')
                }

                throw new Error(error)
            })
        }
    }

    _processExistingMap = (uri) => {
        if (! this.props.maps[uri]) {
            return
        }

        const goals = itemsForMap(this.props.nodes, uri)
        const stash = this.props.stashes.mapLayouts[uri]

        this._rowsBuilder = new RowsBuilder({
            ...this.state.layout,
            focalInstanceId: stash ? stash.focalInstanceId : this.props.maps[uri].default_instance_id,
            goals: Object.keys(goals).reduce((carry, uri) => ({
                ...carry,
                [goals[uri].id]: goals[uri],
            }), {}),
            indexes: stash ? stash.indexes : [],
            instances: itemsForMap(this.props.instances, uri, ({node_id}) => node_id),
            offset: stash ? stash.offset : computeBaseYOffset(window.innerHeight - CONTROLS_HEIGHT - HEADER_HEIGHT - TRAY_HEIGHT),
            relations: itemsForMap(this.props.instances, uri, ({parents, children}) => ({
                parents, children,
            })),
        }, this.state.windowSize)

        this.setState({
            goals: this._rowsBuilder.goals,
            isReady: true,
            layout: this._rowsBuilder.layout,
            tutorialEvent: Object.keys(itemsForMap(this.props.nodes, uri)).length === 0 ? 'playingWithBoxes' : null,
        })

        if (this.props.stashes.maps[uri]) {
            this.props.prompt('Restore your changes?', 'We found some unsaved changes to this goal map. Would you like to restore them?', this._processStashedMap, this.props.unstash, {
                abortLabel: 'Discard',
                confirmLabel: 'Restore',
            })
        }
    }

    _processStashedMap = () => {
        const stash = this.props.stashes.maps[this.props.match.params.map]
        if (! stash) {
            return
        }

        this._rowsBuilder = new RowsBuilder(stash, this.state.windowSize)

        this.setState({
            goals: this._rowsBuilder.goals,
            layout: this._rowsBuilder.layout,
        })

        this.props.unstash()
    }
}

const mappedRelations = (items, relations) => {
    if (items === undefined || ! items.length) {
        return []
    }

    return [
        ...items,
        ...mappedRelations(
            Object.keys(relations).filter((id) => items.includes(id)).reduce((carry, id) => [
                ...carry,
                ...relations[id],
            ], []),
            relations
        ),
    ]
}

const hasParentRelations = (instanceId, {relations}) => Object.values(relations).filter((items) => items.includes(instanceId)).length
const mapUriHasChanged = (props, prevProps) => props.match.params.map !== prevProps.match.params.map

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    instances: state.instances,
    maps: state.maps,
    nodes: state.nodes,
    stashes: state.stashes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetch: () => dispatch(fetchMap(ownProps.match.params.map)),
    prompt: (title, message, confirmMethod, dismissMethod, options = {}) => dispatch(prompt(title, message, confirmMethod, dismissMethod, options)),
    stash: (data) => dispatch(stashMap(ownProps.match.params.map, data)),
    stashLayout: (data) => dispatch(stashMapLayout(ownProps.match.params.map, data)),
    store: (data) => dispatch(storeMap(data)),
    unstash: () => dispatch(unstashMap(ownProps.match.params.map)),
    update: (data) => dispatch(updateMap(ownProps.match.params.map, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BuildContainer)
