import React from 'react'
import { connect } from 'react-redux'

import Security from '../../components/Auth/Security'
import { updateUser } from '../../actions/user'

class SecurityContainer extends React.Component {
    state = {
        isBusy: false,
        isValid: false,
    }

    render () {
        return (
            <Security
                canSubmit={canSubmit(this.state)}
                hasChanged={this.state.hasChanged}
                isInvalid={this.state.isInvalid}
                isValid={this.state.isValid}
                onInvalid={this._handleInvalid}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid}
                user={this.props.users[this.props.auth.userId]} />
        )
    }

    _handleSubmit = (data, resetForm, invalidateForm) => {
        this.setState({
            isBusy: true,
        })

        this.props.update(this.props.auth.userId, data).then(resetForm).finally(() => this.setState({isBusy: false}))
    }

    _handleInvalid = () => this.setState({isValid: false})
    _handleValid = () => this.setState({isValid: true})
}

const canSubmit = ({isBusy, isValid}) => ! isBusy && isValid

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    update: (id, data) => dispatch(updateUser(id, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityContainer)
