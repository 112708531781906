import React from 'react'
import { compareDesc, parseISO } from 'date-fns'
import { connect } from 'react-redux'

import ResourceCreate from '../../components/Goal/ResourceCreate'
import { nodeFromUri } from '../../actions/node'
import { storeResource } from '../../actions/resource'

class ResourceCreateContainer extends React.Component {
    state = {
        busy: false,
        canSubmit: false,
        existingMenuAnchorEl: null,
        existingResourceId: '',
        method: 'attach',
    }

    render () {
        return (
            <ResourceCreate
                busy={this.state.busy}
                canSubmit={this.state.canSubmit}
                existingMenuAnchorEl={this.state.existingMenuAnchorEl}
                existingResourceId={this.state.existingResourceId}
                existingResourceName={this.state.existingResourceId ? existingResource(this.state.existingResourceId, this.props).resourceable.name : null}
                existingResources={existingResources(this.props)}
                method={this.state.method}
                onClose={this._handleClose}
                onInvalid={this._handleInvalid}
                onMethodChange={this._handleMethodChange}
                onSetExistingResource={this._handleSetExistingResource}
                onToggleExisting={this._handleToggleExisting}
                onSubmit={this._handleSubmit}
                onValid={this._handleValid} />
        )
    }

    _handleClose = () => {
        if (this.props.location.state && this.props.location.state.modal) {
            this.props.history.goBack()
        } else {
            this.props.history.push(`${this.props.match.params.view}/${this.props.match.params.map}/g/${this.props.match.params.node}`)
        }
    }

    _handleSubmit = (formData) => {
        this.setState({busy: true})

        this.props.store(formData).then(() => this._handleClose()).catch(({response}) => {
            this.setState({busy: false})
            alert(response.data.errors)
        })
    }

    _handleInvalid = () => this.setState({canSubmit: false})
    _handleMethodChange = (event) => this.setState({method: event.target.value})
    _handleSetExistingResource = (existingResourceId) => this.setState({existingResourceId, existingMenuAnchorEl: null})
    _handleToggleExisting = (event) => this.setState({existingMenuAnchorEl: this.state.existingMenuAnchorEl ? null : event.currentTarget})
    _handleValid = () => this.setState({canSubmit: true})
}

const existingResources = ({comments, match, nodes}) => {
    const nodeId = nodeFromUri(nodes, match.params.node).id

    return Object.values(comments).filter(({node_id, resources}) => node_id === nodeId && resources.filter(({resourceable_type}) => resourceable_type === 'App\\Attachment').length).sort((a, b) => compareDesc(parseISO(b.created_at), parseISO(a.created_at))).map(({resources}) => resources.filter(({resourceable_type}) => resourceable_type === 'App\\Attachment')).flat()
}

const existingResource = (id, {comments, match, nodes}) => {
    const nodeId = nodeFromUri(nodes, match.params.node).id

    return Object.values(comments).filter(({node_id, resources}) => node_id === nodeId && resources.filter(({resourceable_type}) => resourceable_type === 'App\\Attachment').length).map(({resources}) => resources.filter(({resourceable_type}) => resourceable_type === 'App\\Attachment')).flat().find(({resourceable}) => resourceable.id === id)
}

const mapStateToProps = (state, ownProps) => ({
    comments: state.comments,
    nodes: state.nodes,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    store: (data) => dispatch(storeResource(ownProps.match.params.node, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceCreateContainer)
