import Divider from '@material-ui/core/Divider'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Container from '../Shared/Container'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'

const Export = ({isMapBusy, isTargetsBusy, onMapExport, onTargetsExport}) => {
    const classes = useStyles()

    return (
        <Modal>
            <Container modal>
                <Heading>Export</Heading>
                <div>
                    <Typography className={classes.heading} variant="subtitle1">Export the goal map to Excel.</Typography>
                    <LoadingButton busy={isMapBusy} color="primary" onClick={onMapExport} size="large" variant="contained">Export Goal Map</LoadingButton>
                </div>
                <Divider className={classes.divider} />
                <div>
                    <Typography className={classes.heading} variant="subtitle1">Export the goal map&rsquo;s targets to your calendar.</Typography>
                    <LoadingButton busy={isTargetsBusy} color="primary" onClick={onTargetsExport} size="large" variant="contained">Export Targets</LoadingButton>
                </div>
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    heading: {
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
}))

Export.defaultProps = {
    isMapBusy: false,
    isTargetsBusy: false,
}

Export.propTypes = {
    isMapBusy: PropTypes.bool,
    isTargetsBusy: PropTypes.bool,
    onMapExport: PropTypes.func.isRequired,
    onTargetsExport: PropTypes.func.isRequired,
}

export default Export
