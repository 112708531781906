import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import React, { Fragment, useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import AssignmentChip from './AssignmentChip'
import Container from '../Shared/Container'
import Comments from './Comments'
import Heading from '../Shared/Heading'
import Labels from './Labels'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import PriorityChip from './PriorityChip'
import Target from './Target'
import { fetchNode, nodeFromUri } from '../../actions/node'
import { historyShape, nodeMatchShape } from '../../constants/types'

const Goal = ({history, match}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    const goal = useSelector(({nodes}) => nodes[match.params.node]) || {labels_index: []}

    const [ isLoading, setIsLoading ] = useState(Boolean(goal.map_team_uri === undefined || goal.map_team_uri === null))
    const [ isReady, setIsReady ] = useState(Boolean(goal.id))

    useEffect(() => {
        dispatch(fetchNode(match.params.node)).then(() => {
            setIsReady(true)
            setIsLoading(false)
        }).catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                return history.push(`/m/${match.params.map}`)
            }

            throw new Error(error)
        })
    }, [])

    const useCommentsDivider = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Modal>
            <Container>
                {isReady ? (
                    <Fragment>
                        <div className={classes.headerWrapper}>
                            <Heading className={classes.heading}>{goal.name}</Heading>
                            <Typography variant="subtitle2">Quick tag #{goal.tag}</Typography>
                        </div>
                        <div className={classes.wrapper}>
                            <div className={classes.content}>
                                <div className={classes.chips}>
                                    <AssignmentChip
                                        assignedUser={goal.assigned_user}
                                        loading={isLoading}
                                        targetWarning={goal.active_target}
                                        uri={`/${match.params.view}/${match.params.map}/g/${match.params.node}`} />
                                    <PriorityChip
                                        loading={isLoading}
                                        priority={goal.priority}
                                        uri={`/${match.params.view}/${match.params.map}/g/${match.params.node}`} />
                                </div>
                                <Divider className={classes.divider} />
                                <Labels
                                    className={classes.labels}
                                    labelsIndex={goal.labels_index}
                                    loading={isLoading}
                                    uri={`/${match.params.view}/${match.params.map}/g/${match.params.node}`} />
                                <Divider className={classes.divider} />
                                <Target
                                    nodeUri={match.params.node}
                                    uri={`/${match.params.view}/${match.params.map}/g/${match.params.node}`} />
                            </div>
                            {useCommentsDivider && (
                                <Divider className={classes.divider} />
                            )}
                            <Comments
                                className={classes.sidebar}
                                uri={match.params.node} />
                        </div>
                    </Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    wrapper: {
        display: 'grid',
        gridColumnGap: theme.spacing(4),
        position: 'relative',

        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'auto 33%',
            gridAutoRows: 'minmax(0, 1fr)',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        maxHeight: `calc(100vh - 41px - ${theme.spacing(19)}px)`,
        minHeight: 400,
    },
    chips: {
        justifyContent: 'space-between',
        display: 'grid',
        gridAutoColumns: 'min-content',
        gridColumnGap: theme.spacing(4),
        gridRowGap: theme.spacing(2),
        marginBottom: theme.spacing(3),

        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, auto)',
        },
    },
    labels: {
        marginBottom: theme.spacing(3),
    },
    divider: {
        marginBottom: theme.spacing(3),
    },
}))

Goal.propTypes = {
    history: historyShape.isRequired,
    match: nodeMatchShape.isRequired,
}

export default Goal
