import Formsy from 'formsy-react'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Container from '../Shared/Container'
import FormsyHidden from '../formsy/FormsyHidden'
import FormsySelect from '../formsy/FormsySelect'
import FormsyText from '../formsy/FormsyText'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'

const Clone = ({canSubmit, isBusy, isReady, map, onInvalid, onSubmit, onValid, teams}) => {
    const classes = useStyles()

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>Clone &lsquo;{map.name}&rsquo;</Heading>
                        <Formsy onInvalid={onInvalid} onValid={onValid} onValidSubmit={onSubmit}>
                            <fieldset>
                                <FormsyText
                                    defaultValue={map.name}
                                    label="Name"
                                    name="name"
                                    fullWidth required />
                            </fieldset>
                            <fieldset>
                                {teams.length > 1 ? (
                                    <FormsySelect label="Team" name="team_uri" fullWidth required>
                                        {teams.map(({name, uri}) => (
                                            <MenuItem key={uri} value={uri}>{name}</MenuItem>
                                        ))}
                                    </FormsySelect>
                                ) : (
                                    <FormsyHidden
                                        defaultValue={teams[0].uri}
                                        name="team_uri"
                                        required />
                                )}
                            </fieldset>
                            <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                Clone
                            </LoadingButton>
                        </Formsy>
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4),
    },
}))

Clone.defaultProps = {
    canSubmit: false,
    isBusy: false,
    isReady: true,
}

Clone.propTypes = {
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    map: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    onInvalid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape({
        uri: PropTypes.string.isRequired,
    })).isRequired,
}

export default Clone
