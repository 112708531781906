import axios from 'axios'

import { receiveUsers } from './user'

const userKeysMapping = {
    maps: 'creator',
    nodes: 'assigned_user',
}

export const search = (term, scopes, page) => (dispatch, getState) =>
    axios.get('/search', {
        params: {
            term, scopes, page,
        },
    }).then((data) => {
        dispatch(
            receiveUsers(
                Object.keys(data.data).filter((key) => data.data[key].data).reduce((carry, key) => ([
                    ...carry,
                    ...data.data[key].data.filter((item) => item[userKeysMapping[key]]).reduce((carry, item) => ([
                        ...carry,
                        item[userKeysMapping[key]],
                    ]), []),
                ]), []).reduce((carry, item) => carry.map(({id}) => id).includes(item.id) ? carry : ([
                    ...carry,
                    item,
                ]), [])
            )
        )

        return data
    })
