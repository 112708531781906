import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import GuestRoute from './GuestRoute'
import Route from './Route'

import Forgot from './Auth/Forgot'
import Login from './Auth/Login'
import Logout from './Auth/Logout'
import Profile from '../containers/Auth/ProfileContainer'
import Reset from './Auth/Reset'
import Security from '../containers/Auth/SecurityContainer'

import MapBuild from '../containers/Map/BuildContainer'
import MapClone from '../containers/Map/CloneContainer'
import MapConfigure  from '../containers/Map/ConfigureContainer'
import MapEdit from './Map/Edit'
import MapExport from '../containers/Map/ExportContainer'
import MapLabels from './Map/Labels'
import MapList from '../containers/Map/ListContainer'
import MapView from '../containers/Map/ViewContainer'

import LabelCreate from './Label/Create'
import LabelEdit from './Label/Edit'

import GoalLabels from './Goal/LabelsManage'

import ResourcesManageContainer from '../containers/Goal/ResourcesManageContainer'
import ResourceCreateContainer from '../containers/Goal/ResourceCreateContainer'
import ResourceEditContainer from '../containers/Resource/EditContainer'

import TargetManage from './Target/Manage'

import CalculationCreateContainer from '../containers/Calculation/CreateContainer'
import CalculationEditContainer from '../containers/Calculation/EditContainer'

import CommentCreateContainer from '../containers/Comment/CommentCreateContainer'
import CommentEditContainer from '../containers/Comment/CommentEditContainer'

import Assignment from './Goal/Assignment'
import Priority from './Goal/Priority'

import DashboardConfigureContainer from '../containers/DashboardConfigureContainer'
import DashboardContainer from '../containers/DashboardContainer'
import Goal from './Goal/Goal'
import MapsContainer from '../containers/Map/MapsContainer'
import SearchContainer from '../containers/SearchContainer'
import TeamCreateContainer from '../containers/TeamCreateContainer'
import TeamEditContainer from '../containers/TeamEditContainer'
import TeamListContainer from '../containers/TeamListContainer'
import TeamMembersContainer from '../containers/TeamMembersContainer'
import UserAddContainer from '../containers/UserAddContainer'
import UserCreateContainer from '../containers/UserCreateContainer'
import UserEditContainer from '../containers/UserEditContainer'
import WelcomeContainer from '../containers/WelcomeContainer'

import { handleUpdateLastMapUri } from '../actions/auth'

class Routes extends React.Component {
    previousLocation = this.props.location

    shouldComponentUpdate (nextProps) {
        if (nextProps.history.action !== 'POP' && (! this.props.location.state || ! this.props.location.state.modal)) {
            this.previousLocation = this.props.location
        }

        return true
    }

    componentDidUpdate (prevProps) {
        const uri = parseMapUri(this.props.location.pathname, this.lastMapUri)
        if (uri && prevProps.auth.lastMapUri !== uri) {
            this.props.updateLastMapUri(uri)
        }
    }

    render () {
        const isModal = this.props.location.state && this.props.location.state.modal && this.previousLocation !== this.props.location

        const switchProps = {
            ...this.props,
            location: isModal ? this.previousLocation : this.props.location,
        }

        return (
            <React.Fragment>
                <Route path="/profile" component={Profile} exact {...this.props} />
                <Route path="/security" component={Security} exact {...this.props} />

                <Route path="/team" component={TeamListContainer} exact {...this.props}  />
                <Route path="/team/create" component={TeamCreateContainer} exact {...this.props} />
                <Route path="/team/:team/edit" component={TeamEditContainer} exact {...this.props} />
                <Route path="/team/:team/user" component={TeamMembersContainer} exact {...this.props} />
                <Route path="/team/:team/user/add" component={UserAddContainer} exact {...this.props} />
                <Route path="/team/:team/user/create" component={UserCreateContainer} exact {...this.props} />
                <Route path="/team/:team/user/:user/edit" component={UserEditContainer} exact {...this.props} />

                <Route path="/:view(m)" component={MapsContainer} exact {...this.props} />

                <Route path="/:view(d)/:map/configure" component={DashboardConfigureContainer} exact {...this.props} />
                <Route path="/:view(m)/:map/configure" component={MapConfigure} exact {...this.props} />
                <Route path="/:view(d|m)/:map/edit" component={MapEdit} exact {...this.props} />

                <Route path="/:view(d|m|l)/:map/g/:node" component={Goal} exact {...this.props} />

                <Route path="/:view(d|m|l)/:map/export" component={MapExport} exact {...this.props} />
                <Route path="/:view(d|m|l)/:map/clone" component={MapClone} exact {...this.props} />

                <Route path="/:view(d|m|l)/:map/label" component={MapLabels} exact {...this.props} />
                <Route path="/:view(d|m|l)/:map/label/create" component={LabelCreate} exact {...this.props} />
                <Route path="/:view(d|m|l)/:map/label/:label/edit" component={LabelEdit} exact {...this.props} />
                <Route path="/:view(d|m|l)/:map/g/:node/label" component={GoalLabels} exact {...this.props} />

                <Route path="/:view(d|m|l)/:map/g/:node/assignment" component={Assignment} exact {...this.props} />
                <Route path="/:view(d|m|l)/:map/g/:node/priority" component={Priority} exact {...this.props} />

                <Route path="/:view(d|m|l)/:map/g/:node/target" component={TargetManage} exact {...this.props} />

                <Route path="/:view(d|m)/:map/g/:node/resource" component={ResourcesManageContainer} exact {...this.props} />
                <Route path="/:view(d|m)/:map/g/:node/resource/create" component={ResourceCreateContainer} exact {...this.props} />
                <Route path="/:view(d|m)/:map/g/:node/resource/:resource/edit" component={ResourceEditContainer} exact {...this.props} />

                <Route path="/:view(d|m)/:map/g/:node/calculation/create" component={CalculationCreateContainer} exact {...this.props} />
                <Route path="/:view(d|m)/:map/g/:node/calculation/:calculation/edit" component={CalculationEditContainer} exact {...this.props} />

                <Route path="/:view(d|m)/:map/comment/create" component={CommentCreateContainer} exact {...this.props} />
                <Route path="/:view(d|m)/:map/g/:node/comment/create" component={CommentCreateContainer} exact {...this.props} />
                <Route path="/:view(d|m)/:map/comment/:comment/edit" component={CommentEditContainer} exact {...this.props} />
                <Route path="/:view(d|m)/:map/g/:node/comment/:comment/edit" component={CommentEditContainer} exact {...this.props} />

                <Route path="/search" component={SearchContainer} exact {...this.props} />

                <Switch location={switchProps.location}>
                    <Redirect from="/" to={indexRoute(this.props, this.props.auth.lastMapUri)} exact />

                    <GuestRoute path="/auth/login" component={Login} {...this.props} />
                    <GuestRoute path="/password/reset" component={Forgot} {...this.props} exact />
                    <GuestRoute path="/password/reset/:token" component={Reset} {...this.props} exact />
                    <Route path="/auth/logout" component={Logout} {...this.props} />

                    <Route path="/:view(d)/:map" component={DashboardContainer} {...this.props} />
                    <Route path="/:view(l)/:map" component={MapList} {...this.props} />

                    <Route path="/welcome" component={WelcomeContainer} {...this.props} />

                    <Switch>
                        <Route path="/:view(m)/create" component={MapBuild} {...this.props} />
                        <Route path="/:view(m)/:map/build" component={MapBuild} {...this.props} />
                        <Route path="/:view(m)/:map" component={MapView} {...this.props} />
                    </Switch>
                </Switch>
            </React.Fragment>
        )
    }
}

const indexRoute = ({auth, users}) => {
    if (auth && auth.isAuthed) {
        if (auth.lastMapUri) {
            return `/d/${auth.lastMapUri}`
        }

        if (users[auth.userId]?.default_map_uri) {
            return `/d/${users[auth.userId].default_map_uri}`
        }

        return '/m/create'
    }

    return '/auth/login'
}

const parseMapUri = (pathname, lastMapUri = null) => {
    const matches = pathname.match(/^\/[d|m]\/([^\/]+)/)

    return matches ? matches[1] : lastMapUri
}

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateLastMapUri: (uri) => dispatch(handleUpdateLastMapUri(uri))
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
