import AttachFile from '@material-ui/icons/AttachFile'
import DialogContent from '@material-ui/core/DialogContent'
import Formsy from 'formsy-react'
import PropTypes from 'prop-types'
import React from 'react'

import FormsyFile from '../formsy/FormsyFile'
import FormsyText from '../formsy/FormsyText'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'

class CommentCreate extends React.Component {
    render () {
        return (
            <Modal>
                <DialogContent>
                    <Formsy onValidSubmit={this.props.onSubmit} onValid={this.props.onValid} onInvalid={this.props.onInvalid} style={styles.form}>
                        <fieldset>
                            <FormsyText
                                label="Write your comment&hellip;"
                                name="comment"
                                rows={4}
                                rowsMax={12}
                                style={styles.textInput}
                                autoFocus fullWidth multiline required />
                        </fieldset>
                        <div>
                            <FormsyFile name="file" label="Attach a resource" onChange={this.props.onFileInputChange} />
                        </div>
                        <fieldset style={styles.buttonsContainer}>
                            <LoadingButton busy={this.props.isBusy} color="primary" disabled={! this.props.isValid} type="submit" variant="contained" fullWidth>
                                Post
                            </LoadingButton>
                        </fieldset>
                    </Formsy>
                </DialogContent>
            </Modal>
        )
    }
}

CommentCreate.defaultProps = {
    isBusy: false,
    isValid: false,
}

CommentCreate.propTypes = {
    isBusy: PropTypes.bool,
    isValid: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onFileInputChange: PropTypes.func.isRequired,
    onInvalid: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
    selectedFile: PropTypes.object,
}

const renderSelectedFile = (file) => {
    if (! file) {
        return null
    }

    return (
        <div style={styles.selectedFile}>
            <AttachFile style={styles.selectedFileIcon} />
            <span style={styles.selectedFileLabel}>{file.name}</span>
        </div>
    )
}

const styles = {
    form: {
        marginBottom: 12,
        minWidth: 208,
    },
    buttonsContainer: {
        display: 'flex',
        marginTop: 24,
    },
    textInput: {
        marginBottom: 16,
    },
    hiddenFileInput: {
        bottom: 0,
        cursor: 'pointer',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
    },
    button: {
        marginBottom: 24,
    },
    selectedFile: {
        display: 'inline-block',
        lineHeight: '36px',
        marginLeft: 24,
    },
    selectedFileIcon: {
        verticalAlign: 'middle',
    },
    selectedFileLabel: {
        display: 'inline-block',
        marginLeft: 4,
        maxWidth: 165,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
}

export default CommentCreate
