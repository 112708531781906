import { REHYDRATE } from 'redux-persist'

import { INVALIDATE_TOKEN, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, UPDATE_LAST_MAP_URI, UPDATE_TEAM_URI, UPDATE_TOKEN } from '../actions/auth'

const initialState = {
    clientUri: null,
    isAuthed: false,
    isBusy: false,
    lastMapUri: null,
    token: null,
    teamUri: null,
    userId: null,
}

const authReducer = (state = initialState, action) => {
    const actions = {
        [REHYDRATE]: () => ({
            ...state,
            ...action.payload && action.payload.auth || {},
            isBusy: false,
        }),
        [INVALIDATE_TOKEN]: () => ({
            ...state,
            isAuthed: false,
            token: null,
        }),
        [LOGIN_FAILURE]: () => ({
            ...state,
            isBusy: false,
        }),
        [LOGIN_REQUEST]: () => ({
            ...state,
            isBusy: true,
        }),
        [LOGIN_SUCCESS]: () => ({
            ...state,
            clientUri: action.payload.clientUri,
            isAuthed: true,
            isBusy: false,
            token: action.payload.token,
            teamUri: action.payload.teamUri,
            userId: action.payload.userId,
        }),
        [UPDATE_LAST_MAP_URI]: () => ({
            ...state,
            lastMapUri: action.payload.lastMapUri,
        }),
        [UPDATE_TEAM_URI]: () => ({
            ...state,
            teamUri: action.payload.teamUri,
        }),
        [UPDATE_TOKEN]: () => state.isAuthed ? ({
            ...state,
            token: action.payload.token,
        }) : state,
    }

    if (typeof actions[action.type] !== 'function') {
        return state
    }

    return actions[action.type]()
}

export default authReducer
