import FormControl from '@material-ui/core/FormControl'
import HelpIcon from '@material-ui/icons/Help'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React, { Fragment, memo, useState } from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../actions/stashes'

const SelectUnits = ({format: defaultFormat = '', nodeUri, prefix: defaultPrefix = '', suffix: defaultSuffix = '', ...rest}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [ format, setFormat ] = useState(defaultFormat)
    const [ menuOpen, setMenuOpen ] = useState(false)
    const [ prefix, setPrefix ] = useState(defaultPrefix)
    const [ suffix, setSuffix ] = useState(defaultSuffix)

    const _handleChangeFormat = (event) => {
        setFormat(event.target.value)

        dispatch(stashTarget(nodeUri, {
            format: event.target.value,
        }))
    }

    const _handleChangePrefix = (event) => {
        setPrefix(event.target.value)

        dispatch(stashTarget(nodeUri, {
            prefix: event.target.value,
        }))
    }

    const _handleChangeSuffix = (event) => {
        setSuffix(event.target.value)

        dispatch(stashTarget(nodeUri, {
            suffix: event.target.value,
        }))
    }

    const _handleMenuClose = () => setMenuOpen(false)
    const _handleMenuOpen = () => setTimeout(() => setMenuOpen(true), 100)

    return (
        <div className={classes.wrapper}>
            <FormControl className={classes.formatWrapper}>
                <InputLabel>Format</InputLabel>
                <Select className={classes.select} onChange={_handleChangeFormat} onClose={_handleMenuClose} onOpen={_handleMenuOpen} value={format}>
                    <MenuItem value="integer">
                        <span className={classes.listText}>Whole number</span>
                        {menuOpen && (
                            <Tooltip title="Any decimal values will automatically be rounded to the nearest whole number.">
                                <IconButton>
                                    <HelpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </MenuItem>
                    <MenuItem value="decimal">
                        <span className={classes.listText}>Decimal number</span>
                        {menuOpen && (
                            <Tooltip title="All values will be shown to two decimal places.">
                                <IconButton>
                                    <HelpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </MenuItem>
                    <MenuItem value="boolean">
                        <span className={classes.listText}>Met / not met</span>
                        {menuOpen && (
                            <Tooltip title="All values will be interpreted as either true or false.">
                                <IconButton>
                                    <HelpIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </MenuItem>
                </Select>
            </FormControl>
            {formatSupportsAdornments(format) && (
                <Fragment>
                    <Typography variant="subtitle2" paragraph>Format your target value output (optional):</Typography>
                    <div className={classes.inputWrapper}>
                        <TextField
                            inputProps={{maxLength: 5}}
                            label="Prefix (e.g. £)"
                            onChange={_handleChangePrefix}
                            value={prefix} />
                        <Tooltip title="For monetary values you may want a prefix like '£' or '€'">
                            <IconButton>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            inputProps={{maxLength: 20}}
                            label="Suffix (e.g. per day or %)"
                            onChange={_handleChangeSuffix}
                            value={suffix} />
                        <Tooltip title="Add context to a value with a suffix like 'per day' or '%'">
                            <IconButton>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

const formatSupportsAdornments = (format) => ['decimal', 'integer'].includes(format)

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'grid',
        gridAutoColumns: 'max-content',
    },
    formatWrapper: {
        marginBottom: theme.spacing(4),
    },
    select: {
        minWidth: 100,
    },
    inputWrapper: {
        alignItems: 'flex-end',
        display: 'grid',
        gridColumnGap: theme.spacing(1),
        gridTemplateColumns: 'auto min-content',
        marginBottom: theme.spacing(3),
    },
    listText: {
        flex: 1,
        marginRight: theme.spacing(2),
    },
}))

SelectUnits.propTypes = {
    format: PropTypes.oneOf([
        'boolean', 'decimal', 'integer',
    ]),
    nodeUri: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
}

export default SelectUnits
