import Button from '@material-ui/core/Button'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import NodeFinderContainer from '../../containers/NodeFinderContainer'
import { operatorToHex } from '../../actions/calculation'

const Item = React.forwardRef(({editing, index, isValid, label, mapId, onChange, onDelete, onEdit, onOperator, onSelect, operator, validNodeIds}, ref) => editing ? (
    <React.Fragment>
        <div style={styles.input}>
            <NodeFinderContainer
                fullWidth={true}
                include={validNodeIds}
                initialValue={label}
                placeholder="Search for a goal or enter a numeric value&hellip;"
                mapId={mapId}
                onChange={onChange}
                onNodeSelected={onSelect}
                ref={ref} />
        </div>
        <div style={styles.operators}>
            <Button color="primary" disabled={! isValid} onClick={onOperator} value="add" variant="contained">{operatorToHex('add')}</Button>
            <Button color="primary" disabled={! isValid} onClick={onOperator} value="subtract" variant="contained">{operatorToHex('subtract')}</Button>
            <Button color="primary" disabled={! isValid} onClick={onOperator} value="multiply" variant="contained">{operatorToHex('multiply')}</Button>
            <Button color="primary" disabled={! isValid} onClick={onOperator} value="divide" variant="contained">{operatorToHex('divide')}</Button>
            <Button color="primary" disabled={! isValid} onClick={onOperator} value="sum" style={styles.operatorEquals} variant="contained">{operatorToHex('sum')}</Button>
        </div>
    </React.Fragment>
) : (
    <React.Fragment>
        <IconButton onClick={onEdit} value={index}>
            <Edit />
        </IconButton>
        <IconButton onClick={onDelete} value={index}>
            <Delete />
        </IconButton>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="subtitle1">{operatorToHex(operator)}</Typography>
    </React.Fragment>
))

Item.defaultProps = {
    editing: false,
    isValid: false,
}

Item.propTypes = {
    editing: PropTypes.bool,
    index: PropTypes.number,
    isValid: PropTypes.bool,
    label: PropTypes.string,
    mapId: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onOperator: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    operator: PropTypes.oneOf(['add', 'subtract', 'multiply', 'divide', 'sum']),
    validNodeIds: PropTypes.array.isRequired,
}

const styles = {
    input: {
        alignSelf: 'flex-start',
        gridColumn: '1 / 4',
    },
    operators: {
        display: 'grid',
        gridColumn: '4 / 5',
        gridColumnGap: 6,
        gridRowGap: 12,
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(3, 1fr)',
    },
    operatorEquals: {
        gridColumn: '1 / 3',
    },
}

export default Item
