import InputAdornment from '@material-ui/core/InputAdornment'
import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../actions/stashes'

const SetValues = memo(({nodeUri, prefix, start_value: defaultStartValue, suffix, value: defaultTargetValue, ...rest}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [ startValue, setStartValue ] = useState(defaultStartValue)
    const [ targetValue, setTargetValue ] = useState(defaultTargetValue)

    const _handleChangeStartValue = (event) => {
        setStartValue(event.target.value)

        dispatch(stashTarget(nodeUri, {
            start_value: event.target.value,
        }))
    }

    const _handleChangeTargetValue = (event) => {
        setTargetValue(event.target.value)

        dispatch(stashTarget(nodeUri, {
            value: event.target.value,
        }))
    }

    return (
        <div className={classes.wrapper}>
            <TextField
                InputProps={{
                    endAdornment: suffix ? (
                        <InputAdornment position="end">
                            {suffix}
                        </InputAdornment>
                    ) : null,
                    startAdornment: prefix ? (
                        <InputAdornment position="start">
                            {prefix}
                        </InputAdornment>
                    ) : null
                }}
                label="Start value"
                onChange={_handleChangeStartValue}
                value={startValue} />
            <TextField
                InputProps={{
                    endAdornment: suffix ? (
                        <InputAdornment position="end">
                            {suffix}
                        </InputAdornment>
                    ) : null,
                    startAdornment: prefix ? (
                        <InputAdornment position="start">
                            {prefix}
                        </InputAdornment>
                    ) : null
                }}
                label="Target value"
                onChange={_handleChangeTargetValue}
                value={targetValue} />
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'grid',
        gridAutoColumns: 'max-content',
        gridRowGap: theme.spacing(3),
    },
}))

SetValues.propTypes = {
    nodeUri: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number, PropTypes.string,
    ]),
}

export default SetValues
