import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PropTypes from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Container from '../Shared/Container'
import Heading from '../Shared/Heading'
import Chip from '../Label/Chip'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import { deleteLabel, fetchLabels } from '../../actions/label'
import { historyShape, mapMatchShape } from '../../constants/types'

const Labels = memo(({history, match}) => {
    const classes = useStyles()

    const [ isReady, setIsReady ] = useState(false)

    const labels = useSelector(({labels}) => Object.values(labels).filter(({name, map_uri}) => map_uri === match.params.map))
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchLabels(match.params.map)).then(() => setIsReady(true)).catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                return history.push(`/m/${match.params.map}`)
            }

            throw new Error(error)
        })
    }, [])

    const _handleDelete = (id) => dispatch(deleteLabel(match.params.map, id))

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>Labels</Heading>
                        <List dense>
                            {labels.map(({hex, id, name}) => {
                                const onDelete = () => _handleDelete(id)

                                return (
                                    <ListItem key={id}>
                                        <Chip
                                            hex={hex}
                                            name={name} />
                                        <ListItemSecondaryAction>
                                            <Tooltip title="Edit">
                                                <IconButton component={Link} to={{pathname: `/${match.params.view}/${match.params.map}/label/${id}/edit`, state: {modal: true}}}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={onDelete}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                        </List>
                        {Boolean(! labels.length) && (
                            <Typography className={classes.emptyText} variant="body2">
                                Get started by adding the first label to this goal map.
                            </Typography>
                        )}
                        <div className={classes.addButtonWrapper}>
                            <Fab color="primary" component={Link} to={{pathname: `/${match.params.view}/${match.params.map}/label/create`, state: {modal: true}}}>
                                <AddIcon />
                            </Fab>
                        </div>
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
})

const useStyles = makeStyles((theme) => ({
    addButtonWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        paddingTop: theme.spacing(4),
    },
    emptyText: {
        fontWeight: '300',
    },
}))

Labels.propTypes = {
    history: historyShape.isRequired,
    match: mapMatchShape.isRequired,
}

export default Labels
