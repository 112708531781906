import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Formsy from 'formsy-react'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import FormsySelect from '../formsy/FormsySelect'
import FormsyText from '../formsy/FormsyText'
import LoadingButton from '../LoadingButton'
import NodeFinderContainer from '../../containers/NodeFinderContainer'
import PageLoadingIndicator from '../PageLoadingIndicator'

const ConfigurePrimaryKpi = ({format, initialValue, isBusy, isReady, label, mapId, onSelected, onSubmit, prefix, suffix}) => (
    <div style={styles.container}>
        <DialogTitle>Configure Primary KPI</DialogTitle>
        <DialogContent>
            {isReady ? (
                <Formsy onValidSubmit={onSubmit} style={styles.content}>
                    <fieldset>
                        <NodeFinderContainer
                            fullWidth={true}
                            initialValue={initialValue}
                            label="Calculated goal"
                            mapId={mapId}
                            onNodeSelected={onSelected}
                            style={styles.lookup}
                            withSearch={true} />
                    </fieldset>
                    <fieldset>
                        <FormsyText
                            defaultValue={label}
                            label="Label"
                            name="label"
                            fullWidth />
                    </fieldset>
                    <fieldset>
                        <FormsyText
                            defaultValue={prefix}
                            label="Prefix"
                            name="prefix"
                            fullWidth />
                    </fieldset>
                    <fieldset>
                        <FormsyText
                            defaultValue={suffix}
                            label="Suffix"
                            name="suffix"
                            fullWidth />
                    </fieldset>
                    <fieldset>
                        <FormsySelect defaultValue={format} label="Format" name="format" fullWidth required>
                            <MenuItem value="decimal">Decimal</MenuItem>
                            <MenuItem value="integer">Integer</MenuItem>
                        </FormsySelect>
                    </fieldset>
                    <LoadingButton busy={isBusy} color="primary" containerStyle={styles.submit} type="submit" variant="contained">
                        Save
                    </LoadingButton>
                </Formsy>
            ) : (
                <PageLoadingIndicator />
            )}
        </DialogContent>
    </div>
)

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    lookup: {
        flex: 1,
    },
    submit: {
        alignSelf: 'center',
        marginTop: 40,
    },
}

ConfigurePrimaryKpi.defaultProps = {
    isBusy: false,
    isReady: false,
    format: 'decimal',
}

ConfigurePrimaryKpi.propTypes = {
    format: PropTypes.oneOf([
        'decimal', 'integer',
    ]),
    initialValue: PropTypes.string,
    isBusy: PropTypes.bool,
    isReady: PropTypes.bool,
    label: PropTypes.string,
    mapId: PropTypes.string,
    onSelected: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
}

export default ConfigurePrimaryKpi
