import Button from '@material-ui/core/Button'
import Formsy from 'formsy-react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import React, { memo, useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Chip from '../Label/Chip'
import Container from '../Shared/Container'
import FormsyCheckbox from '../formsy/FormsyCheckbox'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import { fetchLabels } from '../../actions/label'
import { fetchNode, updateNode } from '../../actions/node'
import { historyShape, nodeMatchShape } from '../../constants/types'

const LabelsManage = memo(({history, match}) => {
    const classes = useStyles()

    const [ filter, setFilter ] = useState('')
    const [ hasChanged, setHasChanged ] = useState(false)
    const [ isBusy, setIsBusy ] = useState(false)
    const [ isReady, setIsReady ] = useState(false)
    const [ isValid, setIsValid ] = useState(false)
    const [ labelsLoaded, setLabelsLoaded ] = useState(false)

    const canSubmit = Boolean(hasChanged && isValid)

    const goal = useSelector(({nodes}) => nodes[match.params.node]) || {}
    const labels = useSelector(({labels}) => Object.values(labels).filter(({name, map_uri}) => map_uri === match.params.map && (new RegExp(filter, 'i')).test(name)))
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchLabels(match.params.map)).then(() => setLabelsLoaded(true)).catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                return history.push(`/m/${match.params.map}`)
            }

            throw new Error(error)
        })
    }, [])

    useEffect(() => {
        if (goal.enhanced) {
            return
        }

        dispatch(fetchNode(match.params.node)).catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                return history.push(`/m/${match.params.map}`)
            }

            throw new Error(error)
        })
    }, [])

    useEffect(() => {
        if (! isReady && goal.enhanced && labelsLoaded) {
            setIsReady(true)
        }
    }, [
        goal.enhanced, labelsLoaded,
    ])

    const _handleSubmit = (formData, resetForm, invalidateForm) => {
        setIsBusy(true)

        dispatch(updateNode(goal.uri, {
            labels_index: Object.keys(formData.labels_index).filter((item) => formData.labels_index[item]),
        })).then(history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setIsBusy(false))
    }

    const _handleChange = (formData, hasChanged) => setHasChanged(hasChanged)
    const _handleFilterChange = (event) => setFilter(event.target.value)
    const _handleInvalid = () => setIsValid(false)
    const _handleValid = () => setIsValid(true)

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>{goal.name}</Heading>
                        <div className={classes.filters}>
                            <TextField
                                className={classes.filter}
                                label="Filter"
                                onChange={_handleFilterChange} />
                            <Button className={classes.filter} color="secondary" component={Link} size="small" to={{pathname: `/${match.params.view}/${match.params.map}/label`, state: {modal: true}}} variant="contained">
                                Manage goal map labels
                            </Button>
                        </div>
                        {Boolean(labels.length) ? (
                            <Formsy onChange={_handleChange} onInvalid={_handleInvalid} onValid={_handleValid} onValidSubmit={_handleSubmit}>
                                <List className={classes.items} dense>
                                    {labels.map(({hex, id, name}) => (
                                        <ListItem key={id}>
                                            <Chip
                                                hex={hex}
                                                name={name} />
                                            <ListItemSecondaryAction>
                                                <FormsyCheckbox
                                                    defaultValue={Boolean(goal.labels_index.includes(id))}
                                                    name={`labels_index[${id}]`} />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                                <LoadingButton busy={isBusy} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                    Update
                                </LoadingButton>
                            </Formsy>
                        ) : (
                            <Typography className={classes.emptyText} variant="body2">
                                {filter.length ? 'There are no matching labels.' : 'There are no labels available for the goal map. Get started by adding a label to the goal map.'}
                            </Typography>
                        )}
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
})

const useStyles = makeStyles((theme) => ({
    filters: {
        alignItems: 'flex-end',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    filter: {
        marginBottom: theme.spacing(2),
    },
    items: {
        marginBottom: theme.spacing(4),
    },
    emptyText: {
        fontWeight: '300',
    },
}))

LabelsManage.propTypes = {
    history: historyShape.isRequired,
    match: nodeMatchShape.isRequired,
}

export default LabelsManage
