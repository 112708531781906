import PropTypes from 'prop-types'
import React from 'react'
import Stage from '../../components/Map/Stage'
import normalizeWheel from 'normalize-wheel'

import { withRouter } from 'react-router'

import { CONTROLS_HEIGHT, HEADER_HEIGHT, LAYOUT_NODE_HEIGHT, LAYOUT_NODE_SPACING_X, LAYOUT_NODE_SPACING_Y, LAYOUT_NODE_WIDTH, TRAY_HEIGHT } from '../../constants/layout'

class StageContainer extends React.Component {
    static defaultProps = {
        disabled: false,
    }

    static propTypes = {
        disabled: PropTypes.bool,
        onSetRowOffset: PropTypes.func.isRequired,
        onSetScrollingRowIndex: PropTypes.func.isRequired,
        onSetStageOffset: PropTypes.func.isRequired,
        layout: PropTypes.shape({
            rows: PropTypes.arrayOf(PropTypes.shape({
                items: PropTypes.array.isRequired,
            })),
            usingControls: PropTypes.bool,
            usingTray: PropTypes.bool,
        }),
    }

    state = {
        hoveringIndex: null,
    }

    _layout = {
        usingControls: this.props.layout.usingControls,
        usingTray: this.props.layout.usingTray,
        windowHeight: window.innerHeight - (this.props.layout.usingControls ? CONTROLS_HEIGHT : 0) - HEADER_HEIGHT - (this.props.layout.usingTray ? TRAY_HEIGHT : 0),
        windowWidth: window.innerWidth,
    }

    componentDidMount () {
        window.addEventListener('resize', this._handleResize)
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this._handleResize)
    }

    render () {
        return (
            <Stage
                hoveringIndex={this.state.hoveringIndex}
                onMouseEnter={this._handleMouseEnter}
                onMouseLeave={this._handleMouseLeave}
                onMouseMove={this._handleScrollEnd}
                onNudgeRow={this._handleNudgeRow}
                onNudgeStage={this._handleNudgeStage}
                onTouchEnd={this._handleTouchEnd}
                onTouchMove={this._handleTouchMove}
                onTouchStart={this._handleTouchStart}
                onWheel={this._handleScroll}
                {...this.props} />
        )
    }

    _handleGenericEnd = (event) => {
        if (! this._layout.start) {
            return
        }

        const rowIndex= this.props.layout.rows.findIndex(({scrolling}) => scrolling)
        if (rowIndex >= 0) {
            this.props.onSetRowOffset(
                rowIndex,
                getNodeOffsetFromIndex(getNearestNodeIndexFromOffset(this.props.layout.rows[rowIndex].offset, this._layout), this._layout),
                true
            )
        }

        this._layout = {
            ...this._layout,
            start: null,
        }
    }

    _handleGenericMove = (x, y) => {
        const offsetX = this._layout.start.x - x
        const offsetY = this._layout.start.y - y

        if (! this._layout.lock && (Math.abs(offsetX) > 30 || Math.abs(offsetY) > 30)) {
            this._layout.lock = Math.abs(offsetX) > 30 ? 'x' : 'y'
        }

        if (this._layout.lock === 'y' || (! this._layout.lock && Math.abs(offsetY) >= Math.abs(offsetX))) {
            this.props.onSetStageOffset(
                applyBoundariesAndResistance(offsetY, 'y', this._layout)
            )
        }

        const rowIndex= this.props.layout.rows.findIndex(({scrolling}) => scrolling)
        if (rowIndex >= 0 && (this._layout.lock === 'x' || (! this._layout.lock && Math.abs(offsetX) > Math.abs(offsetY)))) {
            this.props.onSetRowOffset(
                rowIndex,
                applyBoundariesAndResistance(offsetX, 'x', this._layout)
            )
        }
    }

    _handleGenericStart = (event, x, y) => {
        const rowIndex = getTriggeredRow(y, this.props)

        this.props.onSetScrollingRowIndex(rowIndex)

        this._layout = {
            ...this._layout,
            boundaries: getBoundaries(rowIndex, this.props, this._layout),
            lock: null,
            start: {
                lastX: rowIndex === null ? 0 : this.props.layout.rows[rowIndex].offset,
                lastY: this.props.layout.offset,
                x, y,
            },
        }
    }

    _handleNudgeRow = (index, reverse = false) => {
        let nextIndex = getRowCurrentIndex(index, this.props.layout)

        if (reverse) {
            nextIndex -= 1
        } else {
            nextIndex += 1
        }

        if (nextIndex < 0) {
            nextIndex = 0
        }

        const maxIndex = this.props.layout.rows[index].items.filter((item) => this.props.layout.instances[item] !== undefined).length - 1
        if (nextIndex > maxIndex) {
            nextIndex = maxIndex
        }

        this.props.onSetRowOffset(
            index,
            getNodeOffsetFromIndex(nextIndex, this._layout),
            true
        )
    }

    _handleNudgeStage = (reverse = false) => {
        const y = (this._layout.windowHeight / 6) * (reverse ? -1 : 1)

        this.props.onSetStageOffset(
            applyBoundariesAndResistance(y, 'y', ({
                ...this._layout,
                boundaries: getBoundaries(null, this.props, this._layout),
                lock: null,
                start: {
                    lastY: this.props.layout.offset,
                    y,
                },
            }))
        )
    }

    _handleResize = (event) => {
        this._layout = {
            windowHeight: window.innerHeight - (this.props.layout.usingControls ? CONTROLS_HEIGHT : 0) - HEADER_HEIGHT - (this.props.layout.usingTray ? TRAY_HEIGHT : 0),
            windowWidth: window.innerWidth,
        }
    }

    _handleScroll = (event) => {
        if (this.props.disabled) {
            return
        }

        if (scrollOutsideBounds(event, this._layout)) {
            return
        }

        this._handleScrollMove(event)

        if (typeof this._endScrollTimeout === 'number') {
            clearTimeout(this._endScrollTimeout)
        }

        this._endScrollTimeout = setTimeout(this._handleScrollEnd, 150)
    }

    _handleScrollEnd = (event) => this._handleGenericEnd(event)

    _handleScrollMove = (event) => {
        const start = this._layout.start ? this._layout.start : this._handleScrollStart(event)

        this._layout.scroll = {
            x: this._layout.scroll.x + getX(event),
            y: this._layout.scroll.y + getY(event),
        }

        this._handleGenericMove(this._layout.scroll.x, this._layout.scroll.y)

        if (Math.abs(start.y - this._layout.scroll.y) > this._layout.windowHeight) {
            this._handleScrollEnd(event)
        }
    }

    _handleScrollStart = (event) => {
        this._handleGenericStart(event, event.clientX, event.clientY)

        this._layout.scroll = {
            x: this._layout.start.x,
            y: this._layout.start.y,
        }

        return this._layout.start
    }

    _handleMouseEnter = (hoveringIndex) => this.setState({hoveringIndex})
    _handleMouseLeave = () => this.setState({hoveringIndex: null})
    _handleTouchEnd = (event) => this._handleGenericEnd(event)
    _handleTouchMove = (event) => this._handleGenericMove(getX(event), getY(event))
    _handleTouchStart = (event) => this._handleGenericStart(event, getX(event), getY(event))
}

const applyBoundariesAndResistance = (offset, axis, {boundaries, start, windowHeight, windowWidth}) => {
    const startOffset = start === undefined ? 0 : start[`last${axis.toUpperCase()}`]
    const nextOffset = startOffset - offset
    const {lower: boundariesLower, upper: boundariesUpper} = boundaries[axis]

    if (nextOffset < boundariesLower && nextOffset > boundariesUpper) {
        return nextOffset
    }

    return nextOffset > boundariesLower ? boundariesLower : boundariesUpper
}

const getBoundaries = (rowIndex = null, {layout}, {windowHeight, windowWidth}) => ({
    x: {
        upper: rowIndex !== null ? (windowWidth / 2) - ((layout.rows[rowIndex].items.length - .5) * LAYOUT_NODE_WIDTH) - ((layout.rows[rowIndex].items.length - 1) * LAYOUT_NODE_SPACING_X) : 0,
        lower: (windowWidth - LAYOUT_NODE_WIDTH) / 2,
    },
    y: {
        upper: layout.rows.length === 0 ? layout.offset : ((layout.rows.length - 1) * (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y)) * -1,
        lower: layout.rows.length === 0 ? layout.offset : (windowHeight - LAYOUT_NODE_HEIGHT),
    },
})

const getRowCurrentIndex = (index, {instances, rows}) => rows[index].items.filter((item) => instances[item] !== undefined).indexOf(rows[index].focalInstanceId)
const getNearestNodeIndexFromOffset = (offset, {windowWidth}) => Math.round(Math.abs(offset - (windowWidth - (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) / 2) / (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X))
const getNodeOffsetFromIndex = (index, {windowWidth}) => ((windowWidth - (LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X)) / 2) - ((LAYOUT_NODE_WIDTH + LAYOUT_NODE_SPACING_X) * index) + (LAYOUT_NODE_SPACING_X / 2)
const getX = (event) => event.type === 'wheel' ? normalizeWheel(event).pixelX * -1 : event.touches[0].pageX
const getY = (event) => event.type === 'wheel' ? normalizeWheel(event).pixelY * -1 : event.touches[0].pageY

const scrollOutsideBounds = ({y}, {usingControls, windowHeight}) => {
    if (y - HEADER_HEIGHT - (usingControls ? CONTROLS_HEIGHT : 0) < 0) {
        return true
    }

    if (y - HEADER_HEIGHT - (usingControls ? CONTROLS_HEIGHT : 0) > windowHeight) {
        return true
    }

    return false
}

const getTriggeredRow = (y, {layout}) => {
    const adjustedY = adjustYForHeader(y - layout.offset, layout.usingControls)
    const index = Math.floor(adjustedY / (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y))

    if (index < 0 || index > (layout.rows.length - 1)) {
        return null
    }

    return adjustedY >= index * (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y) && adjustedY <= LAYOUT_NODE_HEIGHT + (index * (LAYOUT_NODE_HEIGHT + LAYOUT_NODE_SPACING_Y)) ? index : null
}

const adjustYForHeader = (y, usingControls) => y- HEADER_HEIGHT - (usingControls ? CONTROLS_HEIGHT : 0)

export default withRouter(StageContainer)
