import React from 'react'
import { connect } from 'react-redux'

import TeamList from '../components/TeamList'
import { destroyTeam, fetchTeams } from '../actions/team'

class TeamListContainer extends React.Component {
    state = {
        hasFetched: false,
    }

    componentDidMount () {
        this.props.fetch().then(() => this.setState({
            hasFetched: true,
        }))
    }

    render () {
        return (
            <TeamList
                hasFetched={this.state.hasFetched}
                onAdd={this._handleAdd}
                onDelete={this._handleDelete}
                onEdit={this._handleEdit}
                onManageMembers={this._handleManageMembers}
                teams={enrichTeams(this.props)} />
        )
    }

    _handleAdd = () => this.props.history.push('/team/create', {modal: true})
    _handleDelete = (uri) => this.props.destroy(uri)
    _handleEdit = (uri) => this.props.history.push(`/team/${uri}/edit`, {modal: true})
    _handleManageMembers = (uri) => this.props.history.push(`/team/${uri}/user`, {modal: true})
}

const enrichTeams = ({teams, ...rest}) => Object.values(teams).map((item) => ({
    ...item,
    canDelete: canEditOrDeleteTeam(item, rest),
    canEdit: canEditOrDeleteTeam(item, rest),
    canManageMembers: canManageTeamMembers(item, rest),
}))

const canEditOrDeleteTeam = (team, {auth, users}) => auth.userId === team.created_by || users[auth.userId].permissions.includes('GLOBAL_ADMIN')
const canManageTeamMembers = (team, {auth, users}) => users[auth.userId].teams_index[team.uri].includes('MANAGE_MEMBERS') || users[auth.userId].permissions.includes('GLOBAL_ADMIN')

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    teams: state.teams,
    users: state.users,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    destroy: (uri) => dispatch(destroyTeam(uri)),
    fetch: () => dispatch(fetchTeams()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamListContainer)
