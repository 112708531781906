import axios from 'axios'

import hash from '../includes/hash'
import { receiveComments } from './comment'
import { receiveResources } from './resource'

export const ADD_NODE = 'ADD_NODE'
export const INCREMENT_NODE_COMMENT_COUNT = 'INCREMENT_NODE_COMMENT_COUNT'
export const NODE_IS_EDITING = 'NODE_IS_EDITING'
export const NODE_IS_VIEWING = 'NODE_IS_VIEWING'
export const RECEIVE_NODE = 'RECEIVE_NODE'
export const RECEIVE_NODES = 'RECEIVE_NODES'
export const REMOVE_NODE = 'REMOVE_NODE'
export const SET_NODE_WATCHING = 'SET_NODE_WATCHING'

const addNode = (node) => ({
    type: ADD_NODE,
    node,
})

const removeNode = (id) => ({
    type: REMOVE_NODE,
    id,
})

export const nodeIsEditing = (id) => ({
    type: NODE_IS_EDITING,
    id,
})

export const nodeIsViewing = (id) => ({
    type: NODE_IS_VIEWING,
    id,
})

export const receiveNode = (node) => ({
    type: RECEIVE_NODE,
    payload: {
        node,
    },
})

export const receiveNodes = (nodes) => ({
    type: RECEIVE_NODES,
    payload: {
        nodes,
    },
})

export const setNodeWatching = (id, watching) => ({
    type: SET_NODE_WATCHING,
    id, watching,
})

export const incrementNodeCommentCount = (uri) => ({
    type: INCREMENT_NODE_COMMENT_COUNT,
    payload: {
        uri,
    },
})

export const updateNode = (uri, node) => (dispatch) =>
    axios.put(`/node/${uri}`, node).then(({data}) => {
        dispatch(receiveNode(data))

        return data
    })

const getNodeLayoutForPost = (getState, node) => {
    const layout = {}
    const { maps, nodes } = getState()

    if (node.parents.length) {
        layout.child_of = node.parents[0]
    }

    const childNode = Object.values(getState().nodes).find((item) => item.parents.includes(node.id))
    if (childNode) {
        layout.parent_of = childNode.id
    }

    const row = maps[node.map_id].rows.find(({nodes}) => nodes.includes(node.id))
    if (row.nodes.length > 1) {
        layout.sibling_of = row.nodes[row.nodes.length - 2]
    }

    return layout
}

const postNode = (dispatch, getState, node) => {
    const layout = getNodeLayoutForPost(getState, node)

    axios.post('/node', {...node, layout}).then((response) => dispatch(receiveNode({
        ...response.data,
        busy: false,
        exists: true,
    }))).catch((error) => {
        dispatch(receiveNode({
            busy: false,
            id: node.id,
        }))

        throw error
    })
}

const putNode = (dispatch, uri, node) => {
    axios.put(`/node/${uri}`, node).then(({data}) => {
        dispatch(receiveNode({
            ...data,
            busy: false,
            exists: true,
        }))

        return data
    }).catch((error) => {
        dispatch(receiveNode({
            busy: false,
            id: node.id,
        }))

        throw error
    })
}

export const saveNode = (id, node) => (dispatch, getState) => {
    const storedNode = getState().nodes[id]

    if (! storedNode.exists) {
        postNode(dispatch, getState, {
            ...storedNode,
            ...node,
        })
    } else {
        putNode(dispatch, storedNode.uri, node)
    }

    dispatch(receiveNode({
        ...signNode({
            ...storedNode,
            ...node
        }),
        busy: true,
        editing: false,
    }))
}

export const fetchNode = (uri) => (dispatch, getState) =>
    axios.get(`/node/${uri}`).then(({data}) => {
        dispatch(receiveNode(data))

        return data
    })

export const searchNodes = (term, exclude = null) => (dispatch, getState) =>
    axios.get(`/node?term=${term}${exclude ? `&exclude=${exclude}` : ''}`).then(({data}) => {
        if (data.data.length) {
            dispatch(receiveNodes(data.data))
        }

        return data
    })

export const fetchNodesForMap = (uri, page = 1, limit = 10, sort = 'created_at', sort_direction = 'desc', context = {}, cancelToken = null) =>
    (dispatch, getState) =>
        axios.get(`/map/${uri}/node`, {
            params: {
                ...mapContextForRequest(context),
                limit, page, sort, sort_direction,
            },
            cancelToken: cancelToken ? cancelToken.token : null,
        }).then(({data}) => {
            dispatch(receiveNodes(data.data))

            return data
        })

export const fetchNodeFeed = (uri) =>
    (dispatch, getState) =>
        axios.get(`/node/${uri}/feed`).then(({data}) => {
            dispatch(receiveComments(
                data.data.filter(({type}) => type === 'comment').map(({type, ...rest}) => rest)
            ))

            dispatch(receiveResources(
                data.data.filter(({type}) => type === 'resource').map(({type, ...rest}) => rest)
            ))

            return data
        })

export const nodesForMap = (nodes, map_id) => Object.values(nodes).filter(node => node.map_id === map_id)
export const nodeFromId = (nodes, id) => nodes.find((node) => node.id === id)
export const nodeFromUri = (nodes, uri) => Object.values(nodes).find((node) => node.uri === uri) || {
    name: '',
}

const signNode = (node) => ({...node, signature: hash(`${node.name}${node.order}`)})

const mapContextForRequest = (context) => Object.keys(context).filter((item) => ['labels', 'scopes', 'users'].includes(item)).reduce((carry, key, index) => {
    if (Array.isArray(context[key])) {
        context[key].forEach((item, index) => {
            carry[`context[${key}][${index}]`] = item
        })
    }

    return carry
}, {})

export const attachLabelToNode = (uri, labelId) =>
    (dispatch, getState) => {
        const node = nodeFromUri(getState().nodes, uri)

        dispatch(receiveNode({
            id: node.id,
            labels_index: [...new Set([...node.labels.map(({id}) => id), labelId])],
        }))

        return axios.put(`/node/${uri}`, {action: 'label.attach', payload: {label_id: labelId}})
    }

export const detachLabelFromNode = (uri, labelId) =>
    (dispatch, getState) => {
        const node = nodeFromUri(getState().nodes, uri)

        dispatch(receiveNode({
            id: node.id,
            labels_index: node.labels_index.filter((id) => id !== labelId),
        }))

        return axios.put(`/node/${uri}`, {action: 'label.detach', payload: {label_id: labelId}})
    }

export const userOwnsNode = (node, nodes, userId, depth, level = 0, skipFirst = false) => {
    if (Array.isArray(node.assigned)) {
        if (node.assigned.includes(userId)) {
            return true
        }

        if (! skipFirst || level > 0) {
            return false
        }
    }

    if (typeof depth === 'number' && ++level >= depth) {
        return false
    }

    for (let i = 0; i < node.parents.length; i++) {
        if (nodes[node.parents[i]] && userOwnsNode(nodes[node.parents[i]], nodes, userId, depth, level)) {
            return true
        }
    }

    return false
}
