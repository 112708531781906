import Formsy from 'formsy-react'
import React, { memo, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import Chip from './Chip'
import Container from '../Shared/Container'
import Fields from './Fields'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import Modal from '../Shared/Modal'
import PageLoadingIndicator from '../PageLoadingIndicator'
import { fetchLabel, updateLabel } from '../../actions/label'
import { historyShape, labelMatchShape } from '../../constants/types'

const Edit = memo(({history, match}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const label = useSelector(({labels}) => labels[match.params.label])

    const [ hasChanged, setHasChanged ] = useState(false)
    const [ isBusy, setIsBusy ] = useState(false)
    const [ isReady, setIsReady ] = useState(Boolean(label))
    const [ isValid, setIsValid ] = useState(false)

    const canSubmit = Boolean(hasChanged && isValid)

    useEffect(() => {
        if (label) {
            return
        }

        dispatch(fetchLabel(match.params.map, match.params.label)).then(() => setIsReady(true)).catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 404)) {
                return history.push(`/m/${match.params.map}`)
            }

            throw new Error(error)
        })
    }, [])

    const _handleSubmit = (formData, resetForm, invalidateForm) => {
        setIsBusy(true)

        dispatch(updateLabel(match.params.map, match.params.label, formData)).then(history.goBack).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setIsBusy(false))
    }

    const _handleChange = (formData, hasChanged) => setHasChanged(hasChanged)
    const _handleInvalid = () => setIsValid(false)
    const _handleValid = () => setIsValid(true)

    return (
        <Modal>
            <Container modal>
                {isReady ? (
                    <React.Fragment>
                        <Heading>{label.name}</Heading>
                        <Formsy onChange={_handleChange} onInvalid={_handleInvalid} onValid={_handleValid} onValidSubmit={_handleSubmit}>
                            <Fields label={label} />
                            <LoadingButton busy={isBusy} containerClassName={classes.button} color="primary" disabled={! canSubmit} type="submit" variant="contained" fullWidth>
                                Update
                            </LoadingButton>
                        </Formsy>
                    </React.Fragment>
                ) : (
                    <PageLoadingIndicator />
                )}
            </Container>
        </Modal>
    )
})

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4),
    },
}))

Edit.propTypes = {
    history: historyShape.isRequired,
    match: labelMatchShape.isRequired,
}

export default Edit
