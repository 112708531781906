import { REHYDRATE } from 'redux-persist'

import { RECEIVE_TEAM, RECEIVE_TEAMS, REMOVE_TEAM } from '../actions/team'

const initialState = {
    client_uri: null,
    created_by: null,
    id: null,
    name: '',
    uri: null,
}

const teamReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_TEAM]: () => ({
            ...state,
            ...Object.keys(action.payload.team).filter((item) => item !== 'users').reduce((carry, item) => ({
                ...carry,
                [item]: action.payload.team[item],
            }), {}),
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const teamsReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_TEAM]: () => ({
            ...state,
            [action.payload.team.id]: teamReducer(state[action.payload.team.id], action),
        }),
        [RECEIVE_TEAMS]: () => action.payload.teams.reduce((carry, team) => ({
            ...carry,
            [team.id]: teamReducer(state[team.id], {
                type: RECEIVE_TEAM,
                payload: {
                    team,
                }
            }),
        }), {}),
        [REMOVE_TEAM]: () => {
            const nextState = Object.assign({}, state)
            delete nextState[action.payload.id]

            return nextState
        },
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default teamsReducer
