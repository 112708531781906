import Formsy from 'formsy-react'
import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import Container from '../Shared/Container'
import FormsyHidden from '../formsy/FormsyHidden'
import FormsyText from '../formsy/FormsyText'
import Heading from '../Shared/Heading'
import LoadingButton from '../LoadingButton'
import SplashImage from '../../assets/images/splash.jpg'
import { alert } from '../../actions/ui'
import { handleReset } from '../../actions/auth'

const Reset = memo(({history, location, match}) => {
    const [ busy, setBusy ] = useState(false)
    const [ disabled, setDisabled ] = useState(true)

    const dispatch = useDispatch()

    const classes = useStyles()

    const handleInvalid = () => setDisabled(true)
    const handleValid = () => setDisabled(false)

    const handleSubmit = (formData, resetForm, invalidateForm) => {
        setBusy(true)

        handleReset(formData).then((response) => dispatch(alert(null, response.message, () => history.push('/auth/login')))).catch((error) => {
            if (error.response && error.response.status === 422) {
                return invalidateForm(error.response.data.errors)
            }

            throw new Error(error)
        }).finally(() => setBusy(false))
    }

    return (
        <div className={classes.wrapper}>
            <Container className={classes.container} elevation={9} modal>
                <Heading>Reset Password</Heading>
                <Formsy onInvalid={handleInvalid} onValid={handleValid} onValidSubmit={handleSubmit}>
                    <fieldset>
                        <FormsyText
                            defaultValue={new URLSearchParams(location.search).get('email')}
                            label="E-mail address"
                            name="email"
                            type="email"
                            validations="isEmail"
                            validationError="Check the e-mail address is valid."
                            fullWidth required />
                    </fieldset>
                    <fieldset>
                        <FormsyText
                            autoComplete="new-password"
                            label="Your new password"
                            name="password"
                            type="password"
                            validations={{matchRegexp: /^(?=.*[^0-9a-zA-Z])(?=.*[0-9])(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z]).{8,}$/}}
                            validationError="The password must be at least 8 characters, containing at least 1 number and 1 special character."
                            autoFocus fullWidth required />
                    </fieldset>
                    <fieldset>
                        <FormsyText
                            autoComplete="new-password"
                            label="Confirm your new password"
                            name="password_confirmation"
                            type="password"
                            validations="equalsField:password"
                            validationError="The passwords do not match."
                            fullWidth required />
                    </fieldset>
                    <FormsyHidden defaultValue={match.params.token} name="token" />
                    <div className={classes.buttonsWrapper}>
                        <LoadingButton busy={busy} color="primary" disabled={disabled} type="submit" variant="contained">Reset password</LoadingButton>
                        <Link className={classes.forgotLink} to="/auth/login">Return to login</Link>
                    </div>
                </Formsy>
            </Container>
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    wrapper: {
        alignItems: 'center',
        backgroundImage: `url(${SplashImage})`,
        backgroundSize: 'cover',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    container: {
        backgroundColor: 'rgba(255, 255, 255, .95)',
    },
    buttonsWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(3),
    },
    forgotLink: {
        color: theme.palette.text.secondary,
        fontWeight: '300',
        marginTop: theme.spacing(5),
    },
}))

export default Reset
