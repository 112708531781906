import React, { memo } from 'react'
import { useDrop } from 'react-dnd'

const StageDropable = memo(({children, ...rest}) => {
    const [ dropProps, drop ] = useDrop({
        accept: 'goal',
        drop: (item, monitor) => ({
            offset: monitor.getSourceClientOffset(),
        }),
    })

    return (
        <div ref={drop} {...rest}>
            {children}
        </div>
    )
})

export default StageDropable
