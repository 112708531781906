import { REHYDRATE } from 'redux-persist'

import { RECEIVE_LABEL, RECEIVE_LABELS, REMOVE_LABEL } from '../actions/label'
import { RECEIVE_NODES, RECEIVE_NODE } from '../actions/node'

const initialState = {
    active: false,
    hex: null,
    id: null,
    map_uri: null,
    name: null,
}

const labelReducer = (state = initialState, action) => {
    const actions = {
        [RECEIVE_LABEL]: () => ({
            ...state,
            ...action.payload.label,
        }),
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

const labelsReducer = (state = {}, action) => {
    const actions = {
        [REHYDRATE]: () => ({}),
        [RECEIVE_LABELS]: () => ({
            ...state,
            ...action.payload.labels.reduce((carry, label) => ({
                ...carry,
                [label.id]: labelReducer(state[label.id], {
                    type: RECEIVE_LABEL,
                    payload: {
                        label,
                    }
                }),
            }), {}),
        }),
        [RECEIVE_LABEL]: () => ({
            ...state,
            [action.payload.label.id]: labelReducer(state[action.payload.label.id], action),
        }),
        [RECEIVE_NODES]: () => ({
            ...state,
            ...action.payload.nodes.reduce((carry, {labels}) => ({
                ...carry,
                ...labels.reduce((carry, label) => ({
                    ...carry,
                    [label.id]: labelReducer(state[label.id], {
                        type: RECEIVE_LABEL,
                        payload: {
                            label,
                        },
                    }),
                }), {}),
            }), {}),
        }),
        [RECEIVE_NODE]: () => ({
            ...state,
            ...action.payload.node.labels.reduce((carry, label) => ({
                ...carry,
                [label.id]: labelReducer(state[label.id], {
                    type: RECEIVE_LABEL,
                    payload: {
                        label,
                    },
                }),
            }), {}),
        }),
        [REMOVE_LABEL]: () => {
            const nextState = Object.assign({}, state)
            delete nextState[action.payload.id]

            return nextState
        },
    }

    return typeof actions[action.type] === 'function' ? actions[action.type]() : state
}

export default labelsReducer
